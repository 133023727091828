// import "./FontSizeDropdown.css"
import { HDropDown, HDropTitle, HDropItem } from "../HorzDropdown/HorzDropdown"
import { Placement } from "@popperjs/core"

export const LINE_SPACING_SIZES = [1.0, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.8, 2.0, 2.5]

export const LineSpacingDropdownItem = ({ sizeFloat, setFormat, currentValue }) => {
	const size = sizeFloat.toFixed(1)
	return (
		<div
			data-testid={"font-size-" + size}
			className={`clickable toolbar-dropdown-item numerical-dropdown-option ${size === currentValue ? "selected-numerical-dropdown-option" : ""}`}
			key={`line-spacing-${size}`}
			onClick={(e) => {
				setFormat(size)
			}}>
			{size}
		</div>
	)
}

export const LineSpacingDropdownItems = ({
	testid,
	currentValue,
	setFormat,
}: {
	testid: string
	currentValue: string
	setFormat: (format: string) => void
}) => {
	return (
		<div data-testid={testid} className="numerical-dropdown-options vertical-dropdown numeric-dropdown">
			{LINE_SPACING_SIZES.map((sizeFloat) => (
				<LineSpacingDropdownItem
					key={`line-spacing-${sizeFloat}`}
					sizeFloat={sizeFloat}
					setFormat={setFormat}
					currentValue={currentValue}
				/>
			))}
		</div>
	)
}

export const LineSpacingDropdown = ({
	testid,
	currentValue,
	setFormat,
	placement = "bottom",
}: {
	testid: string
	currentValue: string
	setFormat: (format: string) => void
	placement: Placement
}) => {
	return (
		<HDropDown placement={placement} closeOnClick={false} style={{ width: "fit-content" }}>
			<HDropTitle style={{ width: 40, margin: 0, border: "1px solid #aaaaaa", borderRadius: 3, textAlign: "center" }}>
				<div title="set line spacing" className={`clickable`}>
					{currentValue}
				</div>
			</HDropTitle>
			<HDropItem
				onClick={(e) => {
					e.stopPropagation()
					e.preventDefault()
				}}>
				<LineSpacingDropdownItems testid={testid} currentValue={currentValue} setFormat={setFormat} />
			</HDropItem>
		</HDropDown>
	)
}
