import { forwardRef, Children, cloneElement, useState, useCallback, useMemo, ReactNode, ReactElement } from "react"
import { usePopper } from "react-popper"
import { createPortal } from "react-dom"
import "./HorzDropdown.css"
import { useHover } from "../HoverDropdown/HoverDropdown"
import { Placement } from "@popperjs/core"

type HDropContent = ReactElement<React.HTMLAttributes<HTMLDivElement>>

export const HDropItem = ({ onClick, children, ...props }: React.HTMLAttributes<HTMLDivElement>): HDropContent => {
	const onClickInt = useCallback(
		(e) => {
			e.preventDefault()
			e.stopPropagation()
			onClick?.(e)
		},
		[onClick]
	)
	return (
		<div className="h-drop-item" onMouseDown={onClickInt} {...props}>
			{children}
		</div>
	)
}

export const HDropTitle = ({ children, className, ...props }: React.HTMLAttributes<HTMLDivElement>): HDropContent => {
	return (
		<div className={"h-drop-title " + (className ?? "")} {...props}>
			{children}
		</div>
	)
}

interface HDropDownProps extends React.HTMLAttributes<HTMLDivElement> {
	elementForPortal?: HTMLElement
	children: ReactNode
	blurOnShow?: boolean
	placement?: Placement
	closeOnClick?: boolean
	preventMouseDownDefault?: boolean
	ariaLabel?: string
}

export const HDropDown = forwardRef<HTMLDivElement, HDropDownProps>(
	(
		{
			elementForPortal,
			className,
			children,
			blurOnShow = false,
			placement = "bottom",
			closeOnClick = true,
			preventMouseDownDefault = true,
			ariaLabel,
			...props
		},
		ref
	) => {
		const [title, ...items] = Children.toArray(children) as ReactElement[]
		// const [show, setShow] = useState(false)
		const [titleElement, setTitleElement] = useState(null)
		const [dropElement, setDropElement] = useState(null)
		let { onMouseLeave, onMouseEnter, onTouch, afterSelect, show } = useHover({ blurOnShow, baseTimeout: 100 })

		const { styles, attributes, update } = usePopper(titleElement, dropElement, {
			placement,
			strategy: "absolute",
			modifiers: [
				{
					name: "preventOverflow",
					options: {
						mainAxis: true,
					},
				},
			],
		}) //, modifiers: [{name: 'eventListeners', enabled: false, options: {scroll: false, resize: false}}] })
		useMemo(() => {
			if (show) {
				update()
			}
		}, [show, update])

		return (
			<div
				aria-label={ariaLabel}
				role="button"
				onMouseDown={(e) => {
					if (preventMouseDownDefault) {
						e.preventDefault()
					}
				}}
				ref={ref}
				onMouseLeave={onMouseLeave}
				onClick={onMouseEnter}
				onMouseEnter={onMouseEnter}
				style={{ ...props.style }}>
				<div style={{ width: "fit-content", margin: "auto" }} ref={setTitleElement} onTouchStart={onTouch}>
					{title}
				</div>
				{createPortal(
					<div
						ref={setDropElement}
						className={`${className} h-drop-popper`}
						style={{ display: show ? "block" : "none", zIndex: 20, ...styles.popper }}
						{...attributes.popper}>
						{items.map((item) =>
							cloneElement(item, {
								onMouseUp: () => {
									if (closeOnClick) {
										afterSelect()
									}
								},
								style: { ...item.props.style, zIndex: 20 },
							})
						)}
					</div>,
					elementForPortal ?? document.body
				)}
			</div>
		)
	}
)
