import { AraTooltip } from "../AraTooltip/AraTooltip"
import { MouseEventHandler, ReactNode } from "react"

type ButtonVariant = "outline" | "primary"

export function AIDisplayButton({
	variant = "outline",
	onClick,
	disabled = false,
	show = true,
	tooltipLabel,
	children,
}: {
	variant?: ButtonVariant
	onClick: MouseEventHandler<HTMLButtonElement>
	disabled?: boolean
	show?: boolean
	tooltipLabel?: ReactNode
	children: ReactNode
}) {
	return show ? (
		<AraTooltip tooltipLabel={tooltipLabel} timeToShow={50} timeToHide={50}>
			<button disabled={disabled} className={`text-transform-button-${variant}`} type="button" onClick={onClick}>
				{children}
			</button>
		</AraTooltip>
	) : (
		<></>
	)
}
