import { setLeafUnchanged, setWholeTextSelection, markExistsInText } from "../Utils"
import "../ColourFormatting.css"
import { Editor } from "slate"
import { CustomPlugin } from "../types/slate.types"
import { setColorLeafProps } from "./ColourUtils"
import { colorOptions } from "./ColourOptions"

export const withColor: CustomPlugin = (editor) => {
	const { setLeafProps, customMarkOptions, resetProperties } = editor

	editor.customMarkOptions = {
		...(customMarkOptions ?? {}),
		...colorOptions,
	}

	editor.resetProperties = ({ resetList }) => {
		if (resetList.includes("color") && markExistsInText(editor, "color")) {
			// reset property to null in all elements
			setWholeTextSelection(editor)
			Editor.withoutNormalizing(editor, () => {
				Editor.removeMark(editor, "color")
			})
		}
		resetProperties?.({ resetList })
	}

	editor.setLeafProps = (props) => {
		const setPrevLeafProps = setLeafProps ?? setLeafUnchanged
		return setColorLeafProps(setPrevLeafProps(props))
	}

	return editor
}
