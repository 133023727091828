import { useAnonCVContext } from "components/src/CVViewing/CVContexts/AnonCVContext"
import { useHandler } from "libs"
import { useEffect, useState, useMemo, useRef } from "react"
import { createPortal } from "react-dom"
import { Range, Transforms, Editor } from "slate"
import { useSlate } from "slate-react"
import { AraTooltip } from "../../../AraTooltip/AraTooltip"
import { HyperlinkElement, SlateElementRenderProps } from "../../types/slate.types"
import { useEditorContext, initialValueString, isCurrentlySelected, onlyThisElementSelected } from "../../Utils"
import { HyperlinkBox } from "./HyperlinkBox"
import { unwrapSpecificLink, checkCorrectUrl } from "../HyperlinkUtils"
import { LinkComponent } from "./LinkComponent"

function useUpdatingRef<T>(value: T): React.RefObject<T> {
	const ref = useRef(value)
	ref.current = value
	return ref
}

export const HyperLink = (props: SlateElementRenderProps<HyperlinkElement>) => {
	const [plainTextUrl, setPlainTextUrlBase] = useState<string>(props.element.plainTextUrl ?? "")
	const [url, setUrlBase] = useHandler(props.element.url ?? "")
	const editor = useSlate()
	const { editing, idRef } = useEditorContext()
	const { editable, templateMode } = useAnonCVContext()
	const [hide, setHide] = useState<boolean>(false)

	const selected = useMemo(
		() =>
			editor.selection == null
				? false
				: Range.isCollapsed(editor.selection)
					? isCurrentlySelected(editor, props.element)
					: onlyThisElementSelected(editor, props.element),
		[editor, editor.selection, props.element]
	)

	const selectedRef = useUpdatingRef<boolean>(selected)
	const elementRef = useUpdatingRef<HyperlinkElement>(props.element)

	useEffect(() => {
		// on every selection change check if selection is outside hyperlink
		if (!selectedRef.current && urlState === "empty") {
			console.log("removing hyperlink")
			// selection is outside hyperlink and hyperlink is empty
			unwrapSpecificLink(editor, elementRef.current)
		}
	}, [editor.selection])

	const [ref, setRef] = useState<HTMLElement>(null)

	const setPlainTextUrl = (url: string) => {
		setPlainTextUrlBase(url)
		Transforms.setNodes(editor, { plainTextUrl: url }, { match: (n) => n === props.element, at: [] })
	}

	useEffect(() => {
		const unhide = (e) => {
			// unhide as soon as user continues interacting
			if (e?.key !== "Enter") {
				setHide(false)
			}
		}
		document.addEventListener("mousedown", unhide)
		document.addEventListener("keydown", unhide)
		return () => {
			document.removeEventListener("mousedown", unhide)
			document.removeEventListener("keydown", unhide)
		}
	}, [])

	const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key === "Enter") {
			document.getElementById(idRef.current)?.focus()
			const selection = Editor.after(editor, Editor.next(editor)[1])
			Transforms.setSelection(editor, { anchor: selection, focus: selection })
			setHide(true)
		}
	}

	const setUrl = (event) => {
		setUrlBase(event)
		const url = event.target.value
		Transforms.setNodes(editor, { url }, { match: (n) => n === props.element })
	}

	const urlState = getUrlState(url, templateMode)
	const urlBoxIsActive = ref != null && ref.contains(document.activeElement)
	const shouldShowHyperlinkBox = editing && !hide && (urlBoxIsActive || selected)

	const tooltipHidden = !editable || editing || urlState === "empty"
	// console.log(!editable, editing, (urlState !== "invalid"))
	const tooltipLabel = urlState === "valid" ? plainTextUrl : "url invalid"

	const getDisplayState = () => {
		if (!editable && !templateMode) {
			return urlState === "valid" ? "asUrl" : "asText"
		}
		if (urlState === "invalid") {
			return "asInvalid"
		}
		if (!editing) {
			switch (urlState) {
				case "empty":
					return "asText"
				default:
					return "asUrl"
			}
		}
		return "asUrl"
	}

	const displayState = getDisplayState()

	return shouldShowHyperlinkBox ? (
		<span ref={setRef} onKeyDown={onKeyDown}>
			<LinkComponent
				attributes={props.attributes}
				element={props.element}
				plainTextUrl={plainTextUrl}
				state={displayState}
				{...props}
			/>
			<HyperlinkBox
				text={props.element.children}
				setPlainTextUrl={setPlainTextUrl}
				url={url}
				setUrl={setUrl}
				plainTextUrl={plainTextUrl}
			/>
		</span>
	) : (
		<>
			<AraTooltip tooltipLabel={tooltipLabel} placement="bottom" hide={tooltipHidden}>
				<LinkComponent plainTextUrl={plainTextUrl} state={displayState} {...props} />
			</AraTooltip>
			{createPortal(
				// hidden version of the url box, so that the embedded textformatter is rendered to make sure tags are set
				// when we don't need to edit while maintaining focus this may as well be in a portal to get it out of the way
				// pointer events turned off and opacity set to zero while hidden
				<HyperlinkBox
					ref={setRef}
					text={props.element.children}
					setPlainTextUrl={setPlainTextUrl}
					url={url}
					hidden={true}
					setUrl={setUrl}
					plainTextUrl={plainTextUrl}
				/>,
				document.body
			)}
		</>
	)
}

const getUrlState = (url: string, templateMode: boolean): "valid" | "invalid" | "empty" => {
	if (!url || url === initialValueString("")) return "empty"
	if (templateMode && url.includes('"type":"tagging"')) return "valid"
	return checkCorrectUrl(url) ? "valid" : "invalid"
}

export const renderHyperlinkElement = (props: SlateElementRenderProps): JSX.Element | null => {
	if (props.element.type === "hyperlink") {
		return <HyperLink {...(props as SlateElementRenderProps<HyperlinkElement>)} />
	} else {
		return null
	}
}
