import { useState, useRef, useCallback } from "react"
import { Path, Point, Range, Editor, Transforms, Text, Element as SlateElement, NodeEntry } from "slate"
import { isAtEnd, slateTextIsEmpty, isBlockActive } from "../Utils"
import "./TableFormatting.css"
import {
	TableRowElement,
	CustomEditor,
	TableCellElement,
	TableParagraphElement,
	CustomElement,
	ParagraphElement,
	ElementType,
} from "../types/slate.types"

// const TABLE_TYPES = ['table', 'table-row', 'table-cell']

export const MIN_CELL_WIDTH = 40
export const DEFAULT_MAXTABLE_WIDTH = 715

export const tableCellContent = (): TableParagraphElement[] => [
	{
		type: "table-paragraph",
		children: [
			{
				type: "paragraph",
				children: [{ text: "" }],
			},
		],
	},
]

const makeTableColData = (colNum: number): Partial<TableCellElement> => {
	return {
		type: "table-cell",
		col: colNum,
		children: tableCellContent(),
	}
}

export function addColToTable(editor: CustomEditor, path, newTotalCols, colNum) {
	for (var [, rowPath] of Editor.nodes(editor, {
		at: path,
		match: (n) => SlateElement.isElement(n) && n.type === "table-row",
	})) {
		addColToRow(editor, rowPath, newTotalCols, colNum)
	}
}

export function removeColFromTable(editor: CustomEditor, path, newTotalCols, colNum) {
	for (var [, rowPath] of Editor.nodes(editor, {
		at: path,
		match: (n) => SlateElement.isElement(n) && n.type === "table-row",
	})) {
		removeColFromRow(editor, rowPath, newTotalCols, colNum)
	}
}

function removeColFromRow(editor: CustomEditor, path, newTotalCols, colNum) {
	colNum = colNum ?? newTotalCols
	Transforms.removeNodes(editor, { at: [...path, colNum] })
	//reset col numbers after
	for (var col = colNum; col < newTotalCols; col++) {
		Transforms.setNodes(editor, { col }, { at: [...path, col] })
	}
}

function addColToRow(editor: CustomEditor, path: Path, newTotalCols: number, colNum: number) {
	colNum = colNum ?? newTotalCols - 1
	Transforms.insertNodes(editor, makeTableColData(colNum) as TableCellElement, { at: [...path, colNum] })
	//reset col numbers after
	for (var col = colNum + 1; col < newTotalCols; col++) {
		Transforms.setNodes(editor, { col }, { at: [...path, col] })
	}
}

const makeTableRowData = (rowNo: number, numCols: number): TableRowElement => {
	console.log("making row")
	const children = []
	for (var colNum = 0; colNum < numCols; colNum++) {
		children.push(makeTableColData(colNum))
	}
	return {
		type: "table-row",
		row: rowNo,
		children: children,
	}
}

export function addRowToTable(editor: CustomEditor, path: Path, numCols: number, newTotalRows: number, rowNum: number) {
	rowNum = rowNum ?? newTotalRows - 1
	Transforms.insertNodes(editor, makeTableRowData(rowNum, numCols), { at: [...path, rowNum] })
	//reset row numbers after
	for (var row = rowNum + 1; row < newTotalRows; row++) {
		Transforms.setNodes(editor, { row }, { at: [...path, row] })
	}
}

export function removeRowFromTable(
	editor: CustomEditor,
	path: Path,
	numCols: number,
	newTotalRows: number,
	rowNum: number
) {
	rowNum = rowNum ?? newTotalRows
	Transforms.removeNodes(editor, { at: [...path, rowNum] })
	//reset row numbers after
	for (var row = rowNum; row < newTotalRows; row++) {
		Transforms.setNodes(editor, { row }, { at: [...path, row] })
	}
}

const initialTableChildren = (numRows = 2, numCols = 2) => {
	const children = []
	for (var rowNum = 0; rowNum < numRows; rowNum++) {
		children.push(makeTableRowData(rowNum, numCols))
	}
	return children
}

export function useStateRef<T>(initVal: T, callback?: () => void): [T, (val: T) => void, React.MutableRefObject<T>] {
	const [state, setState] = useState(initVal)
	const ref = useRef<T>(state)

	const setStateAndRef = useCallback(
		(val: T) => {
			setState(val)
			ref.current = val
			callback?.()
		},
		[callback]
	)

	return [state, setStateAndRef, ref]
}

export const toggleTable = (editor: CustomEditor, format: ElementType) => {
	if (format !== "table") {
		return null
	}
	// Three situations: no table exists, a table exists and is active, a table exists and isn't active
	// const existingBlock = doesBlockExist(editor, format)
	const isActive = isBlockActive(editor, format)
	// if (existingBlock && !isActive) {
	//     //Section already has table, but not selected, do nothing
	//     return
	// }
	//const wholeText = getWholeText(editor)

	if (isActive) {
		// table exists, and is selected, convert to paragraphs
		const [, tablePath] = [
			...Editor.nodes(editor, { match: (n) => SlateElement.isElement(n) && n.type === "table" }),
		]?.[0]

		const tableText = [
			...(Editor.nodes(editor, {
				at: tablePath,
				mode: "highest",
				match: (n) =>
					SlateElement.isElement(n) &&
					n.type === "table-paragraph" &&
					!Text.isText(n) &&
					!slateTextIsEmpty(n.children, false),
			}) as Generator<NodeEntry<TableParagraphElement>>),
		].reduce((acc, el) => [...acc, ...el[0].children], [])
		const shouldAddEmptyElement = tablePath?.length === 1 && tablePath[0] === 0 && editor.children?.length === 1
		// // if true, table is at first position and is only element, need to avoid adding nothing back to editor
		const textToInsert =
			tableText?.length === 0 && shouldAddEmptyElement
				? [
						{
							type: "paragraph",
							children: [{ text: "" }],
						},
					]
				: tableText

		Editor.withoutNormalizing(editor, () => {
			// remove and insert instead of unwrapping to avoid adding empty paragraphs from empty table cells
			Transforms.removeNodes(editor, {
				at: tablePath,
			})

			Transforms.insertNodes(editor, textToInsert, { at: tablePath })
		})

		// if (shouldAddEmptyElement && tableText?.length >= 1) {
		//     // remove added empty table cell content if table had other content
		//     console.log('removing blank element')
		//     Transforms.removeNodes(editor, {
		//         at: [editor.children?.length - 1],
		//         mode: 'all',
		//         split: false,
		//     })
		// }
		if (isAtEnd(editor) && editor.children.length > 1) {
			console.log("at end with more than one element")
			Transforms.removeNodes(editor, {
				match: (n) => SlateElement.isElement(n) && n.type === "paragraph" && slateTextIsEmpty([n as ParagraphElement]),
			})
		}
	} else {
		// Table doesn't exist insert after selection
		console.log("will insert table")
		const shape: [number, number] = [2, 2]
		const tableFragment: CustomElement[] = [
			{
				type: "table",
				shape,
				children: initialTableChildren(...shape),
			},
		]
		Transforms.collapse(editor, { edge: "end" })
		const endOfBlock = Editor.end(editor, editor.selection.anchor.path)
		Transforms.select(editor, {
			anchor: endOfBlock,
			focus: endOfBlock,
		})
		if (isAtEnd(editor)) {
			tableFragment.push({ type: "paragraph", children: [{ text: "" }] })
		}
		const tablePath = [(editor.selection?.anchor?.path?.[0] ?? 0) + 1]
		Transforms.insertNodes(editor, tableFragment, {
			at: tablePath,
		})
		console.log(editor.selection)
		Transforms.setSelection(editor, { anchor: { path: [...tablePath], offset: 0 } })
		console.log(editor.selection)
		//Add blank paragraph after
	}
	return true
}

export function isAtTableCellStart(editor) {
	const { selection } = editor

	if (selection && Range.isCollapsed(selection)) {
		const [cell] = Editor.nodes(editor, {
			match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "table-cell",
		})

		if (cell) {
			const [, cellPath] = cell
			const start = Editor.start(editor, cellPath)

			return Point.equals(selection.anchor, start)
		}
	}
	return false
}

export function isAtTableCellEnd(editor: CustomEditor) {
	const { selection } = editor

	if (selection && Range.isCollapsed(selection)) {
		const [cell] = Editor.nodes(editor, {
			match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "table-cell",
		})

		if (cell) {
			const [, cellPath] = cell
			const end = Editor.end(editor, cellPath)

			return Point.equals(selection.anchor, end)
		}
	}
	return false
}

export function minTableWidthForCols(colWidths, shape) {
	let minWidth = 0
	for (var i = 0; i < shape[1]; i++) {
		minWidth += colWidths[i] ?? MIN_CELL_WIDTH
	}
	return minWidth
}

export function reduceLastPossibleColumn(
	widthToRemove: number,
	lastColNo: number,
	colWidths: number[],
	setColWidths: (newColWidths: number[]) => void
) {
	var remainingWidthToRemove = widthToRemove
	var colToCheck = lastColNo
	const newColWidths = [...colWidths]
	while (remainingWidthToRemove > 0 && colToCheck >= 0) {
		const availableToRemove = colWidths[colToCheck] - MIN_CELL_WIDTH
		if (availableToRemove > 0) {
			const toBeRemoved = Math.min(availableToRemove, MIN_CELL_WIDTH)
			newColWidths[colToCheck] = newColWidths[colToCheck] - toBeRemoved
			remainingWidthToRemove -= toBeRemoved
		}
		colToCheck--
	}
	setColWidths(newColWidths)
	return remainingWidthToRemove
}

/**
 * Fix the columns widths to the left of the colNo
 * @param colWidths
 * @param setColWidths
 * @param shapeRef
 * @param tableRef
 * @param colNo
 * @returns
 */
export function fixSizesToLeft(
	colWidths: number[],
	setColWidths: (arg0: number[]) => void,
	shapeRef: React.MutableRefObject<number[]>,
	tableRef: React.MutableRefObject<HTMLDivElement>,
	colNo: number
) {
	const info = colWidths.reduce(
		(acc, width) => {
			if (typeof width === "number") {
				acc.fixedWidth = acc.fixedWidth + width
				acc.unfixedCols = acc.unfixedCols - 1
			}
			return acc
		},
		{ fixedWidth: 0, unfixedCols: shapeRef.current[1] }
	)
	if (info.unfixedCols === 0) {
		//all cols fixed do nothing
		return
	}
	const remainingUnfixedWidth = tableRef.current?.getBoundingClientRect().width - info.fixedWidth
	const unfixedColWidth = Math.floor(remainingUnfixedWidth / info.unfixedCols)
	const newColWidths = [...colWidths]
	for (var i = 0; i < colNo; i++) {
		if (typeof colWidths[i] !== "number") {
			console.log("setting col", i, "to", unfixedColWidth)
			newColWidths[i] = unfixedColWidth
		}
	}
	setColWidths(newColWidths)
}
