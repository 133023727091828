import { NodeEntry, Text, Transforms, Editor } from "slate"
import { CustomPlugin } from "../types/slate.types"

const WRAPPING_MARKDOWN: {
	[wrap: string]: { mark: string; regex: RegExp; startPattern?: string; endPattern?: string }
} = {
	"**": { mark: "bold", regex: new RegExp("\\*\\*[^\\*]+?\\*\\*") },
	_: { mark: "italic", regex: new RegExp("\\_[^\\_]+?\\_") },
	"<u>": { mark: "underline", regex: new RegExp("<u>.+?<u>") },
	"<\\u>": { mark: "underline", regex: new RegExp("<u>.+?<\\u>"), startPattern: "<u>", endPattern: "<\\u>" },
	"</u>": { mark: "underline", regex: new RegExp("<u>.+?</u>"), startPattern: "<u>", endPattern: "</u>" },
}

export const withMarkdown: CustomPlugin = (editor) => {
	const { normalizeNode } = editor

	editor.normalizeNode = (entry: NodeEntry) => {
		const [node, path] = entry
		if (Text.isText(node)) {
			let marks = []
			let start
			let end
			let startEnd
			let endStart
			Object.entries(WRAPPING_MARKDOWN).forEach(([wrap, { mark, regex, startPattern, endPattern }]) => {
				if (regex.test(node.text)) {
					startPattern = startPattern ?? wrap
					endPattern = endPattern ?? wrap
					const localStart = node.text.indexOf(startPattern)
					const localStartEnd = localStart + startPattern.length
					const localEndStart = node.text.indexOf(endPattern, localStart + startPattern.length)
					const localEnd = localEndStart + endPattern.length
					if (
						localStart != null &&
						localEnd != null &&
						localEnd - localStart > startPattern.length + endPattern.length
					) {
						// valid markdown found
						if (marks.length === 0) {
							// no previous markdown found
							marks = [mark]
							start = localStart
							startEnd = localStartEnd
							endStart = localEndStart
							end = localEnd
						} else if (startEnd + 1 === localStart && endStart === localEnd + 1) {
							// markdown is directly nested in previous markdown
							startEnd = localStartEnd
							endStart = localEndStart
							marks = [mark, ...marks]
						} else if (localStartEnd + 1 === start && localEndStart === end + 1) {
							// previous markdown is directly nested in this markdown
							start = localStart
							end = localEnd
							marks = [mark, ...marks]
						} else if (localStart < start && localEnd > end) {
							// markdown is surrounding previous markdown, replace previous markdown so outer markdown is applied first
							marks = [mark]
							start = localStart
							startEnd = localStartEnd
							endStart = localEndStart
							end = localEnd
						}
						// ignore this markdown, it will be applied in a later iteration of normalizeNode
					}
				}
			})
			if (marks.length !== 0) {
				const text = node.text.slice(startEnd, endStart)
				const startPoint = { path, offset: start }
				const endPoint = { path, offset: end }
				Editor.withoutNormalizing(editor, () => {
					Transforms.delete(editor, { at: { anchor: startPoint, focus: endPoint } })
					const markOptions = marks.reduce((acc, mark) => ({ ...acc, [mark]: true }), {})
					Transforms.insertNodes(editor, { ...node, ...markOptions, text }, { at: startPoint })
					return
				})
			}
		}
		normalizeNode(entry)
	}

	return editor
}
