import { forwardRef } from "react"
import { getXPos } from "libs"
import { useEditorContext } from "../../Utils"
import "../TableFormatting.css"
import { TableCellElement, SlateElementRenderProps } from "../../types/slate.types"
import { useTableContext } from "../TableContext"
import { fixSizesToLeft, MIN_CELL_WIDTH } from "../TableUtils"
import { AddCircle, MinusCircle } from "./TableCircles"

export const TableCell = forwardRef<HTMLDivElement, SlateElementRenderProps<TableCellElement>>(
	({ element, children, ...attributes }, ref) => {
		const { editing } = useEditorContext()
		const {
			shapeRef,
			activeRef,
			changeRef,
			tableRef,
			colWidths,
			setColWidths,
			addNew,
			edgeHighlighting,
			setEdgeHighlighting,
			allowAddRows,
			blockedCols,
			blockedRows,
		} = useTableContext()
		const { col: colNo, row: rowNo } = element
		const activeIdx = parseInt(activeRef.current?.getAttribute("slate-table-col")) // doesn't need adjustment for width

		function changeColorEvent(mode: "add" | "delete" | null, cell: [number | null, number | null]) {
			return () => {
				if (editing) {
					setEdgeHighlighting([mode, cell[0], cell[1]])
				}
			}
		}

		function onMouseDown(e: React.TouchEvent | React.MouseEvent) {
			if (editing) {
				console.log("mousedown")
				fixSizesToLeft(colWidths, setColWidths, shapeRef, tableRef, colNo)
				activeRef.current = (e.target as HTMLDivElement).parentElement as HTMLDivElement
				changeRef.current = getXPos(e)
			}
		}

		const rightHandMergedColNo = colNo + (element.merge?.right ?? 0) // if merged cell, this is the rightmost col, same col if not merged
		const bottomMergedRowNo = rowNo + (element.merge?.bottom ?? 0) // if merged cell, this is the bottommost row, same row if not merged

		return (
			<div
				className={`td ${
					edgeHighlighting[0] != null
						? `${
								edgeHighlighting[1] != null &&
								edgeHighlighting[1] >= element.row &&
								edgeHighlighting[1] <= (edgeHighlighting[0] === "add" ? bottomMergedRowNo : rowNo) // if adding include highlight on merged cells to avoid half marked blue line
									? `horizontal-${edgeHighlighting[0]}-highlight`
									: ""
							} ${
								edgeHighlighting[2] != null &&
								edgeHighlighting[2] >= element.col &&
								edgeHighlighting[2] <= (edgeHighlighting[0] === "add" ? rightHandMergedColNo : colNo) // if adding include highlight on merged cells to avoid half marked blue line
									? `vertical-${edgeHighlighting[0]}-highlight`
									: ""
							}`
						: ""
				}`}
				style={
					{
						...(element.removed ? { display: "none" } : {}),
						gridColumn: `c${colNo} / span c${Math.min(rightHandMergedColNo + 1, shapeRef.current[1])}`,
						gridRow: `r${rowNo} / span r${Math.min(bottomMergedRowNo + 1, shapeRef.current[0])}`,
						backgroundColor: element.backgroundColor?.all,
						"--box-shadow-color": element.borderColor?.all,
						zIndex: element.borderColor?.all == null ? 1 : 4,
					} as React.CSSProperties
				}>
				<div
					{...attributes}
					slate-table-col={colNo}
					slate-table-width={element?.merge?.right ?? 0}
					ref={ref}
					style={{ minWidth: MIN_CELL_WIDTH }}
					className={`table-cell-content`}>
					{children}
					{editing && (
						<div
							onTouchStart={onMouseDown}
							onMouseDown={onMouseDown}
							contentEditable={false}
							className={`slate-no-edit table-cell-border table-cell-border-${
								activeIdx === colNo ? "active" : "inactive"
							}`}></div>
					)}
					{editing && element.row === 0 && !blockedCols.includes(element.col) && (
						<AddCircle
							cell={[null, element.col]}
							addNew={addNew}
							changeColorEvent={changeColorEvent}
							changeDirection="col"
							style={{ position: "absolute", top: -1, right: 0, transform: "translate(50%, -100%)" }}
						/>
					)}
					{editing && allowAddRows && !blockedRows.includes(element.row) && element.col === 0 && (
						<AddCircle
							cell={[element.row, null]}
							addNew={addNew}
							changeColorEvent={changeColorEvent}
							changeDirection="row"
							style={{ position: "absolute", bottom: 0, left: -1, transform: "translate(-100%, 50%)" }}
						/>
					)}

					{editing &&
						shapeRef.current[1] !== 1 &&
						!blockedCols.includes(element.col) &&
						bottomMergedRowNo === shapeRef.current[0] - 1 && (
							<MinusCircle
								cell={[null, rightHandMergedColNo]}
								addNew={addNew}
								changeColorEvent={changeColorEvent}
								changeDirection="col"
								style={{ position: "absolute", bottom: -3.5, right: 0, transform: "translate(50%, 100%)" }}
							/>
						)}
					{editing &&
						allowAddRows &&
						!blockedRows.includes(element.row) &&
						shapeRef.current[0] !== 1 &&
						rightHandMergedColNo === shapeRef.current[1] - 1 && (
							<MinusCircle
								cell={[bottomMergedRowNo, null]}
								addNew={addNew}
								changeColorEvent={changeColorEvent}
								changeDirection="row"
								style={{ position: "absolute", bottom: 0, right: -2.5, transform: "translate(100%, 50%)" }}
							/>
						)}
				</div>
			</div>
		)
	}
)
