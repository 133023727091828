import bulletIcon from "components/images/bullet-list-line.png"
import numberIcon from "components/images/number-list-line.png"
import { ButtonType, BlockOptionSet } from "../types/slate.types"

export const bulletOptions: { [key: string]: BlockOptionSet } = {
	format_list_numbered: {
		format: "numbered-list",
		icon: () => <img className="toolbar-button-icon" src={numberIcon} alt="numbered"></img>,
		button: ButtonType.block,
		tooltipLabel: () => `Numbered List`,
	},
	format_list_bulleted: {
		format: "bulleted-list",
		icon: () => <img className="toolbar-button-icon" src={bulletIcon} alt="bulleted"></img>,
		button: ButtonType.block,
		tooltipLabel: () => `Bulleted List`,
	},
}
