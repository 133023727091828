import { useHistory, Prompt, useLocation } from "react-router-dom"
import { createContext, useContext, useEffect, useState, useMemo, ReactNode } from "react"
import { SignatureInfo } from "../signatureLib/signatureLib"
import { useQuery } from "../queryLib/queryLib"

type NavigationContextProps = {
	navigationAllowed: boolean
	push: (path: string, messageIfNoNav?: string) => void
	setWarning: (warning: string | null) => void
	signatureInfo: SignatureInfo
} & ReturnType<typeof useHistory>

const NavigationContext = createContext<NavigationContextProps>({} as NavigationContextProps)

export const useSafeHistory = () => useContext(NavigationContext)

export const NavigationContextProvider = ({
	navigationAllowed,
	setNavigationAllowed,
	signatureInfo,
	children,
}: {
	navigationAllowed: boolean
	setNavigationAllowed: (navigationAllowed: boolean) => void
	signatureInfo: SignatureInfo
	children: ReactNode
}) => {
	const history = useHistory()
	const { pathname } = useLocation()
	const query = useQuery()
	const [warning, setWarning] = useState<string>(null)

	useEffect(() => {
		// Set warning to null whenever pathname changes so that it doesn't rewarn
		setWarning(null)
	}, [pathname])

	const safeHistory = useMemo(
		() => ({
			...history,
			updateSignature: (newSig) => {
				query.set("sig", newSig)
				history.replace(`${pathname}?${query.toString()}`)
			},
			push: (path: string, messageIfNoNav: string, preventFurtherNav: boolean) => {
				if (navigationAllowed) {
					setWarning(null)
					const sig = query.get("sig")
					if (sig != null) {
						path = path + `?sig=${sig}`
					}
					if (preventFurtherNav) {
						setNavigationAllowed(false)
					}
					window?.plantrack?.track("navigated to new tab", { info: { path: path } })
					history.push(path)
				} else if (pathname.startsWith("/accessDenied")) {
					// Don't redirect accessDenied to accessDenied...
					return
				} else {
					if (messageIfNoNav) {
						window.parent.postMessage(messageIfNoNav, "*")
						window.parent.postMessage("navigation-disallowed", "*")
						window.parent.postMessage("output-finished", "*")
						history.push(`/accessDenied?message=${messageIfNoNav}`)
					} else {
						window.parent.postMessage("navigation-disallowed", "*")
						window.parent.postMessage("output-finished", "*")
						history.push("/accessDenied")
					}
				}
			},
		}),
		[history, navigationAllowed]
	)

	useEffect(() => {
		const beforeUnload = (e) => {
			// Cancel the event
			if (warning != null && !window.confirm(warning)) {
				e.preventDefault() // If you prevent default behavior in Mozilla Firefox prompt will always be shown
				// Chrome requires returnValue to be set
				e.returnValue = ""
			}
		}
		window.addEventListener("beforeunload", beforeUnload)
		return () => {
			window.removeEventListener("beforeunload", beforeUnload)
		}
	}, [warning])

	return (
		<NavigationContext.Provider
			value={{ setNavigationAllowed, navigationAllowed, signatureInfo, ...safeHistory, setWarning }}>
			<Prompt when={warning != null} message={warning ?? ""} />
			{children}
		</NavigationContext.Provider>
	)
}
