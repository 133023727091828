import {
	Point,
	Text,
	Editor,
	Transforms,
	createEditor,
	Node,
	Range,
	Element as SlateElement,
	Descendant,
	Path,
	Location,
	EditorNodesOptions,
} from "slate"
import { useState, useRef, useContext, createContext, useCallback, ReactNode } from "react"
import { isObjEqual, bulletRegex, recursiveCombineObj } from "libs/src"
import {
	CustomEditor,
	CustomElement,
	CustomText,
	EditorParameters,
	ParagraphElement,
	SlateElementRenderProps,
	ElementType,
	MarkValue,
	SlateLeafRenderProps,
	Marks,
} from "./types/slate.types"
import { NodeEntry } from "slate"
import { EditorContextProps } from "./Utils.types"
import { ExtendedTaggingDataset } from "./TaggingFormatting/index.types"
export const MARK_OPTIONS = {
	format_bold: { format: "bold", icon: <b className="toolbar-button-icon">B</b>, button: "mark" },
	format_italic: {
		format: "italic",
		icon: (
			<em style={{ whiteSpace: "pre-wrap" }} className="toolbar-button-icon">
				I
			</em>
		),
		button: "mark",
	},
	format_underlined: { format: "underline", icon: <u className="toolbar-button-icon">U</u>, button: "mark" },
}

/**
 * Takes plain text, creates the slate format object and serializes it to a json string
 * @param text
 * @param initialMarks
 * @returns
 */
export const initialValueString = (text = "", initialMarks: Marks = {}): string => {
	return serialize(initialValue(text, initialMarks))
}

const newLineRegex = /[\n\r]+/g

const newLineNotBulletRegex = new RegExp(`(?!${bulletRegex.source})${newLineRegex.source}`, "gmsu")

/**
 * Creates the slate format object from a plaint text string.
 * The string is split by new lines and each line is added to a paragraph element.
 * If Marks are provided they are added to all text nodes
 * @param text
 * @param initialMarks
 * @returns
 */
export const initialValue = (text = "", initialMarks: Marks = {}, props = {}): Descendant[] => {
	return text
		.split(newLineNotBulletRegex)
		.map((text) => ({ type: "paragraph", props, children: [{ text, ...initialMarks }] }))
}

/**
 * Check function to determine if a string is in slate format (json)
 * @param val
 * @returns boolean
 */
export function textIsInSlateForm(val: string | Descendant[] | undefined): boolean {
	return typeof val === "string" && val?.substring(0, 2) === "[{"
}

/**
 * Applies props to all children of the editor, overwriting existing props with the new values, and keeping any existing props that are not overwritten
 * @param children
 * @param props
 */
export function applyPropsToAllChildren(children: Descendant[], props: object) {
	children.forEach((child) => {
		if (SlateElement.isElement(child)) {
			child.props = { ...child.props, ...props }
		}
	})
}

/**
 * Receives either json formatted string or plain text and returns slate format object
 * @param val input string
 * @param initialMarks any marks that should be applied to the text node created from plain text
 * @returns
 */
export function deserialize(val: string, initialMarks?: Marks, props?: object): Descendant[] {
	if (textIsInSlateForm(val)) {
		try {
			const parsedText = JSON.parse(val)
			if (props) {
				applyPropsToAllChildren(parsedText, props)
			}
			// apply all marks here?
			return parsedText
		} catch {
			return initialValue(val, initialMarks, props)
		}
	} else {
		return initialValue(val, initialMarks, props)
	}
}

/**
 * Returns plain text from slate format object. Any formatting is removed
 * @param text
 * @returns
 */
export function getPlainTextFromSlateText(text: string): string {
	if (textIsInSlateForm(text)) {
		try {
			const parsedText = JSON.parse(text)
			return Node.string({ children: parsedText }) // hack: create 'parent' node to use string function
		} catch {
			// failure also means it wasn't slate text
			return text
		}
	} else {
		return text
	}
}

/**
 * Converts slate format object to json string
 * @param val
 * @returns
 */
export function serialize(val: Descendant[]): string {
	return JSON.stringify(val)
}

/**
 * Determines if current selection is at the start of the editor. When the selection is null, it returns false. When the selection is a range returns true if first position is one edge of the range
 * @param editor
 * @param location
 * @returns
 */
export function isAtStart(editor: CustomEditor, location?: Point): boolean {
	if (editor.selection == null) {
		return false
	}
	return Point.equals(location ?? Range.start(editor.selection), Editor.start(editor, []))
}

/**
 * Determines if current selection is at the end of the editor. When the selection is null, it returns false. When the selection is a range returns true if last position is one edge of the range
 * @param editor
 * @param location
 * @returns
 */
export function isAtEnd(editor: CustomEditor, location?: Point): boolean {
	if (editor.selection == null) {
		return false
	}
	return Point.equals(location ?? Range.end(editor.selection), Editor.end(editor, []))
}

/**
 * element by element comparison of two arrays. True if equal
 * @param a
 * @param b
 * @returns
 */
export function arrayIsEqual(a: Array<any>, b: Array<any>): boolean {
	if (a?.length !== b?.length) {
		return false
	}
	return !a.some((el, i) => el !== b[i])
}

/**
 * Creates a new editor with ability to display paragraphs.
 * This is the base editor, on whic plugins are applied.
 * Overrides insetFragment to define property definition when fragments are added to editor
 * @param params
 * @returns
 */
export const createEditorWithPara = (params: EditorParameters): CustomEditor => {
	const editor = createEditor()
	const { insertFragment } = editor
	editor.parameters = params
	editor.baseBlocks = ["paragraph"] //base level block elements in editor (that properties should be applied to ie styles etc)

	editor.insertFragment = (fragment) => {
		if (editor.selection == null) {
			insertFragment(fragment)
			return
		}
		const selectedFocus = editor.selection?.focus ?? editor.selection?.anchor
		const [selectedEntry] = Editor.nodes(editor, {
			at: selectedFocus,
			match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && editor.baseBlocks.includes(n.type),
		}) as Generator<NodeEntry<CustomElement>>
		const [selectedNode] = selectedEntry ?? []
		insertFragment(fragment)
		if (selectedNode?.props != null) {
			try {
				const newProps = { ...selectedNode.props }
				delete newProps["className"] // don't copy classname to new node (as it will likely be a different element type)
				addPropertiesToNodes(
					editor,
					{ props: newProps },
					{
						at: { anchor: { ...selectedFocus }, focus: { ...selectedFocus } },
						match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && editor.baseBlocks.includes(n.type),
					}
				)
			} catch {
				console.log("failed to add props to nodes")
			}
		}
	}

	return editor
}

const markerRegexString = (markerChar: string) => `${markerChar}\\w*(?:(?:[^a-zA-Z0-9_]|$))`

const markersRegexString = (markerChars: string[]) => markerChars.map(markerRegexString).join("|")

export const makeNormalizerForMarkers = (markers: { [key: string]: string }) => {
	// markers should be obj of form: {'@': 'potentialTag'}
	// returns a function to be used in the normalizeNode function
	// the normalize function will find the key in the text and wrap it
	// in the element given by the value for markers {key: value},
	// it also sets the field marked to true on the element
	// marked elements are then ignored to avoid infinitely wrapping an element
	const search = new RegExp(markersRegexString(Object.keys(markers)))
	const getMarkerType = (markedText): ElementType => {
		for (var [marker, type] of Object.entries(markers)) {
			if (markedText.startsWith(marker)) {
				return type as ElementType
			}
		}
	}
	return (editor: CustomEditor, [node, path]: NodeEntry<CustomText | CustomElement>) => {
		let parentEntry
		if (path.length > 0) {
			// not at top level so parent exists
			parentEntry = Editor.parent(editor, path)
		} else {
			// continue with fake parent entry when at top level
			parentEntry = [{ marked: false }]
		}
		const [parent] = parentEntry
		if (Text.isText(node) && node.marked) {
			Transforms.setNodes(editor, { marked: false }, { at: path })
		}
		if (SlateElement.isElement(node) && node.marked && node.type !== getMarkerType(Node.string(node))) {
			Transforms.unwrapNodes(editor, { at: path })
		}
		if (search && Text.isText(node) && node.marked !== true && parent.marked !== true) {
			const { text } = node
			const {
				index,
				0: match,
				noMatch,
			} = (text.match(search) as { index: number; 0: ElementType; noMatch?: boolean }) ?? { noMatch: true }
			if (noMatch) {
				return
			}
			Editor.withoutNormalizing(editor, () => {
				Transforms.select(editor, { anchor: { path, offset: index }, focus: { path, offset: index + match?.length } })
				//@ts-ignore
				Transforms.wrapNodes(editor, { type: getMarkerType(match), marked: true }, { split: true })
				Transforms.collapse(editor, { edge: "end" })
			})
			// parts.forEach((part, i) => {
			//     if (i % 2 !== 0) {
			//         // console.log('marker found in text', text, 'at:', part, parts, getMarkerType(part))
			//         ranges.push({
			//             anchor: { path, offset: offset + part.length },
			//             focus: { path, offset },
			//             [getMarkerType(part)]: true,
			//             marked: false
			//         })
			//     }
			//     offset = offset + part.length
			// })
		}
	}
}

/**
 * Returns true if the selection is collapsed and the cursor is at the end of a node of a type includes in types
 * @param editor
 * @param types
 * @returns
 */
export function isAtEndOfTypes(editor: CustomEditor, types: ElementType[]): boolean {
	const { selection } = editor

	if (selection && Range.isCollapsed(selection)) {
		const [cell] = Editor.nodes(editor, {
			match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && types.includes(n.type),
		})

		if (cell) {
			const [, cellPath] = cell
			const end = Editor.end(editor, cellPath)

			return Point.equals(Range.end(editor.selection), end)
		}
	}
	return false
}

/**
 * Returns true if the selection is collapsed and the cursor is at the start of a node of a type includes in types
 * @param editor
 * @param types
 * @returns
 */
export function isAtStartOfTypes(editor: CustomEditor, types: ElementType[]): boolean {
	const { selection } = editor

	if (selection && Range.isCollapsed(selection)) {
		const [cell] = Editor.nodes(editor, {
			match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && types.includes(n.type),
		})

		if (cell) {
			const [, cellPath] = cell
			const start = Editor.start(editor, cellPath)

			return Point.equals(Range.start(editor.selection), start)
		}
	}
	return false
}

/**
 * Returns true if the selection includes a node of type format
 * @param editor
 * @param format
 * @returns
 */
export const isBlockActive = (editor: CustomEditor, format: ElementType): boolean => {
	const [match] = Editor.nodes(editor, {
		match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
	})
	return !!match
}

/**
 * Detects 'sandwiched behaviour in slate editor. This is when the selection is between two empty text nodes which happens after triple clicking an inline tag
 * @param editor
 * @param options
 * @returns
 */
export const isSandwiched = (editor: CustomEditor, options): boolean => {
	// tagging inlines have empty text inserted either side. Triple clicking selects from the first empty text to the second. this needs to detect that
	const [match] = Editor.nodes(editor, options)
	if (!match) return false
	const [, path] = match
	const selectionStart = Range.start(options.at ?? editor.selection)
	const selectionEnd = Range.end(options.at ?? editor.selection)
	if (!isObjEqual(Editor.before(editor, path), selectionStart)) {
		return false
	}
	if (!isObjEqual(Editor.after(editor, path), selectionEnd)) {
		return false
	}
	return true
}

/**
 * Returns the nodes of the type given that exist higher on the path (but *not* at the exact path). Returns an empty array if none found
 * @param editor
 * @param path
 * @param type
 * @returns
 */
export function ancestorsOfType<T extends CustomElement = CustomElement>(
	editor: CustomEditor,
	path: Location,
	type: ElementType
): Generator<NodeEntry<T>> {
	return Editor.nodes(editor, {
		at: path,
		match: (n, p) => SlateElement.isElement(n) && n.type === type && !isObjEqual(p, path),
	})
}

/**
 * Returns the nodes of the types given that exist higher on the path (but *not* at the exact path). Returns an empty array if none found
 * @param editor
 * @param path
 * @param types
 * @returns
 */
export function ancestorsOfTypes<T extends CustomElement = CustomElement>(
	editor: CustomEditor,
	path: Location,
	types: ElementType[]
): Generator<NodeEntry<T>> {
	return Editor.nodes(editor, {
		at: path,
		match: (n, p) => SlateElement.isElement(n) && types?.includes(n.type) && !isObjEqual(p, path),
	})
}
/**
 * Returns true if at least one node of the type given exists higher on the path (but *not* at the exact path).
 * @param editor
 * @param path
 * @param type
 * @returns
 */
export function hasAncestorOfType<T extends CustomElement = CustomElement>(
	editor: CustomEditor,
	path: Location,
	type: ElementType
) {
	const [ancestorEntryOfType] = ancestorsOfType<T>(editor, path, type)
	return ancestorEntryOfType != null
}

/**
 * Returns true if at least one node of the type given exists higher on the path (but *not* at the exact path).
 * @param editor
 * @param path
 * @param types
 * @returns
 */
export function hasAncestorOfTypes<T extends CustomElement = CustomElement>(
	editor: CustomEditor,
	path: Location,
	types: ElementType[]
) {
	const [ancestorEntryOfType] = ancestorsOfTypes<T>(editor, path, types)
	return ancestorEntryOfType != null
}

/**
 * Sets the selection to the start of the editor (collapsed)
 * @param editor
 */
export function setSelectionStart(editor: CustomEditor): void {
	Transforms.select(editor, { anchor: Editor.start(editor, []), focus: Editor.start(editor, []) })
}

/**
 * Sets the selection to the end of the editor (collapsed)
 * @param editor
 */
export function setSelectionEnd(editor: CustomEditor): void {
	Transforms.select(editor, { anchor: Editor.end(editor, []), focus: Editor.end(editor, []) })
}

/**
 * Returns the range that covers the entire editor
 * @param editor
 * @returns
 */
export function getWholeText(editor: CustomEditor): Range {
	return { anchor: Editor.start(editor, []), focus: Editor.end(editor, []) }
}

/**
 * Sets the selection to the entire editor
 * @param editor
 */
export function setWholeTextSelection(editor: CustomEditor): void {
	return Transforms.select(editor, getWholeText(editor))
}

/**
 * Sets the selection to the range from the start to the end of the element at the given path
 * @param editor
 * @param path
 */
export function setSelectionToWholeElementAtPath(editor: CustomEditor, path: Path): void {
	return Transforms.select(editor, { anchor: Editor.start(editor, path), focus: Editor.end(editor, path) })
}

/**
 * Returns true if a node of the given format exists anywhere within the editor (not just at the current path)
 * @param editor
 * @returns
 */
export const doesBlockExist = (editor: CustomEditor, format: ElementType): boolean => {
	const [match] = Editor.nodes(editor, {
		at: getWholeText(editor),
		match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
	})
	return !!match
}
// take array of keys defining path in props, and return setter function for format

export function addPropertiesToNodes(editor: CustomEditor, newProperties: object, options?: EditorNodesOptions<Node>) {
	var currNodes = Editor.nodes(editor, options)
	for (var [node, path] of currNodes) {
		const combinedProps = recursiveCombineObj(node, newProperties)
		Transforms.setNodes(editor, combinedProps, { at: path })
	}
}

export const toggleMark = (editor: CustomEditor, format: string) => {
	ensureSelection(editor)
	const [formatName, formatValue] = format.split("@") // boolean format will just be name, otherwise name@value
	const isActive = isMarkActive(editor, formatName as MarkValue)
	if (isActive) {
		Editor.removeMark(editor, formatName)
	} else {
		Editor.addMark(editor, formatName, formatValue ?? true)
	}
	editor.customMarkOptions?.[formatName]?.postApply?.(editor)
}

export const isMarkActive = (editor: CustomEditor, format: MarkValue): boolean => {
	const marks = Editor.marks(editor)
	return marks ? marks[format] === true : false
}

const standardBulletPointForTextOutput = "•"
function getTextFromNodes(nodes: Node[]): string {
	let text = ""
	for (const node of nodes) {
		if (Text.isText(node)) {
			text += node.text
		} else if (SlateElement.isElement(node) && node.type === "paragraph") {
			text += "\n" + getTextFromNodes(node.children)
		} else if (SlateElement.isElement(node) && node.type === "list-item") {
			let addedText = "\n" + standardBulletPointForTextOutput + ""
			if (node?.props?.className?.includes("hide-bullet")) {
				addedText = ""
			}
			text += addedText + getTextFromNodes(node.children)
		} else {
			text += getTextFromNodes(node.children)
		}
	}
	return text
}

export const getSelectedText = (editor: CustomEditor) => {
	if (editor.selection == null) {
		return ""
	}
	if (Range.isCollapsed(editor.selection)) {
		return ""
	}
	const selected: Node[] = editor.getFragment()
	return getTextFromNodes(selected)
}

export const getMarkValue = (editor: CustomEditor, format: string): string => {
	const [formatName] = format.split(".")
	const marks = Editor.marks(editor)
	return marks?.[formatName] ?? null
}

export const markExistsInText = (editor: CustomEditor, format: MarkValue): boolean => {
	if (slateTextIsEmpty(editor.children)) {
		return false
	}
	const [match] = Editor.nodes(editor, { at: [], match: (n) => Text.isText(n) && format in n })
	return match != null
}

export const getMarksFromText = (leaf: CustomText): object => {
	const marks = { ...leaf }
	delete marks.text
	return marks
}

export function deleteIfInEmptyPara(editor: CustomEditor) {
	const [paraEntry]: Generator<NodeEntry<ParagraphElement>> = Editor.nodes(editor, {
		match: (n) => SlateElement.isElement(n) && n.type === "paragraph",
	})
	if (paraEntry == null) return
	const [para, paraPath] = paraEntry
	if (para && slateTextIsEmpty([para])) {
		Transforms.removeNodes(editor, { at: paraPath })
	}
}

export function selectEndOfText(editor: CustomEditor): void {
	Transforms.select(editor, Editor.end(editor, []))
}

export const EditorContext = createContext<Partial<EditorContextProps>>({})

export const EditorContextProvider = ({ children, ...value }: { children: ReactNode } & EditorContextProps) => {
	const [toolbarPositions, setToolbarPositions] = useState<{
		[key: string]: { top: number; left: number; width: number; height: number } | null
	}>({})
	return (
		<EditorContext.Provider value={{ ...value, toolbarPositions, setToolbarPositions }}>
			{children}
		</EditorContext.Provider>
	)
}

export const useEditorContext = () => useContext(EditorContext)

export function useArray<T>(
	initArray: T[]
): [T[], (idx: number, val: T) => void, React.MutableRefObject<T[]>, () => void, (newArray: T[]) => void] {
	const [array, setArray] = useState<T[]>(initArray ?? [])
	const arrayRef = useRef<T[]>(array)
	const setAtIndex = useCallback((idx: number, val: T) => {
		const newArray = [...arrayRef.current]
		newArray[idx] = val
		setArray([...newArray])
		arrayRef.current = [...newArray]
	}, [])

	const setFullArray = useCallback((newArray: T[]) => {
		setArray([...newArray])
		arrayRef.current = [...newArray]
	}, [])

	const resetArray = useCallback(() => {
		setArray([])
		arrayRef.current = []
	}, [])

	return [array, setAtIndex, arrayRef, resetArray, setFullArray]
}

export function slateTextIsEmpty(
	topEl: Descendant[] | string | null | undefined,
	tryJson = true,
	countTagsAsNonEmpty = true,
	countEmojisAsNonEmpty = true,
	taggingData?: ExtendedTaggingDataset // if tagging dataset provided then tag values will be fetched and count towards emptiness
): boolean {
	if (topEl == null) {
		return true
	}
	if (tryJson && textIsInSlateForm(topEl)) {
		try {
			// @ts-ignore the catch will handle it if it's not valid json
			topEl = JSON.parse(topEl)
		} catch {}
	}
	if (typeof topEl == "string") {
		if (countEmojisAsNonEmpty) {
			return !topEl || !/\S/gm.test(topEl) //pure whitespace treated as empty
		} else {
			return !topEl || !/\S/gm.test(topEl.replace(/[\u{1F4CD}\u{1F3E2}\u{1F393}\u{1F3C6}-]/gmu, "")) //emojis used in icons
		}
	}
	for (var el of topEl) {
		if (
			(SlateElement.isElement(el) && el.type === "table") ||
			(countTagsAsNonEmpty && !Text.isText(el) && el.type === "tagging" && el.tagging != null)
		) {
			return false
		}
		if (SlateElement.isElement(el) && el.type === "divider") {
			return false
		}
		if (SlateElement.isElement(el) && el.type === "pageBreak") {
			return false
		}
		if (
			!countTagsAsNonEmpty &&
			!Text.isText(el) &&
			el.type === "tagging" &&
			el.tagging != null &&
			taggingData != null
		) {
			const thisTagData = taggingData[el.tagging]
			let tagValue = thisTagData?.getData()
			if (thisTagData?.processingSets != null) {
				const preprocessing = thisTagData.processingSets[el.processing ?? "default"]?.preprocessing?.(thisTagData)
				tagValue = preprocessing?.(tagValue) ?? tagValue
			}
			if (!slateTextIsEmpty(tagValue, true, countTagsAsNonEmpty, countEmojisAsNonEmpty)) {
				return false
			} else {
				// if tagging data is provided then we don't want to check the children of a tag
				continue
			}
		}
		if (Text.isText(el) && el.text != null) {
			if (!slateTextIsEmpty(el.text, false, countTagsAsNonEmpty, countEmojisAsNonEmpty, taggingData)) {
				return false
			}
		}
		if (!Text.isText(el) && el.children != null) {
			if (!slateTextIsEmpty(el.children, false, countTagsAsNonEmpty, countEmojisAsNonEmpty, taggingData)) {
				return false
			}
		}
	}
	//No text found in tree
	return true
}

export function ensureSelection(editor: CustomEditor): void {
	if (editor.selection == null) {
		selectEndOfText(editor)
	}
}

export const renderParagraphElement = ({ attributes, children, element }: SlateElementRenderProps): JSX.Element => {
	return (
		<p {...attributes} {...(element?.props ?? {})}>
			{children}
		</p>
	)
}

export const toggleParagraph = (editor: CustomEditor, format) => {
	const isActive = isBlockActive(editor, format)

	Editor.withoutNormalizing(editor, () => {
		Transforms.select(editor, Editor.unhangRange(editor, editor.selection))
		const isList = editor.parentType?.includes(format)
		Transforms.unwrapNodes(editor, {
			match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && editor.parentType.includes(n.type),
			split: true,
		})
		const newProperties = {
			type: isActive ? "paragraph" : isList ? "list-item" : format,
		}
		Transforms.setNodes(editor, newProperties, {
			match: (n) =>
				!Editor.isEditor(n) && SlateElement.isElement(n) && (n.type === "list-item" || n.type === "paragraph"),
		})
	})
	return true
}

export const setLeafUnchanged = (props: SlateLeafRenderProps): SlateLeafRenderProps => {
	return props
}

export function makePlainText(slateText: Descendant[] | string) {
	if (typeof slateText === "string") {
		slateText = deserialize(slateText)
	}
	let plainText = []
	for (var child of slateText) {
		if (Text.isText(child)) {
			plainText.push(child.text)
			continue
		}
		if (child.type === "list-item") {
			plainText.push("\n- ")
		}
		plainText.push(makePlainText(child.children))
	}
	return plainText.join("")
}

export const renderLeaf = ({ attributes, children, text }: SlateLeafRenderProps) => {
	return <span {...attributes}>{children}</span>
}

export const logTypeTree = (node: Node, indent: number = 0, childNo: number = null, includeText = true) => {
	if (!Text.isText(node)) {
		console.log(
			"-".repeat(indent) + " " + (childNo ?? "") + " " + (SlateElement.isElement(node) ? node?.type : "editor")
		)
		node.children.forEach((child, childNo) => {
			logTypeTree(child, indent + 2, childNo)
		})
	} else if (includeText) {
		console.log("-".repeat(indent) + " " + (childNo ?? "") + " " + node.text)
	}
}

export function getPreviousSibling(editor: CustomEditor, entry: NodeEntry): NodeEntry | null {
	const [node, path] = entry
	if (SlateElement.isElement(node) && path[path.length - 1] > 0) {
		const prevPath = [...path.slice(0, path.length - 1), path[path.length - 1] - 1] as Path
		const prevNode = Node.get(editor, prevPath)
		return [prevNode, prevPath]
	} else {
		return null
	}
}

export function getNextSibling(editor: CustomEditor, entry: NodeEntry): NodeEntry | null {
	const [node, path] = entry
	if (SlateElement.isElement(node)) {
		const nextPath = [...path.slice(0, path.length - 1), path[path.length - 1] + 1] as Path
		try {
			const nextNode = Node.get(editor, nextPath)
			return [nextNode, nextPath]
		} catch {
			return null
		}
	} else {
		return null
	}
}

export function wholeTextIsSelected(editor: CustomEditor) {
	return isAtStart(editor, Range.start(editor.selection)) && isAtEnd(editor, Range.end(editor.selection))
}

export const isCurrentlySelected = (editor: CustomEditor, element: CustomElement) => {
	const [el] = Editor.nodes(editor, { match: (n) => n === element })
	return el != null
}

function getRange(editor: CustomEditor, element: Node) {
	const [nodeEntry] = Editor.nodes(editor, { match: (n) => n === element })
	if (nodeEntry == null) {
		return null
	}
	const [, path] = nodeEntry
	const range = Editor.range(editor, path)
	return range
}

function selectionIsSubRange(selection: Range, range: Range, inclusive = true) {
	const selectionStart = Range.start(selection)
	const selectionEnd = Range.end(selection)
	const rangeStart = Range.start(range)
	const rangeEnd = Range.end(range)

	const startComparison = Point.compare(selectionStart, rangeStart)
	const endComparison = Point.compare(selectionEnd, rangeEnd)

	if (inclusive) {
		return startComparison >= 0 && endComparison <= 0
	} else {
		return startComparison > 0 && endComparison < 0
	}
}

export function onlyThisElementSelected(editor: CustomEditor, element: CustomElement): boolean {
	if (editor.selection == null) {
		return false
	}
	const range = getRange(editor, element)
	if (range == null) {
		return false
	}
	return selectionIsSubRange(editor.selection, range)
}

const fragmentHasMultipleElements = (fragment: Node[], allowTaggingBlocks = false): boolean => {
	if (fragment == null) return true
	if (Text.isTextList(fragment)) return false
	if (fragment.length !== 1) return true
	const child = fragment[0]
	if (Text.isText(child)) return false // this should never happen because of previour two checks but fixes type checking
	if (!allowTaggingBlocks && SlateElement.isElement(child) && child.type === "tagging" && child.nodeType === "block")
		return true // block tag elements cannot be assumed to contain data with only individual elements
	return fragmentHasMultipleElements(child.children, allowTaggingBlocks)
}

export const multipleElementsSelected = (editor: CustomEditor): boolean => {
	const selectedFragment = editor.getFragment()
	const hasMultipleElements = fragmentHasMultipleElements(selectedFragment, !(editor.parameters.templateMode ?? false)) // in template mode need to assume tagging block can contain multiple elements
	return hasMultipleElements
}

export function trim(trimChar: string, value: Node[], start = true) {
	if (trimChar == null) return
	const regExp = start ? new RegExp(`^[${trimChar}]+`, "g") : new RegExp(`[${trimChar}]+$`, "g")
	const plainText: string = Node.string({ children: value }).trim()
	if (plainText !== plainText.replace(regExp, "")) {
		for (var [leaf, leafPath] of Node.texts({ children: value }, { reverse: !start })) {
			const trimmedText = leaf.text.trim()
			if (trimmedText.length > 0) {
				const index = leaf.text.indexOf(trimChar)
				if (index != null) {
					return { path: leafPath, offset: index }
				}
			}
		}
	}
}

export const NAVHOTKEYS = {
	tab: "tab",
	ArrowDown: "ArrowDown",
	ArrowRight: "ArrowRight",
	ArrowLeft: "ArrowLeft",
	ArrowUp: "ArrowUp",
	Enter: "Enter",
	Backspace: "Backspace",
}

export const NAVKEYS = Object.keys(NAVHOTKEYS)

export const allowedHotKeysInCustomUneditableSection = [
	"Shift+ArrowRight",
	"Shift+ArrowLeft",
	"Shift+ArrowDown",
	"Shift+ArrowUp",
	"ArrowUp",
	"ArrowRight",
	"ArrowDown",
	"ArrowLeft",
	"Ctrl",
	"Cmd",
	"Ctrl+I",
	"Ctrl+B",
	"Ctrl+U",
	"Ctrl+Z",
	"Ctrl+A",
	"Cmd+I",
	"Cmd+B",
	"Cmd+U",
	"Cmd+Z",
	"Cmd+A",
	"Backspace",
	"Delete",
]
