import {
	Editor,
	Element,
	Transforms,
	Element as SlateElement,
	NodeEntry,
	Path,
	Descendant,
	Range,
	Node,
	Location,
} from "slate"
import { bulletRegex, bulletSectionsRegex } from "libs"
import { ancestorsOfType, isBlockActive, slateTextIsEmpty } from "../Utils"
import {
	CustomEditor,
	CustomElement,
	ElementType,
	ListParentElementType,
	SlateElementRenderProps,
	ParagraphElement,
	ListItemElement,
} from "../types/slate.types"

export const renderBulletElement = (
	{ attributes, children, element }: SlateElementRenderProps,
	hideIfEmpty = false
): JSX.Element => {
	switch (element.type) {
		case "bulleted-list":
			return (
				<ul {...attributes} {...(element?.props ?? {})}>
					{children}
				</ul>
			)
		case "list-item":
			if (hideIfEmpty && slateTextIsEmpty(element.children, false, false)) {
				// this leaves the bullet as a div inside the ul element, but it is blank so this isn't a problem
				return (
					<div style={{ display: "none" }} {...attributes} {...(element?.props ?? {})}>
						{children}
					</div>
				)
			}
			return (
				<li {...attributes} {...(element?.props ?? {})}>
					{children}
				</li>
			)
		case "numbered-list":
			return (
				<ol {...attributes} {...(element?.props ?? {})}>
					{children}
				</ol>
			)
		default:
			return null
	}
}

export function setBulletVisibility(editor: CustomEditor, options?: { at: Location }) {
	Transforms.setNodes(
		editor,
		{ ...options, props: { className: "hide-bullet" } },
		{ split: false, match: (n) => Element.isElement(n) && n.type === "list-item", mode: "all" }
	)
	Transforms.setNodes(
		editor,
		{ ...options, props: { className: "show-bullet" } },
		{ split: false, match: (n) => Element.isElement(n) && n.type === "list-item", mode: "lowest" }
	)
}

function getRangeForIndenting(editor) {
	let at
	if (Range.isExpanded(editor.selection)) {
		// for expanded range move the selection in one point at either end
		let start = Editor.start(editor, editor.selection)
		let end = Editor.end(editor, editor.selection)
		start = start.offset > 0 ? Editor.after(editor, start) : start
		// if the start position has offset greater than 0 then move it forward one point,
		// this will discount a selection that starts at the end of a list-item,
		// but will do nothing if the seleciton is in the middle, or the list-item is empty
		end = Editor.before(editor, end)
		// if the end position has offset 0 then moving it back one point will move it to the end of the previous list-item
		// if the offset was greater than 0 it will do nothing
		at = { anchor: start, focus: end }
	}
	return at
}

export function increaseBulletDepth(editor: CustomEditor, format: ListParentElementType) {
	const at = getRangeForIndenting(editor)
	Editor.withoutNormalizing(editor, () => {
		for (let listItemEntry of Editor.nodes(editor, {
			at,
			match: (n, p) => Element.isElement(n) && n.type === "list-item",
			mode: "lowest",
		})) {
			//wrap each individually because wrapping the while list at once takes a highest ancestor and wraps extra items
			Transforms.wrapNodes(editor, { type: format } as CustomElement, {
				split: false,
				match: (n, p) => listItemEntry[0] === n && p.length < 20,
			})
		}
		Transforms.wrapNodes(editor, { type: "list-item" } as CustomElement, {
			split: false,
			match: (n) => Element.isElement(n) && n.type === format,
		})
		setBulletVisibility(editor)
	})
}

export function decreaseBulletDepth(editor, format?: ListParentElementType) {
	const at = getRangeForIndenting(editor)

	Editor.withoutNormalizing(editor, () => {
		Transforms.liftNodes(editor, {
			at,
			match: (n, p) => {
				if (!Element.isElement(n) || n.type !== "list-item") return false
				if (format == null) {
					return true
				}
				const parent = Node.parent(editor, p)
				return Element.isElement(parent) && parent.type === format
			},
			mode: "lowest",
		})

		Transforms.liftNodes(editor, {
			match: (n, p) => {
				if (!Element.isElement(n) || n.type !== "list-item") return false
				const parent = Node.parent(editor, p)
				return Element.isElement(parent) && parent.type === "list-item"
			},
			mode: "lowest",
		})
		//clean up empty lists
		Transforms.removeNodes(editor, {
			at: [],
			match: (n) =>
				Element.isElement(n) && LIST_TYPES.includes(n.type as ListParentElementType) && n.children.length === 0,
		})
		//normalisation will convert top level list-items to a paragraphs
	})
	setBulletVisibility(editor)
}

export function removeFullBulletDepth(editor, format?: ListParentElementType) {
	if (format) {
		const depth = [...ancestorsOfType(editor, editor.selection, format)].length
		console.log("decreasing bullet depth by ", depth)
		for (let i = 0; i < depth; i++) {
			decreaseBulletDepth(editor, format)
		}
	} else {
		const depth = [...ancestorsOfType(editor, editor.selection, "list-item")].length
		console.log("decreasing bullet depth by ", depth)
		for (let i = 0; i < depth; i++) {
			decreaseBulletDepth(editor)
		}
	}
}

export const toggleBullet = (editor: CustomEditor, format: ElementType): boolean | null => {
	if (format !== "bulleted-list" && format !== "numbered-list") {
		return null
	}
	const isActive = isBlockActive(editor, format)
	if (!isActive) {
		// const otherType = format === "bulleted-list" ? "numbered-list" : "bulleted-list"
		// const otherTypeIsActive = isBlockActive(editor, otherType )
		Editor.withoutNormalizing(editor, () => {
			Transforms.select(editor, Editor.unhangRange(editor, editor.selection))
			const isList = LIST_TYPES.includes(format)
			Transforms.unwrapNodes(editor, {
				match: (n) =>
					!Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type as ListParentElementType),
				split: true,
			})
			const newProperties = {
				type: isList ? "list-item" : format,
			}
			Transforms.setNodes(editor, newProperties as Partial<ParagraphElement | ListItemElement>, {
				match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "paragraph",
			})
			wrapAllListItems(editor, format, isList, isActive)
		})
	} else {
		removeFullBulletDepth(editor, format)
	}
	return true
}

function wrapListItemsInNestedFormatters(
	editor: CustomEditor,
	format: ListParentElementType,
	isList: boolean,
	isActive: boolean
) {
	if (!isActive && isList) {
		const cells = Editor.nodes(editor, {
			mode: "highest",
			match: (n) => {
				const isMatch = !Editor.isEditor(n) && SlateElement.isElement(n) && editor.nestedFormatters.includes(n.type)
				return isMatch
			},
		})
		const block = { type: format, children: [] }
		for (var tableCell of cells) {
			const [, cellPath] = tableCell
			wrapListItems(editor, block, cellPath)
		}
	}
}

function wrapTopLevelListItems(editor: CustomEditor, format: ListParentElementType) {
	const block = { type: format, children: [] }
	wrapListItems(editor, block)
}

function wrapAllListItems(editor: CustomEditor, format: ListParentElementType, isList: boolean, isActive: boolean) {
	wrapTopLevelListItems(editor, format)
	wrapListItemsInNestedFormatters(editor, format, isList, isActive)
}

export function wrapListItems(editor: CustomEditor, block: CustomElement, at?: Path) {
	Transforms.wrapNodes(editor, block, {
		at,
		mode: "lowest",
		match: (n, p) => {
			const isMatch = !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "list-item"
			if (!isMatch) return isMatch //early return if not a list-item
			const [parent] = Editor.parent(editor, p) as NodeEntry<CustomElement>
			const alreadyWrapped = LIST_TYPES.includes(parent.type as ListParentElementType)
			return isMatch && !alreadyWrapped // return false if already wrapped: no need to wrap again
		},
		split: false,
	})
	Transforms.liftNodes(editor, {
		at,
		match: (n, p) => {
			const isMatch = !Editor.isEditor(n) && SlateElement.isElement(n) && n.type !== "list-item"
			if (!isMatch) return isMatch //early return if a list-item
			const [parent] = Editor.parent(editor, p) as NodeEntry<CustomElement>
			const isWrapped = LIST_TYPES.includes(parent.type as ListParentElementType)
			return isMatch && isWrapped // return true if warpped in order to unwarp
		},
	})
}

export function textHasBulletSymbols(text: string) {
	return text.match(bulletRegex)?.length > 0
}

const DEFAULT_MAX_BULLETS = 50

export function makeTextBullets({
	text,
	marksToApply,
	maxBullets,
	includeEmptyStrings = false,
	propsToApply,
}: {
	text: string
	marksToApply?: object
	maxBullets?: number
	includeEmptyStrings?: boolean
	propsToApply?: object
}): Descendant[] {
	const textArray = text?.split(bulletSectionsRegex)
	return textArray.reduce((acc, text) => {
		if (textHasBulletSymbols(text)) {
			const bullets = makeTextBulletSection(text, marksToApply, propsToApply, maxBullets ?? DEFAULT_MAX_BULLETS)
			return [...acc, ...bullets]
		} else if (text?.length === 0 && !includeEmptyStrings) {
			return acc
		} else {
			return [...acc, { type: "paragraph", ...propsToApply, children: [{ text, ...marksToApply }] }]
		}
	}, [])
}

export function makeTextBulletSection(
	text: string,
	marksToApply?: object,
	propsToApply?: object,
	maxBullets: number = 50
): Descendant[] {
	const textArray = text?.split(bulletRegex)
	if (textArray.length > maxBullets) {
		return []
	}
	let newChildren = []
	if (textArray?.length > 1) {
		//bullets exist
		if (textArray[0].length > 0) {
			// previous text is not empty, put in paragraph before bullets
			newChildren.push({ type: "paragraph", ...propsToApply, children: [{ text: textArray[0], ...marksToApply }] })
		}
		const bulletList = textArray
			.slice(1)
			.map((text) => ({ type: "list-item", props: propsToApply, children: [{ text, ...marksToApply }] }))
		newChildren.push({ type: "bulleted-list", children: bulletList })
		return newChildren
	}
}

export const LIST_TYPES: ListParentElementType[] = ["numbered-list", "bulleted-list"]

// export function getFirstConsecutiveListIndex(children: Descendant[]) {
// 	let prevWasList = null
// 	let idx = 0
// 	for (var child of children) {
// 		if (Element.isElement(child) && LIST_TYPES.includes(child.type as ListParentElementType)) {
// 			// Before we return the index we are checking to see is prevWasList is a list type
// 			if (prevWasList === child.type && prevWasList != null) {
// 				return idx
// 			}
// 			prevWasList = child.type
// 		} else if (Element.isElement(child)) {
// 			prevWasList = child.type
// 		}
// 		idx += 1
// 	}
// }

export const isAtLeftOfListItem = (editor: Editor, listParentPath: Path): boolean => {
	if (Range.isExpanded(editor.selection)) return false
	if (editor.selection.anchor.offset !== 0) return false
	const pathFromListParent = editor.selection.anchor.path.slice(listParentPath.length)
	console.log("pathFromListParent", pathFromListParent)
	return pathFromListParent.every((p) => p === 0)
}
