import { AcceptedFileType } from "../Integrations.types"

export function isAcceptedFileType(fileName: string): fileName is AcceptedFileType {
	if (!fileName) return false
	if (!fileName.includes(".")) return false
	const extension = getExtensionFromFileName(fileName)
	return ".pdf,.doc,.docx,.txt,.pages,.rtf,.odt".includes(extension.toLowerCase())
}

export function getExtensionFromFileName(fileName: string) {
	return fileName.split(".").pop().toLowerCase()
}

export const MIME_TYPES = {
	pdf: "application/pdf",
	doc: "application/msword",
	docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	rtf: "application/rtf",
	txt: "text/plain",
	odt: "application/vnd.oasis.opendocument.text",
	pages: "application/x-iwork-pages-sffpages",
}
