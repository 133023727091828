import "./FontDropdown.css"
import { getAllFonts, useLoadContext } from "libs"

export const FontDropdownItems = ({ currentValue, setFormat }) => {
	const { company } = useLoadContext()
	const FONT_INFO = getAllFonts(company)
	const FONTS = Object.keys(FONT_INFO)

	return (
		<div className="font-options">
			{FONTS.map((font) => (
				<div
					className={`clickable font-option ${font === currentValue ? "selected-font-option" : ""}`}
					key={`font-${font}`}
					style={{ fontFamily: `${FONT_INFO[font].fontFamily}`, fontWeight: `${FONT_INFO[font].normal}` }}
					onClick={(e) => {
						setFormat(font)
					}}>
					{font}
				</div>
			))}
		</div>
	)
}
