import { MdArrowForwardIos } from "react-icons/md"
import "./SimpleArrowNavigator.css"

export const SimpleArrowNavigator = ({
	currentIndex,
	setCurrentIndex,
	currentInputs,
}: {
	currentIndex: number
	setCurrentIndex: React.Dispatch<React.SetStateAction<number>>
	currentInputs: Array<any>
}) => {
	const isAtMin = currentIndex <= 0
	const isAtMax = currentInputs == null ? true : currentIndex >= currentInputs.length - 1
	return (
		currentInputs?.length > 1 && (
			<div className="simple-arrow-navigator">
				<MdArrowForwardIos
					style={{ transform: "rotate(180deg)", opacity: isAtMin ? 0.2 : 1 }} // use rotated for consistent spacing
					size={18}
					className={isAtMin ? "" : "clickable"}
					onClick={() => {
						if (isAtMin) return
						setCurrentIndex((i) => i - 1)
					}}
				/>
				{currentIndex + 1}/{currentInputs.length}
				<MdArrowForwardIos
					size={18}
					style={{ opacity: isAtMax ? 0.2 : 1 }}
					className={isAtMax ? "" : "clickable"}
					onClick={() => {
						if (isAtMax) return
						setCurrentIndex((i) => i + 1)
					}}
				/>
			</div>
		)
	)
}
