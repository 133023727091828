import { withProperties } from "./PropertyFormatting/PropertyPlugin"
import { SpacingIcon } from "../Icons/SpacingIcons"
import { ButtonType, ElementType, PropOptionSet } from "./types/slate.types"
import { LINE_SPACING_SIZES } from "../LineSpacingDropdown/LineSpacingDropdown"

const formatLineSpacingPath = ["style"]

const DEFAULT_LINE_SPACING = 1.5

const lineSpacingFromFormat = (format: ElementType) => {
	if (format?.match == null) return format
	const match = format.match(/^line-spacing@(.+)$/)
	if (!match) return format
	return parseFloat(match[1])
}

export const LINE_SPACING_OPTIONS: PropOptionSet = {
	name: "lineSpacing",
	button: ButtonType.prop as ButtonType.prop,
	path: formatLineSpacingPath,
	makeIcon: ({ currentFormat, computedStyle }) => {
		const lineSpacing =
			lineSpacingFromFormat(currentFormat?.format) ??
			computedStyle?.getPropertyValue("--local-line-height") ??
			DEFAULT_LINE_SPACING
		return (
			<div style={{ position: "relative" }}>
				<SpacingIcon size={28} />
				<div
					style={{
						padding: 1,
						borderRadius: 2,
						position: "absolute",
						bottom: 0,
						left: 0,
						fontSize: 6.5,
						backgroundColor: "#fafafa",
						border: "1px solid #dddddd",
						minHeight: 10,
						minWidth: 10,
						lineHeight: 1.4,
					}}>
					{lineSpacing}
				</div>
			</div>
		)
	},
	// currentFormat?.icon ??
	// LINE_SPACING_OPTIONS.options[computedStyle?.getPropertyValue("line-height")]?.icon ??
	// Object.values(LINE_SPACING_OPTIONS.options)[0].icon,
	options: Object.fromEntries(
		LINE_SPACING_SIZES.map((lineSpacing) => [
			`line-spacing@${lineSpacing.toString()}`,
			makeLineSpacingOption(lineSpacing),
		])
	),
	default: { insertAtPath: { "--local-line-height": null } },
	scrollDirection: "vertical",
	ordered: true,
	dropdownType: "numeric",
}

function makeLineSpacingOption(lineSpacing: number): {
	format: ElementType
	insertAtPath: { "--local-line-height": number }
	icon: JSX.Element
	button: string
} {
	return {
		format: `line-spacing@${lineSpacing.toString()}`,
		insertAtPath: { "--local-line-height": lineSpacing },
		icon: <div style={{ fontSize: 13, maxHeight: 16, lineHeight: 1.2, width: "100%" }}>{lineSpacing.toFixed(1)}</div>,
		button: "block",
	}
}

export const withLineSpacing = withProperties(LINE_SPACING_OPTIONS)
