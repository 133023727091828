import { ToggleSet, ToggleSetV5 } from "../DatabaseObjects/DatabaseObjects"

export const DEFAULT_TOGGLES: Partial<ToggleSet> = {
	includeCandidateInfo: true,
	includeCompanyNames: true,
	includeContactDetails: true,
	includeEducation: true,
	includeExperiences: true,
	includeOriginalCV: false,
	includeIcons: true,
	includeLine: true,
	leftAlignDates: false,
	includeLocation: true,
	includeLogo: true,
	includeName: true,
	includeProfileSummary: true,
	includeRecruiterSummary: true,
	includeHeader: false,
	includeFooter: false,
	includeCoverLetterHeader: false,
	includeCoverLetterFooter: false,
	includeCoverLetter: false,
}

export const CURRENT_DEFAULT_TOGGLES: Partial<ToggleSetV5> = {
	includeContactDetails: true,
	includeName: true,
	includeHeader: false,
	includeFooter: false,
}
