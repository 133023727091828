import "./ColourPicker.css"
import { memo } from "react"
import { useAppContext } from "libs"
import editIcon from "../../images/edit-icon.png"
import { useSafeHistory } from "libs"
import { NoColorSwatch } from "./utils"

// const defaultColors = ['red', 'orange', 'yellow', 'green', 'blue', 'purple', 'indigo', 'black', 'white']
const defaultColors = [
	"var(--red)",
	"var(--orange)",
	"var(--yellow)",
	"var(--green)",
	"var(--blue)",
	"var(--indigo)",
	"var(--purple)",
	"black",
	"white",
]

export const FontColorPicker = memo<{
	currentValue: React.CSSProperties["color"]
	setFormat: (color: React.CSSProperties["color"]) => void
	includeNone?: boolean
}>(({ currentValue, setFormat, includeNone = false }) => {
	const {
		company: { brandColors },
	} = useAppContext()
	const history = useSafeHistory()

	function goToSettings(e) {
		e.preventDefault()
		try {
			history.push("/branding/Brand%20Defaults")
		} catch (e) {
			if (e.message === "Navigation cancelled") {
				console.log(e.message)
			} else {
				throw e
			}
		}
	}

	return (
		<div className="font-color-picker">
			<div className="color-picker-heading">Brand Colours</div>
			{history.navigationAllowed && (
				<img
					alt="edit-icon"
					src={editIcon}
					onClick={goToSettings}
					className="color-picker-settings-icon clickable"></img>
			)}
			<div className="color-swatches">
				{brandColors?.length > 0 ? (
					brandColors.map((color) => (
						<div
							data-testid={"brand-" + color}
							key={color}
							onClick={(e) => {
								e.stopPropagation()
								setFormat(color)
							}}
							className={`clickable color-swatch ${currentValue === color ? "selected-color" : ""}`}
							style={{ backgroundColor: color }}></div>
					))
				) : (
					<div className="color-picker-text">No brand colours set, add colours in settings</div>
				)}
			</div>
			<div className="color-picker-heading">Default Colours</div>
			<div className="color-swatches color-swatches-default">
				{defaultColors.map((color) => (
					<div
						data-testid={"default-" + color}
						key={color}
						onClick={(e) => {
							e.stopPropagation()
							setFormat(color)
						}}
						className={`clickable color-swatch ${currentValue === color ? "selected-color" : ""}`}
						style={{ zIndex: 10, backgroundColor: color }}></div>
				))}
			</div>
			{includeNone && (
				<div className="color-swatches color-swatches-default" style={{ paddingTop: 0 }}>
					<NoColorSwatch
						onClick={(e) => {
							e.stopPropagation()
							setFormat(null)
						}}
						className={`clickable color-swatch ${currentValue === null ? "selected-color" : ""}`}
						style={{ zIndex: 10 }}></NoColorSwatch>
					<span className="no-colour-label">No Colour</span>
				</div>
			)}
		</div>
	)
})
