import {
	deleteIfInEmptyPara,
	ensureSelection,
	isBlockActive,
	setWholeTextSelection,
	useEditorContext,
} from "../../TextFormatting/Utils"
import { CustomEditor, CustomElement } from "../../TextFormatting/types/slate.types"
import { Element, Transforms, Range, Text, Editor } from "slate"
import { ReactEditor, RenderElementProps, useSlate } from "slate-react"

export const renderDividerElement = (props: RenderElementProps) => {
	if (props.element.type === "divider") {
		return <DividerElement {...props} />
	} else {
		return null
	}
}

export const selectDividerElement = (editor: CustomEditor, element: CustomElement) => {
	if (element.type !== "divider") {
		return
	}
	if (editor.children.length === 1 && Element.isElement(editor.children[0]) && editor.children[0].type === "divider") {
		// if a divider is the only thing in the editor it mus tbe the correct one just select all as this is less likely to fail
		setWholeTextSelection(editor)
		return
	}
	const thisPath = ReactEditor.findPath(editor, element.children[0])
	const selection = {
		anchor: Editor.start(editor, thisPath),
		focus: Editor.end(editor, thisPath),
	}
	Transforms.setSelection(editor, selection)
}

const DEFAULT_DIVIDER_PROPERTIES = {
	color: "#cccccc",
	size: 13,
	bold: false,
}

const DividerElement = (props: RenderElementProps) => {
	const editor = useSlate()
	const { editing } = useEditorContext()
	const color =
		(Text.isText(props.element.children[0]) ? props.element.children[0].color : undefined) ??
		DEFAULT_DIVIDER_PROPERTIES.color
	const fontSize =
		(Text.isText(props.element.children[0]) ? props.element.children[0].size : undefined) ??
		DEFAULT_DIVIDER_PROPERTIES.size
	const bold = Text.isText(props.element.children[0]) ? props.element.children[0].bold : DEFAULT_DIVIDER_PROPERTIES.bold
	const [entry] = Editor.nodes(editor, { match: (n) => n === props.element.children[0] })
	const inSelection =
		editing &&
		editor.selection != null &&
		Range.isExpanded(editor.selection) &&
		entry != null &&
		Range.includes(editor.selection, Editor.start(editor, entry[1])) &&
		Range.includes(editor.selection, Editor.end(editor, entry[1]))

	const height = fontSize * 1.5
	const lineThickness = (fontSize / 10) * (bold ? 1.5 : 1)
	return (
		<div
			className="divider-element"
			style={{ backgroundColor: inSelection ? "#b4d5fe" : "transparent" }}
			// contentEditable={false}
			onClick={() => {
				ensureSelection(editor)
				selectDividerElement(editor, props.element)
			}}
			{...props.attributes}>
			<svg width="100%" height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
				<line
					x1={`0`}
					y1={`calc(50% - ${lineThickness / 2}px)`}
					x2={`100%`}
					y2={`calc(50% - ${lineThickness / 2}px)`}
					stroke={color}
					strokeWidth={lineThickness}
				/>
			</svg>
			{props.children}
		</div>
	)
}

// export function expandRangeAroundDivider(editor: CustomEditor, selection: Range) {
// 	if (Range.isCollapsed(selection)) {
// 		return null
// 	}
// 	const dividerEntries = Editor.nodes(editor, {
// 		at: [],
// 		match: (n) => Element.isElement(n) && n.type === "divider",
// 	})
// 	if (dividerEntries == null) {
// 		return null
// 	}
// 	let newSelection = selection
// 	for (const [dividerElement, dividerPath] of dividerEntries) {
// 		if (
// 			newSelection &&
// 			Range.isExpanded(newSelection) &&
// 			isObjEqual(Range.end(newSelection), Editor.before(editor, dividerPath))
// 		) {
// 			//expand selection end to include divider and next point
// 			console.log("expanding selection end")
// 			newSelection = {
// 				anchor: Range.start(newSelection),
// 				focus: Editor.after(editor, dividerPath),
// 			}
// 		}
// 		if (
// 			selection &&
// 			Range.isExpanded(newSelection) &&
// 			isObjEqual(Range.start(newSelection), Editor.after(editor, dividerPath))
// 		) {
// 			//expand selection start to include divider and previous point
// 			console.log("expanding selection start")
// 			newSelection = {
// 				anchor: Editor.before(editor, dividerPath),
// 				focus: Range.end(newSelection),
// 			}
// 		}
// 	}
// 	return newSelection
// }

export const canInsertDivider = (editor) => {
	if (editor.selection == null) {
		return false
	}
	const [insertEntry] = Editor.nodes(editor, {
		at: Range.end(editor.selection),
		match: (n, p) => {
			if (!Element.isElement(n) || n.type !== "paragraph") {
				return false
			}
			const parentEntry = Editor.parent(editor, p)
			const [parent] = parentEntry
			// return Editor.isEditor(parent) || (Element.isElement(parent) && editor.nestedFormatters.includes(parent.type)) // allows in tables
			return Editor.isEditor(parent) || (Element.isElement(parent) && parent.type === "paragraph") // allows only in paras
		},
	})
	return insertEntry != null
}

export const toggleDivider = (editor: CustomEditor, format: string) => {
	if (format !== "divider") {
		return null
	}

	const isActive = isBlockActive(editor, format)
	if (isActive) {
		console.log("removing divider")
		Transforms.removeNodes(editor, { match: (n) => Element.isElement(n) && n.type === "divider" })
	} else {
		console.log("adding divider")
		if (!canInsertDivider(editor)) {
			return true
		}
		Editor.withoutNormalizing(editor, () => {
			// without normalising for case where the empty paragraph is the only in the editor to prevent it being replaced by the normalisation that prevetns an empty editor
			// deleteIfInEmptyPara(editor)
			Transforms.collapse(editor, { edge: "end" })
			Transforms.insertNodes(
				editor,
				{ type: "divider", children: [{ text: "\u200B" }] },
				{
					match: (n, p) => {
						if (!Element.isElement(n) || n.type !== "paragraph") {
							return false
						}
						const parentEntry = Editor.parent(editor, p)
						const [parent] = parentEntry
						return (
							// Editor.isEditor(parent) || (Element.isElement(parent) && editor.nestedFormatters.includes(parent.type)) // allow in tables
							Editor.isEditor(parent) || (Element.isElement(parent) && parent.type === "paragraph") // allow only in paras
						)
					},
					select: true,
				}
			)
			const isActive = isBlockActive(editor, format)
			if (!isActive) {
				// if the divider was not inserted then we return here to avoid accidentally cleaning up incorrectly
				return
			}
			// select divider after adding it
			Transforms.setSelection(editor, {
				anchor: Editor.before(editor, editor.selection.anchor) ?? editor.selection.anchor,
				focus: Editor.after(editor, editor.selection.focus) ?? editor.selection.focus,
			})
			// remove empty paragraphs before the divider if they exist (divider is always put on an empty line below, so this make sit look like it was put on the empty line if we started on one)
			const beforeDivider = Editor.previous(editor, { at: editor.selection })
			if (beforeDivider == null) {
				return
			}
			Transforms.removeNodes(editor, {
				at: beforeDivider[1],
				match: (n) =>
					Element.isElement(n) &&
					n.type === "paragraph" &&
					n.children.length <= 1 &&
					Text.isText(n.children[0]) &&
					n.children[0].text === "",
			})
		})
	}
	return true
}
