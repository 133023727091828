import { applyPlugins, PLUGIN_MAPPING } from "./PluginUtils"
import { createEditorWithPara, slateTextIsEmpty, serialize, deserialize } from "./Utils"
import { Editor } from "slate"
import { CustomEditor, EditorParameters } from "./types/slate.types"
import { TaggingContextValue } from "./TaggingFormatting/TaggingContext"

export function headlessEditor(
	plugins?: string[],
	parameters: EditorParameters = {},
	taggingContext?: TaggingContextValue
): CustomEditor {
	const editor = applyPlugins(createEditorWithPara(parameters), plugins ?? Object.keys(PLUGIN_MAPPING), taggingContext)

	editor.applyHeadless = (func, target, args) => {
		editor.children = deserialize(target)
		editor[func]?.(args)
		return serialize(editor.children)
	}

	return editor
}

export const applyPreprocessing = (value, options, parameters, taggingContext?: TaggingContextValue) => {
	// console.log('applying preprocessing')
	const editor = headlessEditor(options, parameters, taggingContext)
	if (slateTextIsEmpty(value)) {
		return value
	}
	editor.children = value
	Editor.normalize(editor, { force: true })
	return [...editor.children]
}
