import { AraTooltip } from "../AraTooltip/AraTooltip"
import { useWindowDimensions } from "libs"
import { Fragment } from "react"
import { MdError } from "react-icons/md"

const WARNINGS = {
	ai: "AI Responses can be inaccurate or misleading.",
}

export function WarningMessages({
	show = { ai: true },
	style = {},
	minWidth = null,
}: {
	show?: { [message: string]: boolean }
	style?: React.CSSProperties
	minWidth?: number
}) {
	const { width } = useWindowDimensions({ includeWidth: true })
	if (minWidth != null && width < minWidth)
		return (
			<div style={{ opacity: show ? 1 : 0, color: "#888888", display: "flex", alignItems: "center", gap: 3, ...style }}>
				<AraTooltip showOnHover={true} tooltipLabel={<WarningMessageTexts messages={show} />} placement="left">
					<div style={{ flex: "0 0 content" }}>
						<MdError size={24} />
					</div>
				</AraTooltip>
			</div>
		)

	return (
		<div style={{ opacity: show ? 1 : 0, color: "#888888", display: "flex", alignItems: "start", gap: 3, ...style }}>
			<div style={{ flex: "0 0 content" }}>
				<MdError size={24} />
			</div>
			<div style={{ fontSize: 12, marginTop: 3, flex: "0 1 content" }}>
				<WarningMessageTexts messages={show} />
			</div>
		</div>
	)
}

function WarningMessageTexts({ messages }: { messages: { [name: string]: boolean } }) {
	return (
		<div>
			{Object.entries(messages).map(([messageType, show]) =>
				show ? (
					<div key={messageType} style={{ lineHeight: 1.8, maxWidth: 250 }}>
						{WARNINGS[messageType]}
					</div>
				) : (
					<Fragment key={messageType}></Fragment>
				)
			)}
		</div>
	)
}
