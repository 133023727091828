import "../ColourFormatting.css"
import { CustomPlugin } from "../types/slate.types"
import { setLeafUnchanged } from "../Utils"
import { caseOptions } from "./CaseOptions"
import { setCaseLeafProps } from "./CaseUtils"

export const withCase: CustomPlugin = (editor) => {
	const { setLeafProps, customMarkOptions } = editor

	editor.customMarkOptions = {
		...(customMarkOptions ?? {}),
		...caseOptions,
	}

	editor.setLeafProps = (props) => {
		const setPrevLeafProps = setLeafProps ?? setLeafUnchanged
		return setCaseLeafProps(setPrevLeafProps(props))
	}

	return editor
}
