import { Editor, Transforms, Element as SlateElement } from "slate"
import { addPropertiesToNodes } from "../Utils"
import { PropOptionSet, CustomEditor } from "../types/slate.types"
import { getValueAtPath, setValueAtPath, objIsSubset } from "libs"

export const createFormatMap =
	(path: Array<number | string>) =>
	(format: React.CSSProperties): object => {
		const props = {}
		setValueAtPath(props, path, format)
		return props
	}

export const getTypeListFromOptionSet = (optionsSet: PropOptionSet) =>
	Object.values(optionsSet?.options ?? {}).map((item) => item?.format)

export const toggleProperties =
	(typeList: string[], propertyOptionSet: PropOptionSet) =>
	(editor: CustomEditor, format: string): boolean | null => {
		if (!typeList.includes(format)) {
			return null
		}
		const formatPropertyMap = createFormatMap(["props", ...propertyOptionSet.path])
		const isActive = isPropertyActive(propertyOptionSet)(editor, format)
		Editor.withoutNormalizing(editor, () => {
			Transforms.select(editor, Editor.unhangRange(editor, editor.selection))
			const newProperties = formatPropertyMap(
				isActive ? propertyOptionSet.default.insertAtPath : propertyOptionSet.options[format].insertAtPath
			)
			// apply to tagging block elements so that they can be styled in the template page. The properties then get passed through to the tag content in the editor
			addPropertiesToNodes(editor, newProperties, {
				match: (n) =>
					!Editor.isEditor(n) &&
					SlateElement.isElement(n) &&
					(editor.baseBlocks.includes(n.type) ||
						(editor.parameters.templateMode, n.type === "tagging" && n.nodeType === "block")),
			})
		})
		return true
	}

/**
 * Given a property option set and the format, checks if the values are set on a node at the current location.
 * For example, given the alignment option set and format 'center', it will return true if the current node has the following style properties: {textAlign: 'center', listStylePosition: 'outside'}
 * @param propertyOptionSet
 * @returns
 */
export const isPropertyActive =
	(propertyOptionSet: PropOptionSet) =>
	(editor: CustomEditor, format: string): boolean => {
		if (format == null) return false
		const [match] = Editor.nodes(editor, {
			match: (n) =>
				!Editor.isEditor(n) &&
				SlateElement.isElement(n) &&
				objIsSubset(propertyOptionSet.options[format].insertAtPath, getValueAtPath(n?.props, propertyOptionSet.path)),
		})
		return !!match
	}

// const isDefaultPropertyActive =
// 	(propertyOptionSet) =>
// 	(editor: CustomEditor, { at }) => {
// 		const [match] = Editor.nodes(editor, {
// 			at,
// 			match: (n) =>
// 				!Editor.isEditor(n) &&
// 				SlateElement.isElement(n) &&
// 				objIsSubset(propertyOptionSet.default.insertAtPath, getValueAtPath(n?.props, propertyOptionSet.path)),
// 		})
// 		return !!match
// 	}
