import { LoaderButton, SearchBar, SortableTableCard, useSearch } from "components"
import {
	adminGetClients,
	colorByValue,
	getCurrentMonth,
	getCurrentYear,
	sortBy,
	useMountEffect,
	useWindowDimensions,
	retrieveStickyValue,
	CustomOutput,
	getWorkingDayProportionThisMonth,
	downloadCSV,
	Indexed,
	Client,
	alphabeticalComparer,
	getPreviousMonth,
	DEFAULT_AVAILABLE_CUSTOM_TAGS,
	clientApi,
	ClientOptions,
} from "libs"
import { useCallback, useMemo, useState } from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { useHistory } from "react-router-dom"
import { utils } from "../Utils/utils"
import "./AdminClientTable.css"
import { AraTooltip } from "components/src/AraTooltip/AraTooltip"
import { INTEGRATION_TYPES } from "components"

function getCreditUsage(calendarMonthUsage, monthlyCredits) {
	if (!calendarMonthUsage || !monthlyCredits) {
		return [0, 0]
	}
	const creditUsage = (parseInt(calendarMonthUsage) / (parseInt(monthlyCredits) || 1)) * 100
	const burnRate = creditUsage / getWorkingDayProportionThisMonth()
	return [creditUsage, burnRate] as const
}

const integrationTypeMapping: { [integrationType: string]: { tags: string; description: string } } = Object.fromEntries(
	INTEGRATION_TYPES.map((key) => [[key], { tags: `@${key}`, description: `Has ${key} integration enabled` }])
)

const searchTermOptionMapping: { [option: string]: { tags: string; description: string } } = {
	textFormatterAiOptions: { tags: "@ai", description: "Has AI options enabled in the editor" },
	formattedWord: { tags: "@word", description: "Has word output enabled" },
	url: { tags: "@digitalcv", description: "Has digital CV output enabled" },
	jobDescription: { tags: "@jobdescr", description: "Has job description enabled" },
	folders: { tags: "@folders", description: "Has folders enabled" },
	uniqueLinks: { tags: "@landing", description: "Has landing pages enabled" },
	fullJobDescription: { tags: "@fittorole", description: "Has fit to role enabled" },
}

const OTHER_SEARCH_TAGS: { [option: string]: { tags: string; description: string } } = {
	aiTags: { tags: "@tags", description: "Has at least one AI Tag set" },
	over: { tags: "@over", description: "Clinet is over their monthly credit limit" },
	br100: { tags: "@br>100%", description: "Client is estimated to pass 100% of their credits this month" },
	br90: { tags: "@br>90%", description: "Client is estimated to pass 90% of their credits this month" },
	br75: { tags: "@br<75%", description: "Client is estimated to use under 75% of their credits this month" },
	br50: { tags: "@br<50%", description: "Client is estimated to use under 50% of their credits this month" },
	br25: { tags: "@br<25%", description: "Client is estimated to use under 25% of their credits this month" },
	uc0: { tags: "@uc<0%", description: "Client is estimated to use less credits this month than last month" },
	uc10: { tags: "@uc<-10%", description: "Client is estimated to use 10% less credits this month than last month" },
	uc20: { tags: "@uc<-20%", description: "Client is estimated to use 20% less credits this month than last month" },
	flagRed: { tags: "@flagRed", description: "Client has a red flag" },
	flagAmber: { tags: "@flagAmber", description: "Client has an amber flag" },
	flagGreen: { tags: "@flagGreen", description: "Client has a green flag" },
	whale: { tags: "@whale", description: "Client is a potential whale" },
}

const FLAGS = {
	default: { icon: "—", position: 0 },
	green: { icon: "🟢", position: 1 },
	amber: { icon: "🟠", position: 2 },
	red: { icon: "🔴", position: 3 },
}

const thisFlagIcon = (flag) => {
	return FLAGS[flag ?? "default"]?.icon
}

const nextFlag = (flag) => {
	const keys = Object.keys(FLAGS)
	const index = flag == null ? 0 : keys.indexOf(flag)
	return keys[(index + 1) % keys.length]
}

const PRIORITIES = ["default", 12]
const PRIORITY_ICONS = {
	default: { icon: "—" },
	12: { icon: "🐳" },
}

const thisPriorityIcon = (priority) => {
	return PRIORITY_ICONS[priority ?? "default"]?.icon
}

const nextPriority = (priority) => {
	const keys = PRIORITIES
	const index = priority == null ? 0 : keys.indexOf(priority)
	const nextIndex = (index + 1) % keys.length
	if (nextIndex === 0) {
		return null
	}
	return keys[nextIndex] as number
}

function makeOptionSearchTerms(clientOptions: ClientOptions) {
	if (!clientOptions) {
		return ""
	}
	const flags = Object.entries(clientOptions?.flags ?? {}).reduce((acc, [key, value]) => {
		if (value) {
			acc += ` ${searchTermOptionMapping[key]?.tags}`
		}
		return acc
	}, "")

	const outputs = Object.values(clientOptions?.outputs ?? {}).reduce((acc, output: CustomOutput) => {
		if (searchTermOptionMapping[output.dataType] != null) {
			acc += ` ${searchTermOptionMapping[output.dataType]?.tags}`
		}
		if (integrationTypeMapping[output.integrationType] != null) {
			acc += ` ${integrationTypeMapping[output.integrationType]?.tags}`
		}
		return acc
	}, "")

	const customTags = Object.keys(clientOptions?.customTags ?? {}).length > 0 ? " @tags" : ""
	return flags + " " + outputs + "" + customTags
}

export function AdminClientTable(props) {
	var [isRefreshing, setRefreshing] = useState(false)
	const history = useHistory()
	const sortFunc = useCallback(
		(a, b) => sortBy(a?.monthlyCredits, b?.monthlyCredits) || sortBy(a?.calendarYearUsage, b?.calendarYearUsage),
		[]
	)
	const { width } = useWindowDimensions({ includeHeight: false })
	const [clients, setClients] = useState<Indexed<Client>>({})
	const [pageNumber, setPageNumber] = useState(0)

	const getRowInfo = useCallback(
		(rowInfo: Client) => {
			const clientId = rowInfo?.pk?.slice(4, 40)
			const calendarMonthUsage = getCurrentMonth(rowInfo?.stats?.all)
			const prevCalendarMonthUsage = getPreviousMonth(rowInfo?.stats?.all)
			const prev2CalendarMonthUsage = getPreviousMonth(rowInfo?.stats?.all, 2)
			const prev3CalendarMonthUsage = getPreviousMonth(rowInfo?.stats?.all, 3)
			const prev4CalendarMonthUsage = getPreviousMonth(rowInfo?.stats?.all, 4)
			const prev5CalendarMonthUsage = getPreviousMonth(rowInfo?.stats?.all, 5)
			const monthlyCredits = rowInfo?.credits?.monthly
			const [creditUsage, burnRate] = getCreditUsage(calendarMonthUsage, monthlyCredits)
			const [prevCreditUsage] = getCreditUsage(prevCalendarMonthUsage, monthlyCredits)
			return {
				id: rowInfo?.client_id ?? "-",
				account: clients?.[clientId]?.sk?.split("#")?.[1] ?? "-",
				status: utils.getStatusColor(rowInfo?.miscData.split("#")[1] ?? "-", rowInfo?.freeTrialEnd),
				statusRaw: rowInfo?.miscData.split("#")[1] ?? "-",
				paymentType: rowInfo?.paymentType ?? "-",
				"calendarMonthUsage-1": prevCalendarMonthUsage,
				"calendarMonthUsage-2": prev2CalendarMonthUsage,
				"calendarMonthUsage-3": prev3CalendarMonthUsage,
				"calendarMonthUsage-4": prev4CalendarMonthUsage,
				"calendarMonthUsage-5": prev5CalendarMonthUsage,
				calendarMonthUsage: calendarMonthUsage ?? "-",
				calendarYearUsage: getCurrentYear(rowInfo?.stats?.all) ?? "-",
				monthlyCredits: rowInfo?.credits?.monthly,
				burnRate,
				creditUsage,
				usageChange: burnRate - prevCreditUsage,
				billingStartDate: rowInfo?.billingDate,
				tagsUsed: Object.keys(rowInfo?.options?.customTags?.tags ?? {}).length,
				tagsAvailable: rowInfo?.options?.customTags?.availableTags ?? DEFAULT_AVAILABLE_CUSTOM_TAGS,
				flag: rowInfo?.manualTracking?.flag ?? null,
				priority: rowInfo?.manualTracking?.priority ?? null,
			}
		},
		[clients]
	)
	const searchData = useMemo(() => {
		return {
			id: (client) => client?.client_id ?? "",
			account: (client) => clients?.[client?.client_id]?.sk?.split("#")?.[1] ?? "",
			status: (client) => client?.miscData.split("#")[1] ?? "",
			paymentType: (client) => client?.paymentType ?? "",
			calendarMonthUsage: (client) => getCurrentMonth(client?.stats?.all) ?? "",
			calendarYearUsage: (client) => getCurrentYear(client?.stats?.all) ?? "",
			monthlyCredits: (client) => client?.credits?.monthly ?? "",
			creditUsage: (client) => {
				const monthlyCredits = client?.credits?.monthly
				const prevCalendarMonthUsage = getPreviousMonth(client?.stats?.all)
				const [creditUsage, burnRate] = getCreditUsage(getCurrentMonth(client?.stats?.all), client?.credits?.monthly)
				const [prevCreditUsage] = getCreditUsage(prevCalendarMonthUsage, monthlyCredits)
				const usageChange = burnRate - prevCreditUsage
				let searchString = creditUsage > 100 ? "@over" : ""
				if (burnRate > 100) {
					searchString += " @br>100%"
				}
				if (burnRate > 90) {
					searchString += " @br>90%"
				}
				if (burnRate < 75) {
					searchString += " @br<75%"
				}
				if (burnRate < 50) {
					searchString += " @br<50%"
				}
				if (burnRate < 25) {
					searchString += " @br<25%"
				}
				if (usageChange < 0) {
					searchString += " @uc<0%"
				}
				if (usageChange < -10) {
					searchString += " @uc<-10%"
				}
				if (usageChange < -20) {
					searchString += " @uc<-20%"
				}
				if (client?.manualTracking?.flag === "red") {
					searchString += " @flagRed"
				}
				if (client?.manualTracking?.flag === "amber") {
					searchString += " @flagAmber"
				}
				if (client?.manualTracking?.flag === "green") {
					searchString += " @flagGreen"
				}
				if (client?.manualTracking?.priority === 12) {
					searchString += " @whale"
				}
				return searchString
			},
			options: (client) => makeOptionSearchTerms(client?.options),
		}
	}, [clients])
	const makeSearchString = useCallback(
		(client) => {
			return Object.values(searchData)
				.map((searchFunc) => searchFunc(client))
				.join(" ")
		},
		[searchData]
	)

	const [searchTermRef, setSearchTermBase, filteredClients] = useSearch<Client>(
		clients,
		makeSearchString,
		"template-table"
	)

	const getTableInfo = useCallback(
		(appsInfo) => {
			return appsInfo.reduce((accumulator, appInfo) => {
				accumulator[appInfo.pk || "-"] = getRowInfo(appInfo)
				return accumulator
			}, {})
		},
		[getRowInfo]
	)

	const tableInfo = useMemo(() => getTableInfo(Object.values(filteredClients)), [filteredClients, getTableInfo])

	const setSearchTerm = useCallback(
		(...args: [string]) => {
			setSearchTermBase(...args)
			setPageNumber(0)
		},
		[setSearchTermBase]
	)

	const loadData = useCallback(() => {
		setRefreshing(true)
		adminGetClients({}).then((clients) => {
			setClients(clients)
			setRefreshing(false)
		})
	}, [])

	useMountEffect(() => {
		loadData()
	})

	const refresh = useCallback(
		(event: Event) => {
			event?.preventDefault?.()
			let newApps = {}
			loadData()
			return newApps
		},
		[loadData]
	)

	const makeTable = useCallback(() => {
		function goToApplication(rowId) {
			const tab =
				retrieveStickyValue({ storageName: "clientDetailsTab", sessionOnly: false, userId: "admin" }) ?? "account"
			history.push("/client/" + rowId + "/" + tab)
		}

		const showFullTableContents = width >= 768

		const headings = showFullTableContents
			? [
					"Account",
					"Status",
					"Payment Type",
					"Monthly Credits",
					"Calendar Month Usage ",
					"Calendar Year Usage",
					"Credit Usage",
					"Burn Rate",
					"Est. Monthly Usage Change",
					"Flag",
					"Priority",
				]
			: ["Account", "Status", "Calendar Month Usage ", "Monthly Credits"]

		const infoKeys = showFullTableContents
			? [
					"account",
					"status",
					"paymentType",
					"monthlyCredits",
					"calendarMonthUsage",
					"calendarYearUsage",
					"creditUsage",
					"burnRate",
					"usageChange",
					"flag",
					"priority",
				]
			: ["account", "status", "calendarMonthUsage", "monthlyCredits"]

		const preprocessing = showFullTableContents
			? [
					null,
					null,
					utils.toTitleCase,
					null,
					null,
					null,
					(a) => colorByValue(a, a.toFixed(0) + "%"),
					(a) => colorByValue(a, a.toFixed(0) + "%"),
					(a) => colorByValue(a, a > 0 ? `+${a.toFixed(0)}%` : `${a.toFixed(0)}%`, 0, false),
					(a, rowId) => (
						<div
							className="clickable"
							style={{
								width: "100%",
								minHeight: 32,
								height: "calc(100% + 12px)",
								fontWeight: 500,
								marginTop: "-6px",
								marginBottom: "-6px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
							onClick={(e) => {
								e.stopPropagation()
								const flag = nextFlag(a)
								clientApi.patch({ clientId: rowId, updates: { manualTracking: { flag } }, changesOnly: true })
								const newClients = { ...clients }
								newClients[rowId].manualTracking = newClients[rowId].manualTracking ?? {}
								newClients[rowId].manualTracking.flag = flag
								setClients(newClients)
							}}>
							{thisFlagIcon(a)}
						</div>
					),
					(a, rowId) => (
						<div
							className="clickable"
							style={{
								width: "100%",
								minHeight: 32,
								height: "calc(100% + 12px)",
								fontWeight: 500,
								marginTop: "-6px",
								marginBottom: "-6px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
							onClick={(e) => {
								e.stopPropagation()
								const priority = nextPriority(a)
								clientApi.patch({ clientId: rowId, updates: { manualTracking: { priority } }, changesOnly: true })
								const newClients = { ...clients }
								newClients[rowId].manualTracking = newClients[rowId].manualTracking ?? {}
								newClients[rowId].manualTracking.priority = priority
								setClients(newClients)
							}}>
							{thisPriorityIcon(a)}
						</div>
					),
				]
			: [null, null, null, null]

		const individualSortFuncs = showFullTableContents
			? [
					(a, b) => -alphabeticalComparer(a.account, b.account),
					(a, b) => -alphabeticalComparer(a.status, b.status),
					(a, b) => alphabeticalComparer(a.paymentType, b.paymentType),
					(a, b) => parseInt(b.monthlyCredits) - parseInt(a.monthlyCredits),
					(a, b) => parseInt(b.calendarMonthUsage) - parseInt(a.calendarMonthUsage),
					(a, b) => parseInt(b.calendarYearUsage) - parseInt(a.calendarYearUsage),
					(a, b) => parseInt(b.creditUsage) - parseInt(a.creditUsage),
					(a, b) => parseInt(b.burnRate) - parseInt(a.burnRate),
					(a, b) => parseInt(b.usageChange) - parseInt(a.usageChange),
					(a, b) => FLAGS[b.flag ?? "default"].position - FLAGS[a.flag ?? "default"].position,
					(a, b) => parseInt(b.priority ?? 0) - parseInt(a.priority ?? 0),
				]
			: [
					(a, b) => -alphabeticalComparer(a.name, b.name),
					(a, b) => -alphabeticalComparer(a.name, b.name),
					null,
					(a, b) => parseInt(a.monthlyCredits) - parseInt(b.montlyCredits),
				]

		return (
			<SortableTableCard
				headings={headings}
				infoKeys={infoKeys}
				rowIdKey="id"
				rowFunc={goToApplication}
				info={Object.values(tableInfo)}
				sortFunc={sortFunc}
				summaryRow={[
					() => (searchTermRef.current === "" ? "Summary" : `"${searchTermRef.current}" Search Summary`),
					null,
					null,
					columnSum("monthlyCredits"),
					columnSum("calendarMonthUsage"),
					columnSum("calendarYearUsage"),
					columnAverage("creditUsage"),
					columnAverage("burnRate"),
					columnAverage("usageChange"),
				]}
				refreshProps={{ onClick: refresh, isLoading: isRefreshing }}
				preprocessing={preprocessing}
				pageNumber={pageNumber}
				setPageNumber={setPageNumber}
				individualSortFuncs={individualSortFuncs}
			/>
		)
	}, [width, tableInfo, sortFunc, refresh, isRefreshing, pageNumber, history, clients, searchTermRef])

	const table = useMemo(makeTable, [makeTable])

	function downloadCsv() {
		const contents = {
			id: {},
			account: {},
			status: { fieldName: "statusRaw" },
			paymentType: {},
			calendarMonthUsage: {},
			"calendarMonthUsage-1": {},
			"calendarMonthUsage-2": {},
			"calendarMonthUsage-3": {},
			"calendarMonthUsage-4": {},
			"calendarMonthUsage-5": {},
			calendarYearUsage: {},
			monthlyCredits: {},
			burnRate: {},
			creditUsage: {},
			usageChange: {},
			tagsUsed: {},
			billingStartDate: {},
			tagsAvailable: {},
		}
		const keys = Object.keys(contents)
		const csvData = Object.values(tableInfo).map((client) => {
			return keys.reduce((obj, key) => {
				const preprocessing = contents[key]?.preprocess ?? ((a) => a)
				const value = client[contents[key]?.fieldName ?? key]
				// console.log(key, value)
				return {
					...obj,
					[key]: preprocessing(value),
				}
			}, {})
		})
		downloadCSV(csvData, keys, "clientStats.csv")
	}

	function displayTable() {
		return (
			<div className="applications">
				<ControlBar searchTermRef={searchTermRef} setSearchTerm={setSearchTerm} />
				<div style={{ width: "100%", textAlign: "right" }}>
					<LoaderButton className="SquarerLoaderButton" onClick={downloadCsv}>
						Download CSV
					</LoaderButton>
				</div>
				<Row className="justify-content-center" style={{ width: "100%", marginLeft: 0, marginRight: 0 }}>
					<Col xs={12} sm={10} style={{ paddingLeft: 0, paddingRight: 0 }}>
						{table}
					</Col>
				</Row>
			</div>
		)
	}

	return displayTable()
}

const columnSum = (key) => (info) => {
	return info.reduce((acc, curr) => acc + parseInt(curr[key] ?? 0), 0)
}

const columnAverage = (key) => (info) => {
	return info.reduce((acc, curr) => acc + parseInt(curr[key] ?? 0), 0) / info.length
}

function makeTooltipText(search) {
	return (
		<div style={{ textAlign: "start", color: "white" }}>
			<div style={{ marginBottom: 8 }}>
				<span style={{ fontSize: 15 }}>Options</span>
				{Object.values(searchTermOptionMapping).map(({ tags, description }) => (
					<div style={{ display: "flex", color: tags.includes(search) ? "white" : "#aaaaaa" }} key={tags}>
						<div style={{ width: 90 }}>{tags}:</div>
						<div>{description}</div>
					</div>
				))}
			</div>
			<div style={{ marginBottom: 8 }}>
				<span style={{ fontSize: 15 }}>Integrations</span>
				{Object.values(integrationTypeMapping).map(({ tags, description }) => (
					<div style={{ display: "flex", color: tags.includes(search) ? "white" : "#aaaaaa" }} key={tags}>
						<div style={{ width: 90 }}>{tags}:</div>
						<div>{description}</div>
					</div>
				))}
			</div>
			<div style={{ marginBottom: 8 }}>
				<span style={{ fontSize: 15 }}>Data</span>
				{Object.values(OTHER_SEARCH_TAGS).map(({ tags, description }) => (
					<div style={{ display: "flex", color: tags.includes(search) ? "white" : "#aaaaaa" }} key={tags}>
						<div style={{ width: 90 }}>{tags}:</div>
						<div>{description}</div>
					</div>
				))}
			</div>
		</div>
	)
}

const ControlBar = ({ searchTermRef, setSearchTerm }) => {
	return (
		<Row className="admin-client-control-bar-container">
			<Col xs="auto">
				<div className="admin-client-control-bar-title">
					{searchTermRef.current === "" ? "all accounts" : `Search results: "${searchTermRef.current}"`}
				</div>
			</Col>
			<Col>
				<Row>
					<Col xs={12} sm="auto" className="admin-client-control-bar-search-container">
						<AraTooltip
							className="admin-search-tooltip"
							placement="left"
							tooltipLabel={makeTooltipText(searchTermRef.current)}
							style={{ width: 400 }}>
							<SearchBar searchTermRef={searchTermRef} setSearchTerm={setSearchTerm} />
						</AraTooltip>
					</Col>
				</Row>
			</Col>
		</Row>
	)
}
