import {
	FeatureTag,
	NewTemplatedApp,
	NewTemplating,
	OldTemplating,
	TemplateChapter,
	OrderableChapter,
	TemplatedApp,
	Templating,
	TwoWayMap,
	DEFAULT_TOGGLES,
	deepCopy,
	CURRENT_DEFAULT_TOGGLES,
} from "libs"
import type {
	Version,
	Feature,
	ToggleSet,
	Template,
	TemplateVersion4,
	TemplatePreVersion4,
	SingleTextTemplateChapter,
	CVTemplateChapter,
	ToggleSetV4,
	ItemMap,
	TemplateItem,
	TwoWayLabelMapping,
	TimelineData,
	OldTemplatingV3,
	NewTemplatingV4,
	NewTemplatingV5,
	TemplateVersion5,
} from "libs"
import { LATEST_TEMPLATE_VERSION, ORDERABLE_CHAPTER_TYPES, LABEL_MAPPINGS, TOGGLE_MAPPING } from "./constants"
import { SplitFeature, CurrentTemplate, ItemCount } from "./TemplateVersioning.types"
import {
	makeTLTagSection,
	makeDateTagSection,
} from "../../CVViewing/CVCardComponents/TimelineComponents/Utils/makeTLTagSection"

export const getLabelMapping = (version: Version): TwoWayLabelMapping => {
	return new TwoWayMap(LABEL_MAPPINGS[version])
}

const sortByIdAlphabetically = (a: TemplateItem, b: TemplateItem) => (b.id >= a.id ? -1 : 1) // assume all unique

const latestLabelMapping = getLabelMapping("4") // label mapping shouold not update anymore

const getElementNo = (id: string, itemMap: ItemMap, labelMapping: TwoWayLabelMapping = latestLabelMapping) => {
	const idParts = id.split("-") as [Feature, string] | [Feature]
	const n = parseInt(labelMapping.backward(idParts[0])) + itemMap[idParts[0]][id]
	return n
}

export const makeItemMap = (templateItems: TemplateItem[]): ItemMap => {
	return [...templateItems].sort(sortByIdAlphabetically).reduce((itemMap, item) => {
		const [label] = item.id.split("-") as [Feature, string] | [Feature]
		if (itemMap[label]) {
			itemMap[label][item.id] = Object.keys(itemMap[label]).length
		} else {
			itemMap[label] = { [item.id]: 0 }
		}
		return itemMap
	}, {} as ItemMap)
}

function sortSplitFeature(a: SplitFeature, b: SplitFeature): number {
	const [, aLabel, aOrder = "0"] = a
	const [, bLabel, bOrder = "0"] = b
	if (aLabel > bLabel) {
		return 1
	}
	if (bLabel > aLabel) {
		return -1
	}
	return parseInt(aOrder) - parseInt(bOrder)
}

export const makeV4ItemMap = (chapters: TemplateChapter[]): ItemMap => {
	const itemMap = {} as ItemMap
	chapters?.forEach((chapter) => {
		if (ORDERABLE_CHAPTER_TYPES.includes(chapter.type)) {
			let ordering = [...((chapter as OrderableChapter)?.ordering ?? [])]
			let splitOrdering: SplitFeature[] = ordering.map((item) => [
				item,
				...(item.split("-") as [Feature, string] | [Feature]),
			])
			splitOrdering?.sort(sortSplitFeature)?.forEach(([item, label]) => {
				if (itemMap[label]) {
					itemMap[label][item] = Object.keys(itemMap[label]).length
				} else {
					itemMap[label] = { [item]: 0 }
				}
			})
		}
	})
	return itemMap
}

export const createOrdering = (
	templateItems: TemplateItem[],
	labelMapping: TwoWayLabelMapping = latestLabelMapping
): [number[], Partial<ToggleSet>] => {
	const itemMap = makeItemMap(templateItems)
	const freeTextStart = labelMapping.length - 1
	var existingOrdering = templateItems.map((item) => getElementNo(item.id, itemMap, labelMapping))
	// existing ordering doesn't necessarily contain all elements,
	// need to add them and return a false for the relevant toggle
	var toggles: Partial<ToggleSet> = TOGGLE_MAPPING.reduce((toggleObj, toggle) => {
		toggleObj[toggle] = true
		return toggleObj
	}, {})
	for (var idx = 0; idx < freeTextStart; idx++) {
		if (!existingOrdering.includes(idx)) {
			toggles[TOGGLE_MAPPING[idx]] = false
			existingOrdering.splice(idx, 0, idx)
		}
	}
	return [existingOrdering, toggles]
}

const getElementFromNumber = (
	n: number,
	toggles: Partial<ToggleSet>,
	labelMapping: TwoWayLabelMapping = latestLabelMapping
): TemplateItem => {
	const freeTextStart = labelMapping.length - 1
	if (n < freeTextStart) {
		if (!toggles?.[TOGGLE_MAPPING[n]]) {
			return null
		}
		return { id: labelMapping.forward(n) }
	} else if (n === freeTextStart) {
		return { id: labelMapping.forward(n) }
	} else {
		return { id: `${labelMapping.forward(freeTextStart)}-${n - freeTextStart}` }
	}
}

export const createTemplateItemsFromOrdering = (
	ordering: number[],
	toggles: Partial<ToggleSet>,
	labelMapping: TwoWayLabelMapping = latestLabelMapping
) => {
	const templateItems = ordering?.map((n) => getElementFromNumber(n, toggles, labelMapping)) ?? []
	return templateItems.filter((i) => i !== null)
}

export const makeItemCountsForChapters = (chapters: TemplateChapter[]): ItemCount => {
	// generate item counts from highest idx on labels,
	// the item counts will be used for the index of the next item of that type
	//
	let counts: ItemCount = {
		freeText: 0,
		profileSummary: 0,
		experienceEducation: 0,
		experience: 0,
		education: 0,
		recruiterSummary: 0,
		candidateInfo: 0,
		skills: 0,
		interests: 0,
		originalCV: 0,
		requirementsTable: 0,
		summaryTimeline: 0,
		candidateHeadshot: 0,
	}
	chapters?.forEach((chapter) => {
		if (chapter?.type === "cv") {
			chapter?.ordering?.forEach((item) => {
				const itemType = item.split("-")[0] as Feature
				counts[itemType] = (counts[itemType] ?? 0) + 1
			})
		}
	})
	return counts
}

export const makeItemCounts = (ordering: number[], toggleSettings: ToggleSet): ItemCount => {
	// generate item counts from highest idx on labels,
	// the item counts will be used for the index of the next item of that type
	//
	return (ordering ?? []).reduce(
		(itemCounts, orderingItem) => {
			const [key, idx = "0"] = getElementFromNumber(orderingItem, toggleSettings)?.id?.split("-") ?? [null]
			if (key != null) itemCounts[key] = Math.max(itemCounts[key] ?? 0, parseInt(idx) + 1)
			return itemCounts
		},
		{
			freeText: 0,
			profileSummary: 0,
			experienceEducation: 0,
			experience: 0,
			education: 0,
			recruiterSummary: 0,
			candidateInfo: 0,
			skills: 0,
			interests: 0,
			originalCV: 0,
			requirementsTable: 0,
			summaryTimeline: 0,
			candidateHeadshot: 0,
		}
	)
}

function toggles(template: Partial<OldTemplating>): Partial<ToggleSet> {
	return (template as TemplatedApp)?.toggles ?? (template as TemplatePreVersion4)?.toggleSettings ?? DEFAULT_TOGGLES
}

function getPreset(toggleSettings?: Partial<ToggleSetV4>) {
	if (toggleSettings?.includeLine) {
		return "preset-style-1"
	}
	if (toggleSettings?.leftAlignDates) {
		return "preset-style-3"
	}
	return "preset-style-2"
}

const bumpToVersion3 = (template: OldTemplating): Partial<OldTemplating> => {
	// takes version 3 or below and bumps to 3
	if (template == null || !Object.keys(template).length) {
		return { templateVersion: "3" }
	} //brand new template
	const currentVersion = template?.templateVersion ?? "0"
	template.templateVersion = currentVersion // ensure version set for typing
	const currentLabelMapping = getLabelMapping(currentVersion)
	const isUpToDate = currentVersion === "3"
	let extraToggles: Partial<ToggleSet> = {}
	if (isUpToDate) {
		return {
			...template,
			templateVersion: "3",
			toggleSettings: { ...toggles(template as OldTemplating), ...extraToggles },
		}
	}
	if (template?.templateVersion === "0" || template?.templateVersion === "1") {
		//convert logo setting to header if no headerInfo available and version is pre-headers
		if ((toggles(template)?.includeLogo ?? DEFAULT_TOGGLES.includeLogo) && toggles(template)?.includeHeader == null) {
			template.headerInfo = { header: { 4: { type: "logo", size: 1 } }, footer: {} }
			extraToggles.includeHeader = true
			extraToggles.includeFooter = false
		} else {
			template.headerInfo = { header: {}, footer: {} }
			extraToggles.includeHeader = false
			extraToggles.includeFooter = false
		}
	}

	const [ordering, toggleSettings] = createOrdering(
		createTemplateItemsFromOrdering(template?.ordering ?? [0, 1, 2, 3], toggles(template), currentLabelMapping),
		getLabelMapping("3")
	)
	const templateV3: OldTemplating = {
		...template,
		ordering,
		toggleSettings: { ...toggles(template), ...toggleSettings, ...extraToggles },
		templateVersion: "3",
	}
	return templateV3
}

const bumpToVersion4 = (template: OldTemplatingV3): Partial<NewTemplatingV4> => {
	// takes version 3 and bumps to 4
	if (template == null || !Object.keys(template).length) {
		return { templateVersion: "4" }
	} //brand new template
	const currentVersion = "3"
	template.templateVersion = currentVersion // ensure version set for typing
	let extraToggles: Partial<ToggleSet> = {}
	const [ordering, toggleSettings] = createOrdering(
		createTemplateItemsFromOrdering(template?.ordering ?? [0, 1, 2, 3], toggles(template), getLabelMapping("4")),
		getLabelMapping("4")
	)
	const templateV3: OldTemplatingV3 = {
		...template,
		ordering,
		toggleSettings: { ...toggles(template), ...toggleSettings, ...extraToggles },
		templateVersion: "3",
	}
	// TODO: handle version 4
	const coverLetterChapter = convertCoverLetterToChapter(templateV3)
	const cvChapter = convertCVToChapter(templateV3)
	delete templateV3.coverLetter
	delete templateV3.toggleSettings
	delete templateV3.toggles
	delete templateV3.ordering
	delete templateV3.headerInfo
	let newTemplate: Partial<TemplateVersion4> = {
		...templateV3,
		templateVersion: "4",
		chapters: [coverLetterChapter, cvChapter].filter((c) => c != null),
	}
	return newTemplate
}

function convertTimelines(template: NewTemplatingV4): NewTemplatingV5 {
	const cvChapters = template.chapters.filter((c) => c.type === "cv") as CVTemplateChapter[]
	const cvChapterKey = Object.keys(template.chapters).find((key) => template.chapters[key] === cvChapters[0])
	const cvChapter = deepCopy(cvChapters[0])
	let hasExperienceEducation = cvChapter?.ordering?.includes("experienceEducation")

	const timelineData: TimelineData = {}
	if (hasExperienceEducation) {
		const toggles = { ...DEFAULT_TOGGLES, ...cvChapter?.toggleSettings }
		const preset = getPreset(toggles)
		timelineData.experienceEducation = {
			// change for ed vs exp
			preset,
			qualifications: {
				name: makeTLTagSection("qualificationName", toggles.includeIcons),
				place: toggles.includeCompanyNames ? makeTLTagSection("institution", toggles.includeIcons) : undefined,
				extra: toggles.includeLocation
					? makeTLTagSection(
							!toggles.includeLine && toggles.leftAlignDates ? "location" : "classification",
							toggles.includeIcons,
							preset === "preset-style-3"
						)
					: undefined,
				text: `[{"type": "tagging", "nodeType": "block", "tagging": "comments", "children": [{"text": ""}]}]`,
				date: makeDateTagSection(preset),
			},
			experiences: {
				name: makeTLTagSection("jobTitle", toggles.includeIcons),
				place: toggles.includeCompanyNames ? makeTLTagSection("company", toggles.includeIcons) : undefined,
				extra: toggles.includeLocation
					? makeTLTagSection("location", toggles.includeIcons, preset === "preset-style-3")
					: undefined,
				text: `[{"type": "tagging", "nodeType": "block", "tagging": "comments", "children": [{"text": ""}]}]`,
				date: makeDateTagSection(preset),
			},
			type: toggles.includeEducation ? "both" : "experience",
			order: template?.timelineOrder ?? "Chronological",
			experienceHeading: template?.experienceHeading,
			educationHeading: template?.educationHeading,
		}
	}

	delete cvChapter.toggleSettings?.includeLine
	delete cvChapter.toggleSettings?.leftAlignDates
	delete cvChapter.toggleSettings?.includeIcons
	delete cvChapter.toggleSettings?.includeEducation
	delete cvChapter.toggleSettings?.includeLocation
	delete cvChapter.toggleSettings?.includeCompanyNames
	const newChapters = [...template.chapters]
	newChapters[cvChapterKey] = cvChapter
	const newTemplate = { ...template, chapters: newChapters, templateVersion: "5", timelineData } as TemplateVersion5
	delete newTemplate?.timelineOrder
	delete newTemplate?.experienceHeading
	delete newTemplate?.educationHeading
	return newTemplate
}

function bumpToVersion5(template: NewTemplatingV4): NewTemplatingV5 {
	if (parseInt(template.templateVersion) < 4) {
		throw Error("Old template versions not supported in this function")
	}
	return convertTimelines(template)
}

export const LATEST_EMPTY_TEMPLATE = {
	templateVersion: LATEST_TEMPLATE_VERSION,
	chapters: [{ type: "cv", name: "CV", headerInfo: {}, ordering: [], toggleSettings: CURRENT_DEFAULT_TOGGLES }],
}

export const convertToVersion = (template: Partial<Templating>, version: Version): Partial<Templating> => {
	if (template == null) {
		return { templateVersion: version }
	}
	if (template?.templateVersion === version) {
		// already correct
		return template
	}
	if (template?.templateVersion === LATEST_TEMPLATE_VERSION) {
		// can't go higher, return
		return template
	}
	let bumpedTemplate: Partial<Templating>
	if (template?.templateVersion === "4" && version !== "4") {
		// no version, assume latest
		bumpedTemplate = bumpToVersion5(template as NewTemplatingV4)
	}
	if (template?.templateVersion === "3" && version !== "3") {
		// no version, assume latest
		bumpedTemplate = bumpToVersion4(template as OldTemplatingV3)
	}
	if ([undefined, null, "0", "1", "2"].includes(template?.templateVersion)) {
		// no version, assume latest
		bumpedTemplate = bumpToVersion3(template as OldTemplating)
	}
	return convertToVersion(bumpedTemplate, version)
}

export function convertToLatest(template: TemplatedApp): Partial<NewTemplatedApp>
export function convertToLatest(template: Template): Partial<CurrentTemplate>
export function convertToLatest(template: OldTemplating): Partial<NewTemplating>
export function convertToLatest(template: NewTemplating): Partial<NewTemplating>
export function convertToLatest(template: Templating): Partial<NewTemplating> {
	if (template?.templateVersion === LATEST_TEMPLATE_VERSION) {
		return template
	}
	return convertToVersion(template, LATEST_TEMPLATE_VERSION) as Partial<CurrentTemplate>
}

const convertCoverLetterToChapter = (template: Partial<TemplatePreVersion4>): SingleTextTemplateChapter | null => {
	const { includeCoverLetter, includeCoverLetterHeader, includeCoverLetterFooter } =
		toggles(template) ?? DEFAULT_TOGGLES
	const headerInfo = template?.headerInfo ?? { coverLetterHeader: {}, coverLetterFooter: {} }
	if (!includeCoverLetter) {
		// convert cover letter to chapters
		return null
	}
	return {
		type: "singleText",
		name: "Cover Letter",
		content: template.coverLetter,
		headerInfo: {
			header: headerInfo.coverLetterHeader,
			footer: headerInfo.coverLetterFooter,
		},
		toggleSettings: {
			includeHeader: includeCoverLetterHeader,
			includeFooter: includeCoverLetterFooter,
		},
	}
}

const convertCVToChapter = (template: Partial<TemplatePreVersion4>): CVTemplateChapter => {
	const headerInfo = template?.headerInfo ?? { header: {}, footer: {} }
	return {
		type: "cv",
		name: "CV",
		headerInfo: {
			header: headerInfo.header,
			footer: headerInfo.footer,
		},
		ordering: convertOrderingToVersion4(template.ordering ?? [0, 1, 2, 3], toggles(template)),
		toggleSettings: convertTogglesToVersion4(toggles(template)),
	}
}

const convertOrderingToVersion4 = (ordering: number[], toggles: Partial<ToggleSet>): FeatureTag[] => {
	const templateItems = createTemplateItemsFromOrdering(ordering, toggles, getLabelMapping("4"))
	return templateItems.map((item) => item.id)
}

const convertTogglesToVersion4 = (toggles: Partial<ToggleSet>): Partial<ToggleSetV4> => {
	return filterNullObjectValues<ToggleSetV4>({
		includeContactDetails: toggles?.includeContactDetails,
		includeCompanyNames: toggles?.includeCompanyNames,
		includeEducation: toggles?.includeEducation,
		includeIcons: toggles?.includeIcons,
		includeLine: toggles?.includeLine,
		leftAlignDates: toggles?.leftAlignDates,
		includeLocation: toggles?.includeLocation,
		includeName: toggles?.includeName,
		includeHeader: toggles?.includeHeader,
		includeFooter: toggles?.includeFooter,
	})
}

function filterNullObjectValues<T>(obj: T): Partial<T> {
	return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null)) as Partial<T>
}
