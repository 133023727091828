import { ButtonType } from "../types/slate.types"
import { TaggingButtonDropdown } from "./components/TaggingDropdown"

export const taggingOptions = {
	taggingMain: {
		format: "tagging",
		button: ButtonType.custom as ButtonType.custom,
		makeButton: () => TaggingButtonDropdown(),
	},
}
