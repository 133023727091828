import { Editor, NodeEntry, Element } from "slate"
import { FontSizeDropdownItems, DEFAULT_BLOCK_FONT_SIZE } from "../../FontSizeDropdown/FontSizeDropdown"
import { CustomElement, ButtonType } from "../types/slate.types"
import { setParentSize, getDefaultSize } from "./SizeUtils"

export const sizeOptions = {
	size: {
		format: "size",
		button: ButtonType.customMark as ButtonType.customMark,
		getDefault: getDefaultSize,
		postApply: (editor) => {
			// When size is applied to more than a whole block need to change block size as well
			for (var parentNode of Editor.nodes(editor, {
				match: (n) => Element.isElement(n) && editor.baseBlocks.includes(n.type),
			})) {
				setParentSize(editor, parentNode as NodeEntry<CustomElement>)
			}
		},
		makeIcon: MakeSizeIcon,
		makeCustomDropdown: ({ currentValue, setFormat, testid }) => (
			<FontSizeDropdownItems
				testid={testid}
				currentValue={currentValue ?? DEFAULT_BLOCK_FONT_SIZE}
				setFormat={setFormat}
			/>
		),
		closeDropdownOnClick: false,
	},
}

const MIN_FONT_SIZE = 4
const MAX_FONT_SIZE = 40

function MakeSizeIcon({ currentValue, setFormat }) {
	const valueToDisplay = currentValue ?? DEFAULT_BLOCK_FONT_SIZE
	return (
		<span
			style={{
				width: "fit-content",
				display: "inline-flex",
				fontSize: 16,
				marginRight: 2,
				height: "20px",
				justifyContent: "flex-start",
				alignItems: "center",
				gap: 1,
			}}
			className="toolbar-button-icon">
			<span style={{ display: "inline-flex", flexDirection: "column", gap: 1 }}>
				<div
					style={{ padding: 2, opacity: valueToDisplay >= MAX_FONT_SIZE ? 0.25 : 1 }}
					onMouseDown={(e) => {
						e.stopPropagation()
						e.preventDefault()
						const newFontSize = floatToMaxOneDecimal(Math.min(MAX_FONT_SIZE, parseFloat(valueToDisplay) + 0.1))
						setFormat(newFontSize)
					}}>
					<div className="icon-up-arrow clickable"></div>
				</div>
				<div
					style={{ padding: 2, opacity: parseFloat(valueToDisplay) <= MIN_FONT_SIZE ? 0.25 : 1 }}
					onMouseDown={(e) => {
						e.stopPropagation()
						e.preventDefault()
						const newFontSize = floatToMaxOneDecimal(Math.max(MIN_FONT_SIZE, parseFloat(valueToDisplay) - 0.1))
						setFormat(newFontSize)
					}}>
					<div className="icon-down-arrow clickable"></div>
				</div>
			</span>
			<div style={{ flexGrow: 1 }}>{valueToDisplay}</div>
		</span>
	)
}

function floatToMaxOneDecimal(num: number) {
	if (Math.round(num * 10) / 10 === Math.round(num)) return num.toFixed(0)
	return num.toFixed(1)
}
