import { useMountEffect } from "libs"
import { useRef, useState, useCallback, TouchEventHandler } from "react"
import Dropdown from "react-bootstrap/Dropdown"
import "./HoverDropdown.css"

const DEFAULT_TIMEOUT = 200
const MAX_TIMEOUT_MULTIPLIER = 4
const MAX_TIMEOUT_RETRIES = 10

export const useHover = ({
	baseTimeout = DEFAULT_TIMEOUT,
	blurOnShow = false,
}: {
	baseTimeout?: number
	blurOnShow?: boolean
}) => {
	const hoveringRef = useRef(0)
	const timeoutRunningRef = useRef(false)
	const timeoutRef = useRef<NodeJS.Timeout>()
	const [show, setShow] = useState(false)
	const [isMounted, setIsMounted] = useState(true)

	useMountEffect(() => {
		setIsMounted(true)
		return () => {
			setIsMounted(false)
			if (timeoutRef.current != null) {
				clearTimeout(timeoutRef.current)
			}
		}
	})

	const onMouseEnter: React.MouseEventHandler = (e) => {
		// console.log('mouseenter')
		e.preventDefault()
		if (blurOnShow) {
			;(document.activeElement as HTMLDivElement)?.blur()
		}
		setShow(true)
		hoveringRef.current = MAX_TIMEOUT_RETRIES
	}
	const onMouseLeave: React.MouseEventHandler = (e) => {
		// console.log('mouseleave')
		e.preventDefault()
		hoveringRef.current = 0
		startTimeout(baseTimeout)
	}

	const startTimeout = (timeout: number) => {
		if (!timeoutRunningRef.current) {
			timeoutRunningRef.current = true
			timeoutRef.current = setTimeout(() => {
				if (isMounted) {
					timeoutRunningRef.current = false
					if (!hoveringRef.current) {
						if (show) {
							setShow(false)
						}
					} else {
						hoveringRef.current -= 1
						startTimeout(Math.min(timeout * 1.5, baseTimeout * MAX_TIMEOUT_MULTIPLIER))
					}
				}
			}, timeout)
		}
	}

	const onTouch: TouchEventHandler<HTMLDivElement | HTMLButtonElement> = (e) => {
		// console.log('touch')
		e.stopPropagation()
		setShow(true)
	}

	const afterSelect = useCallback(() => {
		setShow(false)
		hoveringRef.current = 0
	}, [])
	return { onMouseLeave, onMouseEnter, onTouch, afterSelect, show }
}

export interface DownloadOption {
	text: string
	onClick: React.MouseEventHandler
	selected?: boolean
	key?: string
}

interface HoverDropdownProps {
	options: DownloadOption[]
	BarItem: React.ComponentType<{ children: React.ReactNode }> | React.ComponentType<{ children: React.ReactNode }>[]
	classPrefix: string
	children: React.ReactNode
	baseTimeout?: number
	align?: "start" | "end"
	showOverride?: boolean
	useButtonForSingle?: boolean
	style?: React.CSSProperties
}

export const HoverDropdown = ({
	options,
	BarItem,
	classPrefix,
	children,
	baseTimeout = DEFAULT_TIMEOUT,
	align = "start",
	showOverride,
	useButtonForSingle = false,
	...props
}: HoverDropdownProps) => {
	const { onMouseLeave, onMouseEnter, onTouch, afterSelect, show } = useHover({ baseTimeout })
	const multipleBars = Array.isArray(BarItem)
	if (options.length === 1 && useButtonForSingle) {
		return (
			<div {...props}>
				<Dropdown className="hover-dropdown">
					<Dropdown.Toggle
						onClick={options[0].onClick}
						className={`${classPrefix}-dropdown-toggle`}
						split
						id="dropdown-item-button"
						variant="aralight">
						{children}
					</Dropdown.Toggle>
				</Dropdown>
			</div>
		)
	} else {
		return (
			<div onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter} {...props}>
				<Dropdown className="hover-dropdown" show={showOverride ?? show}>
					<Dropdown.Toggle
						onTouchStart={onTouch}
						onTouchEnd={(e) => {
							console.log("touchend")
							e.preventDefault()
							e.stopPropagation()
						}}
						className={`${classPrefix}-dropdown-toggle`}
						split
						id="dropdown-item-button"
						variant="aralight">
						{children}
					</Dropdown.Toggle>
					<Dropdown.Menu align={align} className={`${classPrefix}-dropdown-menu dropdown-menu-end`}>
						{options.map((option, index) => {
							const maxBarIndex = multipleBars ? BarItem.length - 1 : 0
							const ThisBar = multipleBars ? BarItem[Math.min(index, maxBarIndex)] : BarItem
							return (
								<Dropdown.Item
									className={option.selected ? `${classPrefix}-dropdown-selected-item` : ""}
									key={option.key ?? option.text}
									onClick={(e) => {
										option.onClick(e)
										afterSelect()
									}}>
									<ThisBar>{option.text}</ThisBar>
								</Dropdown.Item>
							)
						})}
					</Dropdown.Menu>
				</Dropdown>
			</div>
		)
	}
}
