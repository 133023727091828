import { useCallback, useRef, useState, useMemo, useEffect } from "react"
import isHotkey from "is-hotkey"
import { Editable, Slate, ReactEditor } from "slate-react"
import { Descendant, Transforms, Node } from "slate"
import "./TextFormatter.css"
import {
	slateTextIsEmpty,
	EditorContextProvider,
	isAtEnd,
	toggleMark,
	renderParagraphElement,
	createEditorWithPara,
	serialize,
	deserialize,
	isAtEndOfTypes,
	isAtStart,
	isAtStartOfTypes,
	setSelectionStart,
	renderLeaf,
	trim,
	NAVHOTKEYS,
} from "./Utils"
import { useTaggingContext, TaggingContextValue } from "./TaggingFormatting/TaggingContext"
import { Toolbar } from "./Toolbar"
import { isObjEqual, randomString, useMountEffect } from "libs"
import { applyPreprocessing } from "./HeadlessEditing"

import { useAnonCVContext } from "../CVViewing/CVContexts/AnonCVContext"
import { CustomEditor, ElementType } from "./types/slate.types"
import { applyPlugins } from "./PluginUtils"
import { DEFAULT_PLUGINS } from "./TextFormattingConstants"
import { DEFAULT_MAXTABLE_WIDTH } from "./TableFormatting"
import { TextFormatterProps } from "./types/slate.types"
import { AIPortal } from "../AIPortal/AIPortal"
import { useLoadContext, getAllFonts } from "libs"

const CREATE_EMPTY_PARA_ON_NAV_TYPES: ElementType[] = ["table-cell", "tagging", "hyperlink", "divider", "pageBreak"]

function createEmptyParaOnForwardNavIfNeeded(editor: CustomEditor, cmd, event: React.KeyboardEvent): void {
	if (isAtEndOfTypes(editor, CREATE_EMPTY_PARA_ON_NAV_TYPES)) {
		if (isAtEnd(editor)) {
			// add paragraph after
			Transforms.insertNodes(
				editor,
				{ type: "paragraph", children: [{ text: "" }] },
				{ at: [(editor.selection?.anchor?.path?.[0] ?? 0) + 1] }
			)
		}
		event.preventDefault()
		Transforms.move(editor, { distance: 1 })
		return
	}
}

function createEmptyParaOnBackwardNavIfNeeded(editor: CustomEditor, event: React.KeyboardEvent): void {
	if (isAtStartOfTypes(editor, CREATE_EMPTY_PARA_ON_NAV_TYPES)) {
		if (isAtStart(editor)) {
			// add paragraph after
			Transforms.insertNodes(editor, { type: "paragraph", children: [{ text: "" }] }, { at: [0] })
		}
		event.preventDefault()
		setSelectionStart(editor)
		return
	}
}

const performNav = (editor: CustomEditor, cmd: string, event: React.KeyboardEvent) => {
	switch (cmd) {
		case "ArrowLeft":
		case "ArrowUp":
			createEmptyParaOnBackwardNavIfNeeded(editor, event)
			return
		case "tab":
		case "ArrowRight":
		case "ArrowDown":
			createEmptyParaOnForwardNavIfNeeded(editor, cmd, event)
			return
		default:
			return
	}
}

const useEditor = ({
	maxTableWidth,
	editable,
	options,
}: {
	maxTableWidth: number
	editable: boolean
	options: string[]
}): CustomEditor => {
	// Combine all editor set up and updating here to clear up TextFormatter
	maxTableWidth = maxTableWidth ?? DEFAULT_MAXTABLE_WIDTH
	const taggingContext: TaggingContextValue = useTaggingContext()
	const { templateMode } = useAnonCVContext()
	const editorRef = useRef<CustomEditor>()
	const editableRef = useRef<boolean>(editable)
	const { company } = useLoadContext()
	useMemo(() => {
		editableRef.current = editable
	}, [editable])
	if (!editorRef.current) {
		editorRef.current = applyPlugins(
			createEditorWithPara({ maxTableWidth, editableRef, templateMode, autofillRows: taggingContext?.autofillRows }),
			options,
			taggingContext
		)
		editorRef.current.availableFonts = getAllFonts(company)
	}

	const editor = editorRef.current
	editor.parameters.templateMode = templateMode // ensure templateMode stays up to date

	return editor
}

/**
 * Main component for text formatting, should almost always be used as part of the EditFromattedText component that adds more layout to the component.
 * @param param0
 * @returns
 */
export default function TextFormatter({
	text,
	onChange,
	maxTableWidth = DEFAULT_MAXTABLE_WIDTH,
	editable,
	sectionTitle,
	editing,
	hideToolbar = false,
	options = DEFAULT_PLUGINS,
	initialMarks,
	setPlainText,
	singleParagraph = false,
	trimChar = null,
	placeholder = null,
}: TextFormatterProps): JSX.Element {
	const taggingContext = useTaggingContext()
	const { templateMode } = useAnonCVContext()
	let [value, setValue] = useState(
		applyPreprocessing(
			deserialize(text, initialMarks),
			options,
			{ maxTableWidth, templateMode, autofillRows: taggingContext?.autofillRows },
			taggingContext
		)
	)
	const editor = useEditor({
		maxTableWidth,
		editable,
		options,
	})
	const defaultRef = useRef() // this ref will contain element above the text editor, and so computedStyles can be used to get defaults
	const idRef = useRef(`${sectionTitle}-${randomString(12)}`)
	const [preventBlur, setPreventBlur] = useState(false)
	const isChanged = useCallback(() => editor.operations.some((op) => "set_selection" !== op.type), [editor.operations])

	const commitChangeFunction = useCallback(() => {
		if (taggingContext == null) {
			return
		}
		if (!editing && taggingContext?.hasQueuedChanges?.current) {
			taggingContext?.commitChanges?.()
		}
	}, [editing, taggingContext])

	const commitChangesEffect = useRef(commitChangeFunction)

	commitChangesEffect.current = useMemo(() => commitChangeFunction, [commitChangeFunction])

	useEffect(() => commitChangesEffect.current(), [editing])
	// usePrintEffect({
	// 	queuedChange: taggingContext?.queuedChanges,
	// })
	// usePrintEffect({
	// 	hasQueuedChanges: taggingContext?.hasQueuedChanges,
	// })
	// usePrintEffect({
	// 	commitChanges: taggingContext.commitChanges,
	// })
	// usePrintEffect({
	// 	taggingContext,
	// })

	const setValueAndChange = useCallback(
		(newValue: Descendant[]): void => {
			setValue(newValue)
			if (isChanged()) {
				const valStr = serialize(value)
				const newValStr = serialize(newValue)
				if (valStr === newValStr) {
					return
				}
				onChange?.({ target: { value: newValStr } }) // onChange expects an event
			}
			setPlainText?.(newValue.reduce((str, node) => str + Node.string(node), ""))
		},
		[isChanged, onChange, setPlainText, value]
	)

	useMountEffect(() => {
		//initial set plain text value if setPlainText is defined
		setPlainText?.(value.reduce((str, node) => str + Node.string(node), ""))
	})

	const trimStart = useMemo(() => trim(trimChar, value, true), [value, trimChar])

	const trimEnd = useMemo(() => trim(trimChar, value, false), [value, trimChar])

	const decorate = useCallback(
		([node, path]) => {
			const decorations = []
			if (trimStart != null) {
				if (!isObjEqual(path, trimStart?.path)) return []
				decorations.push({
					anchor: { ...trimStart },
					focus: { path: trimStart.path, offset: trimStart.offset + 1 },
					remove: true,
				})
			}
			if (trimEnd != null) {
				if (!isObjEqual(path, trimEnd?.path)) return []
				decorations.push({
					anchor: { ...trimEnd },
					focus: { path: trimEnd.path, offset: trimEnd.offset + 1 },
					remove: true,
				})
			}
			return decorations
		},
		[trimStart, trimEnd]
	)

	function shouldDisplayPlaceholder() {
		return !editing && editable && slateTextIsEmpty(value, false)
	}

	useMemo(() => {
		if (editing && !ReactEditor.isFocused(editor)) {
			setTimeout(() => {
				//This seems to be necessary
				try {
					ReactEditor.focus(editor)
				} catch {
					console.log("failed to focus")
				}
			}, 0)
		} else if (!editing && ReactEditor.isFocused(editor)) {
			ReactEditor.blur(editor)
		}
	}, [editing, editor])

	const onKeyDown = useCallback(
		(event: React.KeyboardEvent) => {
			if (editor.selection == null) {
				console.log("no selection")
				return
			}
			if (event.key === "Tab") {
				console.log("preventing tab")
				event.preventDefault()
			}
			if (singleParagraph && event.key === "Enter") {
				event.preventDefault()
				return
			}
			if (editor.customKeyPress?.(event)) return
			for (const hotkey in { ...NAVHOTKEYS, ...editor.markHotkeys }) {
				if (isHotkey(hotkey, event)) {
					const mark = editor.markHotkeys?.[hotkey]
					if (mark) {
						event.preventDefault()
						toggleMark(editor, mark)
					}
					const nav = NAVHOTKEYS[hotkey]
					if (nav) {
						if (editor.customNav?.(nav, event, { taggingContext })) return
						performNav(editor, nav, event)
					}
				}
			}
		},
		[editor, taggingContext]
	)

	const allOptions = useMemo(
		() => ({
			...editor.markOptions,
			...editor.customMarkOptions,
			...editor.customOptions,
			...editor.blockOptions,
			...editor.customBlockOptions,
			...editor.propertyOptions,
		}),
		[
			editor.blockOptions,
			editor.customBlockOptions,
			editor.customMarkOptions,
			editor.customOptions,
			editor.markOptions,
			editor.propertyOptions,
		]
	)

	return (
		<div ref={defaultRef}>
			<EditorContextProvider
				idRef={idRef}
				editing={editing}
				maxTableWidth={maxTableWidth}
				defaultRef={defaultRef}
				setPreventBlur={setPreventBlur}>
				{shouldDisplayPlaceholder() ? (
					<div
						onMouseDown={(e) => {
							e.preventDefault()
						}} //Prevent accidental clickout right after click in
						className={`formatted-placeholder  ${options?.join?.(" ") ?? ""}`}>
						{typeof sectionTitle === "string" ? (
							<span className="default-formatted-placeholder-text">{`add ${sectionTitle}`}</span>
						) : (
							sectionTitle
						)}
					</div>
				) : (
					<>
						<Slate editor={editor} value={value} onChange={setValueAndChange}>
							{editing && !hideToolbar && (
								<>
									<Toolbar
										allAiOptions={editor.allAiOptions}
										allOptions={allOptions}
										message={
											taggingContext != null && Object.keys(taggingContext).length !== 0 && templateMode
												? `Tagging available: Type '@' to add prefilled data`
												: null
										}
									/>
									{!templateMode && <AIPortal />}
								</>
							)}
							<Editable
								data-testid={placeholder}
								id={idRef.current}
								className={`text-format ${
									editable
										? editing
											? "editing-text-format"
											: "clickable display-text-format"
										: "display-text-format output-text-format"
								} ${options?.join?.(" ") ?? ""}`}
								readOnly={!editing} // readonly breaks onchange use prevent default on click in action
								renderElement={editor.renderElement ?? renderParagraphElement}
								renderLeaf={(props) => renderLeaf(editor?.setLeafProps?.(props) ?? props)}
								spellCheck={editing}
								decorate={decorate}
								placeholder={slateTextIsEmpty(value, false) ? placeholder : null}
								onKeyDown={onKeyDown}
								onBlur={(e) => {
									if (preventBlur && e.relatedTarget == null) {
										// relatedTarget is null when clicking outside of any focusable section,
										// if we prevent blurring when it isn't null we will have to editable areas at the same time
										console.log("stopping blur")
										e.stopPropagation()
										e.preventDefault()
									}
								}}
								onClick={() => {
									if (editor?.setShowAiPortal) {
										editor.setShowAiPortal(null)
									}
								}}
							/>
						</Slate>
					</>
				)}
			</EditorContextProvider>
		</div>
	)
}
