import { forwardRef, useState, useRef, useCallback, useEffect, useMemo } from "react"
import { useSlate, ReactEditor } from "slate-react"
import { Editor, Transforms, Text, Element as SlateElement, NodeEntry } from "slate"
import { getXPos } from "libs"
import { useEditorContext, useArray } from "../../Utils"
import widthArrow from "components/images/widthArrow.png"
import "../TableFormatting.css"
import { TableOptions } from "./TableOptionsMenu"
import { TableCellElement, TableElementType, SlateElementRenderProps } from "../../types/slate.types"
import { EdgeHighlightTuple, TableContextProvider } from "../TableContext"
import {
	useStateRef,
	minTableWidthForCols,
	MIN_CELL_WIDTH,
	addColToTable,
	addRowToTable,
	removeColFromTable,
	removeRowFromTable,
	fixSizesToLeft,
	reduceLastPossibleColumn,
} from "../TableUtils"

export const TableElement = forwardRef<
	HTMLDivElement,
	SlateElementRenderProps<TableElementType> & { allowAddRows?: boolean }
>(function ({ element, children, allowAddRows = true, ...attributes }, ref: React.MutableRefObject<HTMLDivElement>) {
	const editor = useSlate()
	const tableRef = useRef<HTMLDivElement>()
	const containerRef = useRef<HTMLDivElement>(null)
	const shapeRef = useRef<[number, number]>(element.shape)
	const { editing, maxTableWidth } = useEditorContext()
	const [edgeHighlighting, setEdgeHighlighting] = useState<EdgeHighlightTuple>([null, null, null]) //
	const activeRef = useRef<HTMLDivElement>()
	const changeRef = useRef<number>(null)
	const [colWidths, , colRef, resetColWidths, setFullArray] = useArray<number | null | undefined>(element.colWidths)
	const [tableWidth, setTableWidthState, tableWidthRef] = useStateRef<number>(element.tableWidth ?? maxTableWidth)
	const [tableEntry] = Editor.nodes(editor, { match: (n) => n === element }) as Generator<NodeEntry<TableElementType>>
	const currentlySelected = tableEntry != null
	const currentlyActive = activeRef.current != null // table is either selected or being resized
	const [currentCellEntry] = Editor.nodes(editor, {
		match: (n) => SlateElement.isElement(n) && n.type === "table-cell",
	}) as Generator<NodeEntry<TableCellElement>>

	const tableWidthTransform = useCallback(() => {
		Transforms.setNodes(editor, { tableWidth: tableWidthRef.current }, { at: ReactEditor.findPath(editor, element) })
	}, [editor, element, tableWidthRef])

	const setTableWidth = useCallback(
		(newWidth: number, applyTransform: boolean = true) => {
			setTableWidthState(newWidth)
			if (applyTransform) {
				tableWidthTransform()
			}
		},
		[setTableWidthState, tableWidthTransform]
	)

	const colWidthsTransform = useCallback((): void => {
		const colWidths = [...colRef.current]
		Transforms.setNodes(editor, { colWidths }, { at: ReactEditor.findPath(editor, element) })
	}, [colRef, editor, element])

	const setColWidths = useCallback(
		(newWidths: number[], applyTransform = true): void => {
			setFullArray(newWidths)
			if (applyTransform) {
				colWidthsTransform()
			}
		},
		[colWidthsTransform, setFullArray]
	)

	const createNewColWidths = useCallback(
		function (change: number): void {
			if (change !== 0) {
				const numCols = shapeRef.current[1]
				const activeIdx = getActiveIndex(activeRef)

				if (activeIdx === numCols - 1) {
					//end col update table width
					const minTableWidth = minTableWidthForCols(colRef.current ?? [], shapeRef.current)
					const newTableWidth = Math.min(Math.max(tableWidthRef.current + change, minTableWidth), maxTableWidth)
					setTableWidth(newTableWidth, false)
					return
				}

				const newColWidths = [...colRef.current]
				const oldColWidth = colRef.current[activeIdx] ?? activeRef.current?.offsetWidth
				const newColWidth = Math.max(oldColWidth + change, MIN_CELL_WIDTH)
				change = newColWidth - oldColWidth // For correct update to end col
				newColWidths[activeIdx] = Math.floor(newColWidth)
				const minTotalWidth = minTableWidthForCols(newColWidths ?? [], shapeRef.current)
				if (minTotalWidth > maxTableWidth && change > 0) {
					console.log("cancelling update to avoid passing max")
					return
				} // Cancel update if bigger than max
				if (minTotalWidth > tableWidthRef.current && change > 0) {
					setTableWidth(minTotalWidth, false)
				}

				setColWidths(newColWidths, false)
			}
		},
		[colRef, maxTableWidth, setColWidths, setTableWidth, tableWidthRef]
	)

	const resetMergingOnRemove = ({ rowNo = null, colNo = null }: { rowNo?: number; colNo?: number }): void => {
		const tablePath = ReactEditor.findPath(editor, element)
		if (rowNo != null) {
			const cellsToReset: Generator<NodeEntry<TableCellElement>> = Editor.nodes(editor, {
				at: tablePath,
				match: (n) =>
					!Text.isText(n) &&
					!Editor.isEditor(n) &&
					n.type === "table-cell" &&
					n.merge?.bottom > 0 &&
					n.row < rowNo &&
					n.row + n.merge.bottom >= rowNo,
			})
			for (var [rowCell, rowCellPath] of cellsToReset) {
				Transforms.setNodes(
					editor,
					{ merge: { ...rowCell.merge, bottom: rowCell.merge.bottom - 1 } },
					{ at: rowCellPath }
				)
			}
		}
		if (colNo != null) {
			const cellsToReset: Generator<NodeEntry<TableCellElement>> = Editor.nodes(editor, {
				at: tablePath,
				match: (n) =>
					!Editor.isEditor(n) &&
					!Text.isText(n) &&
					n.type === "table-cell" &&
					n.merge?.right > 0 &&
					n.col < colNo &&
					n.col + n.merge.right >= colNo,
			})
			for (var [cell, cellPath] of cellsToReset) {
				Transforms.setNodes(editor, { merge: { ...cell.merge, right: cell.merge.right - 1 } }, { at: cellPath })
			}
		}
	}

	const onMouseMove = useCallback(
		function (e: MouseEvent): void {
			const activeIdx = getActiveIndex(activeRef)
			if (activeIdx == null) {
				return
			}
			let scrollChange = 0
			const currentPos = getXPos(e)
			var change = currentPos - changeRef.current + scrollChange
			createNewColWidths(change)
			changeRef.current = currentPos
		},
		[createNewColWidths]
	)

	const onMouseUp = useCallback(
		function (e: MouseEvent): void {
			const activeIdx = getActiveIndex(activeRef)
			if (activeIdx == null) {
				return
			}
			activeRef.current = null
			changeRef.current = null
			Editor.withoutNormalizing(editor, () => {
				// transforms were defered until mouse up to avoid huge amounts of normalising
				tableWidthTransform()
				colWidthsTransform()
			})
		},
		[colWidthsTransform, editor, tableWidthTransform]
	)

	useEffect(() => {
		if (editing) {
			window.addEventListener("mousemove", onMouseMove)
			window.addEventListener("touchmove", onMouseMove)
			return () => {
				window.removeEventListener("mousemove", onMouseMove)
				window.removeEventListener("touchmove", onMouseMove)
			}
		}
	}, [editing, onMouseMove])

	useEffect(() => {
		if (editing) {
			window.addEventListener("mouseup", onMouseUp)
			window.addEventListener("touchend", onMouseUp)
			return () => {
				window.removeEventListener("mouseup", onMouseUp)
				window.removeEventListener("touchend", onMouseUp)
			}
		}
	}, [editing, onMouseUp])

	const exactColWidths = useMemo(makeColumns, [colWidths, element.colWidths, element.shape, tableWidth])

	function makeColumns(): number[] {
		const colWidthsToUse = colWidths?.length !== element.colWidths?.length ? element.colWidths : colWidths // when adding/removing cols element.colWidths is better, when resizing local colWidths is better
		// count right hand nulls in colWidths
		const nullCols = colWidthsToUse?.filter((el) => el == null).length ?? 0
		const unfixedCols = element.shape[1] - (colWidthsToUse?.length ?? 0) + nullCols
		const totalUnfixedLength = tableWidth - (colWidthsToUse?.reduce((a, b) => a + b, 0) ?? 0)
		const unfixedColLength = totalUnfixedLength / unfixedCols
		let baseCols = Array(element.shape[1])
		for (var i = 0; i < element.shape[1]; i++) {
			const colWidth = colWidthsToUse?.[i]
			baseCols[i] = colWidth ? colWidth : unfixedColLength
		}
		return baseCols
	}

	function addNew(change: [number, number] = [0, 1], at = []) {
		// add new only set up for unit additions (for example [0,2] won't work)
		return (e: React.MouseEvent) => {
			e.preventDefault()
			e.stopPropagation()
			console.log("add new clicked", change)
			const path = ReactEditor.findPath(editor, element)
			const newShape: [number, number] = [element.shape[0] + change[0], element.shape[1] + change[1]]
			shapeRef.current = newShape
			const numCols = newShape[1]
			const absoluteMinTableWidth = minTableWidthForCols([], newShape)

			if (absoluteMinTableWidth > maxTableWidth) {
				//no room abort update
				console.log("cannot fit another col")
				shapeRef.current = element.shape
				return
			}
			Editor.withoutNormalizing(editor, () => {
				if (change[1] > 0) {
					addColToTable(editor, path, newShape[1], at[1])
					const newColWidths = [...colRef.current]
					if (at[1] != null) {
						newColWidths.splice(at[1], 0, null)
					}
					setColWidths(newColWidths)
				}
				if (change[1] < 0) {
					removeColFromTable(editor, path, newShape[1], at[1])
					let newColWidths = [...colRef.current]
					if (at[1] != null) {
						newColWidths.splice(at[1], 1)
					} else if (newColWidths.length >= numCols) {
						newColWidths = newColWidths.slice(0, numCols - 1)
					}
					setColWidths(newColWidths)
					resetMergingOnRemove({ colNo: at[1] ?? newShape[1] })
				}
				if (change[0] > 0) {
					addRowToTable(editor, path, newShape[1], newShape[0], at[0])
				}
				if (change[0] < 0) {
					removeRowFromTable(editor, path, newShape[1], newShape[0], at[0])
					resetMergingOnRemove({ rowNo: at[0] ?? newShape[0] })
				}
				Transforms.setNodes(editor, { shape: newShape }, { at: path })
			})

			if (colWidths?.length === 0) {
				setColWidths([]) // force recalculating colWidths (makeColumns runs on colWidths change)
				return
			}

			const widthChange = change[1] * MIN_CELL_WIDTH
			const lastColNo = numCols - 1
			if (tableWidth + widthChange > maxTableWidth) {
				//Adding a col will take table over max, remove width from last cell with spare width
				fixSizesToLeft(colRef.current, setColWidths, shapeRef, tableRef, lastColNo)
				const widthToRemove = tableWidth + widthChange - maxTableWidth
				const remainingWidthToRemove = reduceLastPossibleColumn(widthToRemove, lastColNo, colRef.current, setColWidths)
				if (remainingWidthToRemove > 0) {
					//There was not room to remove width
					console.log("reset shape due to lack of room")
					Transforms.setNodes(editor, { shape: [newShape[0], newShape[1]] }, { at: path })
				}
			}
			const minTableWidth = minTableWidthForCols(colRef.current, newShape)

			if (tableWidth < minTableWidth) {
				setTableWidth(minTableWidth)
			}
		}
	}

	const preventClickInNonEditable = useCallback((e: React.MouseEvent) => {
		/*prevent clicking into non-editable areas to avoid cursor jump*/ if (
			(e.target as HTMLDivElement).classList.contains("slate-no-edit")
		) {
			e.preventDefault()
		}
	}, [])

	const getRowsWithCrossMerge = () => {
		let mergedRows = []
		element.children.forEach((row, i) => {
			row.children.forEach((cell) => {
				if (cell.merge?.bottom) {
					for (let k = 0; k < cell.merge.bottom; k++) {
						mergedRows.push(i + k)
					}
				}
			})
		})
		return mergedRows
	}

	const getColsWithCrossMerge = () => {
		let mergedCols = []
		element.children.forEach((row) => {
			row.children.forEach((cell, j) => {
				if (cell.merge?.right) {
					for (let k = 0; k < cell.merge.right; k++) {
						mergedCols.push(j + k)
					}
				}
			})
		})
		return mergedCols
	}

	const blockedRows = useMemo(getRowsWithCrossMerge, [element.children])
	const blockedCols = useMemo(getColsWithCrossMerge, [element.children])

	return (
		<TableContextProvider
			exactColWidths={exactColWidths}
			shapeRef={shapeRef}
			activeRef={activeRef}
			tableRef={tableRef}
			changeRef={changeRef}
			resetColWidths={resetColWidths}
			colWidths={colWidths}
			setColWidths={setColWidths}
			allowAddRows={allowAddRows}
			addNew={addNew}
			blockedRows={blockedRows}
			blockedCols={blockedCols}
			edgeHighlighting={edgeHighlighting}
			setEdgeHighlighting={setEdgeHighlighting}>
			<div
				onMouseDown={preventClickInNonEditable}
				className={`table-container slate-no-edit ${
					editing ? (currentlyActive ? "table-container-active" : "table-container-editing") : ""
				} ${currentlySelected ? "table-container-selected" : ""}`}
				ref={containerRef}>
				{
					!editing && (
						<div className="table-spacer">
							<p> </p>
						</div>
					) /* Prevents first line and top border splitting over page break */
				}
				<div className="slate-no-edit table-width-arrow">
					<img
						contentEditable={false}
						className="slate-no-edit"
						style={{ width: maxTableWidth + 2 }}
						src={widthArrow}
						alt="width-arrow"></img>
				</div>
				<div
					className={`slate-table`}
					style={{
						width: tableWidth,
					}}>
					<div
						className={`tbody`}
						style={
							{
								"--box-shadow-color": element.borderColor?.all ?? "black",
								backgroundColor: element.backgroundColor?.all ?? "white",
								display: "grid",
								gridTemplateColumns: makeGridTemplateColumns(exactColWidths),
								gridTemplateRows: makeGridTemplateRows(shapeRef.current[0]),
							} as React.CSSProperties
						}
						{...attributes}
						ref={(el) => {
							tableRef.current = el
							ref.current = el
						}}>
						{children}
					</div>
				</div>
				<div contentEditable={false} className="table-options-row slate-no-edit">
					<TableOptions tableEntry={tableEntry} cellEntry={currentCellEntry} allowMergeDown={allowAddRows} />
				</div>
			</div>
		</TableContextProvider>
	)
})

function makeGridTemplateColumns(exactColWidths: number[]) {
	return (
		exactColWidths.reduce((template, colWidth, index) => template + `[c${index}] ${colWidth}px`, "") +
		` [c${exactColWidths.length}]`
	)
}

function makeGridTemplateRows(noRows: number) {
	return (
		Array(noRows)
			.fill(0)
			.reduce((template, _, index) => template + `[r${index}] auto`, "") + ` [r${noRows}]`
	)
}

function getActiveIndex(activeRef: React.MutableRefObject<HTMLElement>) {
	// add width (number of cols) to account for merging
	if (activeRef.current == null) {
		return null
	}
	return (
		parseInt(activeRef.current?.getAttribute("slate-table-col")) +
		parseInt(activeRef.current?.getAttribute("slate-table-width"))
	)
}
