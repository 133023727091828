import { CustomPlugin } from "../types/slate.types"
import { toggleHyperlink } from "./HyperlinkUtils"
import { hyperlinkOptions } from "./HyperlinkOptions"
import { ensureSelection, renderParagraphElement, toggleParagraph, isBlockActive, isAtEndOfTypes } from "../Utils"
import { Editor, Transforms, Element as SlateElement, Range, NodeEntry, Descendant, Node, Text } from "slate"
import { renderHyperlinkElement } from "./components/HyperlinkComponents"

const fixEndOfHyperlink = (editor: Editor) => {
	// ensure that it is possible to move forwards out of a hyperlink at the end of a text editor
	const [el] = Editor.nodes(editor, { match: (n) => SlateElement.isElement(n) && n.type === "hyperlink" })
	if (el == null) return
	const [, path] = el
	const [nextNode] = Editor.next(editor, { at: path })

	if (nextNode == null || (Text.isText(nextNode) && nextNode?.text === "")) {
		if (isAtEndOfTypes(editor, ["hyperlink"])) {
			Transforms.move(editor, { distance: 1, unit: "character" })
			Transforms.insertFragment(editor, [{ text: "" }])
			return true
		}
	}
}

export const withHyperlink: CustomPlugin = (editor) => {
	const { renderElement, toggleBlock, blockOptions, isInline, deleteBackward, normalizeNode, customNav } = editor

	editor.renderElement = (props) => {
		return renderHyperlinkElement(props) ?? renderElement?.(props) ?? renderParagraphElement(props)
	}

	editor.toggleBlock = (editor, format) => {
		ensureSelection(editor)
		return toggleHyperlink(editor, format) ?? toggleBlock?.(editor, format) ?? toggleParagraph(editor, format)
	}

	editor.customNav = (nav, event, options) => {
		if (nav === "ArrowRight" || nav === "ArrowDown") {
			if (Range.isCollapsed(editor.selection) && isBlockActive(editor, "hyperlink")) {
				if (fixEndOfHyperlink(editor)) {
					return true
				}
			}
		}
		return customNav?.(nav, event, options)
	}

	editor.normalizeNode = (entry: NodeEntry<Descendant>) => {
		let [node, path] = entry
		if (SlateElement.isElement(node) && node.type === "hyperlink") {
			const text = Node.string(node)
			if (text.length === 0) {
				Transforms.removeNodes(editor, { at: path })
				return
			}
		}
		normalizeNode(entry)
	}

	editor.deleteBackward = (unit) => {
		deleteBackward(unit)
	}

	editor.isInline = (element) => element.type === "hyperlink" || isInline(element)

	editor.blockOptions = {
		...(blockOptions ?? {}),
		...hyperlinkOptions,
	}

	return editor
}
