import { CustomPlugin, ButtonType } from "../types/slate.types"
import { setLeafUnchanged } from "../Utils"
import { setBasicLeafProps } from "./MarkUtils"

export const withMarks: CustomPlugin = (editor) => {
	const { markOptions, markHotkeys, setLeafProps } = editor

	editor.setLeafProps = (props) => {
		const setPrevLeafProps = setLeafProps ?? setLeafUnchanged
		return setBasicLeafProps(setPrevLeafProps(props))
	}

	editor.markOptions = {
		...(markOptions ?? {}),
		format_bold: {
			format: "bold",
			icon: () => <b className="toolbar-button-icon">B</b>,
			button: ButtonType.mark,
			tooltipLabel: (cmdKey) => `Bold (${cmdKey}+B)`,
		},
		format_italic: {
			format: "italic",
			icon: () => (
				<em style={{ whiteSpace: "pre-wrap" }} className="toolbar-button-icon">
					I{" "}
				</em>
			),
			button: ButtonType.mark,
			tooltipLabel: (cmdKey) => `Italic (${cmdKey}+I)`,
		},
		format_underlined: {
			format: "underline",
			icon: () => <u className="toolbar-button-icon">U</u>,
			button: ButtonType.mark,
			tooltipLabel: (cmdKey) => `Underline (${cmdKey}+U)`,
		},
	}

	editor.markHotkeys = {
		...(markHotkeys ?? {}),
		"mod+b": "bold",
		"mod+i": "italic",
		"mod+u": "underline",
	}

	return editor
}
