import { DataTag } from "../TaggingFormatting"
import { SlateLeafRenderProps } from "../types/slate.types"

export const setBasicLeafProps = ({ attributes, children, leaf, ...other }: SlateLeafRenderProps) => {
	if (leaf.bold) {
		children = <strong>{children}</strong>
	}
	if (leaf.italic) {
		children = <em>{children}</em>
	}
	if (leaf.underline) {
		children = <u>{children}</u>
	}
	if (leaf.dataTag) {
		// This should maybe be moved to an appropriate plugin when full tagging is implemented
		children = <DataTag>{children}</DataTag>
	}
	if (leaf.remove) {
		children = <span style={{ display: "none" }}>{children}</span>
	}

	return { attributes, children, leaf, ...other }
}
