import { Editor, Transforms, Element as SlateElement, Path, Range } from "slate"
import { ColumnsElement, CustomEditor, ElementType } from "../types/slate.types"
import { RenderElementProps } from "slate-react"
import { hasAncestorOfTypes, isBlockActive } from "../../TextFormatting/Utils"
// import { ColumnsBreakElement } from "./ColumnsBreakUtils"

export const COLUMN_TYPES = ["twoColumns", "threeColumns"]

// check if the current selection is wrapped in a column type
export const getCurrentColumnType = (editor) => {
	for (const type of COLUMN_TYPES) {
		const [match] = Editor.nodes(editor, { match: (n) => SlateElement.isElement(n) && n.type === type })
		if (match) return type
	}
	return null
}

export const insertColumnsMatchFunction = (editor: CustomEditor) => (n: SlateElement, p: Path) => {
	if (!SlateElement.isElement(n) || (n.type !== "paragraph" && !editor.parentType?.includes(n.type))) {
		return false
	}
	const parentEntry = Editor.parent(editor, p)
	const [parent] = parentEntry
	return Editor.isEditor(parent) || !hasAncestorOfTypes(editor, p, editor.nestedFormatters)
}
export const canInsertColumns = (editor: CustomEditor): boolean => {
	if (editor.selection == null) {
		return false
	}
	const [insertEntry] = Editor.nodes(editor, {
		match: insertColumnsMatchFunction(editor),
	})
	return insertEntry != null
}

export const renderColumnElement = (props: RenderElementProps) => {
	switch (props.element.type) {
		case "twoColumns":
			return <ColumnElement classname="two-columns" {...props} />
		case "threeColumns":
			return <ColumnElement classname="three-columns" {...props} />
		// case "columnsBreak":
		// 	return <ColumnsBreakElement {...props} />
		default:
			return null
	}
}

const ColumnElement = (props: RenderElementProps & { classname: "two-columns" | "three-columns" }) => {
	return (
		<div className={props.classname} {...props.attributes}>
			{props.children}
		</div>
	)
}

export const toggleColumns = (editor: CustomEditor, format: ElementType): boolean | null => {
	const currentType = getCurrentColumnType(editor)

	if (format !== "twoColumns" && format !== "threeColumns") {
		return null
	}
	if (currentType === format) {
		unwrapColumns(editor)
		return true
	} else {
		unwrapColumns(editor)
		if (format) {
			wrapColumns(editor, format)
			return true
		}
	}
}

const wrapColumns = (editor: CustomEditor, format: ElementType) => {
	if (!canInsertColumns(editor)) {
		return true
	}
	Editor.withoutNormalizing(editor, () => {
		Transforms.wrapNodes(editor, { type: format } as ColumnsElement, {
			mode: "highest", //for nested lists
			match: (n) =>
				!Editor.isEditor(n) &&
				SlateElement.isElement(n) &&
				(editor.parentType.includes(n.type) || n.type === "paragraph"),

			split: false,
		})
	})
}

export const unwrapColumns = (editor: CustomEditor) => {
	Editor.withoutNormalizing(editor, () => {
		COLUMN_TYPES.forEach((type) => {
			Transforms.unwrapNodes(editor, {
				match: (n) => SlateElement.isElement(n) && n.type === type,
			})
		})
	})
}

export const columnsAvailable = (editor: CustomEditor) =>
	canInsertColumns(editor) || isBlockActive(editor, "twoColumns") || isBlockActive(editor, "threeColumns")
