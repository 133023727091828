import { StarIcon } from "../../Icons"
import { ButtonType, AIOptionSet, OptionsProps } from "../types/slate.types"
import "./text-transform.css"
import { ClientAiOptions, alphabeticalComparer } from "libs"

const OPTIONS_WRITE_TEXT: OptionsProps[] = [
	{
		label: "Executive Summary",
		key: "executiveSummary",
	},
	{
		label: "Technical Summary",
		key: "technicalSummary",
	},
	{
		label: "Education Summary",
		key: "educationSummary",
	},
	{
		label: "Key Competencies",
		key: "keyCompetencies",
	},
	// {
	// 	label: "Formal Sales Email",
	// 	key: "formalSalesEmail",
	// },
	// {
	// 	label: "Creative Sales Email",
	// 	key: "creativeSalesEmail",
	// },
	// {
	// 	label: "LinkedIn Post",
	// 	key: "linkedinPost",
	// },
	// {
	// 	label: "Explain CV to a 4 Year Old",
	// 	key: "explain4yo",
	// },
]

export const writeText: { writeText: AIOptionSet } = {
	writeText: {
		format: "textTransform",
		button: ButtonType.textTransform as ButtonType.textTransform,
		makeIcon: () => {
			return <span className="ai-formatting-icon">Write</span>
		},
		makeCustomDropdown: ({
			setFormat,
			clientAiOptions,
			extraOptions,
		}: {
			setFormat: (format: string) => void
			clientAiOptions?: ClientAiOptions
			extraOptions?: OptionsProps[]
		}) => {
			const fullOptions = extraOptions
				? [...OPTIONS_WRITE_TEXT, ...extraOptions?.sort((a, b) => alphabeticalComparer(a?.label, b?.label))]
				: OPTIONS_WRITE_TEXT
			return (
				<div className="custom-dropdown-options-text-transform">
					{fullOptions
						.filter((option) => !clientAiOptions.hide?.includes(option.key))
						.map((option: OptionsProps) => (
							<div
								key={option.key}
								className="clickable custom-dropdown-option-text-transform"
								onClick={() => {
									setFormat(option.key)
								}}>
								{option.key.startsWith("custom") ? <StarIcon /> : undefined}
								{option.label}
							</div>
						))}
				</div>
			)
		},
		disabledTooltipLabel: null,
		enabled: () => true,
	},
}
