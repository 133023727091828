import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
export const Divider = ({ text, className = "", color = null, margin = null }) => (
	<Row className={`divider ${className}`} style={{ color: color ?? "#cccccc", margin: margin ?? "10px 20px" }}>
		<Col>
			<div style={{ borderBottom: "dashed 1px", height: "50%" }}></div>
		</Col>
		<Col xs="2" style={{ textAlign: "center" }}>
			{text}
		</Col>
		<Col>
			<div style={{ borderBottom: "dashed 1px", height: "50%" }}></div>
		</Col>
	</Row>
)
