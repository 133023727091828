import {
	CardView,
	LoaderButton,
	SortableTableCard,
	ImageButton,
	LoadingSpinner,
	LinkIcon,
	AraTooltip,
} from "components"
import { useCallback, useEffect, useState } from "react"
import {
	adminAddDefaultTemplate,
	adminGetDefaultTemplates,
	adminDeleteDefaultTemplate,
	getDateFromUnix,
	useMountEffect,
	Indexed,
	Client,
	clientApi,
	useQuery,
} from "libs"
import binIcon from "components/images/binIconBlue.png"
import { useHistory } from "react-router-dom"
import { CopiedFlash } from "components/src/CopiedFlash/CopiedFlash"
import { SearchBar, useSearch } from "components"
import "./DefaultTemplates.css"

const appIdRegex = new RegExp("[0-9a-f-_]{37}[0-9a-z-_]{12}", "gm")

export function DefaultTemplates() {
	const query = useQuery()
	const [templateId, setTemplateId] = useState<string | undefined>(query.get("templateId") || undefined)
	const [defaultTemplateOptions, setDefaultTemplateOptions] = useState<{
		keepHeaderImages: boolean
		keepTagLanguage: boolean
	}>({ keepHeaderImages: true, keepTagLanguage: false })
	const [loading, setLoading] = useState(false)
	const [refreshing, setRefreshing] = useState(false)
	const [templates, setTemplates] = useState<any[]>([])

	const makeSearchString = useCallback((template) => {
		return `${template.templateName ?? ""}`
	}, [])

	const [searchTermRef, setSearchTermBase, filteredTemplates] = useSearch(
		templates,
		makeSearchString,
		"defaultTemplates"
	)

	const onChangeUrl = (arg0: { target: { value: string } }) => {
		if (arg0.target.value == null || arg0.target.value === "") {
			setTemplateId(undefined)
			return
		}
		const result = arg0.target.value.match(appIdRegex)
		if (result != null) {
			try {
				const appId = result[0]
				setTemplateId(appId)
			} catch {
				setTemplateId(null)
			}
		}
	}

	const fetchDefaultTemplates = useCallback(() => {
		setRefreshing(true)
		adminGetDefaultTemplates()
			.then((res) => {
				setTemplates(res)
			})
			.finally(() => {
				setRefreshing(false)
			})
	}, [])

	useMountEffect(() => {
		fetchDefaultTemplates()
	})

	function createDefault() {
		setLoading(true)
		adminAddDefaultTemplate({ templateId, ...defaultTemplateOptions }).then((res) => {
			setTemplates((prev) => prev.concat(res))
			setLoading(false)
		})
	}

	return (
		<div style={{ maxWidth: "90%", margin: "auto" }}>
			<h1>Default Templates</h1>
			<CardView style={{ margin: 16 }}>
				<h4>Add new default template from template in client account</h4>
				<div style={{ display: "flex", justifyContent: "space-around", margin: "50px 50px" }}>
					<div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
						<div style={{ paddingRight: 15 }}>Existing Template URL/ID:</div>
						<input
							style={{
								width: 600,
								borderColor: templateId == null ? (templateId === null ? "red" : "black") : "green",
							}}
							type="text"
							value={templateId ?? ""}
							onChange={onChangeUrl}></input>
					</div>
					<LoaderButton
						disabled={templateId == null}
						style={{ backgroundColor: "var(--ara-gold)", borderColor: "var(--ara-gold)", color: "white" }}
						className="SquarerLoaderButton"
						isLoading={loading}
						onClick={createDefault}>
						Create Default Template
					</LoaderButton>
				</div>{" "}
				<div>
					<input
						style={{ margin: 5 }}
						type="checkbox"
						checked={defaultTemplateOptions.keepHeaderImages}
						onChange={(e) =>
							setDefaultTemplateOptions({ ...defaultTemplateOptions, keepHeaderImages: e.target.checked })
						}></input>
					<label>Keep header images</label>
				</div>
				<div>
					<input
						style={{ margin: 5 }}
						type="checkbox"
						checked={defaultTemplateOptions.keepTagLanguage}
						onChange={(e) =>
							setDefaultTemplateOptions({ ...defaultTemplateOptions, keepTagLanguage: e.target.checked })
						}></input>
					<label>Keep tag language</label>
				</div>
			</CardView>
			<div style={{ width: "100%", display: "flex" }}>
				<SearchBar
					className="default-template-searchbar"
					setSearchTerm={setSearchTermBase}
					searchTermRef={searchTermRef}
				/>
			</div>
			<DefaultTemplateTable
				filteredTemplates={filteredTemplates}
				isRefreshing={refreshing}
				pageNumber={0}
				fetchTemplates={fetchDefaultTemplates}
				setTemplates={setTemplates}></DefaultTemplateTable>
		</div>
	)
}

function DefaultTemplateTable({ filteredTemplates, isRefreshing, pageNumber, fetchTemplates, setTemplates }) {
	const [tableRows, setTableRows] = useState([])
	const [clients, setClients] = useState<Indexed<Partial<Client>>>({})
	const history = useHistory()

	useEffect(() => {
		createRows(Object.values(filteredTemplates))
	}, [filteredTemplates, clients])

	function createRows(data) {
		const rows = data.map(createRow)
		setTableRows(rows)
	}

	function makeRowActions(templateId) {
		return (
			<div style={{ display: "flex", gap: 5, justifyContent: "center", alignItems: "center" }}>
				<DeleteTemplate setTemplates={setTemplates} templateId={templateId} />
				<PublicTemplateLink templateId={templateId} />
			</div>
		)
	}

	function createRow(templateData) {
		const templateName =
			templateData.templateName != null && templateData.templateName?.length > 0 ? templateData.templateName : "-"
		const clientId = templateData.fromTemplate?.slice(0, 36)
		if (clientId != null && clients[clientId] === undefined) {
			clients[clientId] = null
			setClients(clients)
			clientApi.get({ clientId, query: ["miscData"] }).then((res) => {
				setClients((prev) => ({ ...prev, [clientId]: res }))
			})
		}
		return {
			...templateData,
			templateName,
			date: getDateFromUnix(templateData.createdTime).toLocaleString(),
			actions: makeRowActions(templateData.default_template_id),
			numCustomTags: Object.keys(templateData.customTags ?? {}).length ?? 0,
			clientForOriginalTemplate:
				clientId == null ? (
					"-"
				) : clients[clientId]?.name != null ? (
					<div>{clients[clientId]?.name}</div>
				) : (
					<LoadingSpinner isLoading={true} />
				),
			goToOriginal:
				clientId == null ? undefined : clients[clientId]?.name != null ? (
					<div
						className="clickable blueText"
						onClick={() => {
							history.push(`/client/${clientId}/login?templateId=${templateData.fromTemplate}`)
						}}>
						{"Go To Original -->"}
					</div>
				) : undefined,
		}
	}

	return (
		<SortableTableCard
			pageNumber={pageNumber}
			title="Existing Default Templates"
			headings={["Default Template Name", "Created Date", "Taken from Client", "Number of Custom Tags", "Actions", ""]}
			info={tableRows}
			rowIdKey="default_template_id"
			sortFunc={sortByCreationTime}
			infoKeys={["templateName", "date", "clientForOriginalTemplate", "numCustomTags", "actions", "goToOriginal"]}
			refreshProps={{ onClick: fetchTemplates, isLoading: isRefreshing }}></SortableTableCard>
	)
}

function getMainUrl() {
	const start = window.location.origin.replace("www.", "").split("//")[1].split(".")[0]
	return start === "admin"
		? "https://app.hireara.ai"
		: start.includes("localhost")
			? "http://localhost:3000"
			: `https://${start}.hireara.ai`
}

function PublicTemplateLink({ templateId }) {
	const [copied, setCopied] = useState(false)
	function copyLink() {
		setCopied(true)
		navigator.clipboard.writeText(`${getMainUrl()}/apply?template=${templateId}`)
	}

	return (
		<div style={{ position: "relative", color: "var(--ara-blue)" }}>
			<CopiedFlash style={{ position: "absolute", top: 0, left: "100%" }} show={copied} setShow={setCopied} />
			<AraTooltip tooltipLabel="Copy public link" placement="bottom">
				<LinkIcon
					className="clickable"
					width={22}
					style={{ color: "var(--ara-blue)" }}
					alt="temp-link-copy"
					onClick={copyLink}
					isLoading={false}></LinkIcon>
			</AraTooltip>
		</div>
	)
}

function DeleteTemplate({ setTemplates, templateId }) {
	const [isDeleting, setIsDeleting] = useState(false)

	function deleteTemplateCall(e) {
		e.stopPropagation()
		if (templateId == null) {
			alert("No template id")
			return
		}
		if (global.confirm("Are you sure you want to delete this template? This action cannot be undone.")) {
			setIsDeleting(true)
			adminDeleteDefaultTemplate(templateId).then((res) => {
				setTemplates((prev) => prev.filter((template) => template.default_template_id !== templateId))
				setIsDeleting(false)
			})
		}
	}

	return (
		<ImageButton
			tooltipProps={{ tooltipLabel: "Delete template", placement: "bottom" }}
			style={{ width: 22 }}
			alt="temp-del"
			src={binIcon}
			onClick={deleteTemplateCall}
			isLoading={isDeleting}></ImageButton>
	)
}

function sortByCreationTime(a, b) {
	return b?.createdTime - a?.createdTime
}
