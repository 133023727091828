import { SlateLeafRenderProps } from "../types/slate.types"

export const setCaseLeafProps = ({ attributes, children, leaf, ...other }: SlateLeafRenderProps) => {
	const caseOptions = ["none", "lowercase", "uppercase", "capitalize"]

	if (leaf.case != null && caseOptions.includes(leaf.case)) {
		children = <span style={{ textTransform: leaf.case }}>{children}</span>
	}

	return { attributes, children, leaf, ...other }
}
