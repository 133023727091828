export function HighlightingIcon({ currentValue, size }: { currentValue?: string; size?: number }) {
	return (
		<svg width={size ?? 96} height={size ?? 96} viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_662_10)">
				<rect width="96" height="96" fill="white" />
				<path
					d="M80.4211 6.58569L86.2719 12.3341C89.4316 15.4384 89.4673 20.5192 86.3518 23.6677L74.7359 35.4064L44.3583 66.6276C41.365 69.7039 36.4774 69.8699 33.2824 67.0037L26.8779 61.2583C23.4735 58.2043 23.3177 52.923 26.5361 49.6736L57.3173 18.5966L69.1289 6.66425C72.229 3.53254 77.2778 3.49742 80.4211 6.58569Z"
					stroke="black"
					strokeWidth="8"
				/>
				<path
					d="M80.4222 6.58569L86.273 12.3341C89.4326 15.4384 89.4684 20.5192 86.3529 23.6677L74.7369 35.4064L57.3184 18.5966L69.13 6.66425C72.23 3.53254 77.2789 3.49742 80.4222 6.58569Z"
					fill="black"
				/>
				<path d="M4 76.0317L22.0889 57.0303L37.2445 70.6724L32.3556 76.0317H4Z" fill="black" />
				<path d="M0 92H97" stroke={currentValue ?? "white"} strokeWidth="20" />
			</g>
			<defs>
				<clipPath id="clip0_662_10">
					<rect width="96" height="96" fill="white" />
				</clipPath>
			</defs>
		</svg>
	)
}
