const TAG_ICONS = {
	location: { emoji: "📍", before: true },
	company: { emoji: "🏢", before: true },
	qualificationName: { emoji: "🎓", before: false },
	classification: { emoji: "🏆", before: true },
}

const tagNodeType = {
	comments: "block",
}

export function makeDateTagSection(preset: string) {
	if (preset === "preset-style-1") {
		return `[{"type":"paragraph","children":[{"text":""},{"type":"tagging","tagging":"endDate","nodeType":"inline","children":[{"text":"@End Date", "bold": true}]},{"text":""}],"props":{"style":{"textAlign":"center","listStylePosition":"inside"}}},{"type":"paragraph","children":[{"text":"-", "bold": true}],"props":{"style":{"textAlign":"center","listStylePosition":"inside"}}},{"type":"paragraph","children":[{"text":""},{"type":"tagging","tagging":"startDate","nodeType":"inline","children":[{"text":"@Start Date", "bold": true}]},{"text":""}],"props":{"style":{"textAlign":"center","listStylePosition":"inside"}}}]`
	}
	return `[{"type":"paragraph","children":[{"text":""},{"type":"tagging","tagging":"startDate","nodeType":"inline","children":[{"text":"@Start Date", "bold": true}]},{"text":" - ", "bold": true},{"type":"tagging","tagging":"endDate","nodeType":"inline","children":[{"text":"@End Date", "bold": true}]},{"text":""}]}]`
}

export function makeTLTagSection(tag: string, includeIcons: boolean = false, dashBefore = false): string {
	// convert tag name and toggles into the formatted text for the timeline item
	return `[{"type": "paragraph", "children": [${dashBefore ? '{"text": "- ", "bold": true},' : ""}${
		includeIcons && TAG_ICONS[tag]?.emoji != null && TAG_ICONS[tag]?.before
			? `{"type": "text", "text": "${TAG_ICONS[tag]?.emoji} "},`
			: ""
	} {"type": "tagging", "nodeType": "${
		tagNodeType[tag] ?? "inline"
	}", "tagging": "${tag}", "children": [{"text": "", "bold": true}]} ${
		includeIcons && TAG_ICONS[tag]?.emoji != null && !TAG_ICONS[tag]?.before
			? `,{"type": "text", "text": " ${TAG_ICONS[tag]?.emoji}"}`
			: ""
	}]}]`
}
