import { useState } from "react"
import { ConfirmationPage } from "./ConfirmationPage"
import { FetchFile } from "./FetchFile"
import { ChooseFile } from "./ChooseFile"
import { CheckForExisting } from "./CheckForExisting"
import { IntegratedPlatform, IntegrationData, Page, PlatformIntegrationData } from "./Integrations.types"
import { mercuryIntegrationData } from "./PlatformData/MercuryIntegrationData"
import { arcrmIntegrationData } from "./PlatformData/ArcrmIntegrationData"
import { OAuthLogin } from "./OAuthLogin"
import { bullhornIntegrationData } from "./PlatformData/BullhornIntegrationData"
import { vincereIntegrationData } from "./PlatformData/VincereIntegrationData"
import { SetupUser } from "./SetupUser"
import { NotFound } from "./NotFoundPage"
import { CenteredStatus } from "../CenteredStatus/CenteredStatus"
import { recmanIntegrationData } from "./PlatformData/RecmanIntegrationData"

export function IntegrationEntryPage({
	platform,
	...queryStringParams
}: {
	platform: IntegratedPlatform
	[key: string]: string
}) {
	const integration = INTEGRATIONS[platform]
	const [pageIndex, setPageIndex] = useState(0)
	const page = integration?.pages?.[pageIndex]
	const [error, setError] = useState<string | null>(null)
	const integrationQSData: IntegrationData = {
		platform,
	} as IntegrationData

	Object.entries(integration?.parameterMapping ?? {})?.forEach(([param, paramKey]) => {
		integrationQSData[param] = queryStringParams[paramKey]
	})
	if (queryStringParams.other != null) {
		integrationQSData.other = JSON.parse(atob(queryStringParams.other))
	}

	const [integrationData, setIntegrationData] = useState<IntegrationData>({
		...integration?.initialData,
		...integrationQSData,
	})

	const nextPage = () => {
		setPageIndex((i) => i + 1)
	}

	const showError = (message: string) => {}

	const PageComponent = PAGE_COMPONENTS[page] || NotFound
	return error != null ? (
		<CenteredStatus isLoading={false}>{error} </CenteredStatus>
	) : (
		hasProps(page, integration) && (
			<PageComponent
				// @ts-ignore
				platformPageProps={integration.props?.[page]}
				integrationData={integrationData}
				setIntegrationData={setIntegrationData}
				nextPage={nextPage}
				showError={showError}
			/>
		)
	)
}

const PAGE_COMPONENTS = {
	oauthLogin: OAuthLogin,
	setupUser: SetupUser,
	checkForExisting: CheckForExisting,
	chooseFile: ChooseFile,
	fetchFile: FetchFile,
	confirmationPage: ConfirmationPage,
}

function hasProps<T extends Page>(
	props: T,
	// @ts-ignore ignore generic type as we want to use any combination here
	integration: PlatformIntegrationData
): integration is PlatformIntegrationData<T> {
	return integration?.["props"] !== undefined
}

const INTEGRATIONS = {
	recman: recmanIntegrationData,
	mercury: mercuryIntegrationData,
	bullhorn: bullhornIntegrationData,
	vincere: vincereIntegrationData,
	arcrm: arcrmIntegrationData,
}

export const INTEGRATION_TYPES = Object.keys(INTEGRATIONS) as IntegratedPlatform[]
