import { Editor, Element as SlateElement } from "slate"
import { CustomPlugin, PropOptionSet } from "../types/slate.types"
import { addPropertiesToNodes, ensureSelection, toggleParagraph } from "../Utils"
import { createFormatMap, getTypeListFromOptionSet, toggleProperties } from "./PropertyUtils"
import { getValueAtPath, objIsSubset } from "libs"

/**
 * the withProperties plugin is a higher order plugin that generically adds property formatting to the editor as defined in the propertyOptionSet.
 * The properties defined are then directly added to html elements that correspond to the slate element.
 * The plugin also adds a resetProperties method to the editor that is typically called headlessly to reset properties back to default before applying new properties ie when changing template.
 * @param propertyOptionSet
 * @returns
 */
export const withProperties =
	(propertyOptionSet: PropOptionSet): CustomPlugin =>
	(editor) => {
		const { propertyOptions, toggleBlock, resetProperties } = editor

		editor.toggleBlock = (editor, format) => {
			ensureSelection(editor)
			return (
				toggleProperties(getTypeListFromOptionSet(propertyOptionSet), propertyOptionSet)(editor, format) ??
				toggleBlock?.(editor, format) ??
				toggleParagraph(editor, format)
			)
		}

		// editor.applyDefaults = (computedStyle, defaultOverrides) => {

		//     const format = defaultOverrides?.properties?.[propertyOptionSet.name]
		//     if (format != null && !slateTextIsEmpty(editor.children)) {
		//         editor.toggleBlock(editor, format)
		//     }
		//     applyDefaults?.(computedStyle, defaultOverrides)
		// }

		editor.resetProperties = ({ resetList }) => {
			if (resetList.includes(propertyOptionSet.name)) {
				// reset property to null in all elements
				const formatPropertyMap = createFormatMap(["props", ...propertyOptionSet.path])
				Editor.withoutNormalizing(editor, () => {
					const newProperties = formatPropertyMap(propertyOptionSet.default.insertAtPath)
					addPropertiesToNodes(editor, newProperties, {
						at: [],
						match: (n) =>
							!Editor.isEditor(n) &&
							SlateElement.isElement(n) &&
							(editor.baseBlocks.includes(n.type) || (n.type === "tagging" && n.nodeType === "block")) &&
							!objIsSubset(propertyOptionSet.default.insertAtPath, getValueAtPath(n?.props, propertyOptionSet.path)),
					})
				})
			}
			resetProperties?.({ resetList })
		}

		editor.propertyOptions = {
			...(propertyOptions ?? {}),
			[propertyOptionSet.name]: propertyOptionSet,
		}

		return editor
	}
