import blueCircle from "components/images/blue-table-circle.png"
import redCircleMinus from "components/images/red-table-circle-minus.png"
import "../TableFormatting.css"

function ChangeCircle({
	cell,
	cellAdjust = [0, 0],
	changeDirection,
	addNew,
	changeColorEvent,
	changeType,
	src,
	...props
}: FullChangeCircleProps) {
	// change 0 = col, 1 = row
	const change = changeDirection === "row" ? 1 : 0
	const multiplier = changeType === "delete" ? -1 : 1
	const addNewChange: [number, number] = [multiplier * change, multiplier * (1 - change)]
	return (
		<div contentEditable={false} className="add-table-circle-container slate-no-edit" {...props}>
			<img
				onClick={addNew(addNewChange, [cell[0] + cellAdjust[0], cell[1] + cellAdjust[1]])}
				onMouseEnter={changeColorEvent(changeType, cell)}
				onMouseLeave={changeColorEvent(null, cell)}
				onMouseUp={changeColorEvent(null, cell)} // unhighlight after adding/removing to avoid lingering highlight
				className="add-table-circle slate-no-edit"
				alt="btcm"
				src={src}
				contentEditable={false}></img>
		</div>
	)
}

export function AddCircle(props: ChangeCircleProps) {
	return <ChangeCircle cellAdjust={[1, 1]} changeType={"add"} src={blueCircle} {...props} />
}

export function MinusCircle(props: ChangeCircleProps) {
	return <ChangeCircle changeType={"delete"} src={redCircleMinus} {...props} />
}

type ChangeDirection = "row" | "col"
type ChangeType = "add" | "delete"

interface ChangeCircleProps extends React.HTMLAttributes<HTMLDivElement> {
	addNew: (change: [number, number], at?: [number, number]) => (e: React.MouseEvent) => void
	changeColorEvent: (changeType: "add" | "delete" | null, cell: [number | null, number | null]) => () => void
	changeDirection: ChangeDirection
	cell: [number, number]
}

interface FullChangeCircleProps extends ChangeCircleProps {
	src: string
	changeType: ChangeType
	cell: [number, number]
	cellAdjust?: [number, number]
}
