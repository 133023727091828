import { BaseEditor, Descendant, Path } from "slate"
import { ReactEditor, RenderLeafProps } from "slate-react"
import { HistoryEditor } from "slate-history"
import { ReactNode } from "react"
import { ExtendedTaggingDataset } from "../../TextFormatting/TaggingFormatting/index.types"
import { ClientAiOptions } from "libs"
import { TagSubType } from "../../TextFormatting/TaggingFormatting/index.types"

export enum ButtonType {
	mark = "mark",
	block = "block",
	prop = "prop",
	customMark = "custom-mark",
	custom = "custom",
	textTransform = "textTransform",
	customBlock = "custom-block",
}

export type OptionSet =
	| MarkOptionSet
	| BlockOptionSet
	| CustomMarkOptionSet
	| PropOptionSet
	| CustomOptionSet
	| CustomBlockOptionSet

export type MarkValue = "underline" | "bold" | "italic" | "size" | "color" | "font"

export interface MarkOptionSet {
	format: MarkValue
	icon: () => ReactNode
	button: ButtonType.mark
	tooltipLabel?: (cmdKey: string, modKey: string) => string
}

export interface BlockOptionSet {
	format: ElementType
	icon: () => ReactNode
	button: ButtonType.block
	show?: "ifExists"
	tooltipLabel?: (cmdKey: string, modKey: string) => string
}

export interface OptionsProps {
	label: string
	key: string
}

export interface AIOptionSet {
	format: string
	button?: ButtonType.textTransform
	// path?: string[]
	makeIcon?: (arg0: { currentValue: string }) => ReactNode
	// options?: object
	// default?: { insertAtPath: React.CSSProperties }
	postApply?: (arg0: CustomEditor) => void
	getDefault?: (arg0: HTMLElement) => React.ComponentProps<"div">["className"]
	makeCustomDropdown?: (arg0: {
		currentValue: string
		setFormat: (arg0: boolean | string) => void
		clientAiOptions?: ClientAiOptions
		extraOptions?: OptionsProps[]
	}) => ReactNode
	closeDropdownOnClick?: boolean
	enabled?: (string) => boolean
	disabledTooltipLabel?: string
}

export interface CustomMarkOptionSet {
	format: string
	button: ButtonType.customMark
	// path?: string[]
	makeIcon: (arg0: { currentValue: string; setFormat: (newFormat: string) => void }) => ReactNode
	// options?: object
	// default?: { insertAtPath: React.CSSProperties }
	postApply?: (arg0: CustomEditor) => void
	getDefault?: (arg0: HTMLElement) => React.ComponentProps<"div">["className"]
	makeCustomDropdown: (arg0: { currentValue: string; setFormat: (arg0: string) => void }) => ReactNode
	closeDropdownOnClick?: boolean
}

export interface CustomBlockOptionSet {
	name?: string
	format: ElementType | ElementType[]
	button: ButtonType.customBlock
	available?: (arg0: CustomEditor) => boolean
	// path?: string[]
	// makeIcon: ({
	// 	currentFormat,
	// 	computedStyle,
	// }: {
	// 	currentFormat: { format: ElementType; icon?: JSX.Element }
	// 	computedStyle: CSSStyleDeclaration
	// }) => ReactNode
	makeIcon: ({ activeFormat }: { activeFormat: ElementType | null }) => ReactNode
	// options?: object
	// default?: { insertAtPath: React.CSSProperties }
	postApply?: (arg0: CustomEditor) => void
	getDefault?: (arg0: HTMLElement) => React.ComponentProps<"div">["className"]
	makeCustomDropdown?: (arg0: { currentValue: string }) => ReactNode
	closeDropdownOnClick?: boolean
}

export interface CustomOptionSet {
	makeButton: () => JSX.Element
	button: ButtonType.custom
}
// propreties block components
export interface PropOptionSet {
	name: string
	button: ButtonType.prop
	path: string[]
	makeIcon: ({
		currentFormat,
		computedStyle,
	}: {
		currentFormat: { format: ElementType; icon?: JSX.Element }
		computedStyle: CSSStyleDeclaration
	}) => ReactNode
	options: { [option: string]: PropOption }
	default: { insertAtPath: React.CSSProperties & { [otherOption: `--${string}`]: string | number | null } }
	show?: "if_exists"
	postApply?: (CustomEditor) => void
	scrollDirection?: "horizontal" | "vertical"
	dropdownType?: "numeric" | "text" | "icon"
	ordered?: boolean
}

interface PropOption {
	format: ElementType
	insertAtPath: React.CSSProperties & { [otherOption: `--${string}`]: string | number | null }
	icon: JSX.Element
	button: string
}

export type CustomText = {
	text: string
	bold?: boolean
	italic?: boolean
	underline?: boolean
	dataTag?: boolean
	marked?: boolean
	size?: number
	color?: string
	highlight?: string
	case?: "none" | "lowercase" | "uppercase" | "capitalize"
	hyperlink?: string
	font?: string
	remove?: boolean
}

type CustomProperties = {
	style?: React.CSSProperties
	className?: string
}

type BasicElement = {
	nodeType?: "inline" | "block"
	props?: CustomProperties
	marked?: boolean
}

type AnyChildren = {
	children: Descendant[]
}

type TableColouring = {
	backgroundColor?: { all?: string }
	borderColor?: { all?: string }
}

export type ParagraphElement = {
	type?: "paragraph"
} & BasicElement &
	AnyChildren

export type TableElementType = {
	type?: "table"
	autofill?: boolean
	shape: [number, number]
	colWidths?: number[]
	tableWidth?: number
	children: TableRowElement[]
} & BasicElement &
	TableColouring

export type TableRowElement = {
	type?: "table-row"
	row: number
	children: TableCellElement[]
} & BasicElement

export type TableCellElement = {
	type?: "table-cell"
	row: number
	col: number
	merge: TableCellMergeProperties
	children: TableParagraphElement[]
	removed?: boolean
	partOf?: Path
} & BasicElement &
	TableColouring

export type TableParagraphElement = {
	type?: "table-paragraph"
} & BasicElement &
	AnyChildren

export type TaggingElementType = {
	type?: "tagging"
	subType?: TagSubType
	processing?: string
	isCurrent?: string
	tagging: string
	index?: number
} & BasicElement &
	AnyChildren

export type PotentialTagElement = {
	type?: "potentialTag"
	marked: true
} & BasicElement &
	AnyChildren

export type BulletListElement = {
	type?: "bulleted-list"
} & BasicElement &
	AnyChildren

export type NumberedListElement = {
	type?: "numbered-list"
} & BasicElement &
	AnyChildren

export type ListItemElement = {
	type?: "list-item"
} & BasicElement &
	AnyChildren

export type HyperlinkElement = {
	type?: "hyperlink"
	url: string
	plainTextUrl?: string
} & BasicElement &
	AnyChildren

export type DividerElement = {
	type?: "divider"
} & BasicElement &
	AnyChildren

export type PageBreakElement = {
	type?: "pageBreak"
} & BasicElement &
	AnyChildren

export type ColumnsElement = {
	type?: "twoColumns" | "threeColumns"
} & BasicElement &
	AnyChildren

export type ColumnsBreakElement = {
	type?: "columnsBreak"
} & BasicElement &
	AnyChildren
// export type twoColumnsElement = {
// 	type?: "twoColumns"
// } & BasicElement &
// 	AnyChildren

// export type threeColumnsElement = {
// 	type?: "threeColumns"
// } & BasicElement &
// 	AnyChildren

export type TableCellMergeProperties = {
	bottom: number
	right: number
}

export type CustomElement =
	| ParagraphElement
	| TableElementType
	| TableRowElement
	| TableCellElement
	| TableParagraphElement
	| TaggingElementType
	| PotentialTagElement
	| BulletListElement
	| NumberedListElement
	| ListItemElement
	| HyperlinkElement
	| DividerElement
	| PageBreakElement
	// | twoColumnsElement
	// | threeColumnsElement
	| ColumnsElement
	| ColumnsBreakElement

type PickFieldTypes<U, K extends keyof U> = U extends {} ? U[K] : never
type SetElementTypes = PickFieldTypes<CustomElement, "type"> // creates a set of the 'type' values of CustomElement
type AlignmentType = "left" | "center" | "right" | "justify"

export type ElementType = SetElementTypes | "tagging" | `tagging@${string}` | AlignmentType | `line-spacing@${string}`

// Sub divisions of ElementType
export type ListParentElementType = "bulleted-list" | "numbered-list"
export type ListElementType = ListParentElementType | "list-item"

export interface EditorParameters {
	templateMode?: boolean
	maxTableWidth?: number
	editableRef?: React.MutableRefObject<boolean>
	autofillRows?: number[]
}

export type SlateRenderProps = SlateElementRenderProps & SlateLeafRenderProps

export interface SlateElementRenderProps<T = CustomElement> {
	attributes: any
	children: ReactNode
	element: T
}

export interface SlateElementTaggingRenderProps extends SlateElementRenderProps<TaggingElementType> {
	selected?: boolean
	removeFunc?: (arg0?: Path) => void
	refreshFunc?: (arg0?: Path) => void
	highlightProblem?: boolean
	EditingOptions?: () => JSX.Element
}

export interface SlateLeafRenderProps extends RenderLeafProps {
	attributes: React.ComponentProps<"span"> & { "data-slate-leaf": true }
	children: ReactNode
	text: CustomText
	leaf: CustomText
	textTransform?: string
	availableFonts?: AvailableFonts
}

export interface AIPortalShowProps {
	type: string
	update?: boolean
	tag?: object
	index?: number
}

export interface AvailableFonts {
	[font: string]: { fontFamily: string; bold: string; normal: string }
}

export interface AraEditor {
	parameters?: EditorParameters
	baseBlocks?: ElementType[] // these are only CustomElement types, but Typescript can't infer that so we may need to cast Node to CustomElement when the type comes from here
	customKeyPress?: (event: React.KeyboardEvent) => boolean
	markOptions?: { [mark: string]: MarkOptionSet }
	customMarkOptions?: { [mark: string]: CustomMarkOptionSet }
	allAiOptions?: { [mark: string]: AIOptionSet }
	customOptions?: { [mark: string]: CustomOptionSet }
	blockOptions?: { [mark: string]: BlockOptionSet }
	customBlockOptions?: { [mark: string]: CustomBlockOptionSet }
	propertyOptions?: { [mark: string]: PropOptionSet }
	markHotkeys?: { [key: string]: string }
	customNav?: (nav: any, event: React.KeyboardEvent, parameters: any) => boolean
	renderElement?: (props: SlateElementRenderProps) => JSX.Element
	setLeafProps?: (props: SlateLeafRenderProps) => SlateLeafRenderProps
	parentType?: ElementType[]
	toggleBlock?: (editor: CustomEditor, format: ElementType) => void
	/**
	 * Method that resets properties in the editor back to default for all properties in the resetList
	 * @param param0
	 * @returns
	 */
	resetProperties?: ({ resetList }: { resetList: string[] }) => void
	nestedFormatters?: ElementType[]
	applyHeadless?: (func: string, target: string, args: object) => void
	taggingData?: ExtendedTaggingDataset
	setShowAiPortal?: (show: AIPortalShowProps | null) => void
	bulletFragmentInserted?: boolean
	availableFonts?: AvailableFonts
}

export type Nullable<T> = T | null

export type CustomEditor = BaseEditor & ReactEditor & HistoryEditor & AraEditor

declare module "slate" {
	interface CustomTypes {
		Editor: CustomEditor
		Element: CustomElement
		Text: CustomText
	}
}

export type RenderFunction = () => JSX.Element

export type CustomPlugin = (editor: CustomEditor) => CustomEditor

export interface TextFormatterProps {
	text: string
	onChange: ({ target }: { target: object }) => void
	maxTableWidth?: number
	editable: boolean
	sectionTitle: string | ReactNode
	editing: boolean
	options?: string[]
	initialMarks?: Marks
	hideToolbar?: boolean
	setPlainText?: (arg0: string) => void
	singleParagraph?: boolean
	trimChar?: string
	placeholder?: string
}

export interface Marks {
	[mark: string]: boolean
}
