import { createContext, useContext, useRef, useState } from "react"
import { TemplateChapter } from "libs"
import { CVDisplayOptionsObject } from "../../Templating/TemplateHooks.types"
import { AnonCVContextProps, ScrollToOnRenderObject } from "./CVContexts.types"

export const AnonCVContext = createContext<AnonCVContextProps>({} as AnonCVContextProps)

export function useAnonCVContext() {
	return useContext(AnonCVContext)
}

export function AnonCVContextProvider({
	CVDisplayOptions = {
		color: "#5114FE",
		timelineOrder: "Chronological",
		templateMode: false,
		setTimelineOrder: () => {},
	},
	chapters,
	...props
}: {
	CVDisplayOptions?: CVDisplayOptionsObject
	chapters: TemplateChapter[]
	children: any
}) {
	//These hooks/functions are passed to the formatted cv viewer, and can also be accessed by the containing component to alter/add to behaviour
	var refArray = useRef([])
	var [activeIdx, setActiveIdx] = useState(0)
	var scrollToOnRender = useRef<ScrollToOnRenderObject>()

	return (
		<AnonCVContext.Provider
			value={{
				refArray,
				activeIdx,
				setActiveIdx,
				scrollToOnRender,
				chapters,
				...CVDisplayOptions,
			}}>
			{props.children}
		</AnonCVContext.Provider>
	)
}
