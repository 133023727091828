import { useSlate } from "slate-react"
import { BlockOptionSet, ButtonType } from "../../TextFormatting/types/slate.types"
import { canInsertPageBreak } from "./PageBreakUtils"
import { isBlockActive } from "../Utils"
import { ReactComponent as PageBreak } from "../../../images/page-break-icon.svg"

export const pageBreakOptions: { pageBreak: BlockOptionSet } = {
	pageBreak: {
		format: "pageBreak",
		icon: () => <PageBreakIcon />,
		button: ButtonType.block,
		tooltipLabel: (cmdKey) => `Page Break (${cmdKey}+Enter)`,
	},
}

function PageBreakIcon() {
	const editor = useSlate()

	const available = canInsertPageBreak(editor) || isBlockActive(editor, "pageBreak") // block active mean it can be removed so shouldn't be marked unavailable

	return (
		<div
			aria-label="page-break-button"
			className={`page-break-button ${!available ? "toolbar-button-unavailable unclickable" : ""}`}>
			<PageBreak />
		</div>
	)
}
