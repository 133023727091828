import { forwardRef, useCallback, useEffect, useRef, useMemo, useState, MouseEventHandler } from "react"
import { useSlate } from "slate-react"
import { Node, Editor, Transforms } from "slate"
import { TaggingElementType, SlateElementRenderProps, SlateElementTaggingRenderProps } from "../../types/slate.types"
import {
	displayTagName,
	initialTagBlockNodes,
	setTagText,
	InlineChromiumBugfix,
	useClickToSelectAll,
	getFirstTextCharFromSlateData,
	getDefaultTagProps,
} from "../TaggingUtils"
import { TagElementProps, TagSubType } from "../index.types"
import { getPlainTextFromSlateText, serialize, slateTextIsEmpty, useEditorContext } from "../../Utils"
// import greyCross from "components/images/grey-cross.png"
import { MdOutlineClose } from "react-icons/md"
import { BsArrowRepeat } from "react-icons/bs"
import { IconType } from "react-icons"
import { useAnonCVContext } from "../../../CVViewing/CVContexts/AnonCVContext"
import { DEFAULT_LINE_HEIGHT, recursiveCombineObj, stripBulletPoints, useAppContext, useMountEffect } from "libs"
import { useTaggingContext } from "../TaggingContext"
import "../TaggingFormatter.css"
import { StarIcon } from "../../../Icons"
import { AraTooltip } from "../../../AraTooltip/AraTooltip"
import { ProcessingDropdown } from "./ProcessingDropdown"
import { PotentialTag } from "./PotentialTag"

export const renderTaggingElement = ({ attributes, children, element }) => {
	if (element.type === "tagging")
		return (
			<TaggingElement {...attributes} element={element}>
				{children}
			</TaggingElement>
		)
	if (element.type === "potentialTag")
		return (
			<PotentialTag element={element} attributes={attributes}>
				{children}
			</PotentialTag>
		)
	return null
}

export const DataTag = forwardRef<HTMLSpanElement, TagElementProps>(
	({ type = "inline", children, ...attributes }, ref) => {
		return (
			<span className={`data-tag data-tag-fixed data-tag-${type}`} {...attributes} ref={ref}>
				{children}
			</span>
		)
	}
)

const TagIcon = ({
	Icon,
	onClick,
	iconClassName,
}: {
	Icon: IconType
	onClick: MouseEventHandler
	iconClassName?: string
}) => {
	return (
		<span contentEditable={false} className="data-tag-remove clickable slate-no-edit" onClick={onClick}>
			<Icon size={13} className={iconClassName ?? ""} style={{ margin: "1px 3px", opacity: 0.7 }} />
		</span>
	)
}

const TagRefresh = ({ refreshFunc }: { refreshFunc: MouseEventHandler }) => {
	const [isRefreshing, setIsRefreshing] = useState(false)
	return (
		<TagIcon
			Icon={BsArrowRepeat}
			iconClassName={isRefreshing ? "data-tag-refreshing" : ""}
			onClick={async (e) => {
				setIsRefreshing(true)
				await refreshFunc(e)
				setIsRefreshing(false)
			}}
		/>
	)
}

const TagRemove = ({ removeFunc }: { removeFunc: () => void }) => {
	return <TagIcon Icon={MdOutlineClose} onClick={removeFunc} />
}

const TagName = ({
	tag,
	removeFunc,
	refreshFunc,
	subType,
	EditingOptions,
}: {
	tag: string
	removeFunc: () => void
	refreshFunc?: () => void
	subType: TagSubType
	EditingOptions?: () => JSX.Element
}) => (
	<span className="data-tag-name-outer slate-no-edit" contentEditable={false}>
		<div className="data-tag-name slate-no-edit" contentEditable={false}>
			<div style={{ display: "flex", alignItems: "center" }}>
				{subType === "ai" && <StarIcon />}
				<span className="slate-no-edit" contentEditable={false}>
					{tag}
				</span>
				{refreshFunc && (
					<AraTooltip tooltipLabel={"Try Again"} timeToShow={500} placement="bottom">
						<TagRefresh refreshFunc={refreshFunc} />
					</AraTooltip>
				)}
				<AraTooltip tooltipLabel={"Remove"} timeToShow={500} placement="bottom">
					<TagRemove removeFunc={removeFunc} />
				</AraTooltip>
			</div>
			{EditingOptions && <EditingOptions />}
		</div>
	</span>
)

export const TemplateDataTag = forwardRef<
	HTMLDivElement,
	TagElementProps & SlateElementRenderProps<TaggingElementType>
>(({ element, children, ...attributes }, ref) => {
	const { nodeType = "inline" } = element
	const { templateMode } = useAnonCVContext()
	const { company } = useAppContext()
	const onClick = useClickToSelectAll(element)
	const editor = useSlate()
	const subType = editor.taggingData[element.tagging]?.subType ?? "standard"

	useMountEffect(() => {
		// update text from data
		const displayName = displayTagName(element.tagging, editor.taggingData)
		const currentText = Node.string(element)
		if (currentText === displayName) {
			return
		}
		const newText =
			element.nodeType === "inline"
				? displayName
				: serialize(initialTagBlockNodes(element.nodeType, element.tagging, editor.taggingData))
		setTagText(editor, element, newText)
	})

	const lineHeight = parseFloat(element.props?.style?.["--local-line-height"] ?? DEFAULT_LINE_HEIGHT)
	const fudgeFactor = 0.5 // 0.5 fudge factor to make it shrink a little less
	const iconHeight = Math.min(((lineHeight + fudgeFactor) / (DEFAULT_LINE_HEIGHT + fudgeFactor)) * 17, 17)

	const defaultTagProps = getDefaultTagProps(element, company?.options?.customTags)
	const props = recursiveCombineObj(defaultTagProps, element?.props)

	return (
		<span
			onClick={onClick}
			className={`data-tag data-tag-template data-tag-${nodeType} data-tag-${subType} select-all`}
			{...props}
			{...attributes}
			ref={ref}>
			{!templateMode && nodeType === "inline" && <InlineChromiumBugfix />}
			<span className="slate-no-edit data-tag-template-name">
				{children}
				{subType === "ai" && <StarIcon size={iconHeight} />}
			</span>
			{!templateMode && nodeType === "inline" && <InlineChromiumBugfix />}
		</span>
	)
})

function blockQuadrupleClicks(e) {
	if (e.detail > 3) {
		// quadruple clicks into inline tags broke the selection somehow,
		// leading to lost data and lots of error logs. Block all quadruple clicks here
		e.preventDefault()
	}
}

const EditingDataTag = forwardRef<HTMLDivElement, SlateElementTaggingRenderProps>(
	(
		{ element, selected = false, children, removeFunc, refreshFunc, EditingOptions, highlightProblem, ...attributes },
		ref
	) => {
		const editor = useSlate()
		const { editing } = useEditorContext()
		const { company } = useAppContext()
		const { nodeType: type = "inline", tagging: tagName } = element
		const tag = displayTagName(tagName, editor.taggingData, undefined, element.index)
		const tagData = editor.taggingData[element.tagging]
		const subType = tagData?.subType ?? "standard"

		const defaultTagProps = getDefaultTagProps(element, company?.options?.customTags)
		const props = recursiveCombineObj(defaultTagProps, element?.props)

		return (
			<span
				onMouseDown={blockQuadrupleClicks}
				className={`data-tag data-tag-${type} ${highlightProblem ? "data-tag-error" : ""} data-tag-${subType} ${
					selected ? "data-tag-selected" : ""
				} ${editing ? "data-tag-editing" : "data-tag-editable"}`}
				ref={ref}
				{...props}
				{...attributes}>
				{type === "inline" && <InlineChromiumBugfix />}
				{children}
				{type === "inline" && <InlineChromiumBugfix />}
				{editing && (
					<TagName
						tag={tag}
						removeFunc={removeFunc}
						refreshFunc={refreshFunc}
						subType={subType}
						EditingOptions={EditingOptions}
					/>
				)}
			</span>
		)
	}
)

const NonEditableDataTag = forwardRef<HTMLDivElement, SlateElementTaggingRenderProps>(
	({ element, children, removeFunc, ...attributes }, ref) => {
		const editor = useSlate()
		const { company } = useAppContext()
		const { nodeType: type = "inline", tagging: tagName } = element
		const tag = displayTagName(tagName, editor.taggingData, undefined, element.index)
		const subType = editor.taggingData[element.tagging]?.subType ?? "standard"

		const defaultTagProps = getDefaultTagProps(element, company?.options?.customTags)
		const props = recursiveCombineObj(defaultTagProps, element?.props)
		return (
			<span
				contentEditable={false}
				className={`data-tag data-tag-editing data-tag-non-editable  data-tag-${subType} data-tag-${type} slate-no-edit`}
				ref={ref}
				{...props}
				{...attributes}>
				{type === "inline" && <InlineChromiumBugfix />}
				{children}
				{type === "inline" && <InlineChromiumBugfix />}
				<TagName tag={tag} removeFunc={removeFunc} subType={subType} />
			</span>
		)
	}
)

const EmptyDataTag = forwardRef<HTMLDivElement, SlateElementTaggingRenderProps>(
	({ element, selected, children, EditingOptions, removeFunc, refreshFunc, ...attributes }, ref) => {
		const { nodeType: type = "inline", tagging: tagName } = element
		const editor = useSlate()
		const { editing } = useEditorContext()
		const { company } = useAppContext()
		const tag = displayTagName(tagName, editor.taggingData, undefined, element.index)
		const innerTag = displayTagName(tagName, editor.taggingData, "add ", element.index)
		const onClick = useClickToSelectAll(element)
		const subType = editor.taggingData[element.tagging]?.subType ?? "standard"

		const defaultTagProps = getDefaultTagProps(element, company?.options?.customTags)
		const props = recursiveCombineObj(defaultTagProps, element?.props)

		return (
			<span
				onClick={onClick}
				className={`data-tag data-tag-editing data-tag-empty data-tag-${type}  data-tag-${subType} ${
					selected ? "data-tag-selected" : ""
				}`}
				{...props}
				ref={ref}
				{...attributes}>
				<TagName
					tag={tag}
					removeFunc={removeFunc}
					refreshFunc={refreshFunc}
					subType={subType}
					EditingOptions={editing && EditingOptions}
				/>
				{type === "inline" && <InlineChromiumBugfix />}
				{children}
				<span
					onClick={(e) => {
						onClick(e)
						e.stopPropagation()
					}}
					contentEditable={false}
					className="data-tag-missing-warning slate-no-edit">
					{innerTag}
				</span>
				{type === "inline" && <InlineChromiumBugfix />}
			</span>
		)
	}
)

function testProcessing(process, ...args) {
	if (process == null) {
		return true
	}
	try {
		process(...args)
		return true
	} catch {
		return false
	}
}

const FilledTaggingElement = forwardRef<HTMLDivElement, SlateElementRenderProps<TaggingElementType>>(
	({ element, children, ...attributes }, ref) => {
		const editor = useSlate()
		const { editing } = useEditorContext()
		const { editable } = useAnonCVContext()
		const { taggingData } = useTaggingContext()
		const isEmpty = slateTextIsEmpty(element.children, true, false)
		const taggingObjData = taggingData[element.tagging]
		const highlightEmpty = isEmpty && editable && (taggingObjData.editable ?? true)
		// const setNewTagData = taggingObjData.setData
		const queueTagDataChange = taggingObjData.queueDataChange
		const [taggedArray] =
			editing && editor.selection != null ? Editor.nodes(editor, { match: (n) => n === element }) : []
		const currentlySelected = taggedArray != null
		const tagInfo = editor.taggingData[element.tagging]
		const subType = tagInfo?.subType ?? "standard"
		const processingSet = tagInfo.processingSets?.[element.processing ?? "default"] ?? tagInfo.processingSets?.default
		const preprocessing = useMemo(
			() => (processingSet?.preprocessing(tagInfo) == null ? (a) => a : processingSet?.preprocessing(tagInfo)),
			[processingSet, tagInfo]
		)
		const postprocessing = useMemo(
			() => (processingSet?.postprocessing(tagInfo) == null ? (a) => a : processingSet?.postprocessing(tagInfo)),
			[processingSet, tagInfo]
		)
		const getCurrentData = useCallback(
			() => taggingObjData.getData(true, element.index),
			[element.index, taggingObjData]
		)
		const currentDataString = useCallback(() => preprocessing(getCurrentData()), [getCurrentData, preprocessing])
		const selectContent = useClickToSelectAll(element)
		const aiTagComplete: boolean = tagInfo?.subType === "ai" ? taggingObjData.getComplete?.() ?? true : true

		const hasBeenSelected = useRef(false)
		useMemo(() => {
			hasBeenSelected.current = hasBeenSelected.current || currentlySelected
		}, [currentlySelected])

		const getTagData = useCallback(
			() => (element.nodeType === "inline" ? Node.string(element) || "" : serialize(element.children)),
			[element]
		)

		const hasProblem =
			aiTagComplete === false ||
			currentDataString() === "<empty>" ||
			!testProcessing(postprocessing, getTagData(), getCurrentData())

		const taggingState = highlightEmpty
			? "highlightEmpty"
			: editable
				? taggingObjData.editable ?? true
					? "editable"
					: "nonEditableTag"
				: "output"

		const updateTextFromData = useRef(null)
		updateTextFromData.current = useCallback(() => {
			if (editing) {
				return
			} // should not update while editing

			// compare stripped string versions of new data and incoming
			// by comparing just the strings the formatting of the tag is
			// ignored to avoid the formatting being over written in an
			// unnecessary update
			// Bullet points are stripped to avoid differences from bullet point normalisation
			const newTagData = Node.string(element)
			const currentProcessedString = stripBulletPoints(getPlainTextFromSlateText(currentDataString()))
			if (newTagData === currentProcessedString) {
				return
			}
			setTagText(editor, element, currentDataString())
		}, [currentDataString, editing, editor, element])

		useEffect(() => {
			// update text from data
			updateTextFromData.current()
		}, [currentDataString])

		const queueDataChangeFromText = useRef(null)
		queueDataChangeFromText.current = useCallback(() => {
			const firstChar = getFirstTextCharFromSlateData(element.children)
			if (firstChar === "@") {
				// prevent data from template overwriting actual data
				return
			}
			const newTagData = getTagData()
			if (newTagData === currentDataString()) {
				// Up to date
				return
			}
			try {
				queueTagDataChange(postprocessing(newTagData, getCurrentData()), element.index)
			} catch (e) {
				console.log(e)
				console.warn("postprocessing failed - badly formatted data being ignored")
			}
		}, [
			currentDataString,
			element.children,
			element.index,
			getCurrentData,
			getTagData,
			postprocessing,
			queueTagDataChange,
		])

		useEffect(() => {
			// update data from text on finish editing
			// uses wasEditing to avoid updating on first render so that it should only update when the user has finished editing
			if (!editing && hasBeenSelected.current) {
				queueDataChangeFromText.current()
			}
		}, [editing])

		const thisElement = useRef(element)
		useMemo(() => {
			thisElement.current = element
		}, [element])

		useMountEffect(() => {
			// ensure update text from data on dismount
			return () => {
				if (hasBeenSelected.current) {
					const [tag] = Editor.nodes(editor, { match: (n) => n === thisElement.current, at: [] })
					if (tag == null) {
						return // tag has been removed from editor avoid update
					}
					queueDataChangeFromText.current()
				}
				hasBeenSelected.current = false // set not selected after dismount
			}
		})

		useEffect(() => {
			if (!currentlySelected && hasBeenSelected.current) {
				const [tag] = Editor.nodes(editor, { match: (n) => n === thisElement.current, at: [] })
				if (tag == null) {
					return // tag has been removed from editor avoid update
				}
				// this will trigger when a user clicks out of a tag, but not out of the editor
				// the store function updates the tagging data in place so no renders are triggered
				// the updated data means that if multiple tags are edited at once then the data will be correct
				// this avoids multiple update calls with race conditions
				queueDataChangeFromText.current()
			}
		}, [currentlySelected, editor, taggingObjData.displayName])

		const removeTagElement = useCallback(
			(e) => {
				e.stopPropagation()
				e.preventDefault()
				Transforms.removeNodes(editor, { at: [], match: (n) => n === element })
			},
			[editor, element]
		)

		const refreshAiTagElement =
			subType === "ai"
				? (e) => {
						console.log("refresh ai tag")
						selectContent(e)
						editor.setShowAiPortal({
							type: element.tagging,
							update: !aiTagComplete,
							tag: element,
							index: element.index,
						})
						e.stopPropagation()
					}
				: null

		const EditingOptions = () => {
			const [localIsCurrent, setLocalIsCurrent] = useState(!!getCurrentData()?.isCurrent)
			const ref = useRef<HTMLInputElement>()
			return tagInfo.subType === "date" && tagInfo.shouldUseIsCurrent ? (
				<>
					<div style={{ display: "flex", alignItems: "center", gap: 3, padding: "2px 0" }}>
						<input
							ref={ref}
							type="checkbox"
							name="present-check"
							checked={localIsCurrent}
							onChange={(e) => {
								let currentData = { ...getCurrentData() } // create local copy
								setLocalIsCurrent(!currentData.isCurrent)
								currentData.isCurrent = !currentData.isCurrent
								queueTagDataChange(currentData, element.index)
								setTagText(editor, element, preprocessing(currentData))
								selectContent()
							}}></input>
						<div>Present?</div>
					</div>
				</>
			) : null
		}

		switch (taggingState) {
			case "nonEditableTag":
				return (
					<NonEditableDataTag removeFunc={removeTagElement} element={element} {...attributes} ref={ref}>
						{children}
					</NonEditableDataTag>
				)
			case "editable":
				return (
					<EditingDataTag
						removeFunc={removeTagElement}
						refreshFunc={refreshAiTagElement}
						EditingOptions={EditingOptions}
						highlightProblem={hasProblem}
						selected={currentlySelected}
						element={element}
						{...attributes}
						ref={ref}>
						{children}
					</EditingDataTag>
				)
			case "highlightEmpty":
				return (
					<EmptyDataTag
						removeFunc={removeTagElement}
						refreshFunc={refreshAiTagElement}
						EditingOptions={EditingOptions}
						selected={currentlySelected}
						element={element}
						{...attributes}
						ref={ref}>
						{children}
					</EmptyDataTag>
				)
			case "output":
				return (
					<span {...attributes} ref={ref}>
						{children}
					</span>
				)
			default:
				return (
					<DataTag type={element.nodeType} {...attributes} ref={ref}>
						{children}
					</DataTag>
				)
		}
	}
)

const TemplateTaggingElement = forwardRef<HTMLDivElement, SlateElementRenderProps<TaggingElementType>>(
	({ element, children, ...attributes }, ref) => {
		const editor = useSlate()
		const { editing } = useEditorContext()
		const tagInfo = editor.taggingData[element.tagging]
		return (
			<TemplateDataTag element={element} {...attributes} ref={ref}>
				{children}
				{editing && tagInfo?.processingSets != null && (
					<div className="slate-no-edit" contentEditable={false}>
						<ProcessingDropdown element={element} />
					</div>
				)}
			</TemplateDataTag>
		)
	}
)

export const TaggingElement = forwardRef<HTMLDivElement, SlateElementRenderProps<TaggingElementType>>((props, ref) => {
	const { templateMode } = useAnonCVContext()
	if (templateMode) {
		return <TemplateTaggingElement {...props} ref={ref} />
	}
	return <FilledTaggingElement {...props} ref={ref} />
})
