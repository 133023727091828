import { LoaderButton } from "components"
import { adminDeleteClient, addStickyValue } from "libs"
import { useState } from "react"
import { useHistory } from "react-router-dom"

export function DeletePage({ clientId, data, setData }): JSX.Element {
	const history = useHistory()
	const [isDeleting, setIsDeleting] = useState<boolean>(false)
	const [isArchiving, setIsArchiving] = useState<boolean>(false)
	const [message, setMessage] = useState<string>("")

	return (
		<div className="account-container">
			<h1>Delete Account</h1>
			{message && (
				<div
					style={{
						color: "var(--ara-error)",
						fontSize: 18,
						margin: "20px auto",
					}}>
					{message}
				</div>
			)}
			<div
				style={{
					minHeight: 200,
					display: "flex",
					flexDirection: "column",
					justifyContent: "left",
					alignItems: "left",
					marginBottom: 20,
				}}>
				<h4>Delete Entire Account</h4>
				<div style={{ margin: "15px 0" }}>Delete entire account including all data and client details?</div>
				<LoaderButton
					className="SquarerLoaderButton"
					onClick={async () => {
						if (window.confirm(`Deleting: \n${data.name}\n\nThis is irreversible. Are you sure?`)) {
							setIsDeleting(true)
							const response = await adminDeleteClient({
								clientId,
								updateType: "full",
							})
							if (response.response_error === "504") {
								setMessage(
									"The server is taking too long to respond. Deleting is continuing in the background, please refresh and check again in a few minutes."
								)
							} else {
								addStickyValue({
									// set a different tab to default after deletion to avoid deletion page opening more often than needed
									storageName: "clientDetailsTab",
									value: "account",
									sessionOnly: false,
									userId: "admin",
								})
								history.push("/")
							}
							setIsDeleting(false)
						}
					}}
					isLoading={isDeleting}>
					Delete Entire Account
				</LoaderButton>
			</div>
			<div
				style={{
					minHeight: 200,
					display: "flex",
					flexDirection: "column",
					justifyContent: "left",
					alignItems: "left",
					marginBottom: 20,
				}}>
				<h4>Archive Account</h4>
				<div style={{ margin: "15px 0" }}>
					Archive an account? Removes all PII (user and candidate data) but keeps all templates and client config. Users
					will no longer have access but the account can be reactivated at a later date.
				</div>
				<LoaderButton
					className="SquarerLoaderButton"
					onClick={async () => {
						if (window.confirm(`Deleting: \n${data.name}\n\nThis is irreversible. Are you sure?`)) {
							setIsArchiving(true)
							const response = await adminDeleteClient({
								clientId,
								updateType: "archive",
							})
							if (response.response_error === "504") {
								setMessage(
									"The server is taking too long to respond. Archiving is continuing in the background, please refresh and check again in a few minutes."
								)
							} else {
								addStickyValue({
									// set a different tab to default after deletion to avoid deletion page opening more often than needed
									storageName: "clientDetailsTab",
									value: "account",
									sessionOnly: false,
									userId: "admin",
								})
								history.push("/")
							}
							setIsArchiving(false)
						}
					}}
					isLoading={isArchiving}>
					Archive Account
				</LoaderButton>
			</div>
		</div>
	)
}
