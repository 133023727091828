import { limitStringLength } from "libs"
import { MouseEventHandler, ReactNode, useState } from "react"
import { createPortal } from "react-dom"
import Dropdown from "react-bootstrap/Dropdown"
import "./ClickableDropdown.css"

export interface Option {
	key: string | number
	text: string
	onClick: MouseEventHandler
}

export type DropdownBar = React.FC<{ option: Option; isSelected: boolean }>

const DefaultBar: DropdownBar = ({ option, isSelected }) => {
	return (
		<Dropdown.Item key={option.key} onMouseDown={option.onClick}>
			{isSelected && <span style={{ color: "var(--ara-blue)", marginLeft: -14 }}>● </span>}
			{option.text}
		</Dropdown.Item>
	)
}

type ToggleSource = "select" | "click" | "rootClose" | "keydown"

export function ClickableDropdown({
	label,
	selectedOption,
	defaultOptionName,
	options,
	maxVisibleLength = 25,
	Bar = DefaultBar,
	hideDelay,
	allowToggle = ["select", "click", "rootClose", "keydown"],
	children,
	portal,
	disabled = false,
	hideOnBlur = true,
	...props
}: {
	label?: ReactNode
	selectedOption: Option
	defaultOptionName: string | number
	options: Option[]
	maxVisibleLength?: number
	Bar?: DropdownBar
	hideDelay?: number
	allowToggle?: ToggleSource[]
	children?: ReactNode
	className?: string
	portal?: HTMLElement
	disabled?: boolean
	hideOnBlur?: boolean
}) {
	const [show, setShow] = useState(false)
	return (
		<Dropdown
			className={`clickable-dropdown ${disabled ? "disabled-clickable-dropdown" : "ƒ"} ${props.className}`}
			show={show}
			onBlur={() => {
				if (hideOnBlur) {
					setShow(false)
				}
			}}
			onToggle={(shouldShow: boolean, metaData: { originalEvent; source }) => {
				const e = metaData.originalEvent
				if (disabled) return
				if (allowToggle.includes(metaData.source)) {
					if (!shouldShow && hideDelay) {
						setTimeout(() => {
							setShow(shouldShow)
						}, hideDelay)
					} else {
						setShow(shouldShow)
					}
				} else {
					console.log(metaData.source)
					if (e.key === " ") {
						console.log("space")
						e.preventDefault()
					}
				}
			}}>
			<Dropdown.Toggle
				style={{ width: "max-content", maxWidth: "66vw", textAlign: "left" }}
				split
				id={`${label}dropdown-item-button`}
				variant="aralight">
				{label != null && (
					<span style={{ marginRight: 3 }} className="blueText">
						{label}
					</span>
				)}
				<wbr />
				<span style={{ marginRight: 3, textTransform: "capitalize" }}>
					{limitStringLength(selectedOption?.text ?? defaultOptionName.toString(), maxVisibleLength)}
				</span>
			</Dropdown.Toggle>
			{portal != null ? (
				createPortal(<ClickableDropdownMenu {...{ options, Bar, selectedOption, children }} />, portal)
			) : (
				<ClickableDropdownMenu {...{ options, Bar, selectedOption, children }} />
			)}
		</Dropdown>
	)
}

function ClickableDropdownMenu({ options, Bar, selectedOption, children }) {
	return (
		<Dropdown.Menu align="start" style={{ textTransform: "capitalize" }}>
			{children}
			{options.map((option) => (
				<Bar key={option.key} option={option} isSelected={option?.key === selectedOption?.key} />
			))}
		</Dropdown.Menu>
	)
}
