import { useCallback, useEffect, useRef, forwardRef } from "react"
import { useSlate } from "slate-react"
import { SingleTaggingDataset } from "../index.types"
import { ElementType } from "../../types/slate.types"
import { hasAncestorOfType } from "../../Utils"
import { HDropDown, HDropTitle, HDropItem } from "../../../HorzDropdown/HorzDropdown"
import { filterTaggingDataBySearch } from "../TaggingUtils"
import { StarIcon } from "../../../Icons"
import { useTaggingContext } from "../TaggingContext"

interface TaggingDropdownItemProps {
	dataName: string
	dataPoint: SingleTaggingDataset
	setFormat?: (format: string) => void
	isSelected?: boolean
	selectedRef?: React.MutableRefObject<HTMLDivElement>
}

interface TaggingDropdownProps {
	search?: string
	setFormat?: (format: string) => void
	dropdownData?: [string, SingleTaggingDataset][]
	selected?: number
	[key: string]: any
}

const TaggingDropdownItem = ({ dataName, dataPoint, setFormat, isSelected, selectedRef }: TaggingDropdownItemProps) => {
	return (
		<div
			className={`clickable data-tag-dropdown-item ${isSelected ? "data-tag-dropdown-item-selected" : ""}`}
			ref={(el) => {
				if (isSelected) {
					selectedRef.current = el
				}
			}}
			onMouseDown={(e) => {
				e.preventDefault()
				e.stopPropagation()
				setFormat(dataName)
			}}>
			@{dataPoint?.displayName}
			{dataPoint?.subType === "ai" && (
				<span style={{ marginLeft: 3 }}>
					<StarIcon />
				</span>
			)}
		</div>
	)
}

// const selectPrevChar = (editor) => {
//     const focus = editor.selection.focus
//     Transforms.move(editor, { distance: 1, reverse: true })
//     const anchor = editor.selection.anchor
//     Transforms.select(editor, { anchor, focus })
// }

export const TaggingButtonDropdown = () => {
	const editor = useSlate()
	const setFormat = useCallback(
		(formatValue: string) => {
			const format = `tagging@${formatValue}` as ElementType
			editor.toggleBlock(editor, format)
		},
		[editor]
	)

	const tagActive =
		hasAncestorOfType(editor, editor.selection?.anchor?.path, "tagging") ||
		hasAncestorOfType(editor, editor.selection?.focus?.path, "tagging")
	return (
		<HDropDown placement="bottom-start" closeOnClick={false}>
			<HDropTitle>
				<div
					style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
					className={`toolbar-button toolbar-button-left-border toolbar-button-${
						tagActive ? "unavailable" : "available clickable"
					}`}>
					<span
						style={{ display: "inline-block", fontSize: 18, paddingBottom: 2, textAlign: "right" }}
						className="toolbar-button-icon">
						@
					</span>
				</div>
			</HDropTitle>
			{!tagActive && (
				<HDropItem
					onClick={(e) => {
						e.stopPropagation()
						e.preventDefault()
					}}>
					<TaggingDropDown setFormat={setFormat} style={{ boxShadow: "none", border: "solid 1px #aaaaaa" }} />
				</HDropItem>
			)}
		</HDropDown>
	)
}

export const TaggingDropDown = forwardRef<HTMLDivElement, TaggingDropdownProps>(
	({ search = "", setFormat, dropdownData, selected, ...props }, ref) => {
		const editor = useSlate()
		const selectedRef = useRef<HTMLDivElement>()
		const { currentTaggingData } = useTaggingContext()

		useEffect(() => {
			if (selected != null) {
				setTimeout(() => selectedRef.current?.scrollIntoView?.({ block: "nearest" })) // prevents jumping to top when element not yet rendered
			}
		}, [selected])
		if (dropdownData == null) {
			// No dropdown data provided control internally
			dropdownData = filterTaggingDataBySearch(currentTaggingData(editor), search)
		}
		if (dropdownData?.length > 0) {
			return (
				<div {...props} className="data-tag-dropdown" ref={ref}>
					{dropdownData.map(([dataName, dataPoint], index) => (
						<TaggingDropdownItem
							key={dataName}
							{...{ dataName, dataPoint, setFormat, isSelected: selected === index, selectedRef }}
						/>
					))}
				</div>
			)
		} else {
			return <div style={{ display: "none" }}></div>
		}
	}
)
