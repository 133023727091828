import { createContext, useContext, useRef, useState, useCallback, useMemo } from "react"
import { TaggingDataSetNames, TaggingOptions, taggingDatasets } from "./TaggingDatasets"
import { ExtendedTaggingDataset, PotentialTagOptions, SingleTaggingDataset, TaggingDataset } from "./index.types"
import { makeTaggingData } from "./TaggingUtils"
import { CustomTag, CustomTagName, PlaceholderTag, PlaceholderTagName, useAppContext, useFeaturesContext } from "libs"
import { CustomEditor } from "../types/slate.types"

export type TaggingContextValue = {
	taggingData: ExtendedTaggingDataset
	currentTaggingData: (editor?: CustomEditor) => ExtendedTaggingDataset
	potentialTag: React.MutableRefObject<PotentialTagOptions>
	queuedChanges: React.MutableRefObject<{ [option in keyof TaggingOptions]: object }>
	hasQueuedChanges: React.MutableRefObject<boolean>
	commitChanges: () => void
	selection: number
	setSelection: (arg0: number) => void
	rawOptions: TaggingOptions
	autofillRows?: number[]
}

export interface TaggingContextProps {
	options: Partial<TaggingOptions>
	children: React.ReactNode
	customTags?: boolean
	autofillRows?: number[]
	partition?: (editor: CustomEditor) => Array<TaggingDataSetNames> | null
}

const TaggingContext = createContext<TaggingContextValue>({} as TaggingContextValue)
export const useTaggingContext = () => useContext(TaggingContext)

export const TaggingContextProvider = ({
	options,
	children,
	customTags = false,
	autofillRows,
	partition,
}: TaggingContextProps): JSX.Element => {
	const [selection, setSelection] = useState(0)
	const { permissions } = useFeaturesContext()
	const potentialTag = useRef<PotentialTagOptions>({ available: false, max: 99 })
	const queuedChanges = useRef<object>({})
	const hasQueuedChanges = useRef<boolean>(false)
	const customTaggingDatasets = useCustomTags(customTags)
	const taggingData = useMemo(
		() =>
			options != null
				? makeTaggingData(options, hasQueuedChanges, queuedChanges, customTaggingDatasets, permissions)
				: null,
		[customTaggingDatasets, options, permissions]
	)

	const currentTaggingData = useCallback(
		(editor?: CustomEditor) => {
			if (editor == null || partition == null) {
				return taggingData
			}
			const partitioned = partition(editor)
			if (partitioned == null) {
				return taggingData
			}
			let currentTaggingData = {}

			partitioned.forEach((taggingDataSetName) => {
				Object.keys((taggingDatasets[taggingDataSetName] ?? customTaggingDatasets[taggingDataSetName]).data).forEach(
					(tagName) => {
						if (taggingData[tagName] != null) {
							currentTaggingData[tagName] = taggingData[tagName]
						}
					}
				)
			})

			return currentTaggingData
		},
		[customTaggingDatasets, partition, taggingData]
	)

	const commitChanges = useCallback(() => {
		hasQueuedChanges.current = false
		for (var [option, { setFunc }] of Object.entries(options)) {
			if (queuedChanges.current[option] != null) {
				if (setFunc == null) {
					console.log("no set function available")
				}
				setFunc?.(queuedChanges.current[option])
				queuedChanges.current[option] = {}
			}
		}
	}, [options])
	const value = useMemo(
		() => ({
			commitChanges,
			hasQueuedChanges,
			queuedChanges,
			taggingData,
			potentialTag,
			selection,
			setSelection,
			rawOptions: options,
			autofillRows,
			currentTaggingData,
		}),
		[commitChanges, options, selection, taggingData, autofillRows, currentTaggingData]
	)
	if (options != null) {
		return <TaggingContext.Provider value={value}>{children}</TaggingContext.Provider>
	} else {
		return <>{children}</>
	}
}

export function useCustomTags(enabled: boolean): { customTags: { data: TaggingDataset } } {
	const { company } = useAppContext()
	const { permissions } = useFeaturesContext()
	const placeholderTagsEnabled = permissions.placeholderTags
	const placeholderTags = placeholderTagsEnabled ? company.options?.customTags?.placeholderTags : null
	const tags = company.options?.customTags?.tags
	return useMemo(
		() => ({
			customTags: {
				data: enabled
					? {
							...makeDatasetForCustomTags(tags),
							...makeDatasetForPlaceholderTags(placeholderTags),
						}
					: {},
			},
		}),
		[placeholderTags, tags, enabled]
	)
}

function makeDatasetForCustomTags(tags: { [tagName: CustomTagName]: CustomTag }): TaggingDataset {
	return Object.entries(tags ?? {})
		.filter(([tagName, tag]) => tag.scope?.templates !== false)
		.reduce((dataSet, [tagName, tag]) => {
			dataSet[tagName] = makeDatasetForCustomTag(tagName as CustomTagName, tag)
			return dataSet
		}, {})
}

function makeDatasetForCustomTag(tagName: CustomTagName, tag: CustomTag): SingleTaggingDataset {
	return {
		displayName: tag.displayName,
		path: ["tagData", tagName, "text"],
		completePath: ["tagData", tagName, "complete"],
		subType: "ai",
		type: "block",
	}
}

function makeDatasetForPlaceholderTags(tags: { [tagName: PlaceholderTagName]: PlaceholderTag }): TaggingDataset {
	return Object.entries(tags ?? {}).reduce((dataSet, [tagName, tag]) => {
		dataSet[tagName] = makeDatasetForPlaceholderTag(tagName as PlaceholderTagName, tag)
		return dataSet
	}, {})
}

function makeDatasetForPlaceholderTag(tagName: PlaceholderTagName, tag: PlaceholderTag): SingleTaggingDataset {
	return {
		displayName: tag.displayName,
		path: ["tagData", tagName, "text"],
		type: tag.type ?? "block",
	}
}
