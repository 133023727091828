const getDecimalDurationYears = (value: number) => (value ? value.toFixed(1) + " years" : "")

const reverseDecimalDurationYears = (value: string) => (value ? parseFloat(value.replace(" years", "")) : "")

const getDurationYears = (value: number) => (value ? Math.floor(value).toFixed(0) + " years" : "")

const getDurationYearsAndMonths = (value: number) => {
	if (!value) return ""
	const years = Math.floor(value)
	const months = Math.floor((value - years + 0.01) * 12) // add 0.01 to avoid floating point errors
	const monthText = months > 0 ? `${months} month${months === 1 ? "" : "s"}` : ""
	return years > 0 ? `${years} year${years === 1 ? "" : "s"} ${monthText}` : monthText
}

const reverseDurationYearsAndMonths = (value: string) => {
	if (!value) return ""
	if (value.includes("year")) {
		const [yearsRaw, monthsRaw] = value.replace(/s/g, "").replace(" month", "").split(" year ")
		const years = parseFloat(yearsRaw)
		const months = parseFloat(monthsRaw || "0")
		return years + months / 12
	}
	const monthsRaw = value.replace(" months", "")
	const months = parseFloat(monthsRaw)
	return months / 12
}

const getDurationYearsAndMonthsShort = (value: number) => {
	if (!value) return ""
	const years = Math.floor(value)
	const months = Math.round((value - years) * 12)
	return years > 0 ? `${years}y ${months}m` : `${months}m`
}

const reverseDurationYearsAndMonthsShort = (value: string) => {
	if (!value) return ""
	if (value.includes("y")) {
		const [yearsRaw, monthsRaw] = value.replace("m", "").split("y ")
		const years = parseFloat(yearsRaw)
		const months = parseFloat(monthsRaw)
		return years + months / 12
	}
	const monthsRaw = value.replace("m", "")
	const months = parseFloat(monthsRaw)
	return months / 12
}

export const DURATION_PROCESSING_SETS = {
	yearsAndMonths: {
		label: "Years and Months",
		longLabel: "Years and Months (eg 2 years 3 months)",
		postprocessing: () => reverseDurationYearsAndMonths,
		preprocessing: () => getDurationYearsAndMonths,
	},
	yearsAndMonthsShort: {
		label: "Years and Months Short",
		longLabel: "Years and Months Short (eg 2y 3m)",
		postprocessing: () => reverseDurationYearsAndMonthsShort,
		preprocessing: () => getDurationYearsAndMonthsShort,
	},
	roundedYears: {
		label: "Rounded Years",
		longLabel: "Rounded Years (eg 2 years)",
		postprocessing: () => reverseDecimalDurationYears,
		preprocessing: () => getDurationYears,
	},
	decimal: {
		label: "Decimal",
		longLabel: "Decimal (eg 2.5 years)",
		postprocessing: () => reverseDecimalDurationYears,
		preprocessing: () => getDecimalDurationYears,
	},
	default: {
		label: "Decimal",
		longLabel: "Decimal (eg 2.5 years)",
		postprocessing: () => reverseDecimalDurationYears,
		preprocessing: () => getDecimalDurationYears,
	},
}
