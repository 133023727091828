import { Editor, Element as SlateElement, Transforms, Node, Path, Range } from "slate"
import {
	CustomEditor,
	ElementType,
	HyperlinkElement,
	SlateElementRenderProps,
	CustomElement,
} from "../types/slate.types"
import { isBlockActive, multipleElementsSelected } from "../Utils"

const getCorrectUrlAndMail = (url) => {
	if (!url) {
		return null
	}
	if (url.startsWith("mailto:")) {
		return url
	} else if (url.startsWith("http://") || url.startsWith("https://")) {
		return url
	}
	return "http://" + url
}

const validUrlRegex = /\w\.\w/

const checkCorrectUrl = (url) => {
	if (!url) {
		return false
	}
	if (url.includes("mailto:")) {
		return true
	} else if (url.includes("http://") || url.includes("https://")) {
		return true
	} else if (url.match(validUrlRegex)?.length > 0 && !url.includes(" ")) {
		return true
	}
	return false
}

export const canApplyHyperlink = (editor: CustomEditor): boolean => {
	const someTextSelected = editor.selection && Editor.string(editor, editor.selection) !== ""
	return someTextSelected && !multipleElementsSelected(editor) // Cannot apply if multiple elements selected
}

const toggleHyperlink = (editor: CustomEditor, format: ElementType): boolean | null => {
	if (format !== "hyperlink") {
		return null
	}
	const isActive = isBlockActive(editor, format)

	if (isActive) {
		unwrapLink(editor)
	} else {
		if (!canApplyHyperlink(editor)) {
			return false
		}
		wrapLink(editor)
	}
	return true
}

const wrapLink = (editor: CustomEditor) => {
	Editor.withoutNormalizing(editor, () => {
		if (editor.parameters.templateMode && isBlockActive(editor, "tagging")) {
			// hyperlinks cannot be inside tagging, so expand selection to ensure appropriate wrapping
			const newStart = Editor.before(editor, Range.start(editor.selection))
			const newEnd = Editor.after(editor, Range.end(editor.selection))
			Transforms.select(editor, { anchor: newStart, focus: newEnd })
		}
		const selectionBefore = { ...editor.selection }
		Transforms.insertNodes(editor, [{ text: "" }], { at: Range.end(editor.selection) })
		Transforms.wrapNodes(editor, { type: "hyperlink", url: "" } as HyperlinkElement, {
			split: true,
			at: selectionBefore,
		})
		Editor.addMark(editor, "color", "#0000EE")
		Editor.addMark(editor, "underline", true)
	})
}

const unwrapLink = (editor: CustomEditor) => {
	const nodeEntries = [...Editor.nodes(editor, { match: (n: CustomElement) => n.type === "hyperlink" })]
	for (var nodeEntry of nodeEntries.reverse()) {
		unwrapSpecificLink(editor, nodeEntry[0] as CustomElement)
	}
}

export const unwrapSpecificLink = (editor: CustomEditor, element: CustomElement) => {
	const match = (n: Node, p: Path) => SlateElement.isElement(n) && element === n
	Editor.withoutNormalizing(editor, () => {
		Transforms.unwrapNodes(editor, { match, at: [] })

		for (var text of Node.texts(element)) {
			const [node] = text
			Transforms.unsetNodes(editor, ["underline", "color"], { at: [], match: (n) => n === node })
		}
	})
}

export { getCorrectUrlAndMail, toggleHyperlink, checkCorrectUrl }
