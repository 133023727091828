import { useState, useRef, useCallback, useMemo } from "react"
import {
	useAppContext,
	useEditingHistory,
	makeReducedObj,
	convertUndefinedToNull,
	getValueAtPath,
	changeNestedEntry,
	deepCopy,
	applicationApi,
	templateApi,
	TemplateChapter,
	Template,
	OldTemplating,
	CURRENT_DEFAULT_TOGGLES,
	DEFAULT_TOGGLES,
	runPolling,
} from "libs"
import { headlessEditor } from "../TextFormatting/HeadlessEditing"
import { slateTextIsEmpty } from "../TextFormatting/Utils"
import { updateTagCounts, getTagListsFromCounts } from "../TextFormatting/TaggingFormatting/TagCounting"
import {
	LATEST_TEMPLATE_VERSION,
	TOGGLE_PERMANENT_LABEL_MAPPING,
	TOGGLE_SHOW_LABEL_MAPPING,
	VISIBLE_TOGGLES,
	convertToLatest,
	createOrdering,
	makeItemCountsForChapters,
	makeV4ItemMap,
	ItemCount,
	LATEST_EMPTY_TEMPLATE,
} from "./TemplateVersioning"
import type {
	App,
	CVTemplateChapter,
	CustomTagName,
	Experience,
	NewTemplatingV5,
	Qualification,
	TimelineItem,
} from "libs"
import type { ToggleName, ToggleSet } from "libs"
import type { ToggleOption, FullToggleOptions } from "./TemplateHooks.types"
import { CurrentTemplate } from "./TemplateVersioning"
import { useCustomTags } from "../TextFormatting/TaggingFormatting/TaggingContext"
import { DEFAULT_TIMELINE_DATA } from "../CVViewing/CVCardComponents/TimelineComponents/TimelineCardContent/DefaultTimelineData"

const blankTemplate: Partial<CurrentTemplate> = {
	template_id: null,
	templateName: "No Template",
	...deepCopy(LATEST_EMPTY_TEMPLATE),
}

export const noTemplate: Partial<CurrentTemplate> = {
	...blankTemplate,
	chapters: [
		{
			type: "cv",
			name: "CV",
			ordering: ["recruiterSummary", "candidateInfo", "profileSummary", "experienceEducation"],
			toggleSettings: DEFAULT_TOGGLES,
			headerInfo: { header: { 4: { size: 1, type: "logo" } }, footer: {} },
		},
	],
	timelineData: {
		experienceEducation: DEFAULT_TIMELINE_DATA("preset-style-1"),
	},
}

type Field = { path: (keyof Template)[]; fieldName: string; subPaths?: (string | number)[][]; isArray?: boolean }

const PREFILLED_FIELDS: Field[] = [
	{ path: ["shortSummary"], fieldName: "Short Summary" },
	{ path: ["personalStatementTitle"], fieldName: "Profile Summary Heading" },
	{
		path: ["recruiterInfo"],
		fieldName: "Recruiter's View",
		subPaths: [["recruitersViewText"], ["displayCompanyName"]],
	},
	{ path: ["freeText"], fieldName: "Free Text Boxes", isArray: true, subPaths: [["heading"], ["text"]] },
	{ path: ["educationHeading"], fieldName: "Education Heading" },
	{ path: ["experienceHeading"], fieldName: "Experience Heading" },
]

function getNestedField(obj: object, ...keys: (string | number)[]) {
	const [topKey, ...otherKeys] = keys
	if (otherKeys.length === 0) {
		return obj[topKey]
	} else {
		return getNestedField(obj[topKey] ?? {}, ...otherKeys)
	}
}

export type TemplateWithId = CurrentTemplate & { template_id: string }

function tagsReady(app: App) {
	return app.miscData === "app_status#processed"
}

// const warnConfig = {
// 	defaultTTL: 1000 * 60 * 2,
// 	storage: window.sessionStorage,
// 	keyPrefix: "warnCache",
// }

// const warnCache = BrowserStorageCache.createInstance(warnConfig)

export function useTemplateForCVDisplay(
	appId: string,
	setIsSaving: (arg0: boolean) => void,
	setShowLoading?: (props: { outputName: string | null; animationDuration?: number }) => void
) {
	// must be set to templateToggles every time the template changes, but overwritten by direct changes between any change in template toggles
	const { apps, templates, setApps, company, folders, jobs } = useAppContext()

	const pollingIsRunning = useRef(false)
	// const { hasNonexpiredLinks } = useLinksContext()
	const thisApp: App = useMemo(() => apps[appId], [appId, apps])
	const thisFolder = useMemo(
		() => folders[thisApp?.folder_id ?? appId.slice(0, 49)],
		[folders, thisApp?.folder_id, appId]
	)
	const thisJob = useMemo(
		() => (thisApp?.jobId != null ? jobs[thisApp.jobId] : thisFolder),
		[jobs, thisApp?.jobId, thisFolder]
	)
	const originalData = useRef<{ [appId: string]: App }>({ appId: thisApp })
	const color: string = useMemo(() => thisApp?.colour ?? "#5114FE", [thisApp?.colour])

	const setNewOriginalData = useCallback((appId: string, newData: App) => {
		originalData.current[appId] = newData
	}, [])
	const thisAppRef = useRef<App>(apps[appId])
	useMemo(() => {
		thisAppRef.current = thisApp
	}, [thisApp])

	const setAppToDb = useCallback(
		(newApp: App, keys: Array<keyof App> = null, showSave = true) => {
			setApps({
				from: "singleItem",
				itemId: appId,
				item: newApp,
				for: thisApp?.folder_id,
			})
			if (showSave) {
				setIsSaving(true)
			}
			let changes = keys != null ? makeReducedObj(newApp, [...keys, "pk", "sk", "application_id"]) : newApp
			updateTagCounts(newApp, changes)
			return new Promise((resolve, reject) => {
				applicationApi
					.patch({ appId, update: changes })
					.then((success) => {
						setNewOriginalData(appId, thisAppRef.current)
						if (showSave) {
							setIsSaving(false)
						}
						// if (!success) { history.push('/') }
					})
					.finally(() => {
						resolve("finished db call")
					})
			})
		},
		[appId, thisApp?.folder_id, setApps, setIsSaving, setNewOriginalData]
	)

	// const hasAcceptedLinksWarning: (appId: string) => boolean = useCallback((appId) => {
	// 	// get from session storage
	// 	const accepted = warnCache.getItem(`${appId}-linksWarningAccepted`)
	// 	return accepted ?? false
	// }, [])

	// const setLinksWarningAccepted: (appId: string) => void = useCallback((appId: string) => {
	// 	// set in session storage
	// 	warnCache.setItem(`${appId}-linksWarningAccepted`, true)
	// }, [])

	// const warningMessage = useCallback(() => {
	// 	if (hasNonexpiredLinks && !hasAcceptedLinksWarning(appId)) {
	// 		console.log("show warning message")

	// 		if (
	// 			window.confirm(
	// 				"Landing pages have been created for this CV. The links will live update with the changes you make. Are you sure you wish to continue?"
	// 			)
	// 		) {
	// 			setLinksWarningAccepted(appId)
	// 			return false
	// 		} else {
	// 			return true
	// 		}
	// 	}
	// 	return false
	// }, [hasNonexpiredLinks, hasAcceptedLinksWarning, appId, setLinksWarningAccepted])

	const [undo, redo, addChangeToApp, resetHistory, canUndo, canRedo] = useEditingHistory(thisApp, setAppToDb, null, [
		"experiences",
		"qualifications",
		"freeText",
	])

	const fetchAppWithTags = useCallback(async () => {
		const newAppData = (await applicationApi.getSingle({
			appId,
			folderId: thisApp?.folder_id,
			attributes: ["tagData"],
		})) as App
		if (tagsReady(newAppData)) {
			addChangeToApp({
				tagData: newAppData.tagData,
				miscData: "app_status#processed",
			})
		}
		return newAppData
	}, [addChangeToApp, appId, thisApp?.folder_id])

	const pollForTagData = useCallback(
		(afterPollingFinish?: () => void) => {
			pollingIsRunning.current = true
			const resetPollingFlag = () => {
				pollingIsRunning.current = false
			}
			runPolling({
				fn: fetchAppWithTags,
				maxAttempts: 30,
				doubleEvery: 12,
				initialPollingInterval: 5,
				validate: tagsReady,
			}).finally(() => {
				resetPollingFlag()
				afterPollingFinish?.()
			})
		},
		[fetchAppWithTags]
	)

	const resetFieldsToTemplate = useCallback(
		(fields: (keyof Template)[]) => {
			const templateId = thisApp?.template
			const templateInfo = templateId ? templates[templateId] : noTemplate
			const change = fields.reduce((acc, field) => {
				acc[field] = templateInfo[field] ?? null
				return acc
			}, {})
			addChangeToApp(change)
		},
		[addChangeToApp, templates, thisApp?.template]
	)

	const regenerateTags = useCallback(
		async (tagsToGenerate: string[], updates: Partial<App> = {}, resetFields?: (keyof Template)[]) => {
			const newTagData = deepCopy(thisApp?.tagData ?? {})
			if (newTagData != null) {
				tagsToGenerate.forEach((tag) => {
					delete newTagData[tag]
					if (tag.startsWith("job")) {
						delete newTagData.jobTagData
					}
				})
			}
			updates = { tagData: newTagData, ...updates, miscData: "app_status#generatingTags" }
			addChangeToApp(updates, false) // set status locally to display loading screen
			await applicationApi.generateTags({ appId, tags: tagsToGenerate, updates })
			// start polling
			let onFinishPolling = () => {}
			if (resetFields != null && resetFields.length > 0) {
				onFinishPolling = () => resetFieldsToTemplate(resetFields)
			}

			const initialDelay = 3 // allow time for db set before starting polling
			setTimeout(() => {
				pollForTagData(onFinishPolling)
			}, initialDelay * 1000)
		},
		[addChangeToApp, appId, pollForTagData, thisApp?.tagData, resetFieldsToTemplate]
	)

	useMemo(() => {
		if (thisApp == null) {
			// no app loaded do nothing
			return
		}
		if (!thisApp.loadedIndividually) {
			// shouldn't update until individual loading finishes
			return
		}
		if (
			thisApp?.templateVersion != null &&
			thisApp?.templateVersion !== LATEST_TEMPLATE_VERSION &&
			(thisApp?.defaultTemplate == null || thisApp?.defaultTemplate?.length === 0)
		) {
			// app has old template applied, update
			console.log("updating template version on app")
			const newApp = convertToLatest(thisApp as OldTemplating)
			addChangeToApp(newApp, false)
		} else if (
			thisApp?.templateVersion == null &&
			(thisApp?.defaultTemplate == null || thisApp?.defaultTemplate?.length === 0)
		) {
			// should not be triggered if default template is set as this can lead to overwriting of data
			console.log("app has no template version")
			// app has no template version so can assume none applied and apply latest version blank template
			// non-app attributes will be ignored in save so can just spread noTemplate
			addChangeToApp({ ...noTemplate, ...thisApp } as Partial<App>, false)
		} else {
			console.log("template version is up to date", thisApp?.templateVersion)
		}
	}, [addChangeToApp, thisApp])

	const toggles = useMemo(() => thisApp?.toggles ?? CURRENT_DEFAULT_TOGGLES, [thisApp?.toggles])

	const isBlankTemplate = useMemo(() => {
		const allChapters = (thisApp as NewTemplatingV5)?.chapters
		const cvChapters = allChapters?.filter((chapter) => chapter.type === "cv") as CVTemplateChapter[]
		return (
			allChapters?.length === 0 ||
			(cvChapters?.length === allChapters?.length && cvChapters?.[0]?.ordering?.length === 0)
		)
	}, [thisApp])

	const setAppWithTemplate = useCallback(
		(newTemplateInfoIn: TemplateWithId) => {
			// if template has been set apply changes
			const newTemplateInfo = convertToLatest(newTemplateInfoIn) as CurrentTemplate
			const editor = headlessEditor()
			const updateSlateText = (text) =>
				text == null
					? text
					: editor.applyHeadless("resetProperties", text, { shouldReset: true, resetList: ["align", "color", "size"] })
			if (newTemplateInfo?.template_id === null) {
				addChangeToApp({
					template: newTemplateInfo.template_id,
					templateVersion: newTemplateInfo.templateVersion,
					ordering: null,
					toggles: null,
					colour: null,
					timelineOrder: null,
					freeText: [],
					recruiterInfo: {},
					experienceHeading: null,
					educationHeading: null,
					experiences: removeTimelineText(thisApp.experiences),
					qualifications: removeTimelineText(thisApp.qualifications),
					personalStatementTitle: null,
					shortSummary: null,
					headerInfo: null,
					skills: { text: thisApp?.skills?.text },
					interests: { text: thisApp?.interests?.text },
					coverLetter: null,
					textAlignment: null,
					defaultColors: null,
					defaultSizes: null,
					defaultLineSpacings: null,
					spacing: null,
					outputName: newTemplateInfo?.outputName ?? thisApp?.outputName,
					chapters: newTemplateInfo?.chapters,
					defaultTemplate: null, // once a template is applied the default template is no longer used
					tagCounts: newTemplateInfo?.tagCounts,
					timelineData: newTemplateInfo?.timelineData ?? {
						experienceEducation: DEFAULT_TIMELINE_DATA("preset-style-1"),
					},
					requirementsTable: null,
					summaryTimeline: null,
					originalCV: newTemplateInfo?.originalCV,
					candidateHeadshotData: newTemplateInfo?.candidateHeadshotData,
				} as Partial<App>)
			} else {
				addChangeToApp(
					convertUndefinedToNull(
						{
							template: newTemplateInfo.template_id,
							templateVersion: newTemplateInfo.templateVersion,
							ordering: null,
							toggles: null,
							colour: newTemplateInfo?.colour,
							timelineOrder: newTemplateInfo?.timelineOrder,
							freeText: [...(newTemplateInfo?.freeText ?? [])],
							recruiterInfo: {
								...(newTemplateInfo?.recruiterInfo ?? {}),
							},
							experienceHeading: newTemplateInfo?.experienceHeading,
							educationHeading: newTemplateInfo?.educationHeading,
							experiences: removeTimelineText(thisApp?.experiences)?.map((e) => {
								e.comments = updateSlateText(e.comments)
								return e
							}),
							qualifications: removeTimelineText(thisApp.qualifications)?.map((e) => {
								e.comments = updateSlateText(e.comments)
								return e
							}),
							personalStatementTitle: newTemplateInfo?.personalStatementTitle,
							personalStatement: updateSlateText(thisApp?.personalStatement),
							shortSummary: newTemplateInfo?.shortSummary,
							coverLetter: null,
							skills: { heading: newTemplateInfo?.skills?.heading, text: updateSlateText(thisApp?.skills?.text) },
							interests: {
								heading: newTemplateInfo?.interests?.heading,
								text: updateSlateText(thisApp?.interests?.text),
							},
							textAlignment: newTemplateInfo?.textAlignment,
							defaultColors: newTemplateInfo?.defaultColors,
							defaultSizes: newTemplateInfo?.defaultSizes,
							defaultLineSpacings: newTemplateInfo?.defaultLineSpacings,
							spacing: newTemplateInfo?.spacing,
							outputName: newTemplateInfo?.outputName ?? thisApp?.outputName,
							chapters: newTemplateInfo?.chapters,
							tagCounts: newTemplateInfo?.tagCounts,
							timelineData: newTemplateInfo?.timelineData ?? {
								experienceEducation: DEFAULT_TIMELINE_DATA("preset-style-1"),
							},
							defaultTemplate: null, // once a template is applied the default template is no longer used
							requirementsTable: newTemplateInfo?.requirementsTable,
							summaryTimeline: newTemplateInfo?.summaryTimeline,
							originalCV: newTemplateInfo?.originalCV,
							candidateHeadshotData: newTemplateInfo?.candidateHeadshotData,
						},
						false
					)
				)
			}
		},
		[
			addChangeToApp,
			thisApp?.experiences,
			thisApp?.interests?.text,
			thisApp?.personalStatement,
			thisApp?.qualifications,
			thisApp?.skills?.text,
			thisApp?.outputName,
		]
	)

	const template = useMemo(() => {
		if (thisApp == null) return
		// template set to null rather than undefined when template set
		const newTemplate =
			thisApp?.template != null && templates[thisApp?.template] != null
				? convertToLatest(templates[thisApp?.template])
				: noTemplate
		// has a template but no version, so must be an old template, backfill missing properties from updated template
		// TEMP_template_backfill_if_needed(newTemplate)
		return newTemplate
	}, [templates, thisApp])

	const templateVersion = useMemo(
		() => thisApp?.templateVersion ?? template?.templateVersion ?? "0",
		[template?.templateVersion, thisApp?.templateVersion]
	)

	const willOverwrite = useCallback(
		(newTemplateInfo: Template) => {
			// Check that the current template application doesn't overwrite anything that isn't in the
			// current template and if there is nothing in that field of the current template check that
			// it doesn't overwrite the original data
			const originalApp = originalData.current[appId]
			const templateHasBeenAltered = (path: (string | number)[]) =>
				template != null && getNestedField(template, ...path) !== getNestedField(thisApp, ...path)
			const originalHasBeenAltered = (path: (string | number)[]) =>
				slateTextIsEmpty(getNestedField(template, ...path)) &&
				getNestedField(originalApp, ...path) !== getNestedField(thisApp, ...path)
			for (var { path, isArray, subPaths = [[]] } of PREFILLED_FIELDS) {
				if (isArray) {
					const numElements = getNestedField(template, ...path)?.length ?? 0
					let overWriting = false
					for (var pathIndex = 0; pathIndex < numElements; pathIndex++) {
						if (!overWriting) {
							for (var subPathExtArr of subPaths) {
								const subPath = [...path, pathIndex, ...subPathExtArr]
								if (
									(templateHasBeenAltered(subPath) || originalHasBeenAltered(subPath)) &&
									getNestedField(thisApp, ...subPath) !== getNestedField(newTemplateInfo, ...subPath)
								) {
									return true
								}
							}
						}
					}
				} else {
					let overWriting = false
					if (!overWriting) {
						for (var subPathExt of subPaths) {
							const subPath = [...path, ...subPathExt]
							if (
								(templateHasBeenAltered(subPath) || originalHasBeenAltered(subPath)) &&
								getNestedField(thisApp, ...subPath) !== getNestedField(newTemplateInfo, ...subPath)
							) {
								return true
							}
						}
					}
				}
			}
			return false
		},
		[appId, template, thisApp]
	)

	const customTagIsOutOfDate = useCallback(
		(tag: CustomTagName) => {
			return (
				company?.options?.customTags?.tags?.[tag]?.hash != null &&
				company?.options?.customTags?.tags?.[tag]?.hash !== thisApp?.tagData?.[tag]?.hash
			)
		},
		[company?.options?.customTags?.tags, thisApp?.tagData]
	)

	const jobTagIsOutOfDate = useCallback(
		(tag: string) => {
			if (JOB_TAG_HASH_PATHS[tag] == null) {
				return false
			}
			const jobHash = getValueAtPath(thisJob, JOB_TAG_HASH_PATHS[tag].jobHash)
			const appHash = getValueAtPath(thisApp, JOB_TAG_HASH_PATHS[tag].appHash)
			return jobHash != null && jobHash !== appHash
		},
		[thisApp, thisJob]
	)

	const jobTagIsComplete = useCallback(
		(tag: string) => {
			let complete = thisApp?.tagData?.jobTagData?.[tag]?.complete
			if (JOB_TAG_COMPLETE_PATH[tag] != null) {
				complete = getValueAtPath(thisApp, JOB_TAG_COMPLETE_PATH[tag])
			}
			return complete ?? false
		},
		[thisApp]
	)

	const setTemplate = useCallback(
		async (newTemplateInfo: TemplateWithId) => {
			if (newTemplateInfo?.template_id !== undefined) {
				if (thisApp != null && originalData.current[appId] == null) {
					// save originalData
					originalData.current[appId] = thisApp
				}
				if (willOverwrite(newTemplateInfo)) {
					const message = `Are you sure you want to apply this template?\nSome prefilled fields may overwrite the existing data and any removed cards may lose changes.`
					// eslint-disable-next-line no-restricted-globals
					if (confirm(message) === false) {
						return
					}
				}
				const tagsToGenerate = newTemplateInfo?.tagsToGenerate?.filter(
					(tag: CustomTagName) =>
						(!tag.startsWith("job") &&
							(thisApp?.tagData?.[tag] == null || !thisApp?.tagData?.[tag]?.complete || customTagIsOutOfDate(tag))) ||
						(tag.startsWith("job") && (jobTagIsOutOfDate(tag) || !jobTagIsComplete(tag)))
				)
				if (tagsToGenerate?.length > 0) {
					console.log("tags to generate:", tagsToGenerate)
					regenerateTags(tagsToGenerate)
					setAppWithTemplate(newTemplateInfo)
				} else {
					setAppWithTemplate(newTemplateInfo)
				}
			}
		},
		[
			thisApp,
			appId,
			willOverwrite,
			customTagIsOutOfDate,
			jobTagIsOutOfDate,
			jobTagIsComplete,
			setAppWithTemplate,
			regenerateTags,
		]
	)

	return {
		templateVersion,
		isBlankTemplate,
		toggles,
		template,
		setTemplate,
		color,
		undo,
		redo,
		addChangeToApp,
		resetHistory,
		canUndo,
		canRedo,
		regenerateTags,
	}
}

const HEADING_TEXT_SECTIONS = [
	["educationHeading"],
	["experienceHeading"],
	["coverLetter", "heading"],
	["interests", "heading"],
	["skills", "heading"],
	["personalStatementTitle"],
	["recruiterInfo", "recruitersViewTitle"],
]
const BLOCK_TEXT_SECTIONS = [["shortSummary"], ["recruiterInfo", "recruitersViewText"], ["coverLetter", "text"]]
const editor = headlessEditor()
const resetProperties = (text: string, properties: string[]) =>
	editor.applyHeadless("resetProperties", text, { shouldReset: true, resetList: properties })

const makeToggleOption = (key: ToggleName, toggleSettings: Partial<ToggleSet>, itemCounts: ItemCount): ToggleOption => {
	const value = toggleSettings?.[key] ?? CURRENT_DEFAULT_TOGGLES[key]
	const permLabel = TOGGLE_PERMANENT_LABEL_MAPPING[key]
	if (permLabel != null) {
		return { label: permLabel.label, show: true, value }
	}
	const { label, showKey } = TOGGLE_SHOW_LABEL_MAPPING?.[key] ?? { label: "Missing label", showKey: null }
	return { value, label, show: itemCounts?.[showKey] > 0 }
}

function makeToggleOptions(chapter: TemplateChapter): FullToggleOptions {
	const toggleSettings = chapter?.toggleSettings ?? {}
	const itemCounts = makeItemCountsForChapters([chapter]) // itemcounts for only this chapter
	return VISIBLE_TOGGLES.reduce(
		(toggleOptions, toggleKey) => {
			const option = makeToggleOption(toggleKey, toggleSettings, itemCounts)
			const showKey = TOGGLE_SHOW_LABEL_MAPPING?.[toggleKey]?.showKey
			toggleOptions.all[toggleKey] = option
			if (showKey == null) {
				toggleOptions.permanent[toggleKey] = option
				return toggleOptions
			}
			if (toggleOptions.dependent[showKey] == null) {
				toggleOptions.dependent[showKey] = {}
			}
			toggleOptions.dependent[showKey][toggleKey] = option
			return toggleOptions
		},
		{ all: {}, permanent: {}, dependent: {} } as FullToggleOptions
	)
}

export function useTemplateForTemplating(templateId: string, setIsSaving: (arg0: boolean) => void) {
	// Staggered destructuring of template data. thisTemplate contains the data as received from the db,
	// and it is broken down into the data as used directly by the template
	const { templates, setTemplates, company } = useAppContext()
	const [selectedChapter, setSelectedChapter] = useState<number>(0)
	const customTaggingDatasets = useCustomTags(true)

	const setTemplateToDb = useCallback(
		async (newTemplate, keys = null) => {
			let changes = keys != null ? makeReducedObj(newTemplate, [...keys, "pk", "sk"]) : newTemplate
			changes = updateTagCounts(newTemplate, changes)
			if (changes.tagCounts != null) {
				const groupedTagList = getTagListsFromCounts(changes?.tagCounts, customTaggingDatasets)
				if (groupedTagList.ai != null) {
					changes.tagsToGenerate = groupedTagList.ai
				}
			}
			setIsSaving(true)
			setTemplates({ from: "singleItem", itemId: templateId, item: { ...newTemplate, ...changes }, for: company.id }) // update the stored copy to avoid old copies appearing on refresh

			return new Promise((resolve, reject) => {
				templateApi
					.update({ templateId, body: changes })
					.then(() => {
						"finished call"
					})
					.finally(() => {
						setIsSaving(false)
						resolve("finished db call")
					})
			})
		},
		[setIsSaving, setTemplates, templateId, company.id, customTaggingDatasets]
	)
	const [undo, redo, addChangeToTemplate, , canUndo, canRedo] = useEditingHistory(
		templates[templateId],
		setTemplateToDb
	)
	const thisTemplate: Partial<CurrentTemplate> = useMemo(() => {
		if (templates[templateId] == null) {
			return {}
		} // template not loaded yet, must avoid saving blank converted template
		const oldTemplateVersion = templates[templateId]?.templateVersion
		if (oldTemplateVersion === LATEST_TEMPLATE_VERSION) {
			return deepCopy(templates[templateId]) as CurrentTemplate
		}
		console.log("converting to version", LATEST_TEMPLATE_VERSION)
		const newTemplate = convertToLatest(deepCopy(templates[templateId]) as Template)
		// save template as conversion was run
		addChangeToTemplate(deepCopy(newTemplate))
		return newTemplate
	}, [addChangeToTemplate, templateId, templates])

	const templateInfo: CurrentTemplate = useMemo(() => deepCopy(thisTemplate) ?? {}, [thisTemplate])

	const setTemplateInfoWithoutSaving = useCallback(
		(value) => {
			setTemplates({ from: "singleItem", itemId: templateId, item: value, for: company.id })
		},
		[company.id, setTemplates, templateId]
	)
	const setTemplateInfo = useCallback(
		(value) => {
			addChangeToTemplate(value)
		},
		[addChangeToTemplate]
	)

	const handleTemplateInfo = useCallback(
		(...path: (string | number)[]) =>
			(event: { target: { value: any } }) => {
				const newTemplate = changeNestedEntry(thisTemplate, event.target.value, ...path)
				setTemplateInfo({ [path[0]]: newTemplate[path[0]] })
			},
		[setTemplateInfo, thisTemplate]
	)

	const handleSelectedChapterInfo = useCallback(
		(...path: (string | number)[]) => {
			return handleTemplateInfo("chapters", selectedChapter, ...path)
		},
		[handleTemplateInfo, selectedChapter]
	)

	const setSelectedChapterInfo = useCallback(
		(value: Partial<TemplateChapter>) => {
			const newTemplate = changeNestedEntry(
				thisTemplate,
				{ ...thisTemplate?.chapters?.[selectedChapter], ...value },
				"chapters",
				selectedChapter
			)
			setTemplateInfo({ chapters: newTemplate.chapters })
		},
		[selectedChapter, setTemplateInfo, thisTemplate]
	)

	const [userHasChangedToggles, setUserHasChangedToggles] = useState(
		templateInfo?.chapters?.some((chapter) => chapter?.toggleSettings != null)
	) // at least one toggle set in at least one chapter
	// Create templateItems/setTemplateItems from templateInfo
	const resetToDefault = useCallback(
		({ useCase, property }) => {
			if (useCase === "heading") {
				HEADING_TEXT_SECTIONS.forEach((path) => {
					handleTemplateInfo(...path)({
						target: { value: resetProperties(getValueAtPath(templateInfo, path) ?? "", [property]) },
					})
				})
				const newFreeText = templateInfo.freeText?.map(({ heading, text }) => ({
					text,
					heading: resetProperties(heading, [property]),
				}))
				handleTemplateInfo("freeText")({ target: { value: newFreeText } })
			} else if (useCase === "block") {
				BLOCK_TEXT_SECTIONS.forEach((path) => {
					handleTemplateInfo(...path)({
						target: { value: resetProperties(getValueAtPath(templateInfo, path) ?? "", [property]) },
					})
				})
				const newFreeText = templateInfo.freeText?.map(({ heading, text }) => ({
					heading,
					text: resetProperties(text, [property]),
				}))
				handleTemplateInfo("freeText")({ target: { value: newFreeText } })
				const newTimelineData = Object.fromEntries(
					Object.entries(templateInfo?.timelineData ?? {}).map(([timelineKey, thisTimelineData]) => {
						return [
							timelineKey,
							{
								...thisTimelineData,
								experiences: Object.fromEntries(
									Object.entries(thisTimelineData?.experiences ?? {})?.map(([key, data]) => [
										key,
										resetProperties(data, [property]),
									])
								),
								qualifications: Object.fromEntries(
									Object.entries(thisTimelineData?.qualifications ?? {})?.map(([key, data]) => [
										key,
										resetProperties(data, [property]),
									])
								),
							},
						]
					})
				)
				handleTemplateInfo("timelineData")({ target: { value: newTimelineData } })
			}
		},
		[handleTemplateInfo, templateInfo]
	)

	// Create toggleOptions/setToggleOption from templateInfo for current chapter
	const toggleOptions = useMemo(() => {
		const chapter = templateInfo?.chapters?.[selectedChapter]
		return makeToggleOptions(chapter)
	}, [selectedChapter, templateInfo?.chapters])

	const showHeaderFooterSource = useMemo(
		() => toggleOptions?.all?.includeHeader?.value || toggleOptions?.all?.includeFooter?.value,
		[toggleOptions?.all?.includeHeader?.value, toggleOptions?.all?.includeFooter?.value]
	)

	const setToggleOption = useCallback(
		(toggleName: ToggleName) => (value: boolean) => {
			setUserHasChangedToggles(true)
			handleSelectedChapterInfo("toggleSettings")({
				target: {
					value: {
						...templateInfo?.chapters?.[selectedChapter]?.toggleSettings,
						[toggleName]: value,
					},
				},
			})
		},
		[handleSelectedChapterInfo, selectedChapter, templateInfo?.chapters]
	)
	// create itemCounts (setItemCounts not needed as automatically calculated form templateInfo on each change)
	const itemCounts = useMemo(() => makeItemCountsForChapters(templateInfo?.chapters), [templateInfo?.chapters])
	const itemMap = makeV4ItemMap(templateInfo?.chapters)

	const templateColour = useMemo(() => templateInfo?.colour ?? "#5114fe", [templateInfo?.colour])
	const setTemplateColor = useCallback(
		(colour: string) => {
			handleTemplateInfo("colour")({ target: { value: colour } })
		},
		[handleTemplateInfo]
	)
	const templateAlignment = useMemo(() => templateInfo?.textAlignment ?? {}, [templateInfo?.textAlignment])
	const setTemplateAlignment = useCallback(
		(key: string) => (alignment: string) => {
			handleTemplateInfo("textAlignment", key)({ target: { value: alignment } })
			resetToDefault({ useCase: key, property: "align" })
		},
		[handleTemplateInfo, resetToDefault]
	)

	const defaultColors = useMemo(() => templateInfo?.defaultColors ?? {}, [templateInfo?.defaultColors])
	const setDefaultColors = useCallback(
		(key) => (color) => {
			handleTemplateInfo("defaultColors", key)({ target: { value: color } })
			resetToDefault({ useCase: key, property: "color" })
		},
		[handleTemplateInfo, resetToDefault]
	)

	const defaultSizes = useMemo(() => templateInfo?.defaultSizes ?? {}, [templateInfo?.defaultSizes])
	const setDefaultSizes = useCallback(
		(key: string) => (alignment: string) => {
			handleTemplateInfo("defaultSizes", key)({ target: { value: alignment } })
			resetToDefault({ useCase: key, property: "size" })
			// propertyResettingRef.current[key] = { shouldReset: true, resetList: [] }
		},
		[handleTemplateInfo, resetToDefault]
	)

	const defaultLineSpacings = useMemo(
		() => templateInfo?.defaultLineSpacings ?? {},
		[templateInfo?.defaultLineSpacings]
	)
	const setDefaultLineSpacings = useCallback(
		(key: string) => (lineSpacing: string) => {
			handleTemplateInfo("defaultLineSpacings", key)({ target: { value: lineSpacing } })
			resetToDefault({ useCase: key, property: "lineSpacing" })
			// propertyResettingRef.current[key] = { shouldReset: true, resetList: [] }
		},
		[handleTemplateInfo, resetToDefault]
	)

	const spacing = useMemo(() => templateInfo?.spacing, [templateInfo?.spacing])
	const setSpacing = useCallback(
		(name: string) => {
			handleTemplateInfo("spacing")({ target: { value: name } })
		},
		[handleTemplateInfo]
	)

	return {
		addChangeToTemplate,
		templateInfo,
		setTemplateInfo,
		setTemplateInfoWithoutSaving,
		handleTemplateInfo,
		handleSelectedChapterInfo,
		setSelectedChapterInfo,
		itemCounts,
		itemMap,
		templateColour,
		setTemplateColor,
		toggleOptions,
		setToggleOption,
		createOrdering,
		convertToLatest,
		showHeaderFooterSource,
		userHasChangedToggles,
		selectedChapter,
		setSelectedChapter,
		templateAlignment,
		setTemplateAlignment,
		defaultColors,
		setDefaultColors,
		defaultSizes,
		setDefaultSizes,
		defaultLineSpacings,
		setDefaultLineSpacings,
		spacing,
		setSpacing,
		undo,
		redo,
		canUndo,
		canRedo,
		chapters: templateInfo?.chapters ?? [],
		// addChangeToTemplate, resetHistory,
	}
}

function removeTimelineText(items: TimelineItem[]): Array<Experience | Qualification> {
	if (items == null) return items
	return items?.map((item) => ({
		...item,
		text: undefined,
		name: undefined,
		place: undefined,
		extra: undefined,
		date: undefined,
	}))
}

const JOB_TAG_HASH_PATHS = {
	"job-overallRequirementGradedSummary": {
		appHash: ["tagData", "jobTagData", "overallRequirementSummary", "job-overallRequirementSummary", "hash"],
		jobHash: ["current_hash"],
	},
}
const JOB_TAG_COMPLETE_PATH = {
	"job-overallRequirementGradedSummary": ["tagData", "jobTagData", "overallRequirementSummary", "complete"],
}
