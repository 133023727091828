import { setLeafUnchanged, setWholeTextSelection, markExistsInText } from "../Utils"
import { Editor } from "slate"
import { CustomPlugin } from "../types/slate.types"
import { setFontLeafProps } from "./FontUtils"
import { fontOptions } from "./FontOptions"

export const withFont: CustomPlugin = (editor) => {
	const { setLeafProps, customMarkOptions, resetProperties } = editor

	editor.customMarkOptions = {
		...(customMarkOptions ?? {}),
		...fontOptions,
	}

	editor.resetProperties = ({ resetList }) => {
		if (resetList.includes("font") && markExistsInText(editor, "font")) {
			// reset property to null in all elements
			setWholeTextSelection(editor)
			Editor.withoutNormalizing(editor, () => {
				Editor.removeMark(editor, "font")
			})
		}
		resetProperties?.({ resetList })
	}

	editor.setLeafProps = (props) => {
		const setPrevLeafProps = setLeafProps ?? setLeafUnchanged
		return setFontLeafProps({ ...setPrevLeafProps(props), availableFonts: editor.availableFonts })
	}

	return editor
}
