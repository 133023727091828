export function AIAssistIcon({ fontSize = 16 }) {
	return (
		<span
			style={{
				fontSize: `${fontSize}px`,
				fontWeight: "bold",
			}}>
			AI Assist
			<span
				style={{
					marginLeft: "2px",
					fontSize: `${fontSize - 6}px`,
					fontWeight: "bold",
					color: "black",
				}}></span>
		</span>
	)
}
