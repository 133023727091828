import {
	deserialize,
	setSelectionToWholeElementAtPath,
	hasAncestorOfType,
	ancestorsOfType,
	isAtEndOfTypes,
	isAtStartOfTypes,
	isAtEnd,
} from "../Utils"
import { useSlate, ReactEditor } from "slate-react"
import { Editor, Transforms, Node, Text, Range, Descendant, Element, BasePoint } from "slate"
import { useCallback } from "react"
import { Client, deepCopy, DEFAULT_PROPS_FOR_LANGUAGE, getValueAtPath, PromptOptions, setValueAtPath } from "libs"
import { taggingDatasets } from "./TaggingDatasets"
import { TaggingOptions } from "./TaggingDatasets"
import { CustomElement, ElementType, CustomEditor, TaggingElementType } from "../types/slate.types"
import { ExtendedTaggingDataset, TaggingDataset, Nodetype } from "./index.types"
import { HistoryEditor } from "slate-history"

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
export const InlineChromiumBugfix = () => (
	<span contentEditable={false} style={{ fontSize: 0 }}>
		${String.fromCodePoint(160) /* Non-breaking space */}
	</span>
)

export const useClickToSelectAll = (element: CustomElement) => {
	const editor = useSlate()
	const path = ReactEditor.findPath(editor, element)

	const onClick = useCallback(
		(e?: React.MouseEvent) => {
			console.log("selecting all on click", path)
			e?.preventDefault()
			Transforms.select(editor, { anchor: Editor.start(editor, []), focus: Editor.end(editor, []) }) // prevents losing cursor
			Transforms.select(editor, { anchor: Editor.start(editor, path), focus: Editor.end(editor, path) })
		},
		[editor, path]
	)

	return onClick
}

export const displayTagName = (
	tagName: string,
	taggingData: TaggingDataset,
	prefix = "@",
	elementIndexToAppend: number | null = null
) => {
	return `${prefix}${taggingData[tagName]?.displayName}${
		elementIndexToAppend != null ? ` ${elementIndexToAppend + 1}` : ""
	}`
}

export const makeTaggedText = (text) =>
	`[{\"type\":\"paragraph\",\"children\":[{\"text\":\"${text}\",\"dataTag\":true}]}]`

export const filterTaggingDataBySearch = (taggingData: TaggingDataset, search: string) => {
	const searchRegex = new RegExp(`\\b${search.trim().replace(/[^a-zA-Z ]/g, "")}`, "i")
	const filterBySearch =
		search?.length !== 0 ? ([, tagData]) => tagData.displayName.match(searchRegex)?.length > 0 : () => true
	return Object.entries(taggingData).filter(filterBySearch)
}

function makeChange(newData: any, path: Array<string | number>, fullData: object): object {
	if (path.length === 0) throw new Error("Path must be non-empty")
	// early exit at empty path guarantees that output is an object not a string
	if (newData === getValueAtPath(fullData, path)) return {} // no change
	const newDataOut = deepCopy({ [path[0]]: fullData[path[0]] })
	setValueAtPath(newDataOut, path, newData)
	return newDataOut // return only the changed part of the object
	// return path.reduceRight((obj, k) => ({ [k]: obj }), data as object | string) as object
}

export function getFirstTextCharFromSlateData(data: string | Descendant[]) {
	if (typeof data === "string") {
		return data[0]
	}
	if (data == null || data.length === 0) return null
	if (Text.isText(data[0])) {
		return data[0].text[0]
	}
	return getFirstTextCharFromSlateData(data[0]?.children)
}

// function marksOnFullSelection(editor) {
// 	var marks = {}
// 	let start = true
// 	console.log('checking marks')
// 	for (var [text] of Node.texts(editor, { from: editor.selection.anchor, to: editor.selection.nextFocus })) {
// 		console.log(text)
// 		if (start) {
// 			// fill initial marks
// 			for (var [key, value] of Object.entries(text)) {
// 				if (key !== "text") {
// 					marks[key] = value
// 				}
// 			}
// 			start = false
// 			console.log('initial marks', marks)
// 		} else {
// 			// remove any marks that are not identical to the first set
// 			for (var [mark, markValue] of Object.entries(marks)) {
// 				if (text[mark]!==markValue){
// 					delete marks[mark]
// 				}
// 			}
// 		}
// 		console.log(marks)
// 		if (Object.keys(marks).length===0){
// 			// early return once no marks
// 			console.log('early return')
// 			return {}
// 		}
// 	}
// 	return marks
// }

export const setTagText = (editor: CustomEditor, element: CustomElement, currentDataString: string) => {
	const taggingEditorPath = ReactEditor.findPath(editor, element)
	if (element.nodeType === "inline") {
		try {
			HistoryEditor.withoutSaving(editor, () => {
				Editor.withoutNormalizing(editor, () => {
					Transforms.insertText(editor, currentDataString, { at: taggingEditorPath })
				})
			})
		} catch {
			console.log("failed to insert text")
		}
	} else {
		const currentData = deserialize(currentDataString, {}, element.props)
		const firstChar = getFirstTextCharFromSlateData(element.children)
		const shouldApplyMarks = firstChar === "@" // apply marks when converting for first time

		try {
			HistoryEditor.withoutSaving(editor, () => {
				Editor.withoutNormalizing(editor, () => {
					let marks
					if (shouldApplyMarks) {
						// get marks before update
						setSelectionToWholeElementAtPath(editor, taggingEditorPath) // marks functionality requires selection
						marks = Editor.marks(editor)
					}
					// update (remove then insert)
					for (let [, childPath] of Node.children(editor, taggingEditorPath, { reverse: true })) {
						// remove each node
						Transforms.removeNodes(editor, { at: childPath })
					}
					Transforms.insertNodes(editor, currentData, { at: [...taggingEditorPath, 0], select: true })

					if (shouldApplyMarks) {
						// re-add marks after update
						setSelectionToWholeElementAtPath(editor, taggingEditorPath) // marks functionality requires selection
						for (let key in marks) {
							Editor.addMark(editor, key, marks[key])
						}
					}
				})
			})
		} catch {
			console.log("failed to set text")
		}
	}
}

export const addTagBlock = (editor: CustomEditor, tagging, nodeType: Nodetype, elementToRemove?: CustomElement) => {
	const removeFunc =
		elementToRemove == null
			? () => {}
			: () => Transforms.removeNodes(editor, { match: (n) => n === elementToRemove, at: [] })
	const newNodes = initialTagBlockNodes(nodeType, tagging, editor.taggingData)
	Editor.withoutNormalizing(editor, () => {
		removeFunc()
		Transforms.insertNodes(editor, newNodes, { select: true })
	})
	const after = Editor.after(editor, editor.selection.focus)
	Transforms.setSelection(editor, { anchor: after, focus: after })
}

export const initialTagBlockNodes = (
	nodeType: Nodetype,
	tagging: string,
	taggingData: TaggingDataset
): Descendant[] => {
	const type = "tagging"
	const displayName = displayTagName(tagging, taggingData)
	return nodeType === "inline"
		? [{ type, tagging, nodeType, children: [{ text: displayName }] }, { text: "" }]
		: [{ type, tagging, nodeType, children: [{ type: "paragraph", children: [{ text: displayName }] }] }]
}

export const toggleTaggingBlock = (editor, format) => {
	const [type, tagging] = format.split("@")
	if (type !== "tagging") {
		return null
	}
	if (
		hasAncestorOfType(editor, editor.selection.anchor, "tagging") ||
		hasAncestorOfType(editor, editor.selection.focus, "tagging")
	) {
		return true // cancel if adding tag within tag
	}
	const nodeType = editor.taggingData[tagging].type ?? "inline"
	addTagBlock(editor, tagging, nodeType)
	return true
}

export function selectPotentialTag(cmd, event, taggingContext) {
	const { potentialTag, selection, setSelection } = taggingContext
	if (potentialTag.current.max < 0) return // no potential tags so prevent selection key presses
	switch (cmd) {
		case "ArrowUp":
			if (potentialTag.current.available) {
				event.preventDefault()
				setSelection(Math.max(0, selection - 1))
			}
			return
		case "tab":
		case "Enter":
			if (potentialTag.current.available) {
				event.preventDefault()
				potentialTag.current.setFormat?.()
				return true // returning true will block further nav code from running
			}
			return
		case "ArrowDown":
			if (potentialTag.current.available) {
				event.preventDefault()
				setSelection(Math.min(potentialTag.current.max, selection + 1))
			}
			return
		default:
			return
	}
}

export function deleteIfTemplateTagSelected(editor: CustomEditor): boolean {
	const anchor = Editor.node(editor, editor.selection.anchor)
	if (anchor != null) {
		const [, aPath] = anchor
		const aLevels = Editor.levels(editor, { at: aPath, reverse: true })

		for (var [aLevel, aLevelPath] of aLevels) {
			if (Element.isElement(aLevel) && aLevel.type === "tagging") {
				console.log("deleting tagging")
				Transforms.removeNodes(editor, { at: aLevelPath })
				return true
			}
		}
	}
	return false
}

export function fixMovingThroughTagInlines(editor, cmd, event) {
	if (Range.isCollapsed(editor.selection) && isAtEndOfTypes(editor, ["tagging"]) && cmd === "ArrowRight") {
		// moving rightwards out of tag, prevents issues where can't move into last node if empty text
		const nextPoint = Editor.after(editor, editor.selection.focus)
		if (nextPoint == null) return
		Transforms.select(editor, { anchor: nextPoint, focus: nextPoint })
		event.preventDefault()
		return
		// }
	}
	if (Range.isCollapsed(editor.selection) && isAtStartOfTypes(editor, ["tagging"]) && cmd === "ArrowLeft") {
		// moving leftwards out of tag, prevents issues with jumping to start of text
		const prevPoint = Editor.before(editor, editor.selection.anchor)
		if (prevPoint == null) return
		Transforms.select(editor, { anchor: prevPoint, focus: prevPoint })
		event.preventDefault()
		return
	}
	let { anchor, focus } = editor.selection
	if (editor.parameters.templateMode) {
		// all following is for template mode only
		if (Range.isCollapsed(editor.selection)) {
			// enforce select all when moving through tag in template
			if (cmd === "ArrowLeft") {
				const prevAnchor = Editor.before(editor, anchor)
				if (prevAnchor == null) return
				const newAnchor = preventPointWithinType(editor, prevAnchor, "tagging", { moveToStart: true })
				if (newAnchor !== anchor) {
					Transforms.select(editor, { anchor: newAnchor, focus: prevAnchor })
					event.preventDefault()
					return
				}
			}
			if (cmd === "ArrowRight") {
				const nextFocus = Editor.after(editor, focus)
				if (nextFocus == null) return
				const newFocus = preventPointWithinType(editor, nextFocus, "tagging", { moveToStart: false })
				if (newFocus !== nextFocus) {
					Transforms.select(editor, { anchor: nextFocus, focus: newFocus })
					event.preventDefault()
					return
				}
			}
		}
		if (Range.isExpanded(editor.selection) && hasAncestorOfType(editor, anchor, "tagging")) {
			// expanded selection within a tag, enforce leave tag on left/right
			if (cmd === "ArrowRight") {
				const nextPoint = Editor.after(editor, editor.selection.focus)
				if (nextPoint == null) return
				event.preventDefault()
				Transforms.select(editor, { anchor: nextPoint, focus: nextPoint })
			}
			if (cmd === "ArrowLeft") {
				const prevPoint = Editor.before(editor, editor.selection.anchor)
				if (prevPoint == null) return
				event.preventDefault()
				Transforms.select(editor, { anchor: prevPoint, focus: prevPoint })
			}
		}
	} else if (Range.isCollapsed(editor.selection)) {
		// outside of template with collapsed range only
		// prevent double movement
		if (cmd === "ArrowLeft" && !hasAncestorOfType(editor, focus, "tagging")) {
			const prevPoint = Editor.before(editor, editor.selection.focus)
			if (prevPoint == null || !hasAncestorOfType(editor, prevPoint.path, "tagging")) return
			console.log("prevent double move")
			event.preventDefault()
			Transforms.select(editor, { anchor: prevPoint, focus: prevPoint })
		}
		if (cmd === "ArrowRight" && !hasAncestorOfType(editor, focus, "tagging")) {
			const nextPoint = Editor.after(editor, editor.selection.focus)
			if (nextPoint == null || !hasAncestorOfType(editor, nextPoint.path, "tagging")) return
			console.log("prevent double move")
			event.preventDefault()
			Transforms.select(editor, { anchor: nextPoint, focus: nextPoint })
		}
	}
}

export function moveForwardsOutOfTag(editor) {
	const [tag] = Editor.nodes(editor, { match: (n) => Element.isElement(n) && n.type === "tagging" })
	const nextPath = [...tag[1]]
	nextPath[nextPath.length - 1] += 1
	if (
		isAtEnd(editor) ||
		(nextPath !== Editor.after(editor, tag[1])?.path && (tag[0] as TaggingElementType)?.nodeType === "block")
	) {
		//at end of text, or end of set of block nodes, need to insert
		Transforms.insertNodes(editor, [{ type: "paragraph", children: [{ text: "" }] }], { select: true, at: nextPath })
		return
	}
	const [nextNode] = Editor.nodes(editor, { at: nextPath, match: (n) => Element.isElement(n) && n.type === "tagging" })
	if (nextNode != null) {
		//moved forward into another tag, create empty paragraph in between
		Transforms.insertNodes(editor, [{ type: "paragraph", children: [{ text: "" }] }], { at: nextPath, select: true })
	} else {
		const nextPosition = Editor.start(editor, nextPath)
		Transforms.select(editor, { anchor: nextPosition, focus: nextPosition })
	}
}

export function stripTagFromFragment(fragment, type = null) {
	return fragment.reduce((newFragment, child) => {
		if (child.type === "tagging" && (type == null || child.tagging === type)) {
			newFragment = [...newFragment, ...stripTagFromFragment(child.children)]
		} else if (child.children != null) {
			newFragment = [...newFragment, { ...child, children: [...stripTagFromFragment(child.children)] }]
		} else {
			newFragment = [...newFragment, child]
		}

		return newFragment
	}, [])
}

export function tagsInFragment(fragment) {
	return fragment.reduce((tags, child) => {
		if (child.type === "tagging") {
			tags.push(child.tagging)
		} else if (child.children != null) {
			tags = [...tags, ...tagsInFragment(child.children)]
		}
		return tags
	}, [])
}

export function preventPointWithinType(
	editor: CustomEditor,
	point: BasePoint,
	type: ElementType,
	{ moveToStart = true }
) {
	if (point != null) {
		const [pointTagAncestorEntry] = ancestorsOfType(editor, point?.path, type)
		if (pointTagAncestorEntry != null) {
			// partial tag selected by anchor, expand selection
			const [, pointTagPath] = pointTagAncestorEntry
			const [firstLeaf] = Editor.nodes(editor, {
				at: pointTagPath,
				match: (n) => Text.isText(n),
				reverse: !moveToStart,
			})
			if (firstLeaf != null) {
				const [, leafPath] = firstLeaf
				point = moveToStart ? Editor.start(editor, leafPath) : Editor.end(editor, leafPath)
			}
		}
	}
	return point
}

export function makeTaggingData(
	options: Partial<TaggingOptions>,
	hasQueuedChanges: React.MutableRefObject<boolean>,
	queuedChanges: React.MutableRefObject<object>,
	customTaggingDatasets?: { customTags: { data: TaggingDataset } },
	permissions?: { [feature: string]: boolean }
): ExtendedTaggingDataset {
	var taggingData: ExtendedTaggingDataset = {}
	for (let [option, { dataSource, arrayIndex }] of Object.entries(options)) {
		if (
			taggingDatasets[option]?.condition != null &&
			(!taggingDatasets[option]?.condition(permissions) || permissions == null)
		) {
			continue
		}
		const extendedTaggingDataSet = { ...taggingDatasets }
		if (extendedTaggingDataSet.appAll != null) {
			extendedTaggingDataSet.appAll = {
				data: { ...extendedTaggingDataSet.appAll?.data, ...customTaggingDatasets?.customTags?.data },
			}
		}
		if (extendedTaggingDataSet.appInline != null) {
			extendedTaggingDataSet.appInline = {
				data: { ...extendedTaggingDataSet.appInline?.data, ...customTaggingDatasets?.customTags?.data },
			}
		}
		const baseOptionData = extendedTaggingDataSet[option]?.data as TaggingDataset
		if (baseOptionData == null) {
			console.log(`Dataset ${option} does not exist`)
			continue
		}
		var optionData: ExtendedTaggingDataset = {}
		const basePath = extendedTaggingDataSet[option]?.isArray
			? [...extendedTaggingDataSet[option]?.arrayPath, arrayIndex]
			: extendedTaggingDataSet[option]?.isEmbeddedArray
				? [...extendedTaggingDataSet[option]?.arrayPath]
				: []
		for (let [tag, tagData] of Object.entries(baseOptionData)) {
			if (tagData?.condition != null && (!tagData?.condition(permissions) || permissions == null)) {
				continue
			}
			const tagPath = (index?: number) =>
				tagData.subType === "generated"
					? null
					: extendedTaggingDataSet[option]?.isEmbeddedArray
						? [...basePath, index, ...tagData.path]
						: [...basePath, ...tagData.path]
			const tagCompletePath = (index?: number) =>
				tagData.subType === "ai"
					? extendedTaggingDataSet[option]?.isEmbeddedArray
						? [...basePath, index, ...tagData.completePath]
						: [...basePath, ...tagData.completePath]
					: []
			const preprocessing: (value: string) => string =
				tagData.preprocessing != null ? tagData.preprocessing : (value: string) => value
			const getData = (includeChanges = false, index?: number) => {
				if (tagData.subType === "generated") {
					return tagData.getData()
				}
				return preprocessing(
					getValueAtPath(
						includeChanges ? { ...dataSource, ...queuedChanges.current[option] } : dataSource,
						tagPath(index)
					) ?? ""
				)
			}
			optionData[tag] = {
				...tagData,
				queueDataChange: (newValue: string, index?: number) => {
					if (tagData.editable ?? true) {
						let change = makeChange(newValue, tagPath(index), { ...dataSource, ...queuedChanges.current[option] })
						if (tagData.subType === "ai" && getValueAtPath(change, tagCompletePath(index)) === false) {
							// Check for false avoids setting complete on empty object if change is empty
							// which happens if change triggered twice
							// also avoids unneccessarily setting complete to true if it already was
							setValueAtPath(change, tagCompletePath(index), true)
						}
						for (var key in change) {
							// set all nested changed values in dataSource to avoid stale data
							// (wasn't set before to make sure new-old comparison correct)
							console.log("queueing change for", key)
							hasQueuedChanges.current = true
							if (queuedChanges.current[option] == null) queuedChanges.current[option] = {}
							queuedChanges.current[option][key] = change[key]
						}
					}
				},
				getData,
			}
			if (tagData.subType === "ai") {
				const completePath = tagData.completePath
				optionData[tag].getComplete = () => getValueAtPath(dataSource, completePath)
			}
		}
		taggingData = { ...taggingData, ...optionData }
	}
	return taggingData
}

export const getDefaultTagProps = (element: TaggingElementType, clientTagOptions: Client["options"]["customTags"]) => {
	const defaultTagProps = getDefaultTagPropsForLanguage(element, clientTagOptions)
	return defaultTagProps
}

const getDefaultTagPropsForLanguage = (
	element: TaggingElementType,
	clientTagOptions: Client["options"]["customTags"]
) => {
	const tagOptions = clientTagOptions?.tags?.[element.tagging]?.promptOptions as PromptOptions
	const language = tagOptions?.language ?? clientTagOptions?.defaultLanguage ?? "English (American)"
	return DEFAULT_PROPS_FOR_LANGUAGE[language] ?? {}
}
