import { TaggingElementType } from "../../types/slate.types"
import { ClickableDropdown, Option } from "../../../ClickableDropdown/ClickableDropdown"
import { useSlate, ReactEditor } from "slate-react"
import { Transforms } from "slate"

const clickOption = (editor, element, key) => (e) => {
	e.preventDefault()
	e.stopPropagation()
	Transforms.setNodes(editor, { processing: key }, { match: (n) => n === element })
	ReactEditor.focus(editor) // prevents forced click out after selection
}

export function ProcessingDropdown({ element }: { element: TaggingElementType }) {
	const editor = useSlate()
	const tagInfo = editor.taggingData[element.tagging]

	let currentOption = {
		text: "",
		key: tagInfo.processingSets.default.label,
		onClick: clickOption(editor, element, undefined),
	}

	const processingOptions: Option[] = Object.entries(tagInfo.processingSets)
		.filter(([key]) => key !== "default")
		.map(([key, { longLabel, label }]) => {
			const option = {
				text: longLabel,
				key,
				onClick: clickOption(editor, element, key),
			}
			if (key === element.processing) {
				currentOption = {
					text: "",
					key,
					onClick: clickOption(editor, element, key),
				}
			}
			return option
		})
	return (
		<ClickableDropdown
			className="processing-dropdown"
			label=""
			options={processingOptions}
			selectedOption={currentOption}
			hideDelay={150}
			portal={document.body}
			defaultOptionName=""></ClickableDropdown>
	)
}
