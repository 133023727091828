import { SlateLeafRenderProps } from "../types/slate.types"
import { DEFAULT_FONT } from "libs"

export const setFontLeafProps = ({
	attributes,
	children,
	leaf,
	availableFonts,
	...other
}: SlateLeafRenderProps): SlateLeafRenderProps => {
	if (leaf.font != null) {
		const fontInfo = availableFonts[leaf.font] ?? availableFonts[DEFAULT_FONT]
		children = (
			<span
				className={`bold${fontInfo.bold} normal${fontInfo.normal} in-text-formatter-font`}
				style={{ fontFamily: fontInfo.fontFamily }}>
				{children}
			</span>
		)
	}

	return { attributes, children, leaf, availableFonts, ...other }
}
