import { Divider } from "../../Divider/Divider"
import {
	ensureSelection,
	hasAncestorOfTypes,
	isBlockActive,
	setWholeTextSelection,
	useEditorContext,
} from "../../TextFormatting/Utils"
import { CustomEditor, CustomElement } from "../../TextFormatting/types/slate.types"
import { Element, Transforms, Range, Text, Editor, Node, Path } from "slate"
import { ReactEditor, RenderElementProps, useSlate } from "slate-react"

export const renderPageBreakElement = (props: RenderElementProps) => {
	if (props.element.type === "pageBreak") {
		return <PageBreakElement {...props} />
	} else {
		return null
	}
}

export const selectPageBreakElement = (editor: CustomEditor, element: CustomElement) => {
	if (element.type !== "pageBreak") {
		return
	}
	if (
		editor.children.length === 1 &&
		Element.isElement(editor.children[0]) &&
		editor.children[0].type === "pageBreak"
	) {
		// if a divider is the only thing in the editor it mus tbe the correct one just select all as this is less likely to fail
		setWholeTextSelection(editor)
		return
	}
	const thisPath = ReactEditor.findPath(editor, element.children[0])
	const selection = {
		anchor: Editor.start(editor, thisPath),
		focus: Editor.end(editor, thisPath),
	}
	Transforms.setSelection(editor, selection)
}

const PageBreakElement = (props: RenderElementProps) => {
	const editor = useSlate()
	const { editing } = useEditorContext()

	const [entry] = Editor.nodes(editor, { match: (n) => n === props.element.children[0] })
	const inSelection =
		editing &&
		editor.selection != null &&
		Range.isExpanded(editor.selection) &&
		entry != null &&
		Range.includes(editor.selection, Editor.start(editor, entry[1])) &&
		Range.includes(editor.selection, Editor.end(editor, entry[1]))

	return (
		<div
			className="page-break"
			style={{ backgroundColor: inSelection ? "#b4d5fe" : "transparent" }}
			contentEditable={false}
			onClick={() => {
				ensureSelection(editor)
				selectPageBreakElement(editor, props.element)
			}}
			{...props.attributes}>
			<Divider text="PAGE BREAK" className="page-break-divider" color="#A5A5A5" margin="0" />
			{props.children}
		</div>
	)
}

const insertPageBreakMatchFunction = (editor: CustomEditor) => (n: Node, p: Path) => {
	if (!Element.isElement(n) || (n.type !== "paragraph" && !editor.parentType?.includes(n.type))) {
		return false
	}
	const parentEntry = Editor.parent(editor, p)
	const [parent] = parentEntry
	return (
		Editor.isEditor(parent) || !hasAncestorOfTypes(editor, p, editor.nestedFormatters) //allow except in tables and columns
	)
}

export const canInsertPageBreak = (editor) => {
	if (editor.selection == null) {
		return false
	}
	const [insertEntry] = Editor.nodes(editor, {
		at: Range.end(editor.selection),
		match: insertPageBreakMatchFunction(editor),
	})
	return insertEntry != null
}

export const insertPageBreak = (editor: CustomEditor) => {
	if (!canInsertPageBreak(editor)) {
		return true
	}
	console.log("adding page break")
	Editor.withoutNormalizing(editor, () => {
		// without normalising for case where the empty paragraph is the only in the editor to prevent it being replaced by the normalisation that prevetns an empty editor
		// deleteIfInEmptyPara(editor)
		Transforms.collapse(editor, { edge: "end" })
		Transforms.insertNodes(
			editor,
			{ type: "pageBreak", children: [{ text: "\u200B" }] },
			{
				match: insertPageBreakMatchFunction(editor),
				select: true,
			}
		)
		const isActive = isBlockActive(editor, "pageBreak")
		if (!isActive) {
			// if the divider was not inserted then we return here to avoid accidentally cleaning up incorrectly
			return
		}
		// select divider after adding it
		Transforms.setSelection(editor, {
			anchor: Editor.before(editor, editor.selection.anchor) ?? editor.selection.anchor,
			focus: Editor.after(editor, editor.selection.focus) ?? editor.selection.focus,
		})
		// remove empty paragraphs before the divider if they exist (divider is always put on an empty line below, so this make sit look like it was put on the empty line if we started on one)
		const beforeDivider = Editor.previous(editor, { at: editor.selection })
		if (beforeDivider == null) {
			return
		}
		Transforms.removeNodes(editor, {
			at: beforeDivider[1],
			match: (n) =>
				Element.isElement(n) &&
				n.type === "paragraph" &&
				n.children.length <= 1 &&
				Text.isText(n.children[0]) &&
				n.children[0].text === "",
		})
		return true
	})
}

export const togglePageBreak = (editor: CustomEditor, format: string) => {
	if (format !== "pageBreak") {
		return null
	}

	const isActive = isBlockActive(editor, format)
	if (isActive) {
		console.log("removing page break")
		Transforms.removeNodes(editor, { match: (n) => Element.isElement(n) && n.type === "pageBreak" })
	} else {
		return insertPageBreak(editor)
	}
	return true
}
