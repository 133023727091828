import { useSlate } from "slate-react"
import { ButtonType, CustomBlockOptionSet } from "../types/slate.types"

import { isBlockActive } from "../Utils"
import { ReactNode } from "react"

import { canInsertColumns, columnsAvailable } from "./ColumnsUtils"
import { COLUMN_OPTIONS, ColumnsDropdownItems } from "../../ColumnsDropdown/ColumnsDropdown"

export const columnsOptions: { columns: CustomBlockOptionSet } = {
	columns: {
		name: "columns",
		format: ["twoColumns", "threeColumns"],
		button: ButtonType.customBlock,
		available: (editor) => columnsAvailable(editor),
		makeIcon: ({ activeFormat }) => (
			<ColumnsIcon>
				{activeFormat ? COLUMN_OPTIONS[activeFormat]?.icon() : Object.values(COLUMN_OPTIONS)[0].icon()}
			</ColumnsIcon>
		),
		makeCustomDropdown: ({ currentValue }) => <ColumnsDropdownItems currentValue={currentValue} />,
	},
}

const ColumnsIcon = ({ children }: { children: ReactNode }) => {
	const editor = useSlate()

	return (
		<div
			aria-label="page-break-button"
			className={`page-break-button ${!columnsAvailable(editor) ? "toolbar-button-unavailable unclickable" : ""}`}>
			{children}
		</div>
	)
}
