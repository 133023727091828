import "./InputLikeGridWithLabel.css"

interface InputLikeGridWithLabelProps {
	label: string | React.ReactNode
	children: React.ReactNode
	labelFor: string
}
export function InputLikeGridWithLabel({ label, children, labelFor }: InputLikeGridWithLabelProps) {
	return (
		<div className="input-like-grid-with-label">
			<label htmlFor={labelFor}>{label}</label>
			{children}
		</div>
	)
}
