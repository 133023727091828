import {
	getShortDateFromDDMMYYYY,
	getLongDateFromDDMMYYYY,
	getShortSlashedDateFromDDMMYYYY,
	getYearFromDDMMYYYY,
	parseDDMMYYYYFromDate,
	getShortfullDateFromDDMMYYYY,
	getLongFullDateFromDDMMYYYY,
	getShortFullSlashedDateFromDDMMYYYY,
	getShortAmericanFullSlashedDateFromDDMMYYYY,
	getShortZeroSlashedDateFromDDMMYYYY,
} from "libs"
import { DateSingleTaggingDataset, ProcessingSets } from "../index.types"

/**
 * return data at key in object if key defined, else return object
 * @param key
 * @param obj
 * @returns
 */
function getByKeyIfDefined(key, obj) {
	if (key != null) {
		return obj?.[key]
	} else {
		return obj
	}
}

function makeDatePreprocessing(process: (data: object, ...args) => string) {
	return (taggingData: DateSingleTaggingDataset) => (obj) =>
		taggingData.shouldUseIsCurrent && obj?.isCurrent
			? obj?.currentText ?? "Present"
			: process(
					getByKeyIfDefined(taggingData.dataKeys?.[0], obj),
					...(taggingData?.dataKeys?.slice?.(1)?.map((key) => obj?.[key]) ?? [])
				)
}

function makeDatePostprocessing(process: (newValue: any) => any[]) {
	return (taggingData: DateSingleTaggingDataset) => (newValue, oldObj) =>
		taggingData.shouldUseIsCurrent && oldObj?.isCurrent
			? { ...oldObj, currentText: newValue }
			: { ...oldObj, ...keysAndValuesToObj(taggingData.dataKeys, process(newValue)) }
}

function keysAndValuesToObj(keys: string[], values: string[]) {
	return keys.reduce((acc, key, i) => ({ ...acc, [key]: values[i] }), {})
}

const shortDate = {
	label: "Mth YYYY",
	longLabel: "Mth YYYY (eg Aug 2021)",
	preprocessing: makeDatePreprocessing(getShortDateFromDDMMYYYY),
	postprocessing: makeDatePostprocessing(parseDDMMYYYYFromDate),
}

const longDate = {
	label: "Month YYYY",
	longLabel: "Month YYYY (eg August 2021)",
	preprocessing: makeDatePreprocessing(getLongDateFromDDMMYYYY),
	postprocessing: makeDatePostprocessing(parseDDMMYYYYFromDate),
}

const shortSlashedDate = {
	label: "M/YYYY",
	longLabel: "M/YYYY (eg 8/2021)",
	preprocessing: makeDatePreprocessing(getShortSlashedDateFromDDMMYYYY),
	postprocessing: makeDatePostprocessing(parseDDMMYYYYFromDate),
}

const shortZeroSlashedDate = {
	label: "MM/YYYY",
	longLabel: "MM/YYYY (eg 08/2021)",
	preprocessing: makeDatePreprocessing(getShortZeroSlashedDateFromDDMMYYYY),
	postprocessing: makeDatePostprocessing(parseDDMMYYYYFromDate),
}

const yearOnly = {
	label: "YYYY",
	longLabel: "YYYY (eg 2021)",
	preprocessing: makeDatePreprocessing(getYearFromDDMMYYYY),
	postprocessing: makeDatePostprocessing(parseDDMMYYYYFromDate),
}

export const DATE_PROCESSING_SETS: ProcessingSets = {
	[shortDate.label]: shortDate,
	[shortSlashedDate.label]: shortSlashedDate,
	[shortZeroSlashedDate.label]: shortZeroSlashedDate,
	[longDate.label]: longDate,
	[yearOnly.label]: yearOnly,
	default: shortDate,
}

const shortFullDate = {
	label: "DD Mth YYYY",
	longLabel: "DD Mth YYYY (eg 3 Aug 2021)",
	preprocessing: makeDatePreprocessing(getShortfullDateFromDDMMYYYY),
	postprocessing: makeDatePostprocessing(parseDDMMYYYYFromDate),
}

const longFullDate = {
	label: "DD Month YYYY",
	longLabel: "DD Month YYYY (eg 3 August 2021)",
	preprocessing: makeDatePreprocessing(getLongFullDateFromDDMMYYYY),
	postprocessing: makeDatePostprocessing(parseDDMMYYYYFromDate),
}

const shortFullSlashedDate = {
	label: "DD/MM/YYYY",
	longLabel: "DD/MM/YYYY (eg 03/08/2021)",
	preprocessing: makeDatePreprocessing(getShortFullSlashedDateFromDDMMYYYY),
	postprocessing: makeDatePostprocessing(parseDDMMYYYYFromDate),
}

const shortAmericanFullSlashedDate = {
	label: "MM/DD/YYYY",
	longLabel: "MM/DD/YYYY (eg 08/03/2021)",
	preprocessing: makeDatePreprocessing(getShortAmericanFullSlashedDateFromDDMMYYYY),
	postprocessing: makeDatePostprocessing(parseDDMMYYYYFromDate),
}

export const FULL_DATE_PROCESSING_SETS: ProcessingSets = {
	[shortFullDate.label]: shortFullDate,
	[shortFullSlashedDate.label]: shortFullSlashedDate,
	[shortAmericanFullSlashedDate.label]: shortAmericanFullSlashedDate,
	[longFullDate.label]: longFullDate,
	default: shortFullDate,
}
