import { SingleTimelineData } from "libs"
import { initialValueString } from "../../../../TextFormatting/Utils"
import { makeDateTagSection } from "../Utils/makeTLTagSection"

export const DEFAULT_TIMELINE_DATA = (preset: `preset-style-${number}` = "preset-style-1"): SingleTimelineData => {
	const coreDefaultSettings: Pick<
		SingleTimelineData,
		"type" | "order" | "experienceHeading" | "educationHeading" | "preset"
	> = {
		type: "both",
		order: "Chronological",
		experienceHeading: initialValueString(),
		educationHeading: initialValueString(),
		preset,
	}
	switch (preset) {
		case "preset-style-0":
			//blank preset
			return {
				...coreDefaultSettings,
				experiences: {
					extra: "",
					name: "",
					place: "",
					text: "",
					date: "",
				},
				qualifications: {
					extra: "",
					name: "",
					place: "",
					text: "",
					date: "",
				},
			}
		case "preset-style-5":
			return {
				...coreDefaultSettings,
				experiences: {
					extra: "",
					name: '[{"type":"paragraph","children":[{"text":""},{"type":"tagging","tagging":"jobTitle","nodeType":"inline","children":[{"text":"@Job Title", "bold":true}]},{"text":", "},{"type":"tagging","tagging":"company","nodeType":"inline","children":[{"text":"@Company"}]},{"text":", "},{"type":"tagging","tagging":"startDate","nodeType":"inline","children":[{"text":"@Start Date"}]},{"text":" - "},{"type":"tagging","tagging":"endDate","nodeType":"inline","children":[{"text":"@End Date"}]},{"text":""}]}]',
					place: "",
					text: '[{"type":"tagging","nodeType":"block","tagging":"comments","children":[{"type":"paragraph","children":[{"text":"@Comments"}]}]}]',
					date: "",
				},
				qualifications: {
					extra: "",
					name: '[{"type":"paragraph","children":[{"text":""},{"type":"tagging","tagging":"qualificationName","nodeType":"inline","children":[{"text":"@Qualification", "bold":true}]},{"text":", "},{"type":"tagging","tagging":"institution","nodeType":"inline","children":[{"text":"@Institution"}]},{"text":", "},{"type":"tagging","tagging":"endDate","nodeType":"inline","children":[{"text":"@End Date"}]},{"text":""}]}]',
					place: "",
					text: '[{"type":"tagging","nodeType":"block","tagging":"comments","children":[{"type":"paragraph","children":[{"text":"@Comments"}]}]}]',
					date: "",
				},
			}
		default:
			return {
				...coreDefaultSettings,
				experiences: {
					extra: `[{"type":"paragraph","children":[${
						preset === "preset-style-3" ? '{"text": "- ", "bold": true}, ' : ""
					}{"type":"tagging","nodeType":"inline","tagging":"location","children":[{"text":"@Location","bold":true}]},{"text":""}]}]`,
					name: '[{"type":"paragraph","children":[{"text":""},{"type":"tagging","nodeType":"inline","tagging":"jobTitle","children":[{"text":"@Job Title","bold":true}]},{"text":""}]}]',
					place:
						'[{"type":"paragraph","children":[{"type":"tagging","nodeType":"inline","tagging":"company","children":[{"text":"@Company","bold":true}]},{"text":""}]}]',
					text: '[{"type":"tagging","nodeType":"block","tagging":"comments","children":[{"type":"paragraph","children":[{"text":"@Comments"}]}]}]',
					date: makeDateTagSection(preset),
				},
				qualifications: {
					extra: `[{"type": "paragraph", "children": [${
						preset === "preset-style-3" ? '{"text": "- ", "bold": true}, ' : ""
					}{"type": "tagging", "nodeType": "inline", "tagging": "${
						preset === "preset-style-3" ? "location" : "classification"
					}", "children": [{"text": "${
						preset === "preset-style-3" ? "@Location" : "@Classification"
					}", "bold": true}]} ]}]`,
					name: '[{"type": "paragraph", "children": [{"type": "tagging", "nodeType": "inline", "tagging": "qualificationName", "children": [{"text": "@Qualification", "bold": true}]}]}]',
					place:
						'[{"type": "paragraph", "children": [{"type": "tagging", "nodeType": "inline", "tagging": "institution", "children": [{"text": "@Institution", "bold": true}]} ]}]',
					text: '[{"type": "tagging", "nodeType": "block", "tagging": "comments", "children": [{"text": "@Comments"}]}]',
					date: makeDateTagSection(preset),
				},
			}
	}
}
