import { getTextTransform, SingleTagData, useAppContext, useEntities } from "libs"
import { MouseEventHandler, useMemo, useState } from "react"
import { Editor, Transforms, Range, Node, Element } from "slate"
import { initialValue, getSelectedText, wholeTextIsSelected } from "../TextFormatting/Utils"
import { useMountEffect } from "libs"
import { AIPortalShowProps, CustomEditor, Marks } from "../TextFormatting/types/slate.types"
import { StarIcon, LoadingIcon, AIAssistIcon } from "../Icons"
import { wordCounter, COPY_AVAILABLE } from "libs"
import { useOriginalCVContext } from "../CVViewing/CVContexts/OriginalCVContext"
import { CopiedFlash } from "../CopiedFlash/CopiedFlash"
import tryAgainIcon from "components/images/try-again.jpg"
import { WarningMessages } from "../WarningMessages/WarningMessages"
import { SimpleArrowNavigator } from "../SimpleArrowNavigator/SimpleArrowNavigator"
import { useSlate } from "slate-react"
import { EditFormattedText } from "../EditBox/Components/EditFormattedText"
import { AIDisplayButton } from "./AIDisplayButton"

const INCOMPLETE_TEXT_MAP = {
	Tokens: "Long",
	Timeout: "Slow",
	Incomplete: "Incomplete",
}

function getTextFromResponse(response: SingleTagData) {
	const lastCharIsSpace = response?.text?.[response.text?.length - 1] === " "
	return response?.text?.trim() + (lastCharIsSpace ? " " : "") // remove trailing generic whitespace but include trailing spaces
}

export function AIDisplay({
	setShowAiPortal,
	editor,
	showAiPortal,
	setWords,
}: {
	setShowAiPortal: (arg0: AIPortalShowProps | null) => void
	editor: CustomEditor
	showAiPortal: AIPortalShowProps | null
	setWords: (arg0: number) => void
}) {
	const { appId } = useOriginalCVContext()
	const currentValue = getSelectedText(editor)
	const [loading, setLoading] = useState<boolean>(false)
	const [error, setError] = useState<string>(null)
	const [responses, setResponses] = useState<SingleTagData[]>(
		showAiPortal.update ? [{ text: currentValue, complete: true }] : []
	)
	const [currentResponseIndex, setCurrentResponseIndex] = useState<number>(0)
	const responseAi = useMemo(() => responses[currentResponseIndex], [currentResponseIndex, responses])
	const [incompleteResponse, setIncompleteResponse] = useState<null | string>(showAiPortal.update ? "Incomplete" : null)

	useMountEffect(() => {
		if (showAiPortal != null) {
			console.log("calling text transform api")
			getTransformTextFromApi(false, showAiPortal.update)
		}
		return () => {
			setLoading(false)
			setError(null)
			setResponses([])
			setCurrentResponseIndex(0)
		}
	})

	const getTransformTextFromApi = async (noCache = false, previousText = false) => {
		setLoading(true)
		let data: {
			transformType: string
			text: string
			appId: string
			previousText?: string
			index?: number
		} = {
			transformType: showAiPortal.type,
			text: currentValue,
			appId: appId,
			index: showAiPortal.index,
		}
		const previousTextToSend = previousText ? getTextFromResponse(responseAi) : undefined

		const response = (await getTextTransform(data, setError, noCache, previousTextToSend)) as SingleTagData
		setLoading(false)
		if (response == null || response.text === "") {
			setError(
				"❌ Oops! We ran into an issue. Please try again. If the problem persists, please let us know via live chat."
			)
			return
		}
		setWords(wordCounter(response.text))
		setCurrentResponseIndex(responses.length)
		setResponses(() => [...responses, response])
		setIncompleteResponse(response?.complete ? null : response?.reason)
	}

	const handleTryAgain = () => {
		setError(null)
		getTransformTextFromApi(true)
	}

	return (
		<div className="text-transform-container">
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					margin: "0 15px 0 10px",
				}}>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "start",
					}}>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "center",
							marginTop: "10px",
						}}>
						<StarIcon />
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "center",
							marginTop: "10px",
						}}>
						<AIAssistIcon fontSize={14} />
						{loading && <LoadingIcon />}
					</div>
				</div>
				<div className="clickable" style={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
					{responseAi != null && (
						<>
							<div style={{ color: "var(--ara-blue)", fontSize: 14 }} onClick={handleTryAgain}>
								Try Again
							</div>
							<img style={{ height: 10, margin: "0 3px" }} src={tryAgainIcon} alt="try again"></img>
						</>
					)}
				</div>
			</div>
			<div style={{ display: "flex", justifyContent: "space-between", marginRight: 10 }}>
				{responseAi && <WarningMessages style={{ marginLeft: 25 }}></WarningMessages>}
				<SimpleArrowNavigator
					currentIndex={currentResponseIndex}
					setCurrentIndex={setCurrentResponseIndex}
					currentInputs={responses}
				/>
			</div>
			<div className="text-transform-text-container">
				{responseAi && (
					<div style={{ color: incompleteResponse ? "#aaaaaa" : "black" }}>
						<EditFormattedText
							text={getTextFromResponse(responseAi) ?? ""}
							onChange={() => {}}
							sectionTitle={
								<span style={{ color: "#777777", fontFamily: "var(--ara-font-family, Roboto)" }}>Add Prompt</span>
							}
							editable={false}
							options={["marks", "bullets:bullet", "markdown"]}
							hideToolbar={false}
							editStyle={{ "--format-area-padding": "8px" }}
							// narrowEditingWindow={true}
							// style={{padding: '0px 10px'}}
						/>
					</div>
				)}
				{error && <div style={{ marginTop: 10, fontSize: 13, fontWeight: 400, marginLeft: 2 }}>{error}</div>}
			</div>
			{!loading && incompleteResponse && (
				<IncompleteWarning
					incompleteResponse={incompleteResponse}
					setError={setError}
					getTransformTextFromApi={getTransformTextFromApi}
				/>
			)}
			{responseAi && (
				<AdjustmentButtonRow
					{...{
						text: currentValue,
						setLoading,
						loading,
						responseAi,
						appId,
						showAiPortal,
						setResponses,
						responses,
						setCurrentResponseIndex,
					}}
				/>
			)}
			<FinalButtonRow {...{ responseAi, error, loading, handleTryAgain, setShowAiPortal, showAiPortal }} />
		</div>
	)
}

function IncompleteWarning({
	incompleteResponse,
	setError,
	getTransformTextFromApi,
}: {
	incompleteResponse: string
	setError: (error: string) => void
	getTransformTextFromApi: (noCache: boolean, previousText: boolean) => void
}) {
	return (
		<div
			style={{
				margin: "10px 12px",
				width: "fit-content",
				fontSize: 13,
			}}>
			<div style={{ fontWeight: 500 }}>
				⏰ {INCOMPLETE_TEXT_MAP[incompleteResponse] ?? "Incomplete"} response detected
			</div>
			<div style={{ fontWeight: 400, marginLeft: 2 }}>
				Use the text above or{" "}
				<span
					style={{ fontWeight: 500, textDecoration: "underline" }}
					className="blueText clickable"
					onClick={() => {
						setError(null)
						getTransformTextFromApi(true, true)
					}}>
					click here to continue writing
				</span>{" "}
				text.
			</div>
		</div>
	)
}

type AdjustmentOption = "lengthen" | "shorten" | "jobDescription" | "anonymise"

function AdjustmentButtonRow({
	setLoading,
	responseAi,
	appId,
	showAiPortal,
	setResponses,
	responses,
	setCurrentResponseIndex,
	loading,
	text,
}: {
	setLoading: (loading: boolean) => void
	responseAi: SingleTagData
	appId: string
	showAiPortal: AIPortalShowProps
	setResponses: React.Dispatch<React.SetStateAction<SingleTagData[]>>
	responses: SingleTagData[]
	setCurrentResponseIndex: (index: number) => void
	loading: boolean
	text: string
}) {
	const { apps } = useAppContext()
	const thisApp = apps[appId]
	const jobDescription = thisApp?.supportingDocuments?.jobDescription
	const [, , thisJob] = useEntities(appId)
	const hasJobDescription =
		(jobDescription != null && jobDescription.text != null && jobDescription.text !== "") ||
		thisJob?.jobDescription != null

	const adjustment = (type: AdjustmentOption) => () => {
		setLoading(true)
		getTextTransform(
			{
				text,
				transformType: showAiPortal.type,
				appId,
				adjustText: type,
				previousText: getTextFromResponse(responseAi),
			},
			console.log,
			true
		)
			.then((response: SingleTagData) => {
				setCurrentResponseIndex(responses.length)
				setResponses((prev) => [...prev, response])
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const adjustmentAlreadyApplied = (type: AdjustmentOption) => type === responseAi?.adjustment

	return (
		<div className="text-transform-button-container">
			<AIDisplayButton disabled={loading} variant="outline" onClick={adjustment("shorten")}>
				Make Shorter
			</AIDisplayButton>
			<AIDisplayButton disabled={loading} variant="outline" onClick={adjustment("lengthen")}>
				Make Longer
			</AIDisplayButton>
			<AIDisplayButton
				disabled={loading || adjustmentAlreadyApplied("anonymise")}
				variant="outline"
				onClick={adjustment("anonymise")}>
				Anonymize
			</AIDisplayButton>
			<AIDisplayButton
				show={hasJobDescription}
				disabled={loading || adjustmentAlreadyApplied("jobDescription")}
				variant="outline"
				tooltipLabel={
					adjustmentAlreadyApplied("jobDescription")
						? "Optimise to job description has already been applied to this response"
						: null
				}
				onClick={adjustment("jobDescription")}>
				Optimise to Job Description
			</AIDisplayButton>
		</div>
	)
}

function FinalButtonRow({
	responseAi,
	error,
	loading,
	handleTryAgain,
	setShowAiPortal,
	showAiPortal,
}: {
	responseAi: SingleTagData
	error: string
	loading: boolean
	handleTryAgain: MouseEventHandler<HTMLButtonElement>
	setShowAiPortal: (arg0: AIPortalShowProps | null) => void
	showAiPortal: AIPortalShowProps
}) {
	const editor = useSlate()
	const hasSelection = editor.selection != null && !Range.isCollapsed(editor.selection)
	const [copied, setCopied] = useState(false)

	const handleReplace = () => {
		console.log("handleReplace")
		// Editor.insertText(editor, ' ' + responseAi)
		const marks = Editor.marks(editor) as Marks
		const nodesToInsert = initialValue(getTextFromResponse(responseAi).trim(), marks)

		Editor.withoutNormalizing(editor, () => {
			if (showAiPortal.tag != null) {
				// Ensure tag is exactly replaced
				const [nodeEntry] = [...Editor.nodes(editor, { match: (n) => n === showAiPortal.tag })]
				const [, path] = nodeEntry
				for (let [, childPath] of Node.children(editor, path, { reverse: true })) {
					// remove each child node to avoid emptying editor and causing crash
					Transforms.removeNodes(editor, { at: childPath })
				}
				Transforms.insertNodes(editor, nodesToInsert, { at: [...path, 0] })
				return
			}
			if (wholeTextIsSelected(editor)) {
				for (let [, childPath] of Node.children(editor, [], { reverse: true })) {
					// remove each child node to avoid emptying editor and causing crash
					Transforms.removeNodes(editor, { at: childPath })
				}
				Transforms.insertNodes(editor, nodesToInsert)
			} else {
				Transforms.splitNodes(editor)
				const selectionAfterSplit = editor.selection
				Transforms.insertNodes(editor, nodesToInsert)
				Transforms.removeNodes(editor, {
					mode: "highest",
					at: selectionAfterSplit,
					match: (n) => Element.isElement(n) && editor.baseBlocks.includes(n.type) && Node.string(n) === "",
				}) // only remove empty nodes
			}
		})
		setShowAiPortal(null)
	}
	const handleInsert = () => {
		console.log("handleInsert")
		const initialSelection = editor.selection
		const end = Range.end(initialSelection)
		const marks = Editor.marks(editor) as Marks
		Transforms.insertNodes(editor, initialValue(getTextFromResponse(responseAi).trim(), marks), { at: end })
		Transforms.removeNodes(editor, {
			mode: "highest",
			at: initialSelection,
			match: (n) => Element.isElement(n) && editor.baseBlocks.includes(n.type) && Node.string(n) === "",
		}) // only remove empty nodes
		setShowAiPortal(null)
	}
	const handleDiscard = () => {
		console.log("handleDiscard")
		setShowAiPortal(null)
	}

	const handleCopy = () => {
		setCopied(true)
		navigator.clipboard.writeText(getTextFromResponse(responseAi))
	}

	return (
		<div className="text-transform-button-container">
			<AIDisplayButton onClick={handleTryAgain} show={error && !responseAi}>
				Try Again
			</AIDisplayButton>
			<AIDisplayButton
				show={responseAi && hasSelection}
				variant="primary"
				onClick={handleReplace}
				disabled={loading || !responseAi}>
				Replace
			</AIDisplayButton>
			<AIDisplayButton
				variant={hasSelection ? "outline" : "primary"}
				show={!!responseAi}
				onClick={handleInsert}
				disabled={loading || !responseAi}>
				Insert
			</AIDisplayButton>
			<AIDisplayButton onClick={handleDiscard}>Discard</AIDisplayButton>
			<AIDisplayButton show={COPY_AVAILABLE} disabled={loading || !responseAi} onClick={handleCopy}>
				Copy
			</AIDisplayButton>
			<CopiedFlash show={copied} setShow={setCopied} />
		</div>
	)
}
