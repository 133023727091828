import { withProperties } from "./PropertyFormatting/PropertyPlugin"
import alignLeft from "../../images/align-left.png"
import alignCenter from "../../images/align-center.png"
import alignRight from "../../images/align-right.png"
import alignJustify from "../../images/align-justify.png"
import { ButtonType, PropOptionSet } from "./types/slate.types"

const formatAlignmentPath = ["style"]

// These need to match the values in the calc for ol/ul elements, then the calculation will ensure centering
// const paddingInlineStartPercent = 2
// const paddingInlineStartPixel = 10
// const centeredPaddingInlineEnd = `calc(${(100*paddingInlineStartPixel/(100-paddingInlineStartPercent)).toFixed(3)}px + ${(100*paddingInlineStartPercent/(100-paddingInlineStartPercent)).toFixed(3)}%)`

export const ALIGNMENT_OPTIONS: PropOptionSet = {
	name: "align",
	button: ButtonType.prop as ButtonType.prop,
	path: formatAlignmentPath,
	makeIcon: ({ currentFormat, computedStyle }) =>
		currentFormat?.icon ??
		ALIGNMENT_OPTIONS.options[computedStyle?.getPropertyValue("text-align")]?.icon ??
		Object.values(ALIGNMENT_OPTIONS.options)[0].icon,
	options: {
		left: {
			format: "left",
			insertAtPath: { textAlign: "left", listStylePosition: "outside", paddingInlineEnd: null },
			icon: <img src={alignLeft} alt="left-align" className="toolbar-button-icon alignment-icon"></img>,
			button: "block",
		},
		center: {
			format: "center",
			insertAtPath: { textAlign: "center", listStylePosition: "outside" },
			icon: <img src={alignCenter} alt="center-align" className="toolbar-button-icon alignment-icon"></img>,
			button: "block",
		},
		right: {
			format: "right",
			insertAtPath: { textAlign: "right", listStylePosition: "outside", paddingInlineEnd: null },
			icon: <img src={alignRight} alt="right-align" className="toolbar-button-icon alignment-icon"></img>,
			button: "block",
		},
		justify: {
			format: "justify",
			insertAtPath: { textAlign: "justify", listStylePosition: "outside", paddingInlineEnd: null },
			icon: <img src={alignJustify} alt="justify-align" className="toolbar-button-icon alignment-icon"></img>,
			button: "block",
		},
	},
	default: { insertAtPath: { textAlign: null, listStylePosition: null, paddingInlineEnd: null } },
}

export const withAlign = withProperties(ALIGNMENT_OPTIONS)
