import { EditorParameters } from "../types/slate.types"
import { Editor, Element, Node } from "slate"
import { renderTableElement } from "../TableFormatting/TableRendering"
import { AutofillTableElement } from "./AutofillTableElement"

export function addAttributeToTags(node: Node, attribute: object) {
	// updates object in place
	if (Element.isElement(node)) {
		if (node.type === "tagging") {
			Object.entries(attribute).forEach(([key, value]) => {
				node[key] = value
			})
		} else {
			node.children.forEach((child) => addAttributeToTags(child, attribute))
		}
	}
}

export function renderAutofillTableElement(
	{ element, attributes, children, ...props },
	{ templateMode }: EditorParameters
) {
	if (element.type === "table" && element.autofill && templateMode) {
		// override standard table here
		return <AutofillTableElement element={element} attributes={attributes} children={children} {...props} />
	} else {
		return renderTableElement({ element, attributes, children, ...props })
	}
}

function autoFillElementIsSelected(editor) {
	const [autoFillElement] = Editor.nodes(editor, {
		match: (n) => Element.isElement(n) && n.type === "table" && n.autofill,
	})
	return autoFillElement != null
}

export function autoFillRowIsSelected(editor) {
	if (!autoFillElementIsSelected(editor)) {
		return false
	}
	const [autoFillRow] = Editor.nodes(editor, {
		match: (n) => Element.isElement(n) && n.type === "table-row" && n.row > 0,
	})
	return autoFillRow != null
}
