import { useSlate } from "slate-react"
import { HDropItem } from "../HorzDropdown/HorzDropdown"
import { columnsAvailable, toggleColumns, unwrapColumns } from "../TextFormatting/ColumnsFormatting/ColumnsUtils"
import { ReactComponent as OneColumn } from "../../images/one-column-icon.svg"
import { ReactComponent as TwoColumns } from "../../images/two-columns-icon.svg"
import { ReactComponent as ThreeColumns } from "../../images/three-columns-icon.svg"

export const COLUMN_OPTIONS = {
	oneColumn: {
		icon: () => <OneColumn />,
		onMouseDown: (editor: any) => {
			unwrapColumns(editor)
		},
		text: "One",
	},
	twoColumns: {
		icon: () => <TwoColumns />,
		onMouseDown: (editor: any) => {
			toggleColumns(editor, "twoColumns")
		},
		text: "Two",
	},
	threeColumns: {
		icon: () => <ThreeColumns />,
		onMouseDown: (editor: any) => {
			toggleColumns(editor, "threeColumns")
		},
		text: "Three",
	},
}

export const ColumnsDropdownItems = ({ currentValue }: { currentValue: string }) => {
	const editor = useSlate()
	const available = columnsAvailable(editor)
	if (available)
		return (
			<>
				{Object.entries(COLUMN_OPTIONS).map(([key, value]) => {
					const isActive =
						(key === "oneColumn" && currentValue === null) || currentValue === key ? "active" : "inactive"
					return (
						<HDropItem
							data-testid={"columns-" + key}
							key={`columns-${key}`}
							onMouseDown={() => {
								value.onMouseDown(editor)
							}}>
							<div className={`clickable toolbar-dropdown-item toolbar-button toolbar-button-${isActive}`}>
								{value.icon()}
								{value.text}
							</div>
						</HDropItem>
					)
				})}
			</>
		)
}
