import { setLeafUnchanged, setWholeTextSelection, markExistsInText } from "../Utils"
import "../ColourFormatting.css"
import { Editor, NodeEntry } from "slate"
import { CustomElement, CustomPlugin } from "../types/slate.types"
import { setParentSize, setSizeLeafProps } from "./SizeUtils"
import { sizeOptions } from "./SizeOptions"

/**
 * Function to apply the size plugin to the editor.
 * Extends the setLeafProps, customMarkOptions, resetProperties and normalizeNode functions.
 * @param editor
 * @returns
 */
export const withSize: CustomPlugin = (editor) => {
	const { setLeafProps, customMarkOptions, resetProperties, normalizeNode } = editor

	/**
	 * Normalise node extension: adds parent size setting to normaliseNode
	 * @param entry
	 */
	editor.normalizeNode = (entry) => {
		setParentSize(editor, entry as NodeEntry<CustomElement>)
		normalizeNode(entry)
	}

	editor.customMarkOptions = {
		...(customMarkOptions ?? {}),
		...sizeOptions,
	}

	/**
	 * Reset properties extension: adds ability to remove size property from all elements
	 * @param param0
	 */
	editor.resetProperties = ({ resetList }) => {
		if (resetList.includes("size") && markExistsInText(editor, "size")) {
			// reset property to null in all elements
			setWholeTextSelection(editor)
			console.log("removing size")
			Editor.withoutNormalizing(editor, () => {
				Editor.removeMark(editor, "size")
			})
		}
		resetProperties?.({ resetList })
	}

	editor.setLeafProps = (props) => {
		const setPrevLeafProps = setLeafProps ?? setLeafUnchanged
		return setSizeLeafProps(setPrevLeafProps(props))
	}

	return editor
}
