import "../ColourFormatting.css"
import { CustomPlugin } from "../types/slate.types"
import { writeText } from "./WriteText"

export const withWriteText: CustomPlugin = (editor) => {
	const { allAiOptions } = editor

	editor.allAiOptions = {
		...(allAiOptions ?? {}),
		...writeText,
	}

	return editor
}
