export function LinkIcon({ width, ...props }) {
	return (
		<div {...props}>
			<svg width={width} height={width} viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clip-path="url(#clip0_414_36)">
					<rect width="96" height="96" transform="translate(0.875977 0.888184)" fill="transparent" />
					<path
						d="M39.876 27.8882H15.876C11.4577 27.8882 7.87598 31.4699 7.87598 35.8882V51.8882C7.87598 56.3065 11.4577 59.8882 15.876 59.8882H39.876"
						stroke="currentColor"
						stroke-width="8"
						stroke-linecap="square"
					/>
					<path
						d="M57.876 27.8882H81.876C86.2943 27.8882 89.876 31.4699 89.876 35.8882V51.8882C89.876 56.3065 86.2943 59.8882 81.876 59.8882H57.876"
						stroke="currentColor"
						stroke-width="8"
						stroke-linecap="square"
					/>
					<path d="M32.876 43.8882H64.876" stroke="currentColor" stroke-width="8" stroke-linecap="square" />
				</g>
				<defs>
					<clipPath id="clip0_414_36">
						<rect width="96" height="96" fill="transparent" transform="translate(0.875977 0.888184)" />
					</clipPath>
				</defs>
			</svg>
		</div>
	)
}
