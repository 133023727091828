export const DEFAULT_PLUGINS: string[] = [
	"marks",
	"bullets",
	"tables",
	"align",
	"color",
	"size",
	"case",
	"textTransform",
	"writeText",
	"hyperlink",
	"font",
	"divider",
	"lineSpacing",
	"markdown",
	"pageBreak",
	"textColumns",
]

export const DEFAULT_NOT_FULL_WIDTH_PLUGINS: string[] = [
	"marks",
	"bullets",
	"tables",
	"align",
	"color",
	"size",
	"case",
	"textTransform",
	"writeText",
	"hyperlink",
	"font",
	"divider",
	"lineSpacing",
	"markdown",
]
