export function SpacingIcon({
	option = "standard",
	size,
	color = "black",
}: {
	option?: string
	size: React.CSSProperties["width"]
	color?: React.CSSProperties["color"]
}) {
	return (
		<svg
			data-testid={"spacing-" + option}
			width={size}
			height={size}
			viewBox="0 0 200 200"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			{option === "compact" ? (
				<>
					<rect x="15" y="63" width="100" height="18" rx="9" fill={color} />
					<rect x="15" y="91" width="100" height="18" rx="9" fill={color} />
					<rect x="15" y="119" width="100" height="18" rx="9" fill={color} />
					<path
						d="M155.172 135.828C156.734 137.391 159.266 137.391 160.828 135.828L186.284 110.373C187.846 108.81 187.846 106.278 186.284 104.716C184.722 103.154 182.19 103.154 180.627 104.716L158 127.343L135.373 104.716C133.81 103.154 131.278 103.154 129.716 104.716C128.154 106.278 128.154 108.81 129.716 110.373L155.172 135.828ZM160.828 64.1716C159.266 62.6095 156.734 62.6095 155.172 64.1716L129.716 89.6274C128.154 91.1895 128.154 93.7222 129.716 95.2843C131.278 96.8464 133.81 96.8464 135.373 95.2843L158 72.6569L180.627 95.2843C182.19 96.8464 184.722 96.8464 186.284 95.2843C187.846 93.7222 187.846 91.1895 186.284 89.6274L160.828 64.1716ZM162 133L162 67L154 67L154 133L162 133Z"
						fill={color}
						opacity={0.5}
					/>
				</>
			) : option === "spaced" ? (
				<>
					<rect x="15" y="35" width="100" height="18" rx="9" fill={color} />
					<rect x="15" y="91" width="100" height="18" rx="9" fill={color} />
					<rect x="15" y="147" width="100" height="18" rx="9" fill={color} />
					<path
						d="M155.195 163.852C156.77 165.401 159.302 165.38 160.852 163.805L186.098 138.142C187.647 136.567 187.626 134.034 186.051 132.485C184.477 130.936 181.944 130.956 180.395 132.531L157.954 155.343L135.142 132.902C133.567 131.353 131.034 131.374 129.485 132.949C127.936 134.523 127.956 137.056 129.531 138.605L155.195 163.852ZM159.805 36.1485C158.23 34.5992 155.698 34.62 154.148 36.1949L128.902 61.8585C127.353 63.4333 127.374 65.9659 128.949 67.5152C130.523 69.0644 133.056 69.0436 134.605 67.4688L157.046 44.6567L179.858 67.0979C181.433 68.6471 183.966 68.6263 185.515 67.0515C187.064 65.4766 187.044 62.9441 185.469 61.3948L159.805 36.1485ZM162 160.967L161 38.9672L153 39.0328L154 161.033L162 160.967Z"
						fill={color}
						opacity={0.5}
					/>
				</>
			) : (
				<>
					<rect x="15" y="48" width="100" height="18" rx="9" fill={color} />
					<rect x="15" y="91" width="100" height="18" rx="9" fill={color} />
					<rect x="15" y="134" width="100" height="18" rx="9" fill={color} />
					<path
						d="M155.172 150.828C156.734 152.391 159.266 152.391 160.828 150.828L186.284 125.373C187.846 123.81 187.846 121.278 186.284 119.716C184.722 118.154 182.19 118.154 180.627 119.716L158 142.343L135.373 119.716C133.81 118.154 131.278 118.154 129.716 119.716C128.154 121.278 128.154 123.81 129.716 125.373L155.172 150.828ZM160.828 49.1716C159.266 47.6095 156.734 47.6095 155.172 49.1716L129.716 74.6274C128.154 76.1895 128.154 78.7222 129.716 80.2843C131.278 81.8464 133.81 81.8464 135.373 80.2843L158 57.6569L180.627 80.2843C182.19 81.8464 184.722 81.8464 186.284 80.2843C187.846 78.7222 187.846 76.1895 186.284 74.6274L160.828 49.1716ZM162 148L162 52L154 52L154 148L162 148Z"
						fill={color}
						opacity={0.5}
					/>
				</>
			)}
		</svg>
	)
}
