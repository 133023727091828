import { addPropertiesToNodes } from "../Utils"
import { Descendant, Node, NodeEntry, Element, Editor } from "slate"
import { conversionPxToPt } from "../../FontSizeDropdown/FontSizeDropdown"
import { CustomEditor, SlateLeafRenderProps } from "../types/slate.types"

const sizeClassRegex = new RegExp(/default-font-size-(\S*)/)

/**
 * Use computed style of the editor html element to get a default font size basedon the actual size set in the browser.
 * Used when no size is set in order to be consistent with the actual size of the text.
 * @param editorEl
 * @returns
 */
export const getDefaultSize = (editorEl: HTMLElement) => {
	let defaultSize = window.getComputedStyle(editorEl ?? document.activeElement)?.getPropertyValue("font-size")
	if (defaultSize != null && defaultSize.endsWith("px")) {
		return conversionPxToPt(defaultSize.slice(0, -2))
	}
	// not found in computed style (this shouldn't really happen)
	const editableEl = (editorEl ?? document.activeElement).closest(".editable-text")
	const match = editableEl?.className?.match?.(sizeClassRegex)?.[1]
	return match
}

/**
 * Add size to leaf in html
 * @param param0
 * @returns
 */
export const setSizeLeafProps = ({
	attributes,
	children,
	leaf,
	...other
}: SlateLeafRenderProps): SlateLeafRenderProps => {
	if (leaf.size != null) {
		children = <span style={{ fontSize: `${leaf.size}pt` }}>{children}</span>
	}
	// if (leaf.highlight) {
	//     children = <span className='highlighted'>{children}</span>
	// }
	return { attributes, children, leaf, ...other }
}

/**
 * Copies the font size to the parent base block node (used in normalisation).
 * This is necessary for layout consistency eg bullet point indenting which is based on font size.
 * Where more than one font size is present in the node, the function exits without changing the parent size.
 * @param editor
 * @param nodeEntry
 * @returns
 */
export function setParentSize(editor: CustomEditor, nodeEntry: NodeEntry<Descendant>) {
	const [node, path] = nodeEntry
	if (Element.isElement(node) && editor.baseBlocks.includes(node.type)) {
		// ensure size is set to consistent font size on base block nodes
		// console.log(Node.texts(node))
		if (node.children.length === 0) {
			return
		}
		let currentFontSize
		let fontSizeUniform = true
		for (var [text] of Node.texts(node)) {
			const fontSize = text.size == null ? null : `${text.size}pt`
			if (currentFontSize == null) {
				currentFontSize = fontSize
				continue
			}
			if (text.text === "") {
				continue
			} // ignore empty text if other text existed (which set fontSize above)
			fontSizeUniform = currentFontSize === fontSize
			if (!fontSizeUniform) {
				break
			}
		}
		if (fontSizeUniform && node.props?.style?.fontSize != currentFontSize) {
			addPropertiesToNodes(
				editor,
				{ props: { style: { fontSize: currentFontSize } } },
				{ at: path, match: (n) => n === node }
			)
		}
	}
}
