import { ButtonType, AIOptionSet } from "../types/slate.types"
import "./text-transform.css"
import { wordCounter } from "libs"

interface OptionsProps {
	label: string
	key: string
}

const OPTIONS_TEXT_TRANSFORM: OptionsProps[] = [
	{
		label: "Quick Notes To Text",
		key: "notesToText",
	},
	{
		label: "Fix spelling and grammar",
		key: "spellingAndGrammar",
	},
	{
		label: "Summarize Text",
		key: "summarize",
	},
	{
		label: "Summarize Experience",
		key: "summarizeExperiences",
	},
	{
		label: "Bullet Point",
		key: "bulletPoints",
	},
	{
		label: "Anonymize",
		key: "anonymize",
	},
	{
		label: "Change to 1st Person",
		key: "firstPerson",
	},
	{
		label: "Change to 3rd Person",
		key: "thirdPerson",
	},
	{
		label: "Remove Gendered terms",
		key: "genderedTerms",
	},
	{
		label: "Change to Past Tense",
		key: "pastTense",
	},
]

export const textTransform: { textTransform: AIOptionSet } = {
	textTransform: {
		format: "textTransform",
		button: ButtonType.textTransform as ButtonType.textTransform,
		makeIcon: () => {
			return <span className="ai-formatting-icon">Transform</span>
		},
		makeCustomDropdown: ({ setFormat, clientAiOptions }) => {
			return (
				<div className="custom-dropdown-options-text-transform">
					{OPTIONS_TEXT_TRANSFORM.filter((option) => !clientAiOptions.hide?.includes(option.key)).map(
						(option: OptionsProps) => (
							<div
								key={option.key}
								className="clickable custom-dropdown-option-text-transform"
								onClick={() => {
									setFormat(option.key)
								}}>
								{option.label}
							</div>
						)
					)}
				</div>
			)
		},
		enabled: (currentValue) => {
			return wordCounter(currentValue) >= 3
		},
		disabledTooltipLabel: "Select text (minimum 3 words) to use AI transforms",
	},
}
