import { useCallback, useMemo, useRef, useState } from "react"
import { useHover } from "../HoverDropdown/HoverDropdown"
import { Placement } from "@popperjs/core"
import { usePopper } from "react-popper"
import { createPortal } from "react-dom"
import { NoColorSwatch } from "./utils"
import { FontColorPicker } from "./ColourPicker"
import { forwardRef } from "react"

type SelectedElementProps = {
	localColor: React.CSSProperties["backgroundColor"]
	includeNone: boolean
	cursor: React.CSSProperties["cursor"]
	opacity: React.CSSProperties["opacity"]
	children: React.ReactNode
} & React.HTMLAttributes<HTMLDivElement>

const SelectedElement = forwardRef<HTMLDivElement, SelectedElementProps>(
	({ localColor, includeNone, cursor, opacity, children, ...props }, ref) => {
		return (
			<div ref={ref} style={{ backgroundColor: localColor, opacity, cursor, position: "relative" }} {...props}>
				{includeNone && localColor === null && (
					<NoColorSwatch style={{ position: "absolute", top: 0, left: 0, height: "100%", width: "100%" }} />
				)}
				{children}
			</div>
		)
	}
)

export const InstantApplyColourDropdown = ({
	color,
	onChange,
	disabled = false,
	placement = "bottom-end",
	offset = [0, 3],
	includeNone = false,
}: {
	color
	onChange
	disabled?: boolean
	placement?: Placement
	offset?: [number, number]
	includeNone?: boolean
}) => {
	let { onMouseLeave, onMouseEnter, onTouch, afterSelect, show } = useHover({ baseTimeout: 100 })
	const opacity = disabled ? 0.7 : 1
	const cursor = disabled ? "not-allowed" : "pointer"
	const [localColor, setLocalColorBase] = useState(color)
	const colorRef = useRef(localColor) // need to store reference for dismount
	const setLocalColor = useCallback((newColor) => {
		setLocalColorBase(newColor)
		colorRef.current = newColor
	}, [])
	const [titleElement, setTitleElement] = useState(null)
	const [dropElement, setDropElement] = useState(null)

	const { styles, attributes, update } = usePopper(titleElement, dropElement, {
		placement,
		strategy: "absolute",
		modifiers: [
			{
				name: "preventOverflow",
				options: {
					mainAxis: true,
				},
			},
			{
				name: "offset",
				options: {
					offset,
				},
			},
		],
	})

	useMemo(() => {
		setLocalColor(color)
	}, [color, setLocalColor])

	useMemo(() => {
		if (show) {
			update()
		}
	}, [show, update])

	return (
		<div className="swatch-picker">
			<SelectedElement
				ref={setTitleElement}
				className="swatch"
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				onTouchStart={onTouch}
				{...{ localColor, includeNone, cursor, opacity }}>
				{createPortal(
					<div
						onMouseLeave={onMouseLeave}
						style={{ ...styles.popper }}
						className={`font-popover`}
						ref={setDropElement}
						{...attributes.popper}>
						{show && !disabled && (
							<FontColorPicker
								currentValue={color}
								setFormat={(color) => {
									onChange(color)
									afterSelect()
								}}
								includeNone={includeNone}
							/>
						)}
					</div>,
					document.body
				)}
			</SelectedElement>
		</div>
	)
}
