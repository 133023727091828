import { useEffect } from "react"
import "./CopiedFlash.css"

export const CopiedFlash = ({ show, setShow, ...props }) => {
	useEffect(() => {
		if (show) {
			setTimeout(() => {
				setShow(false)
			}, 1000)
		}
	}, [show, setShow])

	return (
		<div {...props} className={`copied-flash-${show ? "showing" : "hidden"}`}>
			Copied!
		</div>
	)
}
