import { HighlightingIcon } from "../../Icons/HighlightingIcon"
import { FontColorPicker } from "../../ColourPicker/ColourPicker"
import { ButtonType, CustomMarkOptionSet } from "../types/slate.types"
import { getDefaultColour } from "./ColourUtils"

export const colorOptions: { color: CustomMarkOptionSet; highlight: CustomMarkOptionSet } = {
	color: {
		format: "color",
		button: ButtonType.customMark as ButtonType.customMark,
		getDefault: getDefaultColour,
		makeIcon: ({ currentValue }) => (
			<span
				style={{
					textDecoration: "underline",
					textDecorationThickness: "0.15rem",
					textDecorationColor: currentValue ?? "#000000",
					paddingBottom: 1,
				}}
				className="toolbar-button-icon">
				A
			</span>
		),
		makeCustomDropdown: ({ currentValue, setFormat }) => (
			<FontColorPicker currentValue={currentValue ?? "black"} setFormat={setFormat} includeNone={true} />
		),
	},
	highlight: {
		format: "highlight",
		button: ButtonType.customMark as ButtonType.customMark,
		getDefault: getDefaultColour,
		makeIcon: ({ currentValue }) => (
			<span className="toolbar-button-icon">
				<HighlightingIcon currentValue={currentValue} size={17} />
			</span>
		),
		makeCustomDropdown: ({ currentValue, setFormat }) => (
			<FontColorPicker currentValue={currentValue ?? "white"} setFormat={setFormat} includeNone={true} />
		),
	},
}

// Use this one to create Case
