import { CasePicker, POSSIBLE_CASE_OPTIONS } from "../../CasePicker/CasePicker"
import { ButtonType, CustomMarkOptionSet } from "../types/slate.types"

export const caseOptions: { case: CustomMarkOptionSet } = {
	case: {
		format: "case",
		button: ButtonType.customMark as ButtonType.customMark,
		makeIcon: ({ currentValue }: { currentValue: string | null | undefined }) => {
			const activeClassName =
				currentValue != null && !["none", "null", "undefined"].includes(currentValue) ? "toolbar-button-active" : ""
			return (
				<div className={`inner-toolbar-button ${activeClassName}`}>
					<span className={`toolbar-button-icon `}>
						{POSSIBLE_CASE_OPTIONS.find((item) => item.value === currentValue)?.icon || "Aa"}
					</span>
				</div>
			)
		},
		makeCustomDropdown: ({ currentValue, setFormat }) => (
			<CasePicker currentValue={currentValue} setFormat={setFormat} />
		),
	},
}
