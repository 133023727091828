export function getXPos(e) {
	return parseInt(
		e.clientX ??
			(
				e?.touches?.[0] ??
				e?.changedTouches?.[0] ??
				e.originalEvent?.touches?.[0] ??
				e.originalEvent?.changedTouches?.[0]
			)?.pageX
	)
}
