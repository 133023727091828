import { Editor, Element, Transforms, Range } from "slate"
import { CustomPlugin, CustomEditor } from "../types/slate.types"
import { ensureSelection, getSelectedText, renderParagraphElement, toggleParagraph } from "../Utils"
import { pageBreakOptions } from "./PageBreakOptions"
import { insertPageBreak, renderPageBreakElement, togglePageBreak } from "./PageBreakUtils"
import isHotkey from "is-hotkey"

/**
 * Plugin for pagebreak. The pagebreak should be a non-editable element that is rendered as a horizontal line on output it will cause a pagebreak.
 * Added as a block element, so the icon will highlight when the line is selected. when a line is selected the line will cause it to delete.
 * When no pagebreak is already selected the icon adds the line (on the current line if empty, below if not).
 * It should be possible to select it by clicking
 * @param editor
 * @returns
 */
export const withPageBreak: CustomPlugin = (editor: CustomEditor) => {
	const { renderElement, toggleBlock, blockOptions, customKeyPress, normalizeNode } = editor

	editor.normalizeNode = (entry) => {
		const [node, path] = entry
		if (Element.isElement(node) && node.type === "pageBreak") {
			// the divider should only be a direct child of the editor or a nested formatter
			const parentEntry = Editor.parent(editor, path)
			const parent = parentEntry[0]
			if (Element.isElement(parent) && editor.nestedFormatters.includes(parent.type)) {
				Transforms.removeNodes(editor, { at: path, match: (n) => Element.isElement(n) && n.type === "pageBreak" })
				return
			}
		}
		normalizeNode(entry)
	}

	editor.customKeyPress = (event) => {
		const [entry] = Editor.nodes(editor, { match: (n) => Element.isElement(n) && n.type === "pageBreak" })
		const dividerInSelection = entry != null

		if (isHotkey("Mod+Enter", event)) {
			insertPageBreak(editor)
		}
		if (dividerInSelection && ["Backspace", "Delete"].includes(event.key)) {
			Transforms.removeNodes(editor, { at: entry[1] })
			if (getSelectedText(editor)?.length <= 1) {
				// only the divider was selected, so we need to prevent paras before and after merging by double delete
				event.preventDefault()
			}
			return
		}
		if (dividerInSelection && event.key === "Enter") {
			Transforms.insertNodes(
				editor,
				{ type: "paragraph", children: [{ text: "" }] },
				{ at: [entry[1][0] + 1], select: true }
			)
			if (getSelectedText(editor)?.length <= 1) {
				// only the divider was selected, so we need to prevent paras before and after merging by double delete
				event.preventDefault()
			}
			return
		}
		return customKeyPress?.(event)
	}

	editor.renderElement = (props) => {
		return renderPageBreakElement(props) ?? renderElement?.(props) ?? renderParagraphElement(props)
	}

	editor.toggleBlock = (editor, format) => {
		ensureSelection(editor)
		return togglePageBreak(editor, format) ?? toggleBlock?.(editor, format) ?? toggleParagraph(editor, format)
	}

	editor.blockOptions = {
		...(blockOptions ?? {}),
		...pageBreakOptions,
	}

	return editor
}
