import { CustomPlugin, CustomEditor } from "../types/slate.types"
import { ensureSelection, renderParagraphElement, toggleParagraph } from "../Utils"
import { columnsOptions } from "./ColumnsOptions"

import { toggleColumns } from "./ColumnsUtils"
import { renderColumnElement } from "./ColumnsUtils"

// import {  toggleColumnsBreak } from "./ColumnsBreakUtils"
// import { columnsBreakOptions } from "./ColumnsBreakOptions"

export const withColumns: CustomPlugin = (editor: CustomEditor) => {
	const { renderElement, toggleBlock, customBlockOptions } = editor

	// editor.normalizeNode = (entry) => {
	// 		const [node, path] = entry

	// 		//if "columnsBreak" doesn't have a parent of type "twoColumns or threeColumns" then remove it
	// 		if (Element.isElement(node) && node.type === "columnsBreak") {
	// 			const columnsContainerEntry = Editor.above(editor, {
	// 				at: path,
	// 				match: (n) => Element.isElement(n) && (n.type === "twoColumns" || n.type === "threeColumns"),
	// 				mode: "lowest",
	// 			})

	// 			if (!columnsContainerEntry) {
	// 				removeColumnBreak(editor, path)
	// 				return
	// 			}
	// 		}

	// 	normalizeNode(entry)
	// 	logTypeTree(editor)
	// }

	// editor.customKeyPress = (event) => {
	// 	const [entry] = Editor.nodes(editor, { match: (n) => Element.isElement(n) && n.type === "columnsBreak" })
	// 	const dividerInSelection = entry != null

	// 	if (dividerInSelection && ["Backspace", "Delete"].includes(event.key)) {
	// 		Transforms.removeNodes(editor, { at: entry[1] })
	// 		if (getSelectedText(editor)?.length <= 1) {
	// 			// only the divider was selected, so we need to prevent paras before and after merging by double delete
	// 			event.preventDefault()
	// 		}
	// 		return
	// 	}
	// 	if (dividerInSelection && event.key === "Enter") {
	// 		Transforms.insertNodes(
	// 			editor,
	// 			{ type: "paragraph", children: [{ text: "" }] },
	// 			{ at: [entry[1][0] + 1], select: true }
	// 		)
	// 		if (getSelectedText(editor)?.length <= 1) {
	// 			// only the divider was selected, so we need to prevent paras before and after merging by double delete
	// 			event.preventDefault()
	// 		}
	// 		return
	// 	}
	// 	return customKeyPress?.(event)
	// }

	editor.nestedFormatters = [...(editor.nestedFormatters ?? []), "twoColumns", "threeColumns"]

	editor.renderElement = (props) => {
		return renderColumnElement(props) ?? renderElement?.(props) ?? renderParagraphElement(props)
	}

	editor.toggleBlock = (editor, format) => {
		ensureSelection(editor)
		return (
			toggleColumns(editor, format) ??
			// toggleColumnsBreak(editor, format) ??
			toggleBlock?.(editor, format) ??
			toggleParagraph(editor, format)
		)
	}
	editor.customBlockOptions = {
		...(customBlockOptions ?? {}),
		...(!editor.parameters.templateMode ? columnsOptions : {}),
	}

	return editor
}
