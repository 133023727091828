import {
	CustomPlugin,
	ElementType,
	TableRowElement,
	TableCellElement,
	TableElementType,
	TableParagraphElement,
} from "../types/slate.types"
import { Editor, Node, Path, Transforms, Element as SlateElement, NodeEntry } from "slate"
import { toggleTable, minTableWidthForCols, tableCellContent, isAtTableCellEnd, isAtTableCellStart } from "./TableUtils"
import { renderTableElement } from "./TableRendering"
import {
	renderParagraphElement,
	ensureSelection,
	toggleParagraph,
	doesBlockExist,
	isBlockActive,
	isMarkActive,
	isAtEnd,
	isAtStart,
	deleteIfInEmptyPara,
} from "../Utils"
import { tableOptions } from "./TableOptions"

export const withTables: CustomPlugin = (editor) => {
	const {
		deleteBackward,
		deleteForward,
		normalizeNode,
		insertFragment,
		apply,
		renderElement,
		toggleBlock,
		nestedFormatters,
		blockOptions,
	} = editor

	editor.renderElement = (props) => {
		return renderTableElement(props) ?? renderElement?.(props) ?? renderParagraphElement(props)
	}

	editor.toggleBlock = (editor, format) => {
		ensureSelection(editor)
		return (
			toggleTable(editor, format as ElementType) ?? toggleBlock?.(editor, format) ?? toggleParagraph(editor, format)
		)
	}

	editor.blockOptions = {
		...(blockOptions ?? {}),
		...tableOptions,
	}

	editor.nestedFormatters = [...(nestedFormatters ?? []), "table-paragraph"]

	editor.normalizeNode = (entry) => {
		const [node, path] = entry
		if (SlateElement.isElement(node) && node.type === "table") {
			const children = [...Node.children(editor, path)] as [TableRowElement, Path][]
			if (node.shape != null && node.colWidths != null && node.shape?.[1] <= node.colWidths?.length) {
				// Enforce last col never directly prescribed a width (colWidths.length must be less than number of cols)
				const correctColWidths = node.colWidths.slice(0, node.shape[1] - 1)
				Transforms.setNodes(editor, { ...node, colWidths: correctColWidths }, { at: path })
				return
			}
			if (node.tableWidth > editor.parameters.maxTableWidth) {
				console.log("resetting table width")
				Transforms.setNodes(editor, { ...node, tableWidth: editor.parameters.maxTableWidth }, { at: path })
				return
			}
			if (node.colWidths?.length > 0 && node.tableWidth < minTableWidthForCols(node.colWidths, node.shape)) {
				// need to reset cols to avoid overlap
				console.log("reset colWidths")
				Transforms.setNodes(
					editor,
					{ ...node, colWidths: [], tableWidth: editor.parameters.maxTableWidth },
					{ at: path }
				)
				return
			}
			if (children.length !== node.shape?.[0]) {
				for (var i = children.length; i < node.shape?.[0]; i++) {
					// console.log('adding row',i)
					Transforms.insertNodes(
						editor,
						{
							type: "table-row",
							row: i,
							children: Array(node.shape?.[1])
								.fill(0)
								.map((a, i) => ({
									type: "table-cell",
									col: i,
									children: tableCellContent(),
								})),
						} as TableRowElement,
						{ at: [...path, i] }
					)
				}
				for (var k = node.shape?.[0]; k < children.length; k++) {
					// console.log('removing row',k)
					Transforms.removeNodes(editor, { at: [...path, k] })
					children.pop()
				}
				// }

				return
			}
			const parent = Node.parent(editor, path)
			if (SlateElement.isElement(parent) && parent.type === "paragraph") {
				// table should not be nested in paragraph
				Transforms.liftNodes(editor, { match: (n) => n === node })
				return
			}
		}
		if (SlateElement.isElement(node) && node.type === "table-row") {
			// console.log(node)
			const children = [...Node.children(editor, path)] as NodeEntry<TableCellElement>[]
			const [parent] = Editor.parent(editor, path) as NodeEntry<TableElementType>
			const cols = parent?.shape[1]
			const rows = parent?.shape[0]
			// console.log('col values on row',node.row, children.length,shapeRef.current[1])
			children.forEach(([childNode, childPath], m) => {
				if (childNode.col !== m) {
					Transforms.setNodes(editor, { ...childNode, col: m }, { at: childPath })
				}
				if (childNode.row !== node.row) {
					Transforms.setNodes(editor, { ...childNode, row: node.row }, { at: childPath })
				}

				const maxMergeWidth = cols - childNode.col - 1
				if (childNode.merge?.right > 0 && childNode.merge?.right > maxMergeWidth) {
					// fix merge data after cols removed
					Transforms.setNodes(
						editor,
						{ ...childNode, merge: { ...childNode.merge, right: maxMergeWidth } },
						{ at: childPath }
					)
				}
				const maxMergeHeight = rows - childNode.row - 1
				if (childNode.merge?.bottom > 0 && childNode.merge?.bottom > maxMergeHeight) {
					// fix merge data after rows removed
					Transforms.setNodes(
						editor,
						{ ...childNode, merge: { ...childNode.merge, bottom: maxMergeHeight } },
						{ at: childPath }
					)
				}
			})
			for (var j = children.length; j < cols; j++) {
				// console.log('adding col')
				Transforms.insertNodes(
					editor,
					{ type: "table-cell", col: j, children: tableCellContent() } as TableCellElement,
					{ at: [...path, j] }
				)
			}
			for (var l = cols; l < children.length; l++) {
				// console.log('removing col')
				Transforms.removeNodes(editor, { at: [...path, l] })
				children.pop()
			}
			return
		}
		if (SlateElement.isElement(node) && node.type === "table-cell") {
			// ensure table-paragraph within table-cell
			if (node.children?.length > 1) {
				Transforms.wrapNodes(editor, { type: "table-paragraph" } as TableParagraphElement, {
					at: path,
					match: (n, p) => p.length === path.length + 1 && path.every((el, idx) => el === p[idx]),
				})
				return
			} else if (node.children?.length === 1 && node.children?.[0]?.type !== "table-paragraph") {
				Transforms.wrapNodes(editor, { type: "table-paragraph" } as TableParagraphElement, { at: [...path, 0] })
				return
			}
		}
		if (SlateElement.isElement(node) && node.type === "table-paragraph") {
			// prevent table-paragraph not inside table-cell (prevents nested table-p's , and other weirdnesses(whole text wrapped in table-p for example )

			const parent = Node.parent(editor, path)
			if (!SlateElement.isElement(parent) || parent.type !== "table-cell") {
				Transforms.unwrapNodes(editor, { at: path, match: (n) => n === node })
				return
			}
		}

		normalizeNode(entry)
	}

	editor.apply = (op) => {
		if (op.type === "set_selection" && doesBlockExist(editor, "table")) {
			//Prevent selection of mutliple cells, only allow one or all
			const currentSelection = { ...editor.selection, ...op.newProperties }
			const tParas = [
				...Editor.nodes(editor, {
					at: currentSelection,
					match: (n) => SlateElement.isElement(n) && n?.type === "table-paragraph",
				}),
			]
			const [tableMatch] =
				(Editor.above(editor, {
					match: (n) => SlateElement.isElement(n) && n.type === "table",
					at: currentSelection.anchor,
				}) as unknown as NodeEntry<TableElementType>) ??
				(Editor.above(editor, {
					match: (n) => SlateElement.isElement(n) && n.type === "table",
					at: currentSelection.focus,
				}) as unknown as NodeEntry<TableElementType>) ??
				[]
			// If table match either the anchor or the focus is within the table and therefore selection is not all or
			const fullNumberTableCells = (tableMatch?.shape?.[0] ?? 0) * (tableMatch?.shape?.[1] ?? 0)
			if (tParas?.length > 1 && tParas?.length !== fullNumberTableCells) {
				const [currentAnchor] = Editor.nodes(editor, {
					at: currentSelection.anchor,
					match: (n) => SlateElement.isElement(n) && n?.type === "table-paragraph",
				})
				if (currentAnchor != null) {
					const [, anchorLocation] = currentAnchor

					const [anchor, focus] = Editor.edges(editor, anchorLocation)
					op.newProperties = { anchor, focus }
				}
			}
		}
		apply(op)
	}

	editor.insertFragment = (fragment) => {
		// prevent table copy and pasting where table already exists
		for (var item of fragment) {
			if (SlateElement.isElement(item) && item.type === "table") {
				if (isBlockActive(editor, "table") || item.children.length === 1) {
					// Either a second table is being copied into section or a single table cell
					// (passed as the full path) is being copied
					//Need to empty table contents into paste
					// nesting: table-[table-row]-[table-cell]-table-paragraph-[content-children]
					const tableRows = item.children as TableRowElement[]
					const tableCells: TableCellElement[] = tableRows.reduce(
						(acc: TableCellElement[], item) => acc.concat(item.children),
						[] as TableCellElement[]
					)
					const tableContent = tableCells.reduce((acc, cell) => acc.concat(cell.children[0].children), [])
					for (var { format } of Object.values(editor.markOptions ?? [])) {
						if (isMarkActive(editor, format)) {
							Editor.removeMark(editor, format)
						}
					}
					insertFragment(tableContent)
					return
				}
				// inserting whole table, ensure inserted at top level after current path
				Transforms.insertNodes(editor, fragment, { at: [editor.selection.focus.path[0] + 1] })
				return
			}
		}
		insertFragment(fragment)
	}

	editor.deleteBackward = (unit) => {
		if (isAtTableCellStart(editor)) {
			Transforms.move(editor, { distance: 1, reverse: true })
			return
		}

		if (isAtStart(editor) && !isAtEnd(editor)) {
			deleteIfInEmptyPara(editor)
		}

		deleteBackward(unit)
	}

	editor.deleteForward = (unit) => {
		if (isAtTableCellEnd(editor)) {
			Transforms.move(editor, { distance: 1 })
			return
		}
		deleteForward(unit)
	}

	return editor
}
