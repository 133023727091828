import { forwardRef } from "react"
import { SlateElementRenderProps } from "../../types/slate.types"

export const TableRow = forwardRef<HTMLDivElement, SlateElementRenderProps>(
	({ element, children, ...attributes }, ref) => {
		return (
			<div {...attributes} ref={ref} className="tr">
				{children}
			</div>
		)
	}
)
