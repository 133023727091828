import { Node, Text, Editor, Element, Transforms, NodeEntry, Descendant, Range, Path } from "slate"
import "../TextFormatter.css"
import {
	ensureSelection,
	getMarksFromText,
	getNextSibling,
	getPreviousSibling,
	hasAncestorOfType,
	renderParagraphElement,
	toggleParagraph,
} from "../Utils"
import { ListParentElementType, CustomPlugin, CustomElement, BlockOptionSet } from "../types/slate.types"
import { bulletOptions } from "./BulletOptions"
import {
	renderBulletElement,
	toggleBullet,
	textHasBulletSymbols,
	LIST_TYPES,
	makeTextBullets,
	increaseBulletDepth,
	decreaseBulletDepth,
	removeFullBulletDepth,
	isAtLeftOfListItem,
	setBulletVisibility,
} from "./BulletUtils"
import isHotkey from "is-hotkey"

type BulletTypeOption = "bullet" | "number"

function makeBulletTypes(types: BulletTypeOption[]) {
	let bulletTypes: { [type in keyof typeof bulletOptions]?: BlockOptionSet } = {}
	if (types.includes("bullet")) {
		bulletTypes.format_list_bulleted = bulletOptions["format_list_bulleted"]
	}
	if (types.includes("number")) {
		bulletTypes.format_list_numbered = bulletOptions["format_list_numbered"]
	}
	return bulletTypes
}

export const withBullets: (types: BulletTypeOption[]) => CustomPlugin =
	(types = ["bullet", "number"]) =>
	(editor) => {
		const {
			normalizeNode,
			insertFragment,
			insertText,
			renderElement,
			toggleBlock,
			parentType,
			nestedFormatters,
			blockOptions,
			baseBlocks,
			customKeyPress,
		} = editor

		editor.renderElement = (props) => {
			return (
				renderBulletElement({ ...props }, !editor?.parameters?.editableRef?.current) ??
				renderElement?.(props) ??
				renderParagraphElement(props)
			)
		}

		editor.toggleBlock = (editor, format) => {
			ensureSelection(editor)
			return toggleBullet(editor, format) ?? toggleBlock?.(editor, format) ?? toggleParagraph(editor, format)
		}

		editor.parentType = [...(parentType ?? []), ...LIST_TYPES]

		editor.blockOptions = {
			...(blockOptions ?? {}),
			...makeBulletTypes(types),
		}

		editor.baseBlocks = [...baseBlocks, "list-item", "bulleted-list", "numbered-list"]

		editor.nestedFormatters = nestedFormatters ?? []

		editor.normalizeNode = (entry: NodeEntry<Descendant>) => {
			var [node, path] = entry
			if (Text.isText(node)) {
				if (textHasBulletSymbols(node.text)) {
					//text bullets exist convert to bullet format
					const marks = getMarksFromText(node)
					const [baseBlockParentEntry] = Editor.nodes(editor, {
						at: path,
						match: (n) => Element.isElement(n) && (editor.baseBlocks.includes(n.type) || n.type === "tagging"),
					})
					let parentProps
					if (baseBlockParentEntry != null) {
						const parent = baseBlockParentEntry[0]
						parentProps = Element.isElement(parent) ? parent?.props : {}
					}
					const newChildren = makeTextBullets({ text: node.text, marksToApply: marks, propsToApply: parentProps })
					if (newChildren?.length > 0) {
						// should split into bullets
						let pathToChange = path
						const parent = Node.parent(editor, pathToChange)
						if (Element.isElement(parent) && parent.type === "paragraph") {
							pathToChange = pathToChange.slice(0, pathToChange.length - 1)
							// path.pop() // pop fails(?!), better to slice and set than to mutate, possibly path is a customized array type?
						}
						Editor.withoutNormalizing(editor, () => {
							Transforms.removeNodes(editor, { at: pathToChange })
							Transforms.insertNodes(editor, newChildren, { at: pathToChange })
						})
					}
					return
				}
			}

			if (Element.isElement(node) && LIST_TYPES.includes(node.type as ListParentElementType)) {
				const parent = Node.parent(editor, path)
				if (Element.isElement(parent) && LIST_TYPES.includes(parent.type as ListParentElementType)) {
					//list within list add list-item wrapping
					Transforms.wrapNodes(editor, { type: "list-item" } as CustomElement, { at: path })
					setBulletVisibility(editor, { at: path })
					return
				}
			}
			if (
				Element.isElement(node) &&
				node.type === "list-item" &&
				node.children?.every((c) => Element.isElement(c) && LIST_TYPES.includes(c.type as ListParentElementType))
			) {
				const [prevNode, prevPath] = getPreviousSibling(editor, entry) ?? []
				// check if previous node is same type
				if (
					prevNode &&
					Element.isElement(prevNode) &&
					prevNode.type === "list-item" &&
					prevNode.children?.every((c) => Element.isElement(c) && LIST_TYPES.includes(c.type as ListParentElementType))
				) {
					//consecutive list items full of nested lists, merge
					Transforms.mergeNodes(editor, {
						at: path,
					})
					return
				}
				// do first item separately to ensure normalisation runs when first item is altered
				const [nextNode, nextPath] = getNextSibling(editor, entry) ?? []
				// check if next node is same type
				if (
					nextNode &&
					Element.isElement(nextNode) &&
					nextNode.type === "list-item" &&
					nextNode.children?.every((c) => Element.isElement(c) && LIST_TYPES.includes(c.type as ListParentElementType))
				) {
					//consecutive list items full of nested lists, merge
					Transforms.mergeNodes(editor, {
						at: nextPath,
					})
					return
				}
			}
			if (
				Element.isElement(node) &&
				node.type !== "list-item" &&
				node.props != null &&
				node.props.className != null &&
				node.props.className.includes("bullet")
			) {
				// remove bullet class from non list items
				Transforms.setNodes(
					editor,
					{ props: { className: node.props.className.replace("hide-bullet", "").replace("show-bullet", "") } },
					{ at: path }
				)
			}
			if (Element.isElement(node) && LIST_TYPES.includes(node.type as ListParentElementType)) {
				const [prevNode, prevPath] = getPreviousSibling(editor, entry) ?? []
				// check if previous node is same type
				if (prevNode && Element.isElement(prevNode) && prevNode.type === node.type) {
					//consecutive lists, merge
					Transforms.mergeNodes(editor, {
						at: path,
					})
					return
				}
				const [nextNode, nextPath] = getNextSibling(editor, entry) ?? []
				// check if next node is same type
				if (nextNode && Element.isElement(nextNode) && nextNode.type === node.type) {
					//consecutive lists, merge
					Transforms.mergeNodes(editor, {
						at: nextPath,
					})
					return
				}
			}
			// if (Element.isElement(node) && LIST_TYPES.includes(node.type as ListParentElementType)) {
			// 	// Unnest lists within lists/list-items
			// 	const parent = Node.parent(editor, path)
			// 	if (Element.isElement(parent) && [...LIST_TYPES, "list-item"].includes(parent.type)) {
			// 		Transforms.unwrapNodes(editor, { at: path, mode: "highest" })
			// 		return
			// 	}
			// }
			// if (Element.isElement(node) && node.type==='paragraph') {
			// 	// Unnest paras within lists/list-items
			// 	const parent = Node.parent(editor, path)
			// 	if (Element.isElement(parent) && [...LIST_TYPES, "list-item"].includes(parent.type)) {
			// 		Transforms.liftNodes(editor, { at: path })
			// 		return
			// 	}
			// }
			// if (
			// 	!Text.isText(node) &&
			// 	node?.children?.length > 1 &&
			// 	node.children?.filter((c) => Element.isElement(c) && LIST_TYPES.includes(c.type as ListParentElementType))
			// 		?.length > 1
			// ) {
			// 	// Merge any consecutive bulleted lists (separate lists will always have a paragraph element between)
			// 	const firstConsecutiveIndex = getFirstConsecutiveListIndex(node.children)
			// 	if (firstConsecutiveIndex != null) {
			// 		Transforms.mergeNodes(editor, {
			// 			at: [...path, firstConsecutiveIndex],
			// 			match: (n) => Element.isElement(n) && LIST_TYPES.includes(n.type as ListParentElementType),
			// 		})
			// 		return
			// 	}
			// }
			if (Element.isElement(node) && node.type === "list-item") {
				const parent = Node.parent(editor, path)
				if (Element.isElement(parent) && parent.type === "list-item") {
					//nested lists, unwrap inner
					Transforms.unwrapNodes(editor, { at: path, mode: "highest" })
					return
				}
				if (!Element.isElement(parent) || !LIST_TYPES.includes(parent.type as ListParentElementType)) {
					//list item has lost parent, needs removing
					Transforms.setNodes(editor, { ...node, type: "paragraph" }, { at: path })
					return
				}
			}
			if (Element.isElement(node) && node.type !== "list-item") {
				const parent = Node.parent(editor, path)
				if (Element.isElement(parent) && LIST_TYPES.includes(parent.type as ListParentElementType)) {
					//non list item is inside a list, needs to be list-item
					console.log("converting to list-item")
					Transforms.setNodes(editor, { type: "list-item" }, { at: path, mode: "highest" })
					return
				}
			}
			normalizeNode(entry)
		}

		editor.bulletFragmentInserted = false

		// editor.apply = (op) => {
		// 	console.log('op', op)
		// 	if (op.type === "set_selection") {
		// 		// remove flag if selection is changed (this implies the user is now doing something else)
		// 		console.log('setting selection')
		// 		// editor.bulletFragmentInserted = false
		// 	}
		// 	apply(op)
		// }

		editor.insertFragment = (fragment) => {
			if (editor.bulletFragmentInserted) {
				decreaseBulletDepth(editor)
				editor.bulletFragmentInserted = false
			}
			if (fragment.some((node) => Element.isElement(node) && node.type === "bulleted-list")) {
				editor.bulletFragmentInserted = true
				insertFragment(fragment)
				// const lists = [...ancestorsOfType(editor, editor.selection, "bulleted-list")]
				// if (lists.length>0) {
				// 	console.log('moving to new paragraph after inserting fragment')
				// 	const path = lists[lists.length-1][1]
				// 	path[path.length-1] = path[path.length-1]+1
				// 	Editor.withoutNormalizing(editor, () => {
				// 		// decreaseBulletDepth(editor)
				// 	Transforms.insertNodes(editor, { type: "paragraph", children: [{ text: "" }] }, {at: path, select: true})
				// 	// logTypeTree(editor)
				// 	// console.log(JSON.stringify(editor.selection))
				// 	})
				// }
				setBulletVisibility(editor, { at: [] })
				return
			}
			insertFragment(fragment)
		}

		editor.insertText = (text) => {
			if (editor.bulletFragmentInserted) {
				decreaseBulletDepth(editor)
				editor.bulletFragmentInserted = false
			}
			if (textHasBulletSymbols(text)) {
				const newText = makeTextBullets({ text, includeEmptyStrings: true }) // due to normalisation need to include empty strings in conversion
				if (
					editor.selection != null &&
					editor.getFragment().some((node) => Element.isElement(node) && node.type === "bulleted-list")
				) {
					decreaseBulletDepth(editor)
				}
				editor.insertFragment([...newText])
				return
			}
			insertText(text)
		}

		editor.customKeyPress = (event) => {
			if (isHotkey("Tab", event) && hasAncestorOfType(editor, editor.selection, "bulleted-list")) {
				event.preventDefault()
				increaseBulletDepth(editor, "bulleted-list")
				return true
			}

			if (isHotkey("Tab", event) && hasAncestorOfType(editor, editor.selection, "numbered-list")) {
				event.preventDefault()
				increaseBulletDepth(editor, "numbered-list")
				return true
			}
			if (isHotkey("shift+Tab", event) && hasAncestorOfType(editor, editor.selection, "list-item")) {
				event.preventDefault()
				decreaseBulletDepth(editor)
				return true
			}
			if (
				Range.isCollapsed(editor.selection) &&
				isHotkey("BackSpace", event) &&
				hasAncestorOfType(editor, editor.selection, "list-item")
			) {
				const [listParent, listParentPath] =
					Editor.above(editor, { match: (n) => Element.isElement(n) && n.type === "list-item", mode: "lowest" }) ?? []
				if (listParent && (Node.string(listParent).length === 0 || isAtLeftOfListItem(editor, listParentPath))) {
					// empty list item, or on left edge of list item, unindent
					event.preventDefault()
					decreaseBulletDepth(editor)
					return true
				}
			}
			if (
				Range.isExpanded(editor.selection) &&
				isHotkey("BackSpace", event) &&
				hasAncestorOfType(editor, editor.selection, "list-item")
			) {
				Transforms.select(editor, Editor.unhangRange(editor, editor.selection))
				Transforms.delete(editor)
			}
			if (
				Range.isCollapsed(editor.selection) &&
				isHotkey("Enter", event) &&
				hasAncestorOfType(editor, editor.selection, "list-item")
			) {
				const [listParent, listParentPath] =
					Editor.above(editor, { match: (n) => Element.isElement(n) && n.type === "list-item" }) ?? []
				// if listParentParent is null then listParent is the top level list-item and enter should remove an empty bullet
				if (listParent && Node.string(listParent).length === 0) {
					event.preventDefault()
					removeFullBulletDepth(editor)
					return true
				}
			}
			if (
				Range.isCollapsed(editor.selection) &&
				isHotkey("shift+Enter", event) &&
				hasAncestorOfType(editor, editor.selection, "list-item")
			) {
				event.preventDefault()
				Editor.withoutNormalizing(editor, () => {
					Transforms.insertNodes(editor, [
						{ type: "list-item", props: { className: "hide-bullet" }, children: [{ text: "" }] },
					])
				})
				return true
			}
			return customKeyPress?.(event)
		}

		return editor
	}
