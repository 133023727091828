import "../EditBox.css"
import { useState, useRef, useMemo, useEffect } from "react"
import { EditFormattedTextProps, useDisablePdfWhileSelecting, ifBlurringParent } from "../Utils"
import { MARGIN_INFO, getMargins, useWindowDimensions } from "libs"
import { useAnonCVContext } from "../../CVViewing/CVContexts/AnonCVContext"
import { DEFAULT_PLUGINS } from "../../TextFormatting/TextFormattingConstants"
import TextFormatter from "../../TextFormatting/TextFormatter"
import { AraTooltip } from "../../AraTooltip/AraTooltip"
import { useTaggingContext } from "../../TextFormatting/TaggingFormatting"
import { useChapterContext } from "../../CVViewing/CVContexts/ChapterContext"
import { DEFAULT_MAXTABLE_WIDTH } from "../../TextFormatting/TableFormatting"

export const EditFormattedText = ({
	sizeToEdit = false,
	text,
	onChange,
	sectionTitle,
	editable,
	editingInit = false,
	style,
	editStyle,
	maxWidth,
	textPreprocessing = (a) => a,
	resizeWidth = true,
	tooltipProps,
	useCase,
	reduceUseCase = [],
	narrowEditingWindow = false,
	placeholder = null,
	...props
}: EditFormattedTextProps) => {
	let [editing, setEditingBase] = useState(editingInit)
	const [narrowText, setNarrowText] = useState(false)
	const { autofillRows } = useTaggingContext()
	const editingChangeable = useRef(true)
	const selectionPdfDisableRef = useDisablePdfWhileSelecting("embedded-pdf", "embedded-pdf-container")
	const { defaultColors, defaultSizes, defaultLineSpacings } = useAnonCVContext()
	const { chapter } = useChapterContext()
	const { width, height } = useWindowDimensions({ includeWidth: true, includeHeight: true })
	function setEditing(val: boolean, timeout?: number) {
		if (editingChangeable.current) {
			setEditingBase(val)
			if (timeout != null) {
				editingChangeable.current = false
				setTimeout(() => {
					editingChangeable.current = true
				}, timeout)
			}
		}
	}

	let adjustedMaxWidth = maxWidth
	if (chapter != null) {
		// if chapter available the CV is in the editor/templating and the max width needs to be adjusted to the margins
		const margins = getMargins(chapter.margins, chapter.headerInfo, true)
		adjustedMaxWidth =
			(maxWidth ?? DEFAULT_MAXTABLE_WIDTH) +
			MARGIN_INFO.marginLeft.default() +
			MARGIN_INFO.marginRight.default() -
			margins?.marginLeft -
			margins?.marginRight
	}

	// allow text to be updated externally to the editor:
	// Keep the key up to date with incoming text, except during editing (to allow slate editor to treat it as unconttrolled)
	const startEditingText = useMemo(() => text, [editing]) //only updates when editing changes so fixed while editing is taking place
	const key =
		(editing ? startEditingText : text) + (autofillRows?.toString() ?? "") + (adjustedMaxWidth?.toString() ?? "")

	function onClickOut(e: React.FocusEvent<HTMLDivElement>) {
		setEditing(false)
	}

	function onClickIn(e: React.MouseEvent<HTMLDivElement>) {
		// console.log('click in')
		e.stopPropagation()
		if (editable) {
			// e.preventDefault() // Default sometimes causes immediate click out
			setEditing(true) //small timeout stops clickout firing immediately after due to onBlur after changing tabs
		} else {
			e.preventDefault()
		}
	}

	function onFocus(e: React.FocusEvent<HTMLDivElement>) {
		if (!editing) {
			e.target.blur()
		}
	}

	useEffect(() => {
		if (!editable && editing) {
			setEditing(false)
		}
	}, [editable, editing])

	const [checkNarrowElement, setCheckNarrowElement] = useState<HTMLDivElement | undefined>() //useClickOutside(onClickOut)
	//const textRef = useSize(sizeToEdit && editRef, { width: 100, height: 30, ...style }, resizeWidth)
	useEffect(() => {
		setNarrowText(checkNarrowElement?.getBoundingClientRect().width < 740)
	}, [checkNarrowElement, width])

	useEffect(() => {
		// catches the case where changing the window size leaves the element blurred but still editing
		setEditing(false)
	}, [width, height])

	let [currentStyle, extraClassNames] = useMemo(() => {
		const currentStyle = editing ? { ...editStyle } : { ...style }
		let extraClassNames = ""

		if (
			(props?.options ?? DEFAULT_PLUGINS)?.includes("color") &&
			defaultColors != null &&
			useCase in defaultColors &&
			!reduceUseCase.includes("color")
		) {
			currentStyle.color = defaultColors?.[useCase]
			extraClassNames += ` default-font-color-${defaultColors?.[useCase]}`
		}

		if (
			(props?.options ?? DEFAULT_PLUGINS)?.includes("size") &&
			defaultSizes != null &&
			useCase in defaultSizes &&
			!reduceUseCase.includes("size")
		) {
			currentStyle.fontSize = `${defaultSizes?.[useCase]}pt`
			extraClassNames += ` default-font-size-${defaultSizes?.[useCase]}`
		}
		if (
			(props?.options ?? DEFAULT_PLUGINS)?.includes("lineSpacing") &&
			defaultLineSpacings != null &&
			useCase in defaultLineSpacings &&
			!reduceUseCase.includes("lineSpacing")
		) {
			currentStyle["--local-line-height"] = defaultLineSpacings?.[useCase]
		}
		return [currentStyle, extraClassNames]
	}, [
		defaultColors,
		defaultLineSpacings,
		defaultSizes,
		editStyle,
		editing,
		props?.options,
		reduceUseCase,
		style,
		useCase,
	])

	if (narrowEditingWindow) {
		currentStyle = { ...currentStyle, "--format-area-padding": "0px" }
	}

	const initialMarks = useMemo(() => {
		switch (useCase) {
			case "heading":
				return { bold: true }
			case "block":
				return {}
			default:
				return {}
		}
	}, [useCase])

	const currentView = (
		<div
			tabIndex={0}
			ref={(el) => {
				selectionPdfDisableRef.current = el
				setCheckNarrowElement(el)
			}}
			onMouseDown={!editing ? onClickIn : () => {}}
			onFocus={onFocus}
			onBlur={ifBlurringParent(onClickOut, false)}
			className={`editable-text editable-formatted-text ${narrowText ? "narrow-text" : ""} ${
				editing ? "text-editor-outline" : editable ? "clickable text-editor-unselected" : "text-editor-unselected"
			} ${useCase != null ? useCase + "-text" : ""} ${
				reduceUseCase?.length > 0 ? `no-${reduceUseCase.join(" no-")}` : ""
			} ${extraClassNames}`}
			style={currentStyle}>
			<TextFormatter
				key={key}
				text={textPreprocessing(text ?? "") as string}
				onChange={onChange}
				editable={editable}
				editing={editing}
				sectionTitle={sectionTitle}
				maxTableWidth={adjustedMaxWidth}
				initialMarks={initialMarks}
				placeholder={placeholder}
				{...props}></TextFormatter>
		</div>
	)

	return tooltipProps != null ? (
		<AraTooltip {...{ ...tooltipProps, hide: !editable, highlight: tooltipProps?.highlight && !editing }}>
			{currentView}
		</AraTooltip>
	) : (
		currentView
	)
}
