import { hasAncestorOfType, useEditorContext } from "../../Utils"
import { useSlate } from "slate-react"
import { Editor, Node } from "slate"
import { useMemo, useCallback, useEffect, useState } from "react"
import { useMountEffect } from "libs"
import "../TaggingFormatter.css"
import { createPortal } from "react-dom"
import { usePopper } from "react-popper"
import { useTaggingContext } from "../TaggingContext"
import { filterTaggingDataBySearch, addTagBlock } from "../TaggingUtils"
import { TaggingDropDown } from "./TaggingDropdown"

export const PotentialTag = ({ attributes, element, children }) => {
	const editor = useSlate()
	const { potentialTag, selection, setSelection, currentTaggingData } = useTaggingContext()
	const [tagElement, setTagElement] = useState(null)
	const [dropElement, setDropElement] = useState(null)
	const { editing } = useEditorContext()
	const [taggedArray] = Editor.nodes(editor, { match: (n) => n === element })
	const leafIsCurrentlySelected = taggedArray != null
	const withinTag = leafIsCurrentlySelected && hasAncestorOfType(editor, editor.selection, "tagging") // need to avoid dropdown appearing after adding the tag
	// const textIncludesSpace = text.text.match(/\w\W/)?.length > 0
	// const selection = 0
	const showDropdown = editing && !withinTag && leafIsCurrentlySelected
	const search = Node.string(element).substring(1)
	const dropdownData = filterTaggingDataBySearch(currentTaggingData(editor), search)

	useMountEffect(() => {
		return () => {
			// set unavailable on dismount
			potentialTag.current.available = false
		}
	})

	useEffect(() => {
		setSelection(0)
	}, [search, leafIsCurrentlySelected])

	const { styles, attributes: popperAttributes } = usePopper(tagElement, dropElement, {
		placement: "bottom-start",
		strategy: "absolute",
		modifiers: [
			{
				name: "preventOverflow",
				options: {
					mainAxis: true,
				},
			},
		],
	})

	const setFormat = useCallback(
		(tagging) => {
			console.log("setting", tagging)
			const nodeType = editor.taggingData[tagging].type ?? "inline"
			addTagBlock(editor, tagging, nodeType, element)
			potentialTag.current.available = false
		},
		[editor, element, potentialTag]
	)

	useMemo(() => {
		potentialTag.current.available = leafIsCurrentlySelected
		potentialTag.current.max = dropdownData?.length - 1 ?? 99
		potentialTag.current.setFormat = () => {
			const format = dropdownData?.[selection]?.[0]
			if (format != null) {
				setFormat(format)
			}
		}
	}, [leafIsCurrentlySelected, selection, dropdownData, setFormat, potentialTag])

	return (
		<span
			tabIndex={-1}
			{...attributes}
			ref={(el) => {
				setTagElement(el)
				attributes.ref.current = el
			}}>
			{showDropdown &&
				createPortal(
					<div
						className="data-tag-dropdown-decorator"
						ref={setDropElement}
						style={styles.popper}
						{...popperAttributes.popper}>
						<TaggingDropDown search={search} setFormat={setFormat} dropdownData={dropdownData} selected={selection} />
					</div>,
					document.body
				)}
			{children}
		</span>
	)
}
