import { onError, patchUser, retrieveStickyValue, useConfigContext, useFormFields, useLoadContext } from "libs"
import { useStickyState } from "libs"
import { InputLikeGridWithLabel, RecruiterPhoto, SignUpInputField, SubmitOnBlurInputField } from "components"

import defaultSilhouette from "components/images/defaultSilhouette.png"

export function DefaultsTab({ colWidths }) {
	const { localDefaultSettings } = useConfigContext()
	const { user, company, setUser } = useLoadContext()
	const [fields, handleFieldChange, updateFields] = useFormFields({
		recruitersName:
			user.displayName || retrieveStickyValue({ ...localDefaultSettings.recruiterName, userId: user.id }) || user.name,
		recruitersCompany:
			user.displayCompanyName ||
			retrieveStickyValue({ ...localDefaultSettings.companyName, userId: user.id }) ||
			company.name,
		recruitersEmail: user.email || user.miscData?.split("#")[1] || "",
		phoneNumber: user.phone || retrieveStickyValue({ ...localDefaultSettings.recruiterPhone, userId: user.id }) || "",
		jobTitle: user.jobTitle || retrieveStickyValue({ ...localDefaultSettings.jobTitle, userId: user.id }) || "",
		linkedInUrl: user.linkedInUrl || "",
	})
	const [recruiterPhoto, setRecruiterPhoto] = useStickyState({
		...localDefaultSettings.recruiterPhoto,
		initState: defaultSilhouette,
	})
	async function handleSubmitSecondForm() {
		try {
			await patchUser({
				userId: user.id,
				email: user.email,
				clientId: company.id,
				displayName: fields.recruitersName,
				displayCompanyName: fields.recruitersCompany,
				phone: fields.phoneNumber,
				jobTitle: fields.jobTitle,
				linkedInUrl: fields.linkedInUrl,
			})
			setUser({
				...user,
				phone: fields.phoneNumber,
				jobTitle: fields.jobTitle,
				linkedInUrl: fields.linkedInUrl,
				displayName: fields.recruitersName,
				displayCompanyName: fields.recruitersCompany,
			})
		} catch (e) {
			onError(e)
		}
	}

	return (
		<div className="defaults-tab">
			<div className="sign-up-form">
				<label className="sign-up-form-title" htmlFor="defaults-tab-form">
					Defaults values for CV Display:{" "}
				</label>
				<form id="defaults-tab-form">
					<SignUpInputField
						key={"recruitersEmail"}
						label={"Recruiter's Email:"}
						type={"email"}
						placeholder={"Recruiter's Email"}
						initialValue={fields.recruitersEmail}
						inputFieldHandler={handleFieldChange}
						fieldName={"recruitersEmail"}
						disabled={true}
						labelPosition="inline"
						size="medium"
					/>
					<SubmitOnBlurInputField
						key={"recruitersName"}
						label={"Recruiter's Name:"}
						type={"name"}
						placeholder={"Recruiter's Name"}
						initialValue={fields.recruitersName}
						inputFieldHandler={handleFieldChange}
						fieldName={"recruitersName"}
						labelPosition="inline"
						size="medium"
						onBlur={() => handleSubmitSecondForm()}
					/>
					<SubmitOnBlurInputField
						key={"recruitersCompany"}
						label={"Recruiter's Company:"}
						type={"name"}
						placeholder={"Recruiter's Company"}
						initialValue={fields.recruitersCompany}
						inputFieldHandler={handleFieldChange}
						fieldName={"recruitersCompany"}
						labelPosition="inline"
						size="medium"
						onBlur={() => handleSubmitSecondForm()}
					/>

					<SubmitOnBlurInputField
						key={"phoneNumber"}
						label={"Phone Number:"}
						type={"tel"}
						placeholder={"Phone Number"}
						initialValue={fields.phoneNumber}
						inputFieldHandler={handleFieldChange}
						fieldName={"phoneNumber"}
						labelPosition="inline"
						size="medium"
						onBlur={() => handleSubmitSecondForm()}
					/>
					<SubmitOnBlurInputField
						key={"linkedInUrl"}
						label={"LinkedIn URL:"}
						type={"text"}
						placeholder={"LinkedIn URL"}
						initialValue={fields.linkedInUrl}
						inputFieldHandler={handleFieldChange}
						fieldName={"linkedInUrl"}
						labelPosition="inline"
						size="medium"
						onBlur={() => handleSubmitSecondForm()}
					/>
					<SubmitOnBlurInputField
						key={"jobTitle"}
						label={"Job Title:"}
						type={"text"}
						placeholder={"Job Title"}
						initialValue={fields.jobTitle}
						inputFieldHandler={handleFieldChange}
						fieldName={"jobTitle"}
						labelPosition="inline"
						size="medium"
						onBlur={() => handleSubmitSecondForm()}
					/>
					<InputLikeGridWithLabel label={"Display Photo:"} labelFor="recruiter-photo">
						<RecruiterPhoto recruiterPhoto={recruiterPhoto} setRecruiterPhoto={setRecruiterPhoto} />
					</InputLikeGridWithLabel>
				</form>
			</div>
		</div>
	)
}
