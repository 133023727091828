import { Layout } from "components"
import { navbarLink, useLocationByPath, useMountEffect } from "libs"
import { useState } from "react"
import { clientApi } from "libs"
import { ClientProps } from "Utils/client.types"
import { LoadingSpinner } from "components"
import { AccountPage } from "../Account/AccountPage"
import { TeamPage } from "../TeamPage/TeamPage"
import { OptionsPage } from "../Options/OptionsPage"
import { SuperUserLoginPage } from "../SuperUserLogin/SuperUserLogin"
import { VolumePage } from "../Volume/Volume"
import { IntegrationsPage } from "../Integrations/IntegrationsPage"
import { TagsPage } from "../TagsPage/TagsPage"
import { CurrentLink } from "libs/src/admin/adminLib.types"
import { AnalyticsPage } from "../Analytics/Analytics"
import { DeletePage } from "../DeletePage/DeletePage"
import { TemplatesPage } from "../TemplatesPage/TemplatesPage"

export function ClientDetailPage(): JSX.Element {
	const { clientId, currentLink } = useLocationByPath()
	const [loading, setLoading] = useState<boolean>(true)
	const [data, setData] = useState<ClientProps>()

	const query = {
		attributes: [
			"userlist",
			"stats",
			"paymentType",
			"credits",
			"billingDate",
			"freeTrialEnd",
			"options",
			"outputData",
			"apiKey",
			"secret",
			"externalSecrets",
			"externalId",
			"subdomain",
		],
	}

	useMountEffect((mounted) => {
		const fetchClients = async () => {
			setLoading(true)
			const response = await clientApi.get({ clientId, query })
			if (mounted.current) {
				setData(response as ClientProps)
				setLoading(false)
			}
		}
		fetchClients()
	})

	return (
		<>
			{loading && <LoadingSpinner isLoading={loading} />}
			{!loading && data && (
				<Layout navbarLink={navbarLink} headerTitle={data?.sk} clientId={clientId} currentLink={currentLink}>
					<Tab currentLink={currentLink} clientId={clientId} data={data} setData={setData} />
				</Layout>
			)}
		</>
	)
}

const Tab = ({
	currentLink,
	clientId,
	data,
	setData,
}: {
	currentLink: CurrentLink
	clientId: string
	data: object
	setData?: (data: object) => void
}): JSX.Element => {
	switch (currentLink) {
		case "account":
			return <AccountPage data={data} clientId={clientId} setData={setData} />
		case "login":
			return <SuperUserLoginPage data={data} clientId={clientId} />
		case "templates":
			return <TemplatesPage clientId={clientId} data={data} setData={setData} />
		case "team":
			return <TeamPage clientId={clientId} data={data} />
		case "options":
			return <OptionsPage clientId={clientId} data={data} setData={setData} />
		case "integrations":
			return <IntegrationsPage clientId={clientId} data={data} setData={setData} />
		case "tags":
			return <TagsPage clientId={clientId} data={data} setData={setData} />
		case "volume":
			return <VolumePage clientId={clientId} data={data} />
		case "analytics":
			return <AnalyticsPage clientId={clientId} data={data} />
		case "delete":
			return <DeletePage clientId={clientId} data={data} setData={setData} />
		default:
			return <div>404</div>
	}
}
