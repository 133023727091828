export const DEFAULT_CURRENT_TEXT = "Present"

export const DEFAULT_AVAILABLE_CUSTOM_TAGS = 5

export let COPY_AVAILABLE = false
navigator.permissions
	// @ts-ignore: navigator permissions not yet in typescript
	?.query({ name: "clipboard-write" })
	.then((result) => {
		if (result.state === "granted") {
			COPY_AVAILABLE = true
		}
	})
	.catch(() => {
		console.log("Get clipboard write permission failed")
	})

export const SUPPORTED_LANGUAGES = [
	"Arabic",
	"English (British)",
	"English (American)",
	"Chinese (Simplified)",
	"Chinese (Traditional)",
	"Czech",
	"Danish",
	"Dutch",
	"French",
	"German",
	"Italian",
	"Malay",
	"Norwegian (Nynorsk)",
	"Norwegian (Bokmål)",
	"Polish",
	"Portuguese",
	"Russian",
	"Slovak",
	"Spanish",
	"Swedish",
]

export const DEFAULT_PROPS_FOR_LANGUAGE = {
	Arabic: { style: { direction: "rtl", textAlign: "right" } },
}
