import type { Version, Feature, ToggleName, ToggleMapping, FeatureInfo } from "libs"
import type { HFFeature, CurrentVersion } from "./TemplateVersioning.types"

export const LATEST_TEMPLATE_VERSION: CurrentVersion = "5"

export const LABEL_MAPPINGS: { [version in Version]: Feature[] } = {
	// initial templates
	"0": ["recruiterSummary", "candidateInfo", "profileSummary", "experienceEducation", "freeText"],
	// adds original cv
	"1": ["recruiterSummary", "candidateInfo", "profileSummary", "experienceEducation", "originalCV", "freeText"],
	// adds headers and footers
	"2": ["recruiterSummary", "candidateInfo", "profileSummary", "experienceEducation", "originalCV", "freeText"],
	// adds skills && interests
	"3": [
		"recruiterSummary",
		"candidateInfo",
		"profileSummary",
		"experienceEducation",
		"originalCV",
		"skills",
		"interests",
		"freeText",
	],
	// multiple cover letters
	"4": [
		"recruiterSummary",
		"candidateInfo",
		"profileSummary",
		"experienceEducation",
		"originalCV",
		"skills",
		"interests",
		"freeText",
	],
	"5": [
		"recruiterSummary",
		"candidateInfo",
		"profileSummary",
		"experienceEducation",
		"originalCV",
		"skills",
		"interests",
		"freeText",
		"experience",
		"education",
		"requirementsTable",
		"candidateHeadshot",
	],
}

export const TOGGLE_MAPPING: ToggleName[] = [
	"includeRecruiterSummary",
	"includeCandidateInfo",
	"includeProfileSummary",
	"includeExperiences",
	"includeOriginalCV",
	"includeSkills",
	"includeInterests",
]

export const TOGGLE_SHOW_LABEL_MAPPING: Partial<ToggleMapping> = {
	includeName: { label: "Name", showKey: "candidateInfo" },
	includeContactDetails: { label: "Contact Details", showKey: "candidateInfo" },
}

export const TOGGLE_PERMANENT_LABEL_MAPPING: Partial<ToggleMapping> = {
	// includeLogo: { label: 'Logo' },
	// includeCoverLetter: { label: "Cover Letter" },
	includeHeader: { label: "Header" },
	includeFooter: { label: "Footer" },
}

export const COVER_LETTER_TOGGLE_MAPPING: Partial<ToggleMapping> = {
	includeCoverLetter: { label: "Cover Letter" },
	includeCoverLetterHeader: { label: "Header" },
	includeCoverLetterFooter: { label: "Footer" },
}

export const VISIBLE_TOGGLES: ToggleName[] = [
	...Object.keys(TOGGLE_PERMANENT_LABEL_MAPPING),
	...Object.keys(TOGGLE_SHOW_LABEL_MAPPING),
] as ToggleName[]

export const HEADER_FOOTER_FEATURES: { [feature in HFFeature]: { label: string; fullWidth?: boolean } } = {
	logo: {
		label: "Logo",
	},
	headerFooterText: {
		label: "Header/Footer Text",
	},
	fullWidthImage: {
		label: "Full Width Image",
		fullWidth: true,
	},
}

export const DEFAULT_DOCUMENT_INFO = {
	chapters: [{ type: "cv", header: {}, footer: {} }],
}

export const MAIN_FEATURES: Partial<FeatureInfo> = {
	experienceEducation: {
		label: "Experience/Education",
	},
	experience: {
		label: "Experience",
	},
	education: {
		label: "Education",
	},
	originalCV: {
		label: "Original CV",
	},
	recruiterSummary: {
		label: "Recruiter Summary",
	},
	freeText: {
		label: "Free text",
	},
	candidateHeadshot: {
		label: "Candidate Headshot",
	},
}

export let ADVANCED_FEATURES: Partial<FeatureInfo> = {
	requirementsTable: {
		label: "Requirements Table",
	},
}
if (process.env.REACT_APP_IS_TEST === "true" || process.env.REACT_APP_IS_DEV === "true") {
	ADVANCED_FEATURES.summaryTimeline = {
		label: "Summary Timeline",
	}
}

export const UNIQUE_FEATURES: (Feature | HFFeature)[] = [
	"candidateInfo",
	"profileSummary",
	"recruiterSummary",
	"originalCV",
	"skills",
	"interests",
	"summaryTimeline",
	"candidateHeadshot",
]

export const ORDERABLE_CHAPTER_TYPES = ["cv"]
