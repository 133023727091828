import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { useState, useCallback, useRef, createContext, useContext, memo, ReactNode, useMemo } from "react"
import { useSlate } from "slate-react"
import { createPortal } from "react-dom"
import "./TextFormatter.css"
import {
	isBlockActive,
	isMarkActive,
	getMarkValue,
	doesBlockExist,
	useEditorContext,
	toggleMark,
	getSelectedText,
} from "./Utils"
import { isPropertyActive } from "./PropertyFormatting/PropertyUtils"
import { HDropDown, HDropTitle, HDropItem } from "../HorzDropdown/HorzDropdown"
import "./Toolbar.css"
import { CustomTag, isObjEqual, useFeaturesContext, useMountEffect, isMacOS } from "libs"

import {
	BlockOptionSet,
	ButtonType,
	CustomMarkOptionSet,
	MarkOptionSet,
	OptionSet,
	PropOptionSet,
	AIOptionSet,
	OptionsProps,
	CustomBlockOptionSet,
} from "./types/slate.types"
import { AraTooltip } from "../AraTooltip/AraTooltip"
import { useAppContext } from "libs"
import { StarIcon, AIAssistIcon } from "../Icons"
import { useAnonCVContext } from "../CVViewing/CVContexts/AnonCVContext"

interface ToolbarContextProps {
	toolbarSpaceElement: HTMLDivElement
	setToolbarSpaceElement: (element: HTMLDivElement) => void
	toolbarVisible: boolean
	setToolbarVisible: (visible: boolean) => void
	shouldAnimate: boolean
}

const ToolbarContext = createContext<ToolbarContextProps>({} as ToolbarContextProps)
const useToolbarContext = () => useContext(ToolbarContext)

export const ToolbarContextProvider = ({ shouldAnimate = true, children }) => {
	const [toolbarSpaceElement, setToolbarSpaceElement] = useState<HTMLDivElement>()
	const [toolbarVisible, setToolbarVisible] = useState<boolean>()
	return (
		<ToolbarContext.Provider
			value={{ shouldAnimate, toolbarSpaceElement, setToolbarSpaceElement, toolbarVisible, setToolbarVisible }}>
			{children}
		</ToolbarContext.Provider>
	)
}

export const ToolbarHoveringSpace = memo(({ children }: { children?: ReactNode }) => {
	const { setToolbarSpaceElement, toolbarVisible } = useToolbarContext()
	const minWidth = "100%"
	return (
		<Col className="toolbar-hovering-col toolbar-col" style={{ minWidth, pointerEvents: "none" }} xs={12}>
			<Row
				id="toolbar-space"
				onMouseDown={(e) => {
					if (toolbarVisible) {
						e.preventDefault()
						e.stopPropagation()
					}
				}}
				ref={setToolbarSpaceElement}>
				{children}
			</Row>
		</Col>
	)
})

export const ToolbarFillingSpace = memo(({ children }: { children?: ReactNode }) => {
	const { setToolbarSpaceElement, toolbarVisible } = useToolbarContext()
	const minWidth = "100%"
	return (
		<div className="toolbar-filling-col toolbar-col" style={{ minWidth, pointerEvents: "none" }}>
			<div
				id="toolbar-space"
				onMouseDown={(e) => {
					if (toolbarVisible) {
						e.preventDefault()
						e.stopPropagation()
					}
				}}
				ref={setToolbarSpaceElement}>
				{children}
			</div>
		</div>
	)
})

interface AllOptions {
	[key: string]: OptionSet
}

interface AiOptions {
	[key: string]: AIOptionSet
}

export const Toolbar = memo(
	({
		allOptions,
		message,
		allAiOptions,
	}: {
		allOptions: AllOptions
		message: ReactNode
		allAiOptions: AiOptions
	}): React.ReactPortal => {
		const { toolbarSpaceElement, setToolbarVisible, shouldAnimate } = useToolbarContext()
		const { permissions } = useFeaturesContext()
		const { templateMode } = useAnonCVContext()
		const showAiOptions = permissions.textFormatterAiOptions ?? true
		const hasAnimated = useRef(!shouldAnimate)

		useMountEffect(() => {
			setToolbarVisible(true)
			return () => {
				// check if another toolbar has been added before setting to false
				const toolbarElement = document.getElementById("added-toolbar")
				const toolbarElementExists = toolbarElement != null
				setToolbarVisible(toolbarElementExists)
			}
		})

		return createPortal(
			<div
				id="added-toolbar"
				className="toolbar-row slate-no-edit"
				contentEditable={false}
				style={{
					//Force the toolbar to be on the same line as the text
					display: "flex",
					flexDirection: "row",
					justifyContent: "start",
					gap: "5%",
				}}>
				<div
					onAnimationEnd={() => {
						hasAnimated.current = true
					}}
					className={`${hasAnimated.current ? "" : "toolbarSlideAnimation"} formatting-toolbar`}
					style={{ paddingLeft: 1 }}>
					{Object.entries(allOptions).map(([key, props]) => (
						<OptionButton key={key} {...props} />
					))}

					{/* use empty button to space with border */}
					{message != null && <div className="toolbar-message">{message}</div>}
				</div>
				{/* <div className="toolbar-button" style={{ height: 35, width: 10 }}></div> */}
				<div className="formatting-toolbar-right" style={showAiOptions ? {} : { display: "none" }}>
					{!templateMode && allAiOptions != null && (
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
								gap: "5px",
							}}>
							<div
								style={{
									margin: "auto",
								}}>
								<StarIcon />
							</div>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "center",
								}}>
								<AIAssistIcon />
							</div>
							{Object.entries(allAiOptions).map(([key, props]: [string, AIOptionSet]) => (
								<AIDropdown key={key} {...props} />
							))}
						</div>
					)}
				</div>
			</div>,
			toolbarSpaceElement
		)
	},
	(oldProps, newProps) => isObjEqual(oldProps, newProps)
)

const cmdKey = isMacOS() ? "Cmd" : "Ctrl"
const modKey = isMacOS() ? "⌘" : "^"

const BasicButton = ({
	onMouseDown,
	active,
	children,
	ariaLabel,
	tooltipLabel,
}: {
	onMouseDown: () => void
	active: boolean
	children: ReactNode
	ariaLabel?: string
	tooltipLabel?: string
}): JSX.Element => {
	return (
		<AraTooltip
			tooltipLabel={<span style={active ? { color: "white" } : {}}>{tooltipLabel}</span>}
			timeToShow={800}
			placement="bottom">
			<div
				role="button"
				aria-label={ariaLabel}
				className={`clickable toolbar-button`}
				// active={isBlockActive(editor, format)}
				onMouseDown={(event) => {
					event.preventDefault()
					event.stopPropagation()
					onMouseDown()
				}}>
				<div className={`inner-toolbar-button toolbar-button-${active ? "active" : "inactive"}`}>{children}</div>
			</div>
		</AraTooltip>
	)
}

const BlockButton = ({ format, icon, show, tooltipLabel }: BlockOptionSet): JSX.Element => {
	const editor = useSlate()
	let showFunc = isBlockActive
	if (show === "ifExists") {
		showFunc = doesBlockExist
	}
	const onMouseDown = () => {
		editor.toggleBlock(editor, format)
	}
	const active = showFunc(editor, format)
	return (
		<BasicButton
			ariaLabel={`${format} toolbar button`}
			onMouseDown={onMouseDown}
			active={active}
			tooltipLabel={tooltipLabel?.(cmdKey, modKey)}>
			{icon()}
		</BasicButton>
	)
}

const PropButton = (propSet: PropOptionSet): JSX.Element => {
	const dropRef = useRef<HTMLDivElement>()
	const { defaultRef } = useEditorContext()
	const isThisPropertyActive = isPropertyActive(propSet)
	const editor = useSlate()
	const [activeFormat, ...extraActiveFormats] = [...Object.values(propSet.options), { format: null }].filter(
		({ format }) => isThisPropertyActive(editor, format)
	)
	let multipleFormatsSelected = extraActiveFormats?.length > 0
	let computedStyle = null
	if (activeFormat == null) {
		const defaultPropertyElement = defaultRef.current
		if (defaultPropertyElement != null) {
			computedStyle = window.getComputedStyle?.(defaultPropertyElement)
		}
	}
	const activeIcon = propSet.makeIcon({ currentFormat: activeFormat, computedStyle })
	const optionsToShow = useMemo(
		() =>
			propSet.ordered
				? Object.values(propSet.options).sort((a, b) => {
						return a.format < b.format ? -1 : 1
					})
				: Object.values(propSet.options),
		[propSet.options, propSet.ordered]
	)
	return (
		<HDropDown
			ariaLabel={`${propSet.name} toolbar button`}
			placement="bottom-start"
			ref={dropRef}
			className={`formatting-toolbar toolbar-full-border ${
				propSet.scrollDirection === "vertical" ? "vertical-toolbar" : ""
			}`}>
			<HDropTitle>
				<div className={`clickable prop-button toolbar-button toolbar-button-left-border`}>
					<div className={`inner-toolbar-button`}>{activeIcon}</div>
				</div>
			</HDropTitle>
			<div
				data-testid={`toolbar-${propSet.name}`}
				className={`${propSet.scrollDirection === "vertical" ? "vertical-prop-dropdown vertical-dropdown" : ""} ${
					propSet.dropdownType ?? "icon"
				}-dropdown`}>
				{optionsToShow.map(({ format, icon }) => {
					const isActive = activeFormat?.format === format && !multipleFormatsSelected ? "active" : "inactive"
					return (
						<HDropItem
							key={`${format}-${isActive}`}
							onClick={(event) => {
								editor.toggleBlock(editor, format)
							}}>
							<div className={`clickable toolbar-dropdown-item toolbar-button toolbar-button-${isActive}`}>
								<div className={`inner-toolbar-button`}>{icon}</div>
							</div>
						</HDropItem>
					)
				})}
			</div>
		</HDropDown>
	)
}

const MarkButton = ({ format, icon, tooltipLabel }: MarkOptionSet) => {
	const editor = useSlate()
	const active = isMarkActive(editor, format)
	const onMouseDown = () => {
		toggleMark(editor, format)
	}
	return (
		<BasicButton onMouseDown={onMouseDown} active={active} tooltipLabel={tooltipLabel?.(cmdKey, modKey)}>
			{icon()}
		</BasicButton>
	)
}

const CustomMarkButton = ({
	format,
	makeIcon,
	getDefault,
	makeCustomDropdown,
	closeDropdownOnClick,
}: CustomMarkOptionSet) => {
	const editor = useSlate()
	const { defaultRef: refToEditor } = useEditorContext()
	const defaultValue = getDefault?.(refToEditor?.current)
	const currentValue = getMarkValue(editor, format) ?? defaultValue
	const setFormat = useCallback(
		(formatValue) => {
			toggleMark(editor, `${format}@${formatValue}`)
		},
		[editor, format]
	)
	// console.log("format", format)
	return (
		<HDropDown
			ariaLabel={`${format} toolbar button`}
			role="button"
			placement="bottom-start"
			closeOnClick={closeDropdownOnClick}>
			<HDropTitle>
				<div className={`custom-mark clickable toolbar-button toolbar-button-left-border `}>
					<div className={`inner-toolbar-button`}>{makeIcon({ currentValue, setFormat })}</div>
				</div>
			</HDropTitle>
			<HDropItem
				data-testid={`toolbar-${format}`}
				onClick={(e) => {
					e.stopPropagation()
					e.preventDefault()
				}}>
				{makeCustomDropdown({ currentValue, setFormat })}
			</HDropItem>
		</HDropDown>
	)
}

const CustomBlockButton = ({ format, makeIcon, makeCustomDropdown, available, name }: CustomBlockOptionSet) => {
	const editor = useSlate()
	let activeFormat = null
	const formatIsArray = Array.isArray(format)

	//if format is an array, check if any of the formats are active
	if (formatIsArray) {
		const activeFormats = format.filter((f) => isBlockActive(editor, f))
		if (activeFormats.length > 0) {
			activeFormat = activeFormats[0]
		}
	} else {
		activeFormat = isBlockActive(editor, format) ? format : null
	}

	// )
	return (
		<HDropDown
			ariaLabel={`${formatIsArray ? name : format} toolbar button`}
			placement="bottom-start"
			role="button"
			//hide dropdown if not available
			className={available(editor) ? `formatting-toolbar toolbar-full-border icon-and-text-dropdown` : ""}>
			<HDropTitle>
				<div className={`custom-mark clickable toolbar-button toolbar-button-left-border `}>
					<div className={`inner-toolbar-button`}>{makeIcon({ activeFormat })}</div>
				</div>
			</HDropTitle>

			<div
				data-testid={`toolbar-${formatIsArray ? name : format}`}
				className={"vertical-prop-dropdown vertical-dropdown"}>
				{makeCustomDropdown({ currentValue: activeFormat })}
			</div>
		</HDropDown>
	)
}

function makeAIOptionsFromCustomTags(customTags: { [tagName: string]: CustomTag }): OptionsProps[] {
	return Object.entries(customTags).map(([key, { displayName }]) => {
		return {
			key,
			label: displayName,
		}
	})
}

function filterTagsByScope(
	tags: {
		[tagName: `custom-${string}`]: CustomTag
	},
	scope: string,
	defaultInclude?: boolean
): {
	[tagName: `custom-${string}`]: CustomTag
} {
	return Object.entries(tags).reduce((acc, [key, tag]) => {
		if (tag?.scope?.[scope] ?? defaultInclude) {
			acc[key] = tag
		}
		return acc
	}, {})
}

const AIDropdown = ({
	makeIcon,
	makeCustomDropdown,
	closeDropdownOnClick,
	disabledTooltipLabel,
	enabled,
}: AIOptionSet) => {
	const editor = useSlate()
	const { company } = useAppContext()
	const { permissions } = useFeaturesContext()
	const showAiOptions = permissions.textFormatterAiOptions ?? true
	const clientAiOptions = company?.options?.aiOptions ?? {}
	const extraAiOptions = makeAIOptionsFromCustomTags(
		filterTagsByScope(company?.options?.customTags?.tags ?? {}, "templates", true)
	)
	const currentValue: string = getSelectedText(editor)
	const isEnabled = enabled(currentValue)

	const setFormat = (key: string) => {
		editor.setShowAiPortal({ type: key })
	}
	return (
		<>
			{showAiOptions && (
				<AraTooltip tooltipLabel={disabledTooltipLabel} placement="bottom" hide={isEnabled} timeToShow={50}>
					<HDropDown placement="bottom-start" closeOnClick={closeDropdownOnClick}>
						<HDropTitle>
							<div className={`${isEnabled ? "clickable" : "disabled-text-transform"} `}>
								{makeIcon({ currentValue })}
							</div>
						</HDropTitle>
						{isEnabled && (
							<>
								<HDropItem
									onClick={(e) => {
										e.stopPropagation()
										e.preventDefault()
									}}>
									{makeCustomDropdown({ currentValue, setFormat, clientAiOptions, extraOptions: extraAiOptions })}
								</HDropItem>
							</>
						)}
					</HDropDown>
				</AraTooltip>
			)}
		</>
	)
}

const OptionButton = (props: OptionSet): JSX.Element => {
	const button = props.button
	if (button === ButtonType.mark) {
		return <MarkButton {...props}></MarkButton>
	}
	if (button === ButtonType.block) {
		return <BlockButton {...props}></BlockButton>
	}
	if (button === ButtonType.prop) {
		return <PropButton {...props}></PropButton>
	}
	if (button === ButtonType.customMark) {
		return <CustomMarkButton {...props}></CustomMarkButton>
	}
	if (button === ButtonType.customBlock) {
		return <CustomBlockButton {...props}></CustomBlockButton>
	}
	if (button === ButtonType.custom) {
		return props.makeButton()
	}
	//default to mark
	return <MarkButton {...(props as MarkOptionSet)}></MarkButton>
}
