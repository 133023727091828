import { useEffect, useState } from "react"
import { SignUpSelectFieldProps } from "./SignUpSelectField"
import { Dropdown } from "react-bootstrap"

export interface SubmitOnSelectSelectFieldProps extends SignUpSelectFieldProps {
	handleSubmitOnSelect: (option: String) => Promise<void>
	field: string
}

export function SubmitOnSelectSelectField({ handleSubmitOnSelect, ...props }: SubmitOnSelectSelectFieldProps) {
	const {
		fieldName,
		updateField,
		options,
		label,
		required,
		placeholder,
		field,
		selectedItemStyle,
		size = "full-width",
		labelPosition = "top",
	} = props

	const [show, setShow] = useState(false)
	const [selectedOption, setSelectedOption] = useState({ changed: false, option: placeholder })
	const allowToggle = ["select", "click", "rootClose", "keydown"]

	const handleSelect = (option: string, optionLabel: string | undefined = undefined) => {
		if (optionLabel) {
			setSelectedOption({ changed: true, option: optionLabel })
		} else {
			setSelectedOption({ changed: true, option: option })
		}
		updateField({ [fieldName]: option })
	}
	useEffect(() => {
		if (selectedOption.changed) {
			handleSubmitOnSelect(fieldName)
		}
	}, [selectedOption])

	return (
		<div className={`sign-up-select sign-up-select-label-position-${labelPosition}`}>
			<label>
				{label} {required && <span style={{ color: "var(--ara-red" }}>{" *"}</span>}
			</label>
			<Dropdown
				className={`sign-up-select-${size}`}
				show={show}
				onToggle={(shouldShow: boolean, metaData: { originalEvent; source }) => {
					const e = metaData.originalEvent
					if (allowToggle.includes(metaData.source)) {
						setShow(shouldShow)
					} else {
						console.log(metaData.source)
						if (e.key === " ") {
							console.log("space")
							e.preventDefault()
						}
					}
				}}>
				<Dropdown.Toggle className="sign-up-select-toggle">
					<span style={selectedItemStyle}>{selectedOption.option}</span>
				</Dropdown.Toggle>
				<Dropdown.Menu className="sign-up-select-menu">
					{options.map((option) => {
						if (option.text) {
							return (
								<Dropdown.Item onClick={() => handleSelect(option.key, option.label)} key={option.key}>
									{option.text}
								</Dropdown.Item>
							)
						} else {
							return (
								<Dropdown.Item onClick={() => handleSelect(option)} key={option}>
									{option}
								</Dropdown.Item>
							)
						}
					})}
				</Dropdown.Menu>
			</Dropdown>
		</div>
	)
}
