import { getBase64, bytesToMegabytes, useConfigContext } from "libs"
import defaultSilhouette from "../../images/defaultSilhouette.png"
import "./RecruiterPhoto.css"

export function RecruiterPhoto({ recruiterPhoto, setRecruiterPhoto, disabled = false }) {
	const { MAX_LOGO_SIZE } = useConfigContext()

	async function handleFileChange(event) {
		const file = event.target.files[0]
		if (file.size > MAX_LOGO_SIZE) {
			// eslint-disable-next-line no-template-curly-in-string
			alert(`Photo should be no bigger than ${bytesToMegabytes(MAX_LOGO_SIZE)}MB`)
			event.target.value = ""
			return
		}
		if (file != null) {
			recruiterPhoto = await getBase64(file)
			setRecruiterPhoto(recruiterPhoto) // To try to stop add/get order problem
		}
	}

	return (
		<>
			<input type="file" id="recruiter-photo" accept="image/*" onChange={handleFileChange} disabled={disabled}></input>
			<label className={`recruiterLabel ${!disabled ? "clickable" : ""}`} htmlFor="recruiter-photo">
				<img className="recruiterPhoto" src={recruiterPhoto ?? defaultSilhouette} alt="recruiter"></img>
			</label>
		</>
	)
}
