import { BlockOptionSet, ButtonType } from "../types/slate.types"
import tableIcon from "components/images/table-icon.png"

export const tableOptions: { format_table: BlockOptionSet } = {
	format_table: {
		format: "table",
		icon: () => (
			<img
				style={{ marginBottom: 3, marginTop: 1, width: 18 }}
				className="toolbar-button-icon"
				src={tableIcon}
				alt="table"></img>
		),
		button: ButtonType.block as ButtonType.block,
		tooltipLabel: () => `Table`,
	},
}
