import { useSlate } from "slate-react"
import { isBlockActive, getSelectedText, useEditorContext } from "../../TextFormatting/Utils"
import { BlockOptionSet, ButtonType } from "../types/slate.types"
import { AraTooltip } from "../../AraTooltip/AraTooltip"
import { useRef, useEffect, useCallback } from "react"
import { debounce } from "libs"
import { canApplyHyperlink } from "./HyperlinkUtils"

export const hyperlinkOptions: { hyperlink: BlockOptionSet } = {
	hyperlink: {
		format: "hyperlink",
		button: ButtonType.block,
		icon: () => {
			return <HyperLinkIcon />
		},
	},
}

const HyperLinkIcon = () => {
	const { setToolbarPositions, toolbarPositions } = useEditorContext()
	const editor = useSlate()
	const ref = useRef<HTMLDivElement>(null)

	const someTextSelected = !!getSelectedText(editor)
	const active = isBlockActive(editor, "hyperlink")
	const disabled = !canApplyHyperlink(editor)
	const available = !disabled || active

	const handlePosition = useCallback(
		(el) => {
			const rect: DOMRect = el?.getBoundingClientRect()
			const delay = rect?.left < 0 ? 300 : 0 // if left is negative it means it has measured the position before animation, wait for animation to finish
			setTimeout(() => {
				const rect: DOMRect = el?.getBoundingClientRect()
				if (rect == null) {
					return
				}
				let { left, top } = rect
				left = Math.max(0, left)
				// prevent infinite loop
				if (toolbarPositions.hyperlink) {
					if (top === toolbarPositions.hyperlink.top && left === toolbarPositions.hyperlink.left) {
						return
					}
				}
				setToolbarPositions({
					...toolbarPositions,
					hyperlink: {
						top,
						left,
					},
				})
			}, delay)
		},
		[setToolbarPositions, toolbarPositions]
	)

	useEffect(() => {
		const setRefPosition = debounce(() => handlePosition(ref.current), 20)
		window.addEventListener("scroll", setRefPosition)
		return () => {
			window.removeEventListener("scroll", setRefPosition)
		}
	}, [handlePosition])

	return (
		<div
			ref={(el) => {
				handlePosition(el) // reset position everytime el changes
				ref.current = el // keep a reference to the element for scrolling
			}}>
			<div className={!available ? "toolbar-button-unavailable unclickable" : ""}>
				<AraTooltip
					tooltipLabel={`${
						someTextSelected ? "Please select a single section of text" : "Select text to add a hyperlink"
					}`}
					placement="bottom"
					hide={available}>
					<IconSvg />
				</AraTooltip>
			</div>
		</div>
	)
}

const IconSvg = () => {
	return (
		<div style={{ paddingTop: "5px", paddingBottom: "7px" }}>
			<svg
				aria-label="hyperlink"
				width="30"
				height="20"
				viewBox="0 0 55 44"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M7.49342 21.9999C5.6135 20.1998 4.58343 17.9288 4.58301 15.5832C4.58301 10.0465 10.2434 5.49988 17.1872 5.49988L28.6455 5.49988C35.5663 5.49988 41.2497 10.0465 41.2497 15.5832C41.2497 21.1199 35.5893 25.6665 28.6455 25.6665H22.9163"
					stroke="black"
					strokeWidth="2.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M47.5062 21.9999C49.3862 23.7999 50.4162 26.0709 50.4167 28.4165C50.4167 33.9532 44.7562 38.4999 37.8125 38.4999H26.3542C19.4333 38.4999 13.75 33.9532 13.75 28.4165C13.75 22.8799 19.4104 18.3332 26.3542 18.3332H32.0833"
					stroke="black"
					strokeWidth="2.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	)
}
