import { correctHyperlink } from "libs"
import { InlineChromiumBugfix } from "../../../TextFormatting/TaggingFormatting/TaggingUtils"
import "./hyperlink-box.css"

export const LinkComponent = ({
	attributes,
	children,
	plainTextUrl,
	state,
}: {
	attributes: any
	children: any
	element: any
	plainTextUrl: string
	state: "asUrl" | "asText" | "asInvalid"
}) => {
	const link = correctHyperlink(plainTextUrl)
	if (state === "asText" || state === "asInvalid") {
		return (
			<span {...attributes} className={`${state === "asText" ? "force-inherit-text" : "hyperlink-invalid-link-box"}`}>
				<InlineChromiumBugfix />
				{children}
				<InlineChromiumBugfix />
			</span>
		)
	}
	return (
		<a
			{...attributes}
			href={link ? link : plainTextUrl}
			className={`${state === "asUrl" ? "" : "hyperlink-invalid-link-box"}`}
			target="_blank"
			rel="noopener noreferrer">
			<InlineChromiumBugfix />
			{children}
			<InlineChromiumBugfix />
		</a>
	)
}
