import { createContext, useContext, ReactNode } from "react"
import { ToggleSet, TemplateChapter, NewTemplating, useImage } from "libs"
import { useTemplatingContext } from "../../Templating/TemplatingContext"
import { DEFAULT_TOGGLES } from "libs"
import { useAnonCVContext } from "./AnonCVContext"
import { Images } from "./CVContexts.types"

interface ChapterContextProps extends Partial<ToggleSet> {
	chapter: TemplateChapter
	addChangeToChapter: (change: Partial<TemplateChapter>) => void
	index: number
	images: Images
}
const ChapterContext = createContext<ChapterContextProps>({} as ChapterContextProps)
export const ChapterContextProvider = ({
	children,
	index,
	templating,
	updatesOn = "app",
}: {
	children: ReactNode
	index: number
	templating: NewTemplating
	updatesOn?: "template" | "app"
}) => {
	const { addChangeToApp } = useAnonCVContext()
	const { addChangeToTemplate } = useTemplatingContext()
	const { chapters } = templating
	const chapter = chapters[index]
	const images: Images = {
		header: useImage(),
		footer: useImage(),
	}

	const addChangeToChapter = (change: Partial<TemplateChapter>) => {
		const newChapter = { ...chapter, ...change }
		const newChapters = [...chapters]
		newChapters[index] = newChapter as TemplateChapter
		if (updatesOn === "template") {
			addChangeToTemplate({ chapters: newChapters })
		} else {
			addChangeToApp({ chapters: newChapters })
		}
	}
	return (
		<ChapterContext.Provider
			value={{ chapter, index, addChangeToChapter, ...DEFAULT_TOGGLES, ...chapter?.toggleSettings, images }}>
			{children}
		</ChapterContext.Provider>
	)
}
export const useChapterContext = () => useContext(ChapterContext)
