import { InstantApplyColourDropdown } from "../../../ColourPicker"
import { HDropDown, HDropItem, HDropTitle } from "../../../HorzDropdown/HorzDropdown"
import { useSlate } from "slate-react"
import { Transforms, Editor, Node, Path, NodeEntry, Element } from "slate"
import { useEditorContext } from "../../Utils"
import { tableCellContent } from "../TableUtils"
import { useTableContext } from "../TableContext"
import arrowRight from "components/images/arrow-right.png"
import { CustomEditor, TableCellElement, TableElementType } from "../../types/slate.types"

export function TableOptions({
	tableEntry,
	cellEntry,
	allowMergeDown = true,
}: {
	tableEntry: NodeEntry<TableElementType>
	cellEntry: NodeEntry<TableCellElement>
	allowMergeDown?: boolean
}) {
	const { editing } = useEditorContext()
	if (tableEntry != null) {
		return (
			editing && (
				<HDropDown
					placement={"bottom"}
					closeOnClick={true}
					style={{ width: "fit-content", margin: "0 auto auto auto" }}>
					<HDropTitle>
						<div contentEditable={false} className={`slate-no-edit table-options-button clickable`}>
							Table Options
						</div>
					</HDropTitle>
					<HDropItem className={`slate-no-edit`}>
						<TableOptionsDropdown tableEntry={tableEntry} cellEntry={cellEntry} allowMergeDown={allowMergeDown} />
					</HDropItem>
				</HDropDown>
			)
		)
	} else {
		return <></>
	}
}

function getMergeCells(
	editor: CustomEditor,
	tablePath: Path,
	mergeCellPath: Path,
	currentMergeRange: [number, number] = [0, 1]
) {
	const cells = [
		...(Editor.nodes(editor, {
			at: tablePath,
			match: (n) => Element.isElement(n) && n.type === "table-cell",
		}) as Generator<NodeEntry<TableCellElement>>),
	]
	return getNextMergeCells(editor, cells, mergeCellPath, currentMergeRange)
}

function getNextMergeCells(
	editor: CustomEditor,
	allCells: NodeEntry<TableCellElement>[],
	mergeCellPath: Path,
	currentMergeRange: [number, number] = [0, 1]
): [NodeEntry<TableCellElement>[], [number, number], Path] {
	const rowIndexAdjust = mergeCellPath.length - 2
	const maxRowNo = mergeCellPath[rowIndexAdjust + 0] + currentMergeRange[0]
	const maxColNo = mergeCellPath[rowIndexAdjust + 1] + currentMergeRange[1]
	const currentCells = allCells.filter(
		([, cellPath]) =>
			// include up to maximum that is within the current merge range
			maxRowNo >= cellPath[rowIndexAdjust + 0] &&
			maxColNo >= cellPath[rowIndexAdjust + 1] &&
			// only include from merge cell down/right
			cellPath[rowIndexAdjust + 0] >= mergeCellPath[rowIndexAdjust + 0] &&
			cellPath[rowIndexAdjust + 1] >= mergeCellPath[rowIndexAdjust + 1]
	)
	const newMergeRange: [number, number] = [...currentMergeRange]
	let newMergeCellPath = [...mergeCellPath]
	currentCells.forEach(([cell, cellPath]) => {
		// loop through all cells in current merging zone and check if previous merges need to be accounted for
		if (!cell.removed && cell.merge != null) {
			// cell within range has been merged previously, increase the range if needed to absorb this
			newMergeRange[0] = Math.max(
				cellPath[rowIndexAdjust + 0] + (cell.merge.bottom ?? 0) - mergeCellPath[rowIndexAdjust + 0],
				newMergeRange[0]
			)
			newMergeRange[1] = Math.max(
				cellPath[rowIndexAdjust + 1] + (cell.merge.right ?? 0) - mergeCellPath[rowIndexAdjust + 1],
				newMergeRange[1]
			)
		}
		if (
			cell.removed &&
			(cell.partOf?.[rowIndexAdjust + 0] < mergeCellPath[rowIndexAdjust + 0] ||
				cell.partOf?.[rowIndexAdjust + 1] < mergeCellPath[rowIndexAdjust + 1])
		) {
			// cell within range was removed by a merge from above/left, so top/left edge needs moving and range increasing
			newMergeCellPath = [
				...mergeCellPath.slice(0, rowIndexAdjust),
				Math.min(cell.partOf[rowIndexAdjust + 0], mergeCellPath[rowIndexAdjust + 0]),
				Math.min(cell.partOf[rowIndexAdjust + 1], mergeCellPath[rowIndexAdjust + 1]),
			]
			const verticalDisplacement = mergeCellPath[rowIndexAdjust + 1] - newMergeCellPath[rowIndexAdjust + 1]
			const horizontalDisplacement = mergeCellPath[rowIndexAdjust + 0] - newMergeCellPath[rowIndexAdjust + 0]
			newMergeRange[0] = newMergeRange[0] + horizontalDisplacement
			newMergeRange[1] = newMergeRange[1] + verticalDisplacement
		}
	})
	if (currentMergeRange[0] < newMergeRange[0] || currentMergeRange[1] < newMergeRange[1]) {
		return getNextMergeCells(editor, allCells, newMergeCellPath, newMergeRange)
	} else {
		const cellsForMerge = currentCells.filter(
			([, cellPath]) =>
				// don't include mergeCell in result
				cellPath[rowIndexAdjust + 0] !== newMergeCellPath[rowIndexAdjust + 0] ||
				cellPath[rowIndexAdjust + 1] !== newMergeCellPath[rowIndexAdjust + 1]
		)
		return [cellsForMerge, currentMergeRange, newMergeCellPath]
	}
}

function getUnmergeCellPaths(
	editor: CustomEditor,
	tablePath: Path,
	mergeCellEntry: NodeEntry<TableCellElement>,
	unmergeRange: [number, number]
) {
	const cells = [
		...Editor.nodes(editor, { at: tablePath, match: (n) => Element.isElement(n) && n.type === "table-cell" }),
	]
	const [mergeCell, mergeCellPath] = mergeCellEntry
	const rowIndexAdjust = mergeCellPath.length - 2
	const cellEdgeColNo = mergeCellPath[rowIndexAdjust + 1] + mergeCell.merge.right
	const cellEdgeRowNo = mergeCellPath[rowIndexAdjust + 0] + mergeCell.merge.bottom
	const maxColNoAfterUnmerge = cellEdgeColNo - unmergeRange[1]
	const maxRowNoAfterUnmerge = cellEdgeRowNo - unmergeRange[0]
	return cells.reduce((unmergeCells, [, cellPath]) => {
		if (
			// is not part of current merged cell (after)
			cellPath[rowIndexAdjust + 0] > cellEdgeRowNo ||
			cellPath[rowIndexAdjust + 1] > cellEdgeColNo ||
			// is not part of current merged cell (before)
			cellPath[rowIndexAdjust + 0] < mergeCellPath[rowIndexAdjust + 0] ||
			cellPath[rowIndexAdjust + 1] < mergeCellPath[rowIndexAdjust + 1] ||
			// is not part of unmerging zone
			(cellPath[rowIndexAdjust + 0] <= maxRowNoAfterUnmerge && cellPath[rowIndexAdjust + 1] <= maxColNoAfterUnmerge)
		) {
			// not part of unmerging zone, do not add to output
			return unmergeCells
		} else {
			return [...unmergeCells, cellPath]
		}
	}, [])
}

function setCellRemoved(editor: CustomEditor, cellPath: Path, mergeCellPath: Path) {
	Transforms.removeNodes(editor, { at: cellPath })
	Transforms.insertNodes(
		editor,
		[{ type: "table-cell", removed: true, partOf: mergeCellPath, children: tableCellContent() } as TableCellElement],
		{ at: cellPath }
	)
}

function TableOptionsDropdown({
	tableEntry,
	cellEntry,
	allowMergeDown = true,
}: {
	tableEntry: NodeEntry<TableElementType>
	cellEntry?: NodeEntry<TableCellElement>
	allowMergeDown?: boolean
}) {
	const editor = useSlate()
	const { shapeRef } = useTableContext()
	const [tableElement, tablePath] = tableEntry
	const [cellElement, cellPath] = cellEntry ?? []
	const backgroundColor = tableElement?.backgroundColor?.all ?? "white"
	const borderColor = tableElement?.borderColor?.all ?? "black"
	const cellBackgroundColor = cellElement?.backgroundColor?.all ?? null
	const cellBorderColor = cellElement?.borderColor?.all ?? null

	const isLastColWhenMerged = cellElement.col + (cellElement?.merge?.right ?? 0) >= shapeRef.current[1] - 1
	const isLastRowWhenMerged = cellElement.row + (cellElement?.merge?.bottom ?? 0) >= shapeRef.current[0] - 1
	const rightHandMerges = cellElement?.merge?.right ?? 0
	const bottomMerges = cellElement?.merge?.bottom ?? 0

	const onChangeBorderColor = (color) => {
		Transforms.setNodes(editor, { borderColor: { all: color } }, { at: [], match: (n) => n === tableElement })
	}

	const onChangeBackgroundColor = (color) => {
		Transforms.setNodes(editor, { backgroundColor: { all: color } }, { at: [], match: (n) => n === tableElement })
	}

	const onChangeCellBorderColor = (color) => {
		Transforms.setNodes(editor, { borderColor: { all: color } }, { at: cellPath })
	}

	const onChangeCellBackgroundColor = (color) => {
		Transforms.setNodes(editor, { backgroundColor: { all: color } }, { at: cellPath })
	}

	const merge = (intendedMergeRange: [number, number]) => () => {
		const intendedMerge: [number, number] = [
			(cellElement.merge?.bottom ?? 0) + intendedMergeRange[0],
			(cellElement.merge?.right ?? 0) + intendedMergeRange[1],
		]
		const [cellsToRemove, mergeRange, mergeCellPath] = getMergeCells(editor, tablePath, cellPath, intendedMerge)
		Editor.withoutNormalizing(editor, () => {
			const newChildren = cellsToRemove.reduce(
				(chdn, [removedCell]) => {
					if (Node.string(removedCell).length > 0) {
						return [...chdn, ...removedCell.children]
					} else {
						return chdn
					}
				},
				[...cellElement.children]
			)
			Transforms.removeNodes(editor, { at: mergeCellPath })
			Transforms.insertNodes(
				editor,
				{
					type: "table-cell",
					children: [{ type: "table-paragraph", children: newChildren }],
					merge: { bottom: mergeRange[0], right: mergeRange[1] },
				} as TableCellElement,
				{ at: mergeCellPath, select: true }
			)
			cellsToRemove.forEach(([, removeCellPath]) => {
				setCellRemoved(editor, removeCellPath, mergeCellPath)
			})
		})
		Transforms.collapse(editor, { edge: "end" })
	}

	const mergeRight = () => {
		if (isLastColWhenMerged) {
			console.log("in last cell")
			return
		}
		merge([0, 1])()
	}

	const mergeDown = () => {
		if (isLastRowWhenMerged) {
			console.log("in last cell")
			return
		}
		merge([1, 0])()
	}

	const unmerge = (unmergeRange: [number, number]) => () => {
		const cellPathsToUnmerge = getUnmergeCellPaths(editor, tablePath, cellEntry, unmergeRange)
		const lastMergedCellPath = [...cellPath]
		const lastMergedCellPosition = lastMergedCellPath[lastMergedCellPath.length - 1] + rightHandMerges
		lastMergedCellPath[lastMergedCellPath.length - 1] = lastMergedCellPosition
		Editor.withoutNormalizing(editor, () => {
			Transforms.setNodes(
				editor,
				{
					merge: {
						bottom: (cellElement?.merge?.bottom ?? 1) - unmergeRange[0],
						right: (cellElement?.merge?.right ?? 1) - unmergeRange[1],
					},
				},
				{ at: cellPath, match: (n) => n === cellElement }
			)
			try {
				cellPathsToUnmerge.forEach((cellPathToUnmerge) => {
					Transforms.setNodes(editor, { removed: false, partOf: undefined }, { at: cellPathToUnmerge })
				})
			} catch {
				// most likely if this fails it means the row/col the cell was in has been removed
				console.log("failed to reset removed field")
			}
		})
	}

	const unMergeRight = (): void => {
		if (rightHandMerges === 0) {
			console.log("not merged")
			return
		}
		unmerge([0, 1])()
	}

	const unMergeDown = (): void => {
		if (bottomMerges === 0) {
			console.log("not merged")
			return
		}
		unmerge([1, 0])()
	}

	return (
		<div className="table-options-dropdown">
			<span className="table-dropdown-heading">Full Table Options</span>
			<div className="table-dropdown-option">
				<div className="table-option-label">Table Border</div>
				<InstantApplyColourDropdown color={borderColor} onChange={onChangeBorderColor} placement="right-start" />
			</div>
			<div className="table-dropdown-option">
				<div className="table-option-label">Table Background </div>
				<InstantApplyColourDropdown
					color={backgroundColor}
					onChange={onChangeBackgroundColor}
					placement="right-start"
				/>
			</div>
			{cellEntry != null && (
				<>
					<span className="table-dropdown-heading">Cell Options</span>
					<div className="table-dropdown-option">
						<div className="table-option-label">Cell Border</div>
						<InstantApplyColourDropdown
							color={cellBorderColor}
							onChange={onChangeCellBorderColor}
							placement="right-start"
							includeNone={true}
						/>
					</div>
					<div className="table-dropdown-option">
						<div className="table-option-label">Cell Background</div>
						<InstantApplyColourDropdown
							color={cellBackgroundColor}
							onChange={onChangeCellBackgroundColor}
							placement="right-start"
							includeNone={true}
						/>
					</div>
					{!isLastColWhenMerged && (
						<div className="table-dropdown-option clickable" onClick={mergeRight}>
							<div className="table-option-label">Merge Right</div>
							<img className="merge-arrow merge-arrow-right" height={22} src={arrowRight} alt="merge-right"></img>
						</div>
					)}
					{rightHandMerges > 0 && (
						<div className="table-dropdown-option clickable" onClick={unMergeRight}>
							<div className="table-option-label">UnMerge Right</div>
							<img className="merge-arrow merge-arrow-left" height={22} src={arrowRight} alt="merge-right"></img>
						</div>
					)}
					{!isLastRowWhenMerged && allowMergeDown && (
						<div className="table-dropdown-option clickable" onClick={mergeDown}>
							<div className="table-option-label">Merge Down</div>
							<img className="merge-arrow merge-arrow-down" height={22} src={arrowRight} alt="merge-right"></img>
						</div>
					)}
					{bottomMerges > 0 && (
						<div className="table-dropdown-option clickable" onClick={unMergeDown}>
							<div className="table-option-label">UnMerge Down</div>
							<img className="merge-arrow merge-arrow-up" height={22} src={arrowRight} alt="merge-right"></img>
						</div>
					)}
				</>
			)}
		</div>
	)
}
