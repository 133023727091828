import { TableElement } from "../TableFormatting/components/TableElement"
import { useEditorContext } from "../Utils"

export function AutofillTableElement({ element, attributes, children, ...props }) {
	const { maxTableWidth } = useEditorContext()
	return (
		<TableElement allowAddRows={false} element={element} {...attributes} {...(element?.props ?? {})}>
			{children}
		</TableElement>
	)
}

function AutofillPlaceholder({ maxTableWidth }) {
	const { editing } = useEditorContext()

	return editing ? (
		<></>
	) : (
		<div
			contentEditable={false}
			className="slate-no-edit"
			style={{
				width: maxTableWidth + 2,
				textAlign: "center",
				color: "#aaaaaa",
				marginLeft: -1,
				marginTop: -10,
				border: "2px solid #cccccc",
			}}>
			<div
				contentEditable={false}
				className="slate-no-edit"
				style={{
					width: "100%",
					fontSize: 20,
				}}>
				⋮
			</div>
			<div
				contentEditable={false}
				className="slate-no-edit"
				style={{
					width: "100%",
					// border: "2px solid #cccccc",
					marginTop: -1,
				}}>
				Rows will autofill here
			</div>
			<div
				contentEditable={false}
				className="slate-no-edit"
				style={{
					width: "100%",
					fontSize: 20,
				}}>
				⋮
			</div>
		</div>
	)
}
