import {
	AcceptedFileType,
	FileFetchFunction,
	FileFetchingDetails,
	IntegrationData,
	PlatformIntegrationData,
} from "../Integrations.types"
import { forwardWithSecurity } from "libs"
import { isAcceptedFileType, MIME_TYPES } from "./PlatformUtils"

interface ArcrmFileFetchingDetails extends FileFetchingDetails {
	cVId: string
}

//todo: implement these queries
const fileFetchQuery = (cVId) =>
	`{cvs(filter: {cVId: ${cVId}}){ cv {applicantId,cVId,fileExtension,description,binaryContent}}}`
const fileListQuery = (integrationData: IntegrationData) =>
	`{cvs(filter: { applicantId: ${integrationData.externalId} }) {cv {applicantId,cVId,fileExtension,description,updatedOn}}}`
const getUserQuery = (integrationData: IntegrationData) =>
	`{users(filter: { userId: ${integrationData.userId} })  {user {userId,userFullName,jobTitle,emailAddress}}}`

type ArcrmFilesContentDetail = { data: { cvs: { cv: Array<ArcrmFileContentDetail> } } }
type ArcrmFileContentDetail = { cVId: string; binaryContent: string; fileExtension: string; description: string }

const getFileFromArcrm: FileFetchFunction = async (
	clientId: string,
	integrationData: IntegrationData,
	fileFetchingDetails: ArcrmFileFetchingDetails & { [key: string]: string }
) => {
	const fileData = (await forwardWithSecurity({
		clientId,
		platform: "arcrm",
		method: "post",
		json: { query: fileFetchQuery(fileFetchingDetails.cVId) },
		returnType: "json",
	})) as ArcrmFilesContentDetail
	const cv = fileData.data.cvs.cv[0]
	const fileOut = {
		fileType: (cv.fileExtension[0] === "." ? cv.fileExtension.slice(1) : cv.fileExtension) as AcceptedFileType,
		fileMimeType:
			MIME_TYPES[(cv.fileExtension[0] === "." ? cv.fileExtension.slice(1) : cv.fileExtension) as AcceptedFileType],
		b64File: cv.binaryContent,
		name: cv.description,
	}
	return fileOut
}

type ArcrmFilesDetail = { data: { cvs: { cv: Array<ArcrmFileDetail> } } }
type ArcrmFileDetail = { cVId: string; fileExtension: string; description: string; updatedOn: string }

const listFilesFromArcrm = async (clientId: string, integrationData: IntegrationData) => {
	const filesData = (await forwardWithSecurity({
		clientId,
		platform: "arcrm",
		method: "post",
		json: { query: fileListQuery(integrationData) },
		returnType: "json",
	})) as ArcrmFilesDetail
	const files = filesData.data.cvs.cv
	return files.filter(isAcceptedFile).map((cv) => ({
		id: `arcrm-${cv.cVId}`,
		name: cv.description,
		extension: (cv.fileExtension[0] === "." ? cv.fileExtension.slice(1) : cv.fileExtension) as AcceptedFileType,
		dateCreated: new Date(cv.updatedOn),
		fileFetchingDetails: { cVId: cv.cVId },
	}))
}

interface ArcrmUserDetail {
	data: {
		users: {
			user: Array<{
				userId: string
				userFullName: string
				jobTitle?: string
				emailAddress: string
			}>
		}
	}
}

const getUserDetailsFromArcrm = async (clientId: string, integrationData: IntegrationData) => {
	const userData = (await forwardWithSecurity({
		clientId,
		platform: "arcrm",
		method: "post",
		json: { query: getUserQuery(integrationData) },
		returnType: "json",
	})) as ArcrmUserDetail
	const user = userData.data.users.user[0]
	return {
		name: user.userFullName,
		email: user.emailAddress,
		jobTitle: user.jobTitle,
	}
}

function isAcceptedFile(file: ArcrmFileDetail): file is ArcrmFileDetail {
	return isAcceptedFileType("test" + file.fileExtension)
}

export const arcrmIntegrationData: PlatformIntegrationData<
	"setupUser" | "checkForExisting" | "chooseFile" | "fetchFile" | "confirmationPage"
> = {
	parameterMapping: {
		externalClientId: "externalClientId",
		clientId: "clientId",
		externalId: "externalId",
		userId: "userId",
	},
	pages: ["setupUser", "checkForExisting", "chooseFile", "fetchFile", "confirmationPage"],
	props: {
		setupUser: {
			getUserDetails: getUserDetailsFromArcrm,
		},
		fetchFile: {
			getFile: getFileFromArcrm,
		},
		chooseFile: {
			listFiles: listFilesFromArcrm,
		},
		checkForExisting: {},
	},
	initialData: {},
}
