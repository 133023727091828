import {
	AcceptedFileType,
	FileFetchFunction,
	FileFetchingDetails,
	IntegrationData,
	PlatformIntegrationData,
} from "../Integrations.types"
import { forwardWithSecurity } from "libs"
import { getExtensionFromFileName, isAcceptedFileType, MIME_TYPES } from "./PlatformUtils"

interface MercuryFileFetchingDetails extends FileFetchingDetails {
	filePath: string
	fileName: string
}

const BASE_MERCURY_URL = "https://apim-uks-thirdpartyintegration-prod.azure-api.net/"

const getFileFromMercury: FileFetchFunction = async (
	clientId: string,
	integrationData: IntegrationData,
	fileFetchingDetails: MercuryFileFetchingDetails & { [key: string]: string }
) => {
	const file = (await forwardWithSecurity({
		clientId,
		platform: "mercury",
		url: `${BASE_MERCURY_URL}api/v1/documents`,
		method: "get",
		headers: { "tenant-id": integrationData.externalClientId },
		queryStringParameters: fileFetchingDetails,
		returnType: "json",
	})) as { name: string; file: string }
	const fileOut = {
		fileType: getExtensionFromFileName(file.name) as AcceptedFileType,
		fileMimeType: MIME_TYPES[getExtensionFromFileName(file.name)],
		b64File: file.file,
		name: file.name,
	}
	return fileOut
}

type MercuryFileDetail = {
	name: string
	fullPath: string
	relativePath: string
	modifiedUtc: string
	createdUtc: string
	itemType: 0
}

type AcceptableMercuryFileDetail = {
	name: `${string}.${AcceptedFileType}`
	fullPath: string
	relativePath: string
	modifiedUtc: string
	createdUtc: string
	itemType: 0
}

const listFilesFromMercury = async (clientId: string, integrationData: IntegrationData) => {
	const files = (await forwardWithSecurity({
		clientId,
		platform: "mercury",
		url: `${BASE_MERCURY_URL}api/v1/contacts/${integrationData.externalId}/filedetails`,
		method: "get",
		headers: { "tenant-id": integrationData.externalClientId },
		returnType: "json",
	})) as MercuryFileDetail[]
	return files.filter(isAcceptedFile).map((file, i) => ({
		id: `mercury-${i}`,
		name: file.name,
		extension: getExtensionFromFileName(file.name) as AcceptedFileType,
		dateCreated: new Date(file.createdUtc),
		fileFetchingDetails: { fileName: file.name, filePath: file.fullPath },
	}))
}

interface MercuryUserDetail {
	firstName: string
	lastName: string
	internalEmailAddress: string
	mobilePhone?: string
	mainPhone?: string
}

const getUserDetailsFromMercury = async (clientId: string, integrationData: IntegrationData) => {
	const files = (await forwardWithSecurity({
		clientId,
		platform: "mercury",
		url: `${BASE_MERCURY_URL}api/v1/systemUsers/${integrationData.userId}`,
		method: "get",
		headers: { "tenant-id": integrationData.externalClientId },
		returnType: "json",
	})) as MercuryUserDetail
	return {
		name: `${files.firstName} ${files.lastName}`,
		email: files.internalEmailAddress,
		phone: files.mobilePhone || files.mainPhone,
	}
}

function isAcceptedFile(file: MercuryFileDetail): file is AcceptableMercuryFileDetail {
	return isAcceptedFileType(file.name)
}

export const mercuryIntegrationData: PlatformIntegrationData<
	"setupUser" | "checkForExisting" | "chooseFile" | "fetchFile" | "confirmationPage"
> = {
	parameterMapping: {
		externalClientId: "externalClientId",
		clientId: "clientId",
		externalId: "externalId",
		userId: "userId",
	},
	pages: ["setupUser", "checkForExisting", "chooseFile", "fetchFile", "confirmationPage"],
	props: {
		setupUser: {
			getUserDetails: getUserDetailsFromMercury,
		},
		fetchFile: {
			getFile: getFileFromMercury,
		},
		chooseFile: {
			listFiles: listFilesFromMercury,
		},
		checkForExisting: {},
	},
	initialData: {},
}
