import "../ColourFormatting.css"
import { CustomPlugin } from "../types/slate.types"
import { textTransform } from "./TextTransform"

export const withTextTransform: CustomPlugin = (editor) => {
	const { allAiOptions } = editor

	editor.allAiOptions = {
		...(allAiOptions ?? {}),
		...textTransform,
	}

	return editor
}
