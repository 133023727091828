import { correctHyperlink, useMountEffect, getFontFamily, useAppContext } from "libs"
import { forwardRef } from "react"
import { Element as SlateElement, Node } from "slate"
import { TaggingContextProvider, useTaggingContext } from "../../TaggingFormatting/TaggingContext"
import { TaggingOptions } from "../../../TextFormatting/TaggingFormatting/TaggingDatasets"
import { EditFormattedText } from "../../../EditBox/Components/EditFormattedText"
import { serialize, useEditorContext } from "../../Utils"
import { getCorrectUrlAndMail } from "../HyperlinkUtils"
import "./hyperlink-box.css"

export const HyperlinkBox = forwardRef(
	(
		{
			text,
			url,
			setUrl,
			setPlainTextUrl,
			plainTextUrl,
			hidden = false,
			...props
		}: {
			text: Node[]
			url: string
			hidden?: boolean
			setUrl: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
			setPlainTextUrl: (url: string) => void
			plainTextUrl: string
		},
		ref
	) => {
		const textToShow = SlateElement.isElement(text[0])
			? serialize(text)
			: serialize([{ type: "paragraph", children: text }])
		const { toolbarPositions, setPreventBlur } = useEditorContext()
		const { company } = useAppContext()
		const { rawOptions } = useTaggingContext()
		const link = correctHyperlink(plainTextUrl) ? plainTextUrl : ""
		const correctUrl = getCorrectUrlAndMail(link)
		useMountEffect(() => {
			return () => {
				setPreventBlur(false)
			}
		})

		const newTaggingOptions: TaggingOptions = {
			reducedHyperlinkRecruiterInfoData: { ...rawOptions?.recruiterInfo },
			reducedHyperlinkInlineData: { ...rawOptions?.appInline },
		}

		return (
			<div
				onMouseEnter={() => {
					setPreventBlur(!hidden)
				}}
				onMouseLeave={() => {
					setPreventBlur(false)
				}}
				ref={ref as React.RefObject<HTMLDivElement>}
				contentEditable={false}
				className="hyperlink-box"
				style={{
					top: hidden ? 0 : toolbarPositions?.hyperlink?.top,
					left: hidden ? 0 : toolbarPositions?.hyperlink?.left ?? "-100%",
					opacity: hidden ? 0 : 1,
					pointerEvents: hidden ? "none" : "auto",
				}}
				{...props}>
				<div className="hyperlink-box-container">
					{/* Show the text, as input but cant edit */}
					<div className="hyperlink-box-row">
						<span> Text: </span>
						<div style={{ width: "250px", fontFamily: getFontFamily(company?.branding?.font, company) }}>
							<EditFormattedText
								text={textToShow ?? ""}
								onChange={() => {}}
								sectionTitle="hyperlink text"
								editable={false}
								options={["marks", "hyperlink", "color", "case", "font"]}
								hideToolbar={true}
								narrowEditingWindow={true}
							/>
						</div>
					</div>
					{/* Show URL, as input but can edit  */}
					<div className="hyperlink-box-row">
						<span> URL: </span>
						<div style={{ display: "flex", left: "0px", width: "250px" }}>
							<TaggingContextProvider options={newTaggingOptions}>
								<EditFormattedText
									text={url ?? ""}
									onChange={setUrl}
									sectionTitle="URL"
									editable={true}
									options={[]}
									hideToolbar={true}
									setPlainText={setPlainTextUrl}
									narrowEditingWindow={true}
									style={{ border: "1px solid #807F7F", minWidth: "100%" }}
									editStyle={{ minWidth: "100%" }}
									singleParagraph={true}
								/>
							</TaggingContextProvider>
						</div>
					</div>
					<div className="hyperlink-box-row">
						<a
							href={correctUrl}
							target="_blank"
							rel="noopener noreferrer"
							className={`${correctUrl ? "hyperlink-box-link" : "  hyperlink-box-link-disabled"}`}>
							Test Link
						</a>
					</div>
				</div>
			</div>
		)
	}
)
