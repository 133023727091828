import { withReact } from "slate-react"
import { withHistory } from "slate-history"
import "./TextFormatter.css"
import { ensureSelection } from "./Utils"
import { withTagging } from "./TaggingFormatting"
import { withColor } from "./ColourFormatting"
import { withCase } from "./CaseFormatting"
import { withTextTransform, withWriteText } from "./AIFormatting"
import { withSize } from "./SizeFormatting"
import { withBullets } from "./BulletFormatting"
import { withAlign } from "./AlignmentFormatting"
import { withMarks } from "./MarkFormatting"
import { CustomEditor, CustomPlugin } from "./types/slate.types"
import { withTables } from "./TableFormatting"
import { withAutofillTables } from "./AutofillTableFormatting"
import { withHyperlink } from "./HyperlinkFormatting/HyperlinkPlugin"
import { withFont } from "./FontFormatting"
import { DEFAULT_PLUGINS } from "./TextFormattingConstants"
import { TaggingContextValue } from "./TaggingFormatting/TaggingContext"
import { withDivider } from "./DividerFormatting/DividerPlugin"
import { withLineSpacing } from "./LineSpacingFormatting"
import { withMarkdown } from "./MarkdownFormatting/MarkdownPlugin"
import { withPageBreak } from "./PageBreakFormatting/PageBreakPlugin"
import { withColumns } from "./ColumnsFormatting/ColumnsPlugin"

const newLineAndLowerCaseRegex = /(?: *[\n\r]+ *)([a-z])/g // finds newline (w or w/o space) followed by lowercase letter and returns lowercase letter as group 1. The spaces are important!!!
const newLineFullStopAndUpperCaseRegex =
	/([^\.])(?: *[\n\r]+ *)([A-Z])|([\.])(?: *[\n\r]+ *)([^A-Z])|([^\.])(?: *[\n\r]+ *)([^A-Z])/g // finds any of the three options for not fullstop newline capital letter. The spaces are important!!!
const otherWhiteSpaceRegex = /[\f\v\t]+/g // finds all other whitespace (tabs and weird shit)

const bestGuessNewLineStripping = (text: string): string => {
	const hasFullStops = text.match(".")?.length > 0
	if (hasFullStops) {
		return text.replace(newLineFullStopAndUpperCaseRegex, "$1$3$5 $2$4$6") // horrendous substitution: to do not . before and A after, needed to do all other combinations leading to 3 pairs of capture groups
	} else {
		return text.replace(newLineAndLowerCaseRegex, " $1") // add group 1 back in after space
	}
}

export const PLUGIN_MAPPING: { [plugin: string]: CustomPlugin } = {
	marks: withMarks,
	tables: withTables,
	autofillTables: withAutofillTables,
	bullets: withBullets(["bullet", "number"]),
	"bullets:bullet": withBullets(["bullet"]),
	"bullets:number": withBullets(["number"]),
	align: withAlign,
	color: withColor,
	size: withSize,
	case: withCase,
	textTransform: withTextTransform,
	writeText: withWriteText,
	hyperlink: withHyperlink,
	font: withFont,
	divider: withDivider,
	lineSpacing: withLineSpacing,
	markdown: withMarkdown,
	pageBreak: withPageBreak,
	textColumns: withColumns,
}

export function applyPlugins(editor: CustomEditor, plugins = DEFAULT_PLUGINS, taggingContext: TaggingContextValue) {
	return withoutNewlines(
		withReact(
			applyTaggingPlugin(
				plugins.reduce((prevEditor, pluginName) => {
					if (pluginName === "tables" && plugins.includes("autofillTables")) {
						// prevent tables plugin from being applied if autofillTables is also applied
						return prevEditor
					}
					return PLUGIN_MAPPING[pluginName](prevEditor)
				}, withHistory(editor)),
				taggingContext
			)
		)
	)
}

function applyTaggingPlugin(editor: CustomEditor, taggingContext: TaggingContextValue) {
	if (taggingContext == null || Object.keys(taggingContext)?.length === 0) {
		return editor
	}
	return withTagging(taggingContext)(editor)
}

const withoutNewlines: CustomPlugin = (editor) => {
	// remove newlines from copy and pasted data if followed by lower case (copying from pdf adds too many new lines)
	const { insertData, normalizeNode, isInline, undo, redo } = editor

	editor.isInline = (element) => {
		return element.nodeType === "inline" || isInline(element)
	}

	editor.undo = () => {
		undo()
		// if at any point in the operations being undone the text was emptied completely
		// the selection will be lost. Ensure selection will put it at the end of the text if it was lost
		// For changes that might have had an empty text editor at an intermediate point,
		// placing the selction at the end is a reasonable default
		ensureSelection(editor)
	}

	editor.redo = () => {
		redo()
		// if at any point in the operations being redone the text was emptied completely
		// the selection will be lost. Ensure selection will put it at the end of the text if it was lost
		// For changes that might have had an empty text editor at an intermediate point,
		// placing the selction at the end is a reasonable default
		ensureSelection(editor)
	}

	editor.normalizeNode = (entry) => {
		normalizeNode(entry)
	}

	editor.insertData = (data) => {
		console.log("inserting data")
		const html = data.getData("text/html")
		if (html.includes("data-slate")) {
			// copy and pasting from within editor, no need to strip newlines
			insertData(data)
			return
		}
		const notHtml = data.getData("text/plain")
		// if (textHasBulletSymbols(notHtml)){
		// 	// this should be moved to the bullet plugin, but it will probably require several new functions on the editor to ensure that the running order is correct
		// 	const sections = getBulletSections(notHtml)
		// 	for (var section of sections){
		// 		newLineStripping(section, insertData)
		// 		// logTypeTree(editor)
		// 	}
		// 	console.log('inserted bullet fragment turned off here')
		// 	editor.bulletFragmentInserted = false
		// 	return
		// } else {
		newLineStripping(notHtml, insertData)
		// }
	}
	return editor
}

function newLineStripping(notHtml: string, insertData) {
	let newNotHtml = bestGuessNewLineStripping(notHtml) // add group 1 back in after space
	newNotHtml = newNotHtml.replace(otherWhiteSpaceRegex, " ")
	const newData = new DataTransfer()
	// Object.assign(newData, data)
	newData.setData("text/plain", newNotHtml)
	insertData(newData)
}
