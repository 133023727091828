import { renderParagraphElement } from "../Utils"
import { withTables } from "../TableFormatting"
import { CustomPlugin } from "../types/slate.types"
import { Editor, Element, Transforms } from "slate"
import { deepCopy } from "libs"
import { addAttributeToTags, renderAutofillTableElement } from "./AutofillTableUtils"

export const withAutofillTables: CustomPlugin = (editor) => {
	// get copy of renderElement before it is overriden by tables plugin so that we can ensure we use an altered version
	const { renderElement: preTableRenderElement } = editor

	editor = withTables(editor) // apply standard tables override

	const { normalizeNode } = editor

	editor.normalizeNode = (entry) => {
		const [node, path] = entry

		if (Element.isElement(node) && node.type === "table" && node.autofill && editor.parameters.templateMode !== true) {
			// recreate as standard table and remove autofill attribute
			const rowToRepeat = deepCopy(node.children[1])
			Editor.withoutNormalizing(editor, () => {
				let newRows = []
				editor.parameters.autofillRows.forEach((row, i) => {
					const thisRow = deepCopy(rowToRepeat)
					thisRow.row = i + 1 // repeated rows start at 1 because of heading row
					addAttributeToTags(thisRow, { index: row }) // element uses data index
					newRows.push(thisRow)
				})
				Transforms.removeNodes(editor, { at: [...path, 1] }) // remove template node
				Transforms.insertNodes(editor, newRows, { at: [...path, 1] }) // insert autofill rows
				// removing autofill attribute here
				Transforms.setNodes(
					editor,
					{ autofill: false, shape: [node.shape[0] + editor.parameters.autofillRows?.length - 1, node.shape[1]] },
					{ at: path }
				)
			})
			return
		}

		return normalizeNode(entry)
	}

	editor.renderElement = (props) => {
		return (
			renderAutofillTableElement(props, editor.parameters) ??
			preTableRenderElement?.(props) ??
			renderParagraphElement(props)
		)
	}

	return editor
}
