import { FontDropdownItems } from "../../FontDropdown/FontDropdown"
import { ButtonType } from "../types/slate.types"
import { DEFAULT_FONT } from "libs"

const getDefaultFont = (editorEl: HTMLElement) => {
	const defaultFontFamily = window.getComputedStyle(editorEl ?? document.activeElement)?.getPropertyValue("font-family")
	const defaultFont = defaultFontFamily?.split(",")[0]?.replace(/['"]+/g, "")

	return defaultFont
}

export const fontOptions = {
	font: {
		format: "font",
		button: ButtonType.customMark as ButtonType.customMark,
		getDefault: getDefaultFont,
		makeIcon: MakeFontIcon,
		makeCustomDropdown: ({ currentValue, setFormat }) => (
			<FontDropdownItems currentValue={currentValue ?? DEFAULT_FONT} setFormat={setFormat} />
		),
		closeDropdownOnClick: false,
	},
}

function MakeFontIcon({ currentValue, setFormat }) {
	const valueToDisplay = currentValue ?? DEFAULT_FONT
	return (
		<span
			style={{
				width: "110px",
				display: "inline-flex",
				marginRight: 2,
				justifyContent: "flex-start",
				alignSelf: "center",
				padding: "0px 3px 0px 3px",
				lineHeight: "1",
			}}
			className="toolbar-button-icon">
			<div
				style={{
					overflow: "hidden",
					textOverflow: "ellipsis",
					whiteSpace: "nowrap",
					textAlign: "left",
				}}>
				{valueToDisplay}
			</div>
		</span>
	)
}
