import { PlainToggleSwitch, LoaderButton } from "components"
import { useCallback, useMemo, useState } from "react"
import {
	SUPPORTING_FILE_DESIGNATIONS,
	FileDesignation,
	OutputType,
	clientApi,
	spacedToCamelCase,
	useHandler,
	RESTRICTABLE_FEATURES,
	ClientProductionFeaturePermissionDefinition,
	RestrictableFeature,
} from "libs"
import { CustomDropdown } from "components"
import { combineAtLeaves } from "libs/src/objLib/combineAtLeaves"
import { useHistory } from "react-router-dom"

interface ClientOptionFlag {
	label: string
	default?: boolean
	createExtraOptions?: (flagState: boolean) => object
	createExtraDisplay?: (props: { flagState: boolean; clientId: string; data: object }) => React.ReactNode
}

const createDefaultJobDescriptionOptions = (flagState: boolean) => {
	return flagState
		? {
				rolesAvailable: -1,
				options: {
					jobOptions: {
						editableJobs: true,
						includeRequirementName: true,
						rankings: [
							{ key: "1-5", text: "1 (low) - 5 (high)" },
							{ key: "1-10", text: "1 (low) - 10 (high)" },
							{ key: "A-C", text: "C (low) - A (high)" },
							{ key: "A-F", text: "F (low) - A (high)" },
						],
					},
				},
			}
		: { rolesAvailable: 0 }
}

const EXTRA_OPTIONS: {
	[key: string]: (flagState: boolean) => object
} = {
	fullJobDescription: createDefaultJobDescriptionOptions,
}

function GoToSubdomain({ flagState, clientId, data }: { flagState: boolean; clientId: string; data: object }) {
	const history = useHistory()
	return (
		<div>
			{flagState && (
				<span
					className="blueText clickable"
					style={{ fontSize: 12 }}
					onClick={() => history.push(`/client/${clientId}/account`)}>
					Edit subdomain
				</span>
			)}
		</div>
	)
}

const EXTRA_DISPLAY: {
	[key in RestrictableFeature]?: (props: { flagState: boolean; data: object }) => JSX.Element
} = {
	customSubdomain: GoToSubdomain,
}

const CLIENT_OPTION_FLAGS: { [key: string]: ClientOptionFlag } = Object.fromEntries(
	Object.entries(RESTRICTABLE_FEATURES)
		.filter(([key, data]) => data?.releaseState === "production" && data?.clientAccessControlled)
		.map(([key, data]) => [
			key,
			{
				label: data.label,
				default: (data as ClientProductionFeaturePermissionDefinition).defaultClientAccess,
				createExtraOptions: EXTRA_OPTIONS[key],
				createExtraDisplay: EXTRA_DISPLAY[key],
			},
		])
)
const CLIENT_OPTION_FLAGS_ARR: string[] = Object.keys(CLIENT_OPTION_FLAGS)

type OutputObject = { name: string; dataType: string }

export function OptionsPage({ clientId, data, setData }): JSX.Element {
	const currentOptionFlags: { [flag: string]: boolean } = useMemo(
		() => data?.options?.flags ?? {},
		[data?.options?.flags]
	)
	const currentOutputs: { [outputName: string]: OutputObject } = useMemo(
		() => data?.options?.outputs ?? {},
		[data?.options?.outputs]
	)
	const currentOutputKeys = useMemo(() => Object.keys(currentOutputs), [currentOutputs])

	const [newOutputType, handleNewOutputType] = useHandler<string>("")
	const [newOutputLabel, handleNewOutputLabel] = useHandler<string>("")
	const [isSettingNewOutput, setIsSettingNewOutput] = useState<boolean>(false)
	const [isRemovingOutput, setIsRemovingOutput] = useState<{ [key: string]: boolean }>({})
	const [supportingDocs, setSupportingDocs] = useState<Array<FileDesignation>>(data?.options?.supportingDocuments ?? [])

	const setSupportingDoc = useCallback(
		(des: FileDesignation) => {
			return () => {
				let supportingDocsChange = [...supportingDocs]
				let newSupportingDocs
				if (supportingDocs.includes(des)) {
					const indexOfDes = supportingDocs.indexOf(des)
					supportingDocsChange[indexOfDes] = null
					if (supportingDocsChange.length === 1) {
						supportingDocsChange = null
					}
					newSupportingDocs = supportingDocs.filter((d) => d !== des)
					setSupportingDocs(newSupportingDocs)
				} else {
					newSupportingDocs = Object.keys(SUPPORTING_FILE_DESIGNATIONS).filter(
						(d: FileDesignation) => d === des || supportingDocs.includes(d)
					) // forces same ordering as constant
					supportingDocsChange = newSupportingDocs
					setSupportingDocs(newSupportingDocs)
				}
				const newClientOptions = { supportingDocuments: supportingDocsChange }
				clientApi.patch({ clientId, updates: { options: newClientOptions }, changesOnly: true })
			}
		},
		[clientId, supportingDocs]
	)

	const newOutputLabelWithDefault =
		newOutputLabel || (OUTPUT_TYPES.find((type) => type.key === newOutputType)?.defaultName ?? "")

	const setFlag = useCallback(
		(flag: string) => (value: boolean) => {
			currentOptionFlags[flag] = value
			const extra = CLIENT_OPTION_FLAGS[flag]?.createExtraOptions?.(value)
			const change = combineAtLeaves(extra, { options: { flags: { [flag]: value } } })[0]
			const newData = combineAtLeaves(extra, { ...data, options: { ...data.options, flags: currentOptionFlags } })[0]
			// const change = { clientId, options: { flags: { [flag]: value } }, ...extra }
			// const newData = { ...data, ...extra, options: { ...data.options, flags: currentOptionFlags } }
			clientApi.patch({ clientId, updates: change, changesOnly: true })
			setData(newData)
		},
		[currentOptionFlags, data, clientId, setData]
	)

	const addNewOutput = useCallback(() => {
		if (!newOutputLabelWithDefault || !newOutputType) return
		const newOutput = { name: newOutputLabelWithDefault, dataType: newOutputType }
		const outputName = spacedToCamelCase(newOutputLabelWithDefault)
		const newData = {
			...data,
			options: { ...data.options, outputs: { ...currentOutputs, [outputName]: newOutput } },
			outputData: { ...data.outputData, [outputName]: { outputType: "return" } },
		}
		const change = {
			options: { outputs: { [outputName]: newOutput } },
			outputData: { [outputName]: { outputType: "return" } },
		}
		setIsSettingNewOutput(true)
		clientApi
			.patch({
				clientId,
				updates: change,
				changesOnly: true,
			})
			.then(() => {
				setData(newData)
				handleNewOutputLabel({ target: { value: "" } })
				handleNewOutputType({ target: { value: "" } })
			})
			.catch(() => {
				alert("Failed to add. Refresh page to check current data")
			})
			.finally(() => {
				setIsSettingNewOutput(false)
			})
	}, [
		newOutputLabelWithDefault,
		newOutputType,
		data,
		currentOutputs,
		setIsSettingNewOutput,
		clientId,
		setData,
		handleNewOutputLabel,
		handleNewOutputType,
	])

	const removeOutput = useCallback(
		(outputName: string) => () => {
			const newData = { ...data, options: { ...data.options, outputs: { ...currentOutputs } } }
			delete newData.options.outputs[outputName]
			delete newData.outputData[outputName]
			const change = { options: { outputs: { [outputName]: null } }, outputData: { [outputName]: null } }
			setIsRemovingOutput({ [outputName]: true, ...isRemovingOutput })
			clientApi
				.patch({ clientId, updates: change, changesOnly: true })
				.then(() => {
					setData(newData)
				})
				.catch(() => {
					alert("Failed to remove. Refresh page to check current data")
				})
				.finally(() => {
					setIsRemovingOutput({ [outputName]: false, ...isRemovingOutput })
				})
		},
		[data, currentOutputs, setIsRemovingOutput, isRemovingOutput, clientId, setData]
	)

	return (
		<div className="account-container">
			<h1>Options</h1>
			<h4>Switch Options</h4>
			<div style={{ marginBottom: 20 }}>
				{CLIENT_OPTION_FLAGS_ARR.map((flag) => {
					return (
						<div
							key={flag}
							style={{ display: "flex", justifyContent: "start", alignItems: "center", gap: 10, marginBottom: 5 }}>
							<div style={{ textAlign: "right", width: "20%", fontWeight: 500, minWidth: "fit-content" }}>
								{CLIENT_OPTION_FLAGS[flag].label}:
							</div>
							<div>
								{" "}
								<PlainToggleSwitch
									label={flag}
									onChange={setFlag(flag)}
									value={currentOptionFlags?.[flag] ?? CLIENT_OPTION_FLAGS[flag].default ?? false}></PlainToggleSwitch>
							</div>
							<div>
								{CLIENT_OPTION_FLAGS[flag]?.createExtraDisplay?.({
									flagState: currentOptionFlags?.[flag],
									data,
									clientId,
								})}
							</div>
						</div>
					)
				})}
			</div>
			<h4>Output Options</h4>
			{Object.keys(currentOutputs)?.length > 0 && (
				<>
					{currentOutputKeys.map((outputName) => {
						const output = currentOutputs[outputName]
						return (
							<div key={outputName} style={{ display: "flex", justifyContent: "start", alignItems: "center", gap: 10 }}>
								<div style={{ textAlign: "right", width: "20%", minWidth: "fit-content", fontWeight: 500 }}>
									{output.name}:
								</div>
								<div style={{ margin: 20, minWidth: 150 }}>
									<div>Type: {STANDARD_LABELS[output.dataType] ?? output.dataType}</div>
								</div>
								<LoaderButton
									isLoading={isRemovingOutput[outputName]}
									onClick={removeOutput(outputName)}
									className="SquarerLoaderButton">
									Remove
								</LoaderButton>
							</div>
						)
					})}
				</>
			)}
			<div style={{ display: "flex", justifyContent: "start", gap: 30, alignItems: "center", marginBottom: 30 }}>
				<input
					style={{ textAlign: "right", width: "20%", minWidth: "fit-content", fontWeight: 500, height: "fit-content" }}
					type="text"
					placeholder="New Output Name:"
					onChange={handleNewOutputLabel}
					value={newOutputLabelWithDefault}
				/>
				<div style={{ minWidth: 150 }}>
					<CustomDropdown
						value={newOutputType}
						actions={OUTPUT_TYPES}
						bestOption={"Choose Type"}
						onChange={handleNewOutputType}></CustomDropdown>
				</div>
				<LoaderButton
					isLoading={isSettingNewOutput}
					onClick={addNewOutput}
					className="SquarerLoaderButton"
					disabled={newOutputLabelWithDefault?.length < 1}>
					Add New Output
				</LoaderButton>
				<div className="greyText" style={{ minWidth: 280 }}>
					{newOutputLabelWithDefault != null &&
					currentOutputKeys != null &&
					currentOutputKeys.includes(spacedToCamelCase(newOutputLabelWithDefault))
						? "**WARNING*** Will overwrite existing output"
						: ""}
				</div>
			</div>
			<h4>Supporting Document Options</h4>
			<div style={{ marginBottom: 20 }}>
				{Object.entries(SUPPORTING_FILE_DESIGNATIONS).map(([des, { label }]) => {
					return (
						<div key={des} style={{ display: "flex", justifyContent: "start", alignItems: "center", gap: 10 }}>
							<div style={{ textAlign: "right", width: "20%", fontWeight: 500, minWidth: "fit-content" }}>{label}:</div>
							<div>
								{" "}
								<PlainToggleSwitch
									label={label}
									onChange={setSupportingDoc(des as FileDesignation)}
									value={supportingDocs.includes(des as FileDesignation) ?? false}></PlainToggleSwitch>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}

type OutputDropdownOption = { key: OutputType; label: string; defaultName: string }

const STANDARD_LABELS = {
	formattedCV: "Formatted PDF",
	formattedWord: "Formatted Word",
	originalCV: "Original PDF",
	data: "Data",
	transformedData: "Transformed Data",
	url: "Digital CV",
	editableUrl: "Editable Url",
}

const OUTPUT_TYPES: OutputDropdownOption[] = [
	{ key: "url", label: STANDARD_LABELS.url, defaultName: "Landing Page" },
	{ key: "editableUrl", label: STANDARD_LABELS.editableUrl, defaultName: "Editable Link" },
	{ key: "formattedWord", label: STANDARD_LABELS.formattedWord, defaultName: "Word" },
] //, {key: 'formattedPdf', label: 'Formatted PDF (Not available)'}, {key: 'originalPdf', label: 'Original PDF (not available)'}, {key: 'data', label: 'Data (not available)'}, {key: 'transformedData', label: 'Transformed Data (not available)'}]
