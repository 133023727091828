import { SlateLeafRenderProps } from "../types/slate.types"

const colorClassRegex = new RegExp(/default-font-color-(\S*)/)

export const getDefaultColour = (editorEl: HTMLElement) => {
	const editableEl = (editorEl ?? document.activeElement).closest(".editable-text")
	const match = editableEl?.className?.match?.(colorClassRegex)?.[1]
	return match
}

export const setColorLeafProps = ({ attributes, children, leaf, ...other }: SlateLeafRenderProps) => {
	if (leaf.color != null) {
		children = <span style={{ color: leaf.color }}>{children}</span>
	}
	if (leaf.highlight != null && leaf.highlight !== "null") {
		children = <mark style={{ backgroundColor: leaf.highlight, padding: "0.125em 0 0" }}>{children}</mark>
	}
	// if (leaf.highlight) {
	//     children = <span className='highlighted'>{children}</span>
	// }
	return { attributes, children, leaf, ...other }
}
