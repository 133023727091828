import type { Placement } from "@popperjs/core"

type TextAlignment = "left" | "center" | "right" | "justify"

export function placementFromAlignment(alignment: TextAlignment): Placement {
	switch (alignment) {
		case "left":
			return "bottom-start"
		case "center":
			return "bottom"
		case "justify":
			return "bottom"
		case "right":
			return "bottom-end"
		default:
			return "bottom-start"
	}
}

export function fallbackPlacements(placement: Placement): Placement[] {
	switch (placement) {
		case "bottom-start":
			return ["top-start", "bottom"]
		case "bottom":
			return ["top", "bottom-start"]
		case "bottom-end":
			return ["top-end", "bottom"]
		default:
			return ["top-start", "bottom"]
	}
}
