import { forwardWithSecurity } from "libs"
import { FetchedFileObject, IntegrationData, PlatformIntegrationData, AcceptedFileType } from "../Integrations.types"
import { getExtensionFromFileName, isAcceptedFileType } from "./PlatformUtils"

interface VincereFileDetail {
	file_name: string
	url: string
	uploaded_date: string
	original_cv: boolean
}

interface AcceptableVincereFileDetail {
	file_name: `${string}.${AcceptedFileType}`
	url: string
	uploaded_date: string
	original_cv: boolean
}

function isAcceptedFile(file: VincereFileDetail): file is AcceptableVincereFileDetail {
	return isAcceptedFileType(file.file_name)
}

const listFilesFromVincere = async (clientId: string, integrationData: IntegrationData) => {
	const { externalClientId, userId: externalUserId } = integrationData
	const apiBaseUrl = `https://${externalClientId}/api/v2/`

	const strippedCandidateId = integrationData.externalId.replace(/vnc.*?\_/, "")

	const candidatePath = `candidate/${strippedCandidateId}/files`

	const candidateUrl = apiBaseUrl + candidatePath

	const files = (await forwardWithSecurity({
		clientId,
		externalClientId,
		externalUserId,
		platform: "vincere",
		url: candidateUrl,
		method: "get",
		returnType: "json",
	})) as VincereFileDetail[]
	return files.filter(isAcceptedFile).map((file, i) => {
		const extension = getExtensionFromFileName(file.file_name) as AcceptedFileType
		return {
			id: `vincere-${i}`,
			name: file.file_name,
			extension,
			dateCreated: new Date(file.uploaded_date),
			fileFetchingDetails: { fileName: file.file_name, filePath: file.url, fileExtension: extension },
		}
	})
}

const getFileFromVincere = async (
	clientId: string,
	integrationData: IntegrationData,
	fileFetchingDetails: any
): Promise<FetchedFileObject> => {
	const response = await forwardWithSecurity({
		clientId,
		externalClientId: integrationData.externalClientId,
		platform: null, // no platform needed for this request as no sec params need adding
		url: fileFetchingDetails.filePath,
		method: "get",
		returnType: "base64",
	})
	const b64File = response?.base64
	return {
		fileType: fileFetchingDetails.fileExtension,
		b64File,
		name: fileFetchingDetails.fileName,
	}
}

interface VincereUserDetail {
	full_name: string
	email: string
	id: string
	phone?: string
	job_title?: string
}

const getVincereUserDetails = async (clientId: string, integrationData: IntegrationData) => {
	const { externalClientId, userId: externalUserId } = integrationData
	const url = `https://${externalClientId}/api/v2/user/search/fl=id,email,full_name,job_title,phone?q=${encodeURIComponent(`id:${externalUserId}#`)}`
	const result = (await forwardWithSecurity({
		clientId,
		externalClientId,
		externalUserId,
		platform: "vincere",
		url,
		method: "get",
		returnType: "json",
	})) as { result: { items: VincereUserDetail[] } }
	const userDetail = result.result.items[0] // search by id should return 1 result
	return {
		name: userDetail.full_name,
		email: userDetail.email,
		phone: userDetail.phone,
		jobTitle: userDetail.job_title,
	}
}

const callbackUrl = `${window.location.origin.toString()}/oauthcallback`

export const vincereIntegrationData: PlatformIntegrationData<
	"oauthLogin" | "setupUser" | "checkForExisting" | "chooseFile" | "fetchFile" | "confirmationPage"
> = {
	parameterMapping: {
		clientId: "clientId",
		externalClientId: "externalClientId",
		tenant: "tenant",
		externalId: "externalId",
		userId: "userId",
		candidateId: "candidateId",
		shortId: "shortId",
	},
	pages: ["oauthLogin", "setupUser", "checkForExisting", "chooseFile", "fetchFile", "confirmationPage"],
	props: {
		setupUser: {
			getUserDetails: getVincereUserDetails,
		},
		oauthLogin: {
			newTab: true,
			getLoginUrl: (integrationData: IntegrationData, state: string) => {
				// @ts-ignore
				const vincereClientId = integrationData.other.vincereClientId
				return `https://id.vincere.io/oauth2/authorize?response_type=code&client_id=${vincereClientId}&redirect_uri=${callbackUrl}${state != null ? `&state=${encodeURIComponent(state)}` : ""}`
			},
			handleCallback: async (query: URLSearchParams) => {
				query.get("code")
			},
		},
		checkForExisting: {},
		chooseFile: {
			listFiles: listFilesFromVincere,
		},
		fetchFile: {
			getFile: getFileFromVincere,
		},
		confirmationPage: {},
	},
	initialData: {},
}
