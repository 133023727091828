import { useSlate } from "slate-react"
import { BlockOptionSet, ButtonType } from "../../TextFormatting/types/slate.types"
import { canInsertDivider } from "./DividerUtils"
import { isBlockActive } from "../Utils"

export const dividerOptions: { divider: BlockOptionSet } = {
	divider: {
		format: "divider",
		icon: () => <DividerIcon />,
		button: ButtonType.block,
		tooltipLabel: () => `Divider Line`,
	},
}

function DividerIcon() {
	const editor = useSlate()

	const available = canInsertDivider(editor) || isBlockActive(editor, "divider") // block active mean it can be removed so shouldn't be marked unavailable

	return (
		<div
			aria-label="divider-line-button"
			className={`divider-icon-outer ${!available ? "toolbar-button-unavailable unclickable" : ""}`}>
			<div className="divider-icon"></div>
		</div>
	)
}
