import { Editor, Element, Transforms, Range, Point } from "slate"
import { CustomPlugin, CustomEditor } from "../types/slate.types"
import {
	allowedHotKeysInCustomUneditableSection,
	ensureSelection,
	getSelectedText,
	isBlockActive,
	renderParagraphElement,
	toggleParagraph,
} from "../Utils"
import { dividerOptions } from "./DividerOptions"
import { renderDividerElement, toggleDivider } from "./DividerUtils"
import isHotkey from "is-hotkey"
import { isObjEqual } from "libs"

/**
 * Plugin for divider line. The divider line should be a non-editable element that is rendered as a horizontal line.
 * Added as a block element, so the icon will highlight when the line is selected. when a line is selected the line will cause it to delete.
 * When no divider is already selected the icon adds the line (on the current line if empty, below if not).
 * It should be possible to select it by clicking, and then the font size, colour and bold text properites will affect the appearance of the line.
 * The style of the line is set depending on if it is part of the slate editor selection to match the standard ::selection style (background-color: #b4d5fe; standard on mac, may be slighlty different on other platforms)
 * @param editor
 * @returns
 */
export const withDivider: CustomPlugin = (editor: CustomEditor) => {
	const { renderElement, toggleBlock, blockOptions, customKeyPress, apply, normalizeNode, setLeafProps } = editor

	editor.normalizeNode = (entry) => {
		const [node, path] = entry
		if (Element.isElement(node) && node.type === "divider") {
			// the divider should only be a direct child of the editor or a nested formatter
			const parentEntry = Editor.parent(editor, path)
			const parent = parentEntry[0]
			if (!Editor.isEditor(parent)) {
				Transforms.removeNodes(editor, { at: path, match: (n) => Element.isElement(n) && n.type === "divider" })
				return
			}
		}
		normalizeNode(entry)
	}

	editor.customKeyPress = (event) => {
		const [entry] = Editor.nodes(editor, { match: (n) => Element.isElement(n) && n.type === "divider" })
		const dividerInSelection = entry != null
		if (dividerInSelection && ["Backspace", "Delete"].includes(event.key)) {
			Transforms.removeNodes(editor, { at: entry[1] })
			if (getSelectedText(editor)?.length <= 1) {
				// only the divider was selected, so we need to prevent paras before and after merging by double delete
				event.preventDefault()
			}
			return
		}
		if (dividerInSelection && event.key === "Enter") {
			Transforms.insertNodes(
				editor,
				{ type: "paragraph", children: [{ text: "" }] },
				{ at: [entry[1][0] + 1], select: true }
			)
			if (getSelectedText(editor)?.length <= 1) {
				// only the divider was selected, so we need to prevent paras before and after merging by double delete
				event.preventDefault()
			}
			return
		}
		// don't allow generaltyping in the divider
		if (
			dividerInSelection &&
			![...allowedHotKeysInCustomUneditableSection, "Ctrl+C", "Cmd+C"].some((allowedKey) => isHotkey(allowedKey, event))
		) {
			// don't allow typing in the divider
			event.preventDefault()
			return
		}
		return customKeyPress?.(event)
	}

	editor.renderElement = (props) => {
		return renderDividerElement(props) ?? renderElement?.(props) ?? renderParagraphElement(props)
	}

	editor.setLeafProps = (props) => {
		if (editor.selection != null) {
			// before applying marks check the start of the selection is not just before the divider as this breaks the setting (don't know why tbh)
			// if it is move the selection to the start of the divider
			const pointAfterStart = Editor.after(editor, Range.start(editor.selection))
			const [entry] = Editor.nodes(editor, {
				at: pointAfterStart,
				match: (n) => Element.isElement(n) && n.type === "divider",
			})
			if (entry != null && isObjEqual(Editor.start(editor, entry[1]), pointAfterStart)) {
				const selectionChange = { [Range.isForward(editor.selection) ? "anchor" : "focus"]: pointAfterStart }
				Transforms.setSelection(editor, { ...editor.selection, ...selectionChange })
			}
		}
		return setLeafProps?.(props)
	}

	editor.toggleBlock = (editor, format) => {
		ensureSelection(editor)
		return toggleDivider(editor, format) ?? toggleBlock?.(editor, format) ?? toggleParagraph(editor, format)
	}

	editor.blockOptions = {
		...(blockOptions ?? {}),
		...dividerOptions,
	}

	editor.apply = (op) => {
		if (op.type === "insert_text" && isBlockActive(editor, "divider")) {
			// can't use void or contentEditable=false as it will prevent the cursor from being placed in the line - so text insert blocked here
			return
		}
		apply(op)
	}

	return editor
}
