import { CustomPlugin, TaggingElementType } from "../types/slate.types"
import {
	hasAncestorOfType,
	ancestorsOfType,
	renderParagraphElement,
	makeNormalizerForMarkers,
	initialValue,
	doesBlockExist,
	toggleParagraph,
	ensureSelection,
	isAtStartOfTypes,
	isSandwiched,
	allowedHotKeysInCustomUneditableSection,
} from "../Utils"
import { BaseRange, Node, Point } from "slate"
import {
	stripTagFromFragment,
	deleteIfTemplateTagSelected,
	selectPotentialTag,
	fixMovingThroughTagInlines,
	moveForwardsOutOfTag,
	displayTagName,
	toggleTaggingBlock,
	preventPointWithinType,
	tagsInFragment,
} from "./TaggingUtils"
import { Text, Element, Editor, Transforms, Range, Descendant, NodeEntry } from "slate"
import isHotkey from "is-hotkey"
import { taggingOptions } from "./TaggingOptions"
import { isObjEqual } from "libs"
import { TaggingContextValue } from "./TaggingContext"
import { renderTaggingElement } from "./components/TaggingElement"

export const withTagging =
	(taggingContext: TaggingContextValue): CustomPlugin =>
	(editor) => {
		const {
			customOptions,
			normalizeNode,
			toggleBlock,
			renderElement,
			isInline,
			customNav,
			customKeyPress,
			apply,
			insertFragment,
			deleteForward,
			deleteBackward,
		} = editor
		editor.taggingData = taggingContext.taggingData

		editor.insertFragment = (fragment) => {
			if (
				!editor.parameters.templateMode &&
				hasAncestorOfType<TaggingElementType>(editor, editor.selection.anchor.path, "tagging")
			) {
				const [[tagAncestor]] = ancestorsOfType<TaggingElementType>(editor, editor.selection.anchor.path, "tagging")
				if (tagAncestor.nodeType === "inline") {
					editor.insertText(Node.string({ children: fragment } as Node))
					return
				}
			}
			if (!editor.parameters.templateMode) {
				// prevent pasting of tags
				fragment = stripTagFromFragment(fragment)
			}
			if (editor.parameters.templateMode && tagsInFragment(fragment)?.length > 0) {
				const tagTypes = tagsInFragment(fragment)
				for (var tagType of tagTypes) {
					if (!Object.keys(taggingContext.currentTaggingData(editor)).includes(tagType)) {
						console.log(tagType, "tag isn't allowed in this section")
						fragment = stripTagFromFragment(fragment, tagType)
					}
				}
			}
			insertFragment(fragment)
		}

		editor.customNav = (cmd, event, params) => {
			if (editor.parameters.templateMode && cmd === "Backspace") {
				// Because contentEditable is false, no normal delete command is called
				if (deleteIfTemplateTagSelected(editor)) {
					event.preventDefault() // prevent default to be sure (found cases where double deleting occurs)
				}
			}
			if (
				params.taggingContext.potentialTag.current.available &&
				!hasAncestorOfType(editor, editor.selection.anchor.path, "tagging")
			) {
				if (selectPotentialTag(cmd, event, params.taggingContext)) {
					return true
				} // return true to prevent further nav code running
			}
			fixMovingThroughTagInlines(editor, cmd, event)
			return customNav?.(cmd, event, params)
		}

		editor.customKeyPress = (event) => {
			if (
				editor.parameters.templateMode &&
				editor.selection != null &&
				hasAncestorOfType(editor, editor.selection.anchor.path, "tagging") &&
				!allowedHotKeysInCustomUneditableSection.some((allowedKey) => isHotkey(allowedKey, event))
			) {
				if (isAtStartOfTypes(editor, ["tagging"]) && (isHotkey("Enter", event) || isHotkey("Shift+Enter", event))) {
					const [[, tagPath]] = Editor.nodes(editor, { match: (n) => Element.isElement(n) && n.type === "tagging" })
					Transforms.insertNodes(editor, [{ type: "paragraph", children: [{ text: "" }] }], { at: tagPath })
					return
				}
				//prevent non nav events in tags
				moveForwardsOutOfTag(editor)
				event.preventDefault()
				return
			}
			if (
				!editor.parameters.templateMode &&
				editor.selection != null &&
				hasAncestorOfType(editor, editor.selection.anchor.path, "tagging")
			) {
				const [[tagAncestor]] = ancestorsOfType(editor, editor.selection.anchor.path, "tagging")
				if (tagAncestor.nodeType === "inline" && (isHotkey("Enter", event) || isHotkey("Shift+Enter", event))) {
					// prevent inline tags splitting
					moveForwardsOutOfTag(editor)
					return
				}
			}
			return customKeyPress?.(event)
		}

		editor.isInline = (element) => {
			return (
				element.type === "potentialTag" ||
				(element.type === "tagging" && (element.nodeType ?? editor.taggingData[element.tagging]?.type) !== "block") ||
				isInline(element)
			)
		}

		editor.renderElement = (props) => {
			return renderTaggingElement(props) ?? renderElement?.(props) ?? renderParagraphElement(props)
		}

		editor.normalizeNode = (entry) => {
			const [node, path] = entry
			if (Element.isElement(node) && node.type === "tagging" && Node.string(node).length === 0) {
				// allowing tags to lose all their text nodes causes serious bugs, these should be normalised to prevent crashes
				// this was previously a possible state in some cases where the tag was deleted
				if (node.children.length === 0) {
					console.log("tag has no children, removing")
					Transforms.removeNodes(editor, { at: path })
					return
				}
			}
			if (Editor.isEditor(node) && node.children.length === 0) {
				// Necessary because delete fragment can leave nothing in editor
				console.log("fixing empty editor")
				Transforms.insertNodes(editor, initialValue(), { at: [0] })
				ensureSelection(editor)
				return
			}
			if (!Editor.isEditor(node) && editor.parameters.templateMode && !hasAncestorOfType(editor, path, "tagging")) {
				// create potential tag element for any text containing @ when in template mode and not inside an existing tag
				makeNormalizerForMarkers({ "@": "potentialTag" })(editor, entry as NodeEntry<Descendant>)
			} else if (Element.isElement(node) && node.type === "potentialTag") {
				// potential tag made it out of templating, convert to text
				Transforms.unwrapNodes(editor, { at: path })
				return
			}
			if (editor.parameters.templateMode && Element.isElement(node) && node.type === "tagging") {
				const tagText = Node.string(node)
				const expectedTagText = displayTagName(node.tagging, editor.taggingData)
				if (tagText?.length > 0 && tagText !== expectedTagText) {
					// tag name incorrect, remove. This is likely to occur with a partial deletion
					// this enforces whole tag deletion without needing more complicated rules
					console.log("tag clean up after delete")
					Transforms.removeNodes(editor, { at: path })
					if (tagText.startsWith(expectedTagText)) {
						// add back in extra that got combined into tag
						const toAddBackIn = tagText.slice(expectedTagText.length)
						Transforms.insertNodes(editor, [{ type: "paragraph", children: [{ text: toAddBackIn }] }], { at: path })
					}
					return
				}
			}
			if (Element.isElement(node) && node.type === "tagging") {
				//prevent tag nested in tag
				if (hasAncestorOfType(editor, path, "tagging")) {
					Transforms.unwrapNodes(editor, { at: path })
				}
			}
			if (
				Element.isElement(node) &&
				node.type === "tagging" &&
				!Object.keys(editor.taggingData).includes(node.tagging)
			) {
				//prevent unknown tags (from c+p for example)
				Transforms.unwrapNodes(editor, { at: path })
			}
			if (Element.isElement(node) && node.type === "tagging" && node.nodeType === "block") {
				if (Text.isTextList(node.children)) {
					// block tag with text children, wrap inner in paragraph
					Transforms.wrapNodes(
						editor,
						{ type: "paragraph", children: [] },
						{ at: path, match: (n) => node.children.includes(n) }
					)
					return
				}
			}
			normalizeNode(entry)
		}

		editor.toggleBlock = (editor, format) => {
			ensureSelection(editor)
			return toggleTaggingBlock(editor, format) ?? toggleBlock?.(editor, format) ?? toggleParagraph(editor, format)
		}

		editor.customOptions = {
			...(customOptions ?? {}),
			...(editor.parameters.templateMode ? taggingOptions : {}),
		}

		editor.deleteBackward = (unit) => {
			if (
				unit === "character" &&
				Range.isCollapsed(editor.selection) &&
				editor.selection.anchor != null &&
				Point.equals(editor.selection.anchor, Editor.start(editor, []))
			) {
				// ensures deleting at an empty tag in the first position of the editor removes the tag
				const [tagEntry] = ancestorsOfType(editor, editor.selection, "tagging") // char delete so selection collapsed and only one tag possible
				if (tagEntry != null && Element.isElement(tagEntry[0])) {
					const [tag] = tagEntry
					const texts = [...Node.texts(tag)]
					if (texts?.length <= 1 && Node.string(tag)?.length === 0) {
						console.log("remove empty tag")
						Transforms.removeNodes(editor, { match: (n) => n === tag })
					}
				}
			}
			deleteBackward(unit)
		}

		editor.deleteForward = (unit) => {
			if (editor.parameters.templateMode) {
				if (
					editor.selection.anchor != null &&
					(editor.selection.focus == null || isObjEqual(editor.selection.anchor, editor.selection.focus))
				) {
					const nextEntry = Editor.next(editor, { at: editor.selection.anchor })
					if (nextEntry) {
						const [, nextPath] = nextEntry
						const levels = Editor.levels(editor, { at: nextPath, reverse: true })
						for (var [level, levelPath] of levels) {
							if (Element.isElement(level) && level.type === "tagging") {
								console.log("deleting tagging")
								Transforms.removeNodes(editor, { at: levelPath })
								return
							}
						}
					}
				}
			}

			deleteForward(unit)
		}

		editor.apply = (op) => {
			if (
				op.type === "set_selection" &&
				!editor.parameters.templateMode &&
				op.newProperties != null &&
				op.newProperties.focus != null &&
				op.newProperties.anchor != null &&
				Range.isExpanded(op.newProperties as BaseRange) &&
				isSandwiched(editor, {
					at: op.newProperties,
					match: (n) => Element.isElement(n) && n.type === "tagging" && n.nodeType === "inline",
				})
			) {
				console.log("unsandwich tag")
				const oldPropChange = op.newProperties as BaseRange
				// prevent sandwiching range when editing inline tags
				// this prevents a triple clicked tag from inserting text outside
				op.newProperties = Range.isBackward(oldPropChange)
					? {
							anchor: Editor.before(editor, Range.end(oldPropChange)),
							focus: Editor.after(editor, Range.start(oldPropChange)),
						}
					: {
							anchor: Editor.after(editor, Range.start(oldPropChange)),
							focus: Editor.before(editor, Range.end(oldPropChange)),
						}
			}
			if (op.type === "split_node") {
				const properties = op.properties as Element
				if (properties?.type === "tagging") {
					console.log("prevent tag splitting")
					return
				}
			}
			if (editor.parameters.templateMode && op.type === "set_selection" && doesBlockExist(editor, "tagging")) {
				//Prevent selection of partial tag
				let { anchor, focus } = { ...editor.selection, ...op.newProperties }
				if (
					Range.isExpanded({ anchor, focus }) &&
					(hasAncestorOfType(editor, anchor.path, "tagging") || hasAncestorOfType(editor, focus.path, "tagging"))
				) {
					// Only apply selection change if selection is not collapsed and an endpoint lies within a tag
					const orderedAnchor = Range.start({ anchor, focus })
					const ordered = orderedAnchor === anchor
					anchor = preventPointWithinType(editor, anchor, "tagging", { moveToStart: ordered })
					focus = preventPointWithinType(editor, focus, "tagging", { moveToStart: !ordered })
					op.newProperties = { anchor, focus }
				}
			}
			apply(op)
		}

		return editor
	}
