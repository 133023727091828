import { TableElement } from "./components/TableElement"
import { TableRow } from "./components/TableRow"
import { TableCell } from "./components/TableCell"

export const renderTableElement = ({ attributes, children, element }) => {
	switch (element.type) {
		case "table":
			return (
				<TableElement element={element} {...attributes} {...(element?.props ?? {})}>
					{children}
				</TableElement>
			)
		case "table-row":
			return (
				<TableRow element={element} {...attributes} {...(element?.props ?? {})}>
					{children}
				</TableRow>
			)
		case "table-cell":
			return (
				<TableCell element={element} {...attributes} {...(element?.props ?? {})}>
					{children}
				</TableCell>
			)
		case "table-paragraph":
			return (
				<div className="table-p" {...attributes} {...(element?.props ?? {})}>
					{children}
				</div>
			)
		default:
			return null
	}
}
