import { HeaderInfo, MarginSet } from "../DatabaseObjects/DatabaseObjects"

export const MARGIN_INFO = {
	marginTop: {
		label: "Top",
		default: (headerInfo: HeaderInfo) =>
			headerInfo?.header == null || Object.keys(headerInfo.header).length === 0 ? 20 : 20,
		min: 0,
		max: 110,
	},
	marginBottom: {
		label: "Bottom",
		default: (headerInfo: HeaderInfo) =>
			headerInfo?.footer == null || Object.keys(headerInfo.footer).length === 0 ? 20 : 20,
		min: 0,
		max: 110,
	},
	marginLeft: { label: "Left", default: () => 20, min: 0, max: 100 },
	marginRight: { label: "Right", default: () => 20, min: 0, max: 100 },
}

export function getMargin(margin: string, margins: MarginSet, headerInfo: HeaderInfo, restrictToRange = false) {
	if (margins?.[margin] == null || margins?.[margin] === "") {
		return MARGIN_INFO[margin].default(headerInfo)
	}
	if (isNaN(margins?.[margin])) {
		return MARGIN_INFO[margin].default(headerInfo)
	}
	const marginValue = parseInt(margins?.[margin]) || 0
	if (restrictToRange) {
		return Math.max(Math.min(marginValue, MARGIN_INFO[margin].max), MARGIN_INFO[margin].min)
	}
	return marginValue
}

export function getMargins(margins: MarginSet, headerInfo: HeaderInfo, restrictToRange = false) {
	return Object.fromEntries(
		Object.keys(MARGIN_INFO).map((key) => {
			return [key, getMargin(key, margins, headerInfo, restrictToRange)]
		})
	)
}
