/**
 * Recursively maps keys in a JSON object to a new key from the given keyMapping object
 * @param json
 * @param keyMapping
 * @returns
 */
export const mapKeys = (json: object, keyMapping: object) => {
	const mappedJson = {}
	if (!keyMapping) {
		return json
	}
	if (typeof json !== "object") {
		return json
	}
	if (Array.isArray(json)) {
		return json.map((item) => mapKeys(item, keyMapping))
	}
	for (const key in json) {
		if (keyMapping[key]) {
			mappedJson[keyMapping[key]] = mapKeys(json[key], keyMapping)
		} else {
			mappedJson[key] = mapKeys(json[key], keyMapping)
		}
	}
	return mappedJson
}

/**
 * Compresses a JSON object to a smaller size. First maps any given keys to a shorter key, then compresses the JSON object using brotli-compress
 * @param json The JSON object to compress
 * @returns The compressed JSON object
 */
export const reduceJsonSize = (json: object, keyMapping: object): string => {
	const mappedJson = JSON.stringify(mapKeys(json, keyMapping))
	return mappedJson.replaceAll('"', "~")
}

/**
 * Decompresses a JSON object that was compressed using reduceJsonSize
 * @param compressedJson The compressed JSON object
 * @returns The decompressed JSON object
 */
export const expandJsonSize = (jsonStr: string, keyMapping: object) => {
	const json = JSON.parse(jsonStr.replaceAll("~", '"'))
	const expandedJson = mapKeys(json, keyMapping)
	return expandedJson
}

export const REVERSE_STATE_JSON_MAPPING = {
	p: "platform",
	u: "userId",
	c: "clientId",
	e: "externalClientId",
	i: "integrationData",
	f: "platformPageProps",
	s: "sig",
	x: "externalId",
	o: "other",
	v: "vincereClientId",
	n: "newTab",
	h: "shortId",
}
export const STATE_JSON_MAPPING = Object.fromEntries(
	Object.entries(REVERSE_STATE_JSON_MAPPING).map(([key, value]) => [value, key])
)
