import { Client, FontInfoSet } from "../DatabaseObjects/DatabaseObjects"

export function fallback(fontType = "sans-serif") {
	return `${fontType}, 'Noto Color Emoji', 'Noto ${fontType === "sans-serif" ? "Sans" : "Serif"} Thai'`
}

export const FONT_INFO: FontInfoSet = {
	Anybody: { fontFamily: `Anybody, ${fallback("sans-serif")}`, normal: "400", bold: "600" }, //https://fonts.google.com/specimen/Anybody
	Arial: { fontFamily: `Arial, ${fallback("sans-serif")}`, normal: "400", bold: "600" }, // added, Web Safe
	Baskerville: { fontFamily: `Baskerville, ${fallback("serif")}`, normal: "400", bold: "600" }, //added, Web Safe
	Cambria: { fontFamily: `Cambria, ${fallback("serif")}`, normal: "400", bold: "600" }, //Web Safe
	Calibri: { fontFamily: `Calibri, ${fallback("sans-serif")}`, normal: "400", bold: "600" }, //Web Safe
	"Courier New": { fontFamily: `'Courier New', Courier, ${fallback("monospace")}`, normal: "400", bold: "600" }, //added, Web Safe
	Commissioner: { fontFamily: `'Commissioner', ${fallback("sans-serif")}`, normal: "400", bold: "600" }, //https://https://fonts.google.com/specimen/Commissioner
	Gabarito: { fontFamily: `Gabarito, ${fallback("sans-serif")}`, normal: "400", bold: "600" }, //https://fonts.google.com/specimen/Gabarito
	Georgia: { fontFamily: `Georgia, ${fallback("serif")}`, normal: "400", bold: "600" }, //added, Web Safe
	Helvetica: { fontFamily: `Helvetica, ${fallback("sans-serif")}`, normal: "400", bold: "600" }, // Web Safe
	Inter: { fontFamily: `Inter, ${fallback("sans-serif")}`, normal: "400", bold: "600" }, //https://fonts.google.com/specimen/Inter, added,
	"Inter Light": { fontFamily: `Inter, ${fallback("sans-serif")}`, normal: "300", bold: "500" }, //https://fonts.google.com/specimen/Inter, added,
	Lato: { fontFamily: `Lato, 'Lucida Grande', Tahoma, ${fallback("sans-serif")}`, normal: "400", bold: "700" }, //https://fonts.google.com/specimen/Lato
	// "Lucida Grande": { fontFamily: `Lucida Grande, ${fallback('sans-serif')}`, normal: "300", bold: "600" }, //added, Web Safe
	Montserrat: { fontFamily: `'Montserrat', ${fallback("sans-serif")}`, normal: "400", bold: "600" }, //https://fonts.google.com/specimen/Montserrat
	"Noto Sans": { fontFamily: `'Noto Sans', ${fallback("sans-serif")}`, normal: "400", bold: "600" }, //https://fonts.google.com/noto/specimen/Noto+Sans
	Nunito: { fontFamily: `'Nunito', ${fallback("sans-serif")}`, normal: "400", bold: "600" }, //https://fonts.google.com/specimen/Nunito
	"Open Sans": { fontFamily: `'Open Sans', ${fallback("sans-serif")}`, normal: "400", bold: "600" }, //https://fonts.google.com/specimen/Open+Sans
	// "Optima": { fontFamily: `Optima, ${fallback('serif')}`, normal: "400", bold: "600" }, //added, Web Safe
	Oswald: { fontFamily: `'Oswald', ${fallback("sans-serif")}`, normal: "400", bold: "600" }, //https://fonts.google.com/specimen/Oswald
	Outfit: { fontFamily: `'Outfit', ${fallback("sans-serif")}`, normal: "400", bold: "600" }, //https://fonts.google.com/specimen/Outfit
	// "Palatino Linotype": { fontFamily: `'Palatino Linotype', Palatino, ${fallback('serif')}`, normal: "400", bold: "600" }, //added, Web Safe
	Poppins: { fontFamily: `'Poppins', ${fallback("sans-serif")}`, normal: "400", bold: "600" }, //https://fonts.google.com/specimen/Poppins
	Raleway: { fontFamily: `'Raleway', ${fallback("sans-serif")}`, normal: "400", bold: "600" }, //https://fonts.google.com/specimen/Raleway
	"Roboto Condensed": { fontFamily: `'Roboto Condensed', ${fallback("sans-serif")}`, normal: "400", bold: "600" }, //https://fonts.google.com/specimen/Roboto+Condensed
	"Roboto Mono": { fontFamily: `'Roboto Mono', ${fallback("monospace")}`, normal: "400", bold: "600" }, //https://fonts.google.com/specimen/Roboto+Mono
	Roboto: { fontFamily: `Roboto, ${fallback("sans-serif")}`, normal: "300", bold: "500" }, //https://fonts.google.com/specimen/Roboto, added
	// "Segoe UI": { fontFamily: `'Segoe UI', SegoeUI, ${fallback('sans-serif')}`, normal: "400", bold: "600" }, //Web Safe
	"Source Sans 3": { fontFamily: `'Source Sans 3', ${fallback("sans-serif")}`, normal: "400", bold: "600" }, //https://fonts.google.com/specimen/Source+Sans+Pro
	"Times New Roman": { fontFamily: `'Times New Roman', Times, ${fallback("serif")}`, normal: "400", bold: "600" }, //added, Web Safe
	Ubuntu: { fontFamily: `'Ubuntu', ${fallback("sans-serif")}`, normal: "400", bold: "600" }, //https://fonts.google.com/specimen/Ubuntu
	Verdana: { fontFamily: `Verdana, ${fallback("sans-serif")}`, normal: "400", bold: "600" }, //added, Web Safe
}

export const DEFAULT_FONT = "Roboto"

export function loadClientFonts(client: Client) {
	const extraFonts = client?.options?.extraFonts
	if (extraFonts == null) {
		return extraFonts
	}
	return Object.entries(extraFonts).reduce((acc, [fontName, fontInfo]) => {
		const { fontFamily, normal, bold, fallbackFont } = fontInfo
		acc[fontName] = { fontFamily: `${fontFamily}, ${fallback(fallbackFont)}`, normal, bold }
		return acc
	}, {})
}

export function getAllFonts(client) {
	return { ...FONT_INFO, ...loadClientFonts(client) }
}

export function getNormalWeightClass(font, client) {
	const fonts = getAllFonts(client)
	return `normal${fonts[font]?.normal ?? fonts[DEFAULT_FONT].normal}`
}

export function getBoldWeightClass(font, client) {
	const fonts = getAllFonts(client)
	return `bold${fonts[font]?.bold ?? fonts[DEFAULT_FONT].bold}`
}

export function getFontFamily(font, client) {
	const fonts = getAllFonts(client)
	return fonts[font]?.fontFamily ?? fonts[DEFAULT_FONT].fontFamily
}

export function getAvailableFonts(client) {
	const fonts = getAllFonts(client)
	return Object.keys(fonts)
}
