let isMac = null

export function isMacOS() {
	if (isMac === null) {
		const platform = navigator?.platform
		if (platform == null) {
			return false
		}
		isMac = (platform?.indexOf("Mac") === 0 || platform === "iPhone") ?? false
	}
	return isMac
}
