import { useEffect, useState, useMemo } from "react"
import { createPortal } from "react-dom"
import { usePopper } from "react-popper"
import type { Placement } from "@popperjs/core"
import { Node, Element } from "slate"
import { useSlate, ReactEditor } from "slate-react"
import "./AIPortal.css"
import { AIPortalShowProps } from "../TextFormatting/types/slate.types"
import { placementFromAlignment, fallbackPlacements } from "./placements"
import { AIDisplay } from "./AIDisplay"
import { getBoldWeightClass, getBrandingStyles, getNormalWeightClass, useAppContext } from "libs"
import { useAnonCVContext } from "../CVViewing/CVContexts/AnonCVContext"

type TextAlignment = "left" | "center" | "right" | "justify"

export function AIPortal() {
	const { company } = useAppContext()
	const { headingTextAlignment = "center", blockTextAlignment = "left", spacing = "standard" } = useAnonCVContext()
	const editor = useSlate()
	const [showAiPortal, setShowAiPortal] = useState<AIPortalShowProps | null>(null)
	editor.setShowAiPortal = setShowAiPortal
	const [titleElement, setTitleElement] = useState<HTMLElement>(null)
	const [dropElement, setDropElement] = useState<HTMLElement>(null)
	const [words, setWords] = useState<number>(null)
	const alignment = useMemo<TextAlignment>(
		() => (titleElement?.closest('[data-slate-node="element"]') as HTMLElement)?.style?.["text-align"] ?? "left",
		[titleElement]
	)
	const placement = useMemo<Placement>(() => placementFromAlignment(alignment), [alignment])

	const { styles, attributes, update } = usePopper(titleElement, dropElement, {
		placement,
		strategy: "absolute",
		modifiers: [
			{
				name: "preventOverflow",
				options: {
					mainAxis: true,
				},
			},
			{
				name: "flip",
				options: {
					fallbackPlacements: fallbackPlacements(placement),
				},
			},
			{
				name: "offset",
				options: {
					offset: [0, 8],
				},
			},
		],
	})

	useEffect(() => {
		if (titleElement != null) {
			const rect = titleElement.getBoundingClientRect()
			if (rect.top + 200 > window.innerHeight) {
				window.scrollBy({ top: rect.bottom - 200 })
			}
		}
	}, [titleElement])

	useEffect(() => {
		if (showAiPortal == null) {
			setTitleElement(null)
			return
		}
		if (showAiPortal.tag != null) {
			const [lastElementInTag] = Node.first(showAiPortal.tag as Element, [])
			const tagElement = ReactEditor.toDOMNode(editor, lastElementInTag as Element)
			setTitleElement(tagElement)
			return
		}
		const fromDom = window.getSelection()?.anchorNode?.parentNode as HTMLElement
		if (fromDom != null && fromDom.closest != null) {
			setTitleElement(fromDom)
		} else {
			let possibleTitleElement =
				editor.selection && (ReactEditor.toDOMRange(editor, editor.selection).commonAncestorContainer as HTMLElement)
			if (possibleTitleElement == null) {
				setTitleElement(null)
				return
			}
			while (possibleTitleElement.closest == null) {
				possibleTitleElement = possibleTitleElement.parentNode as HTMLElement
			}
			setTitleElement(possibleTitleElement)
		}
		return () => {
			setTitleElement(null)
		}
	}, [showAiPortal, editor])

	const stylesForPortal = {
		withWords: {
			width: words * 8 + 40,
			height: "fit-content",
		},
		withoutWords: {
			height: "fit-content",
		},
	}

	useEffect(() => {
		if (words) {
			update?.()
		}
		return () => {
			setWords(null)
		}
	}, [words])

	return showAiPortal != null ? (
		<>
			{createPortal(
				<div
					style={{
						zIndex: 10,
						...(words ? stylesForPortal.withWords : stylesForPortal.withoutWords),
						maxWidth: Math.max(editor?.parameters?.maxTableWidth - 100, 350),
						...styles.popper,
						...getBrandingStyles({ company }),
					}}
					{...attributes.popper}
					ref={setDropElement}
					className={`text-transform-container-portal heading-text-align-${headingTextAlignment} block-text-align-${blockTextAlignment} ${getNormalWeightClass(
						company?.branding?.font,
						company
					)}${getBoldWeightClass(company?.branding?.font, company)} spacing-${spacing}`}
					onMouseDown={(e: { preventDefault: () => void }) => {
						e.preventDefault()
					}}>
					<AIDisplay
						setShowAiPortal={setShowAiPortal}
						editor={editor}
						showAiPortal={showAiPortal}
						setWords={setWords}
					/>
				</div>,
				document.body
			)}
		</>
	) : null
}
