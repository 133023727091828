import "./FontSizeDropdown.css"
import { HDropDown, HDropTitle, HDropItem } from "../HorzDropdown/HorzDropdown"
import { Placement } from "@popperjs/core"

export const DEFAULT_HEADING_FONT_SIZE = "13"
export const DEFAULT_BLOCK_FONT_SIZE = "8.5"

const POSSIBLE_FONT_SIZES = [
	"4.5",
	"5",
	"6",
	"7",
	"8",
	"8.5",
	"9",
	"9.5",
	"10",
	"11",
	"12",
	"13",
	"14",
	"15",
	"16",
	"18",
	"20",
	"22",
	"24",
	"28",
	"32",
	"36",
]

export const conversionPxToPt = (size) => {
	const floatPtSize = (parseFloat(size) * 3) / 4
	const nearestHalf = Math.round(2 * floatPtSize) / 2
	if (Math.round(floatPtSize) === Math.round(nearestHalf)) {
		// is integer
		return nearestHalf.toFixed(0)
	} else {
		// is half
		return nearestHalf.toFixed(1)
	}
}

export const FontSizeDropdownItems = ({
	testid,
	currentValue,
	setFormat,
}: {
	testid: string
	currentValue: string
	setFormat: (format: string) => void
}) => {
	return (
		<div
			data-testid={testid}
			className="font-size-options numerical-dropdown-options vertical-dropdown numeric-dropdown">
			{POSSIBLE_FONT_SIZES.map((size) => (
				<div
					data-testid={"font-size-" + String(size)}
					className={`clickable toolbar-dropdown-item font-size-option numerical-dropdown-option ${size === currentValue ? "selected-numerical-dropdown-option" : ""}`}
					key={`font-size-${size}`}
					onClick={(e) => {
						setFormat(size)
					}}>
					{size}
				</div>
			))}
		</div>
	)
}

export const FontSizeDropdown = ({
	testid,
	currentValue,
	setFormat,
	placement = "bottom",
}: {
	testid: string
	currentValue: string
	setFormat: (format: string) => void
	placement: Placement
}) => {
	return (
		<HDropDown placement={placement} closeOnClick={false} style={{ width: "fit-content" }}>
			<HDropTitle style={{ width: 40, margin: 0, border: "1px solid #aaaaaa", borderRadius: 3, textAlign: "center" }}>
				<div title="set font size" className={`clickable`}>
					{currentValue}
				</div>
			</HDropTitle>
			<HDropItem
				onClick={(e) => {
					e.stopPropagation()
					e.preventDefault()
				}}>
				<FontSizeDropdownItems testid={testid} currentValue={currentValue} setFormat={setFormat} />
			</HDropItem>
		</HDropDown>
	)
}
