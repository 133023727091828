import { FetchedFileObject, IntegrationData, PlatformIntegrationData } from "../Integrations.types"

const listFilesFromBullhorn = async (clientId: string, integrationData: IntegrationData) => {
	// Implementation here
	return []
}

const getFileFromBullhorn = async (
	clientId: string,
	integrationData: IntegrationData,
	fileFetchingDetails: any
): Promise<FetchedFileObject> => {
	// Implementation here
	return {
		fileType: "pdf",
		b64File: "file content",
		name: "file.pdf",
	}
}

export const bullhornIntegrationData: PlatformIntegrationData<
	"oauthLogin" | "checkForExisting" | "chooseFile" | "fetchFile" | "confirmationPage"
> = {
	parameterMapping: {
		clientId: "client_id",
		externalClientId: "external_client_id",
		externalId: "external_id",
		userId: "user_id",
	},
	pages: ["oauthLogin", "checkForExisting", "chooseFile", "fetchFile", "confirmationPage"],
	props: {
		oauthLogin: {
			getLoginUrl: (integrationData: IntegrationData) =>
				`https://auth.bullhornstaffing.com/oauth/authorize?client_id=${integrationData.externalClientId}&response_type=code&redirect_uri=https://${"test"}.hireara.ai/oatuhcallback`,
		},
		chooseFile: {
			listFiles: listFilesFromBullhorn,
		},
		fetchFile: {
			getFile: getFileFromBullhorn,
		},
		confirmationPage: {},
		checkForExisting: {},
	},
	initialData: {},
}
