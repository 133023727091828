import { useRef, useMemo, useCallback, CSSProperties } from "react"
import { Indexed, useStickyState } from "libs"
import cross from "components/images/grey-cross.png"
import "./SearchBar.css"

export type SearchableObject<T> = Indexed<T> | Array<T>

type SearchStringCreator<T> = (obj: T) => string

export function useSearch<T>(searchObj: SearchableObject<T>, makeSearchString: SearchStringCreator<T>, label: string) {
	// searchObj should have {id: obj}, where makeSearchString(obj) returns the string to be searched
	const [searchTerm, setSearchTermBase] = useStickyState({
		initState: "",
		storageName: `${label}_search_term`,
		sessionOnly: false,
	})
	const searchTermRef = useRef(searchTerm)
	const setSearchTerm = useCallback(
		(newSearchTerm: string) => {
			setSearchTermBase(newSearchTerm)
			searchTermRef.current = newSearchTerm
		},
		[setSearchTermBase]
	)

	const filteredObj = useMemo<SearchableObject<T>>(() => {
		const result: SearchableObject<T> =
			searchTerm === ""
				? searchObj
				: Object.entries(searchObj).reduce((objOut, [id, innerObj]) => {
						const searchString = makeSearchString(innerObj)
						if (searchString?.toLowerCase()?.includes(searchTerm.toLowerCase())) {
							objOut[id] = innerObj
						}
						return objOut
					}, {})
		return result
	}, [makeSearchString, searchObj, searchTerm])

	return [searchTermRef, setSearchTerm, filteredObj] as const
}

export function SearchBar({
	searchTermRef,
	setSearchTerm,
	className = "",
	justifyContent = "center",
}: {
	searchTermRef: React.MutableRefObject<string>
	setSearchTerm: (newSearchTerm: string) => void
	className?: string
	justifyContent?: CSSProperties["justifyContent"]
}) {
	const isEmpty = searchTermRef.current === ""
	const color = isEmpty ? "black" : "var(--ara-purple)"

	const clearSearch = () => {
		setSearchTerm("")
	}

	return (
		<div className={`search-bar ${className}`} style={{ justifyContent }}>
			<svg height={24} viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect width="96" height="96" fill="white" />
				<circle cx="36" cy="36" r="32" stroke={color} strokeWidth="8" />
				<path
					d="M86.7798 90.9327C88.3995 92.435 90.9304 92.3399 92.4327 90.7202C93.935 89.1005 93.8399 86.5696 92.2202 85.0673L86.7798 90.9327ZM92.2202 85.0673L57.7202 53.0673L52.2798 58.9327L86.7798 90.9327L92.2202 85.0673Z"
					fill={color}
				/>
			</svg>
			<input
				style={{ borderBottomColor: color, outlineColor: color, borderBottomWidth: 1 }}
				className="search-input"
				type="text"
				placeholder="Search"
				value={searchTermRef.current}
				onChange={(e) => {
					setSearchTerm(e.target.value)
				}}></input>
			<img
				style={{ opacity: isEmpty ? 0 : 1, pointerEvents: isEmpty ? "none" : "all" }}
				className="clickable"
				onClick={clearSearch}
				alt="clear"
				src={cross}></img>
		</div>
	)
}
