import { getDDMMYYYYFromDate, recruiterTagNames } from "libs"
import { TaggingDataset } from "./index.types"
import { DATE_PROCESSING_SETS, FULL_DATE_PROCESSING_SETS } from "./processingSets/DateTaggingProcessingSets"
import { DURATION_PROCESSING_SETS } from "./processingSets/DurationProcessingSets"

const generatedData: TaggingDataset = {
	todaysDate: {
		subType: "generated",
		displayName: "Today's Date",
		editable: false,
		getData: () => getDDMMYYYYFromDate(new Date()),
		processingSets: FULL_DATE_PROCESSING_SETS,
	},
}
const fullText: TaggingDataset = {
	fullText: {
		displayName: "Full CV Text",
		path: ["plainText"],
		type: "block",
	},
}

const inlineJobData: TaggingDataset = {
	jobName: {
		displayName: "Job Name",
		path: ["role_name"],
		editable: false,
	},
	jobExternalId: {
		displayName: "Job External ID",
		path: ["externalId"],
		editable: false,
		preprocessing: (value: string) => value.split("_")[1],
	},
}

const blockRoleData: TaggingDataset = {
	jobDescription: {
		displayName: "Job Description",
		path: ["jobDescription"],
		editable: false,
		type: "block",
	},
}

export const jobAssessment: TaggingDataset = {
	"job-overallRequirementSummary": {
		condition: (permissions) => permissions.fullJobDescription,
		displayName: "Overall Requirement Summary",
		path: ["tagData", "jobTagData", "overallRequirementSummary", "job-overallRequirementSummary", "text"],
		completePath: ["tagData", "jobTagData", "overallRequirementSummary", "job-overallRequirementSummary", "complete"],
		type: "block",
		subType: "ai",
	},
	"job-overallRequirementGrade": {
		condition: (permissions) => permissions.fullJobDescription,
		displayName: "Overall Requirement Summary Grade",
		path: ["tagData", "jobTagData", "overallRequirementSummary", "job-overallRequirementGrade", "text"],
		completePath: ["tagData", "jobTagData", "overallRequirementSummary", "job-overallRequirementGrade", "complete"],
		type: "inline",
		subType: "ai",
	},
}

export const requirements: TaggingDataset = {
	requirementDescription: {
		displayName: "Requirement Description",
		path: ["description"],
		type: "block",
		editable: false,
	},
	requirementName: {
		displayName: "Requirement Name",
		path: ["name"],
		type: "inline",
		editable: false,
	},
	requirementPriority: {
		displayName: "Requirement Priority",
		path: ["priority"],
		type: "inline",
		editable: false,
	},
	requirementRank: {
		displayName: "Requirement Ranking",
		path: ["rank"],
		type: "inline",
		editable: false,
	},
}

export const requirementsAIOutputs: TaggingDataset = {
	"job-array-requirementSummary": {
		displayName: "AI Requirement Summary",
		path: ["job-array-requirementSummary", "text"],
		completePath: ["job-array-requirementSummary", "complete"],
		type: "block",
		subType: "ai",
	},
	"job-array-requirementGrade": {
		displayName: "AI Requirement Grade",
		path: ["job-array-requirementGrade", "text"],
		completePath: ["job-array-requirementGrade", "complete"],
		type: "inline",
		subType: "ai",
	},
}

export const experiencesInlineData: TaggingDataset = {
	jobTitle: {
		displayName: "Job Title",
		path: ["jobTitle"],
	},
	company: {
		displayName: "Company",
		path: ["company"],
	},
	anonymisedCompany: {
		displayName: "Anonymous Company Description",
		path: ["anonymisedCompany"],
	},
	location: {
		displayName: "Location",
		path: ["location"],
	},
	startDate: {
		displayName: "Start Date",
		path: [],
		dataKeys: ["startDate", "hideStartMonths"],
		subType: "date",
		processingSets: DATE_PROCESSING_SETS,
	},
	endDate: {
		displayName: "End Date",
		path: [],
		dataKeys: ["endDate", "hideEndMonths"],
		subType: "date",
		shouldUseIsCurrent: true,
		processingSets: DATE_PROCESSING_SETS,
	},
	duration: {
		displayName: "Duration",
		path: ["duration_y"],
		processingSets: DURATION_PROCESSING_SETS,
	},
}

export const experiencesBlockData: TaggingDataset = {
	comments: {
		displayName: "Comments",
		path: ["comments"],
		type: "block",
	},
}

export const careerGapsData: TaggingDataset = {
	startDate: {
		displayName: "Start Date",
		path: [],
		dataKeys: ["startDate", "hideStartMonths"],
		subType: "date",
		processingSets: DATE_PROCESSING_SETS,
		// editable: false,
	},
	endDate: {
		displayName: "End Date",
		path: [],
		dataKeys: ["endDate", "hideEndMonths"],
		subType: "date",
		shouldUseIsCurrent: true,
		processingSets: DATE_PROCESSING_SETS,
		// editable: false,
	},
	duration: {
		displayName: "Duration",
		path: ["duration_y"],
		processingSets: DURATION_PROCESSING_SETS,
		// editable: false,
	},
	// reason: {
	// 	displayName: "Reason",
	// 	path: ["reason"],
	// 	type: "block",
	// },
}

export const educationData: TaggingDataset = {
	qualificationName: {
		displayName: "Qualification",
		path: ["qualificationName"],
	},
	institution: {
		displayName: "Institution",
		path: ["institution"],
	},
	anonymisedInstitution: {
		displayName: "Anonymous Institution Description",
		path: ["anonymisedInstitution"],
	},
	classification: {
		displayName: "Classification",
		path: ["classification"],
	},
	location: {
		displayName: "Location",
		path: ["location"],
	},
	startDate: {
		displayName: "Start Date",
		path: [],
		dataKeys: ["startDate", "hideStartMonths"],
		subType: "date",
		processingSets: DATE_PROCESSING_SETS,
	},
	endDate: {
		displayName: "End Date",
		path: [],
		dataKeys: ["endDate", "hideEndMonths"],
		subType: "date",
		shouldUseIsCurrent: true,
		processingSets: DATE_PROCESSING_SETS,
	},
	comments: {
		displayName: "Comments",
		path: ["comments"],
		type: "block",
	},
}

export const aiTagData: TaggingDataset = {
	executiveSummary: {
		displayName: "AI Executive Summary",
		path: ["tagData", "executiveSummary", "text"],
		completePath: ["tagData", "executiveSummary", "complete"],
		subType: "ai",
		type: "block",
	},
	technicalSummary: {
		displayName: "AI Technical Summary",
		path: ["tagData", "technicalSummary", "text"],
		completePath: ["tagData", "technicalSummary", "complete"],
		subType: "ai",
		type: "block",
	},
	educationSummary: {
		displayName: "AI Education Summary",
		path: ["tagData", "educationSummary", "text"],
		completePath: ["tagData", "educationSummary", "complete"],
		subType: "ai",
		type: "block",
	},
	keyCompetencies: {
		displayName: "AI Key Competencies",
		path: ["tagData", "keyCompetencies", "text"],
		completePath: ["tagData", "keyCompetencies", "complete"],
		subType: "ai",
		type: "block",
	},
	keyAchievements: {
		displayName: "AI Key Achievements",
		path: ["tagData", "keyAchievements", "text"],
		completePath: ["tagData", "keyAchievements", "complete"],
		subType: "ai",
		type: "block",
	},
}

const appInlineData: TaggingDataset = {
	externalId: {
		displayName: "External ID",
		path: ["externalId"],
		editable: false,
		preprocessing: (value: string) => value.split("_")[1],
	},
	mostRecentJobTitle: {
		displayName: "Most Recent Job Title",
		path: ["experiences", 0, "jobTitle"],
	},
	mostRecentEmployer: {
		displayName: "Most Recent Employer",
		path: ["experiences", 0, "company"],
	},
	candidateName: {
		displayName: "Candidate Name",
		path: ["candidateName"],
	},
	candidateFirstName: {
		displayName: "Candidate First Name",
		path: ["candidateFirstName"],
	},
	candidateLastName: {
		displayName: "Candidate Last Name",
		path: ["candidateLastName"],
	},
	emailAddress: {
		displayName: "Email Address",
		path: ["emailAddress"],
	},
	address: {
		displayName: "Address",
		path: ["address"],
	},
	location: {
		displayName: "Location",
		path: ["location"],
	},
	phoneNumber: {
		displayName: "Phone Number",
		path: ["phoneNumber"],
	},
	linkedUrl: {
		displayName: "LinkedIn URL",
		path: ["linkedUrl"],
	},
	languages: {
		displayName: "Languages",
		path: ["languages"],
	},
	identifer: {
		displayName: "Identifier",
		path: ["identifier"],
	},
	totalYearsExperience: {
		displayName: "Total Years Experience",
		path: ["yearsOfExperience"],
	},
}
const appBlockData: TaggingDataset = {
	skills: {
		displayName: "Skills",
		path: ["skills", "text"],
		type: "block",
	},
	interests: {
		displayName: "Interests",
		path: ["interests", "text"],
		type: "block",
	},
	personalStatement: {
		displayName: "Profile Summary",
		path: ["personalStatement"],
		type: "block",
	},
	certificates: {
		displayName: "Certificates",
		path: ["certifications"],
		type: "block",
	},
	publications: {
		displayName: "Publications",
		path: ["publications"],
		type: "block",
	},
	consultantNotes: {
		displayName: "Consultant Notes",
		path: ["tagData", "consultantNotes"],
		type: "block",
	},
}
const recruiterInfoData: TaggingDataset = {
	recruiterName: {
		displayName: recruiterTagNames.recruiterName,
		path: ["recruiterInfo", "displayName"],
	},
	companyName: {
		displayName: recruiterTagNames.companyName,
		path: ["recruiterInfo", "displayCompanyName"],
	},
	jobTitle: {
		displayName: recruiterTagNames.jobTitle,
		path: ["recruiterInfo", "displayJobTitle"],
	},
	recruiterEmail: {
		displayName: recruiterTagNames.recruiterEmail,
		path: ["recruiterInfo", "email"],
	},
	recruiterPhone: {
		displayName: recruiterTagNames.recruiterPhone,
		path: ["recruiterInfo", "phone"],
	},
	recruiterLinkedInUrl: {
		displayName: recruiterTagNames.recruiterLinkedInUrl,
		path: ["recruiterInfo", "linkedInUrl"],
	},
}

const reducedAppInlineDataForFilename: TaggingDataset = {
	externalId: {
		displayName: "External ID",
		path: ["externalId"],
		editable: false,
		preprocessing: (value: string) => value.split("_")[1],
	},
	candidateName: {
		displayName: "Candidate Name",
		path: ["candidateName"],
	},
	candidateFirstName: {
		displayName: "Candidate First Name",
		path: ["candidateFirstName"],
	},
	candidateLastName: {
		displayName: "Candidate Last Name",
		path: ["candidateLastName"],
	},
	location: {
		displayName: "Location",
		path: ["location"],
	},
	identifer: {
		displayName: "Identifier",
		path: ["identifier"],
	},
}
const reducedRecruiterInfoDataForFilename: TaggingDataset = {
	recruiterName: {
		displayName: recruiterTagNames.recruiterName,
		path: ["recruiterInfo", "displayName"],
	},
	companyName: {
		displayName: recruiterTagNames.companyName,
		path: ["recruiterInfo", "displayCompanyName"],
	},
}

export const reducedHyperlinkInlineData: TaggingDataset = {
	linkedUrl: {
		displayName: "LinkedIn URL",
		path: ["linkedUrl"],
	},
	emailAddress: {
		displayName: "Email Address",
		path: ["emailAddress"],
	},
}

export const reducedHyperlinkRecruiterInfoData: TaggingDataset = {
	recruiterEmail: {
		displayName: recruiterTagNames.recruiterEmail,
		path: ["recruiterInfo", "recruiterEmail"],
	},
}

export type TaggingDataSetNames =
	| "appInline"
	| "appBlock"
	| "appAll"
	| "recruiterInfo"
	| "reducedAppInlineDataForFilename"
	| "reducedRecruiterInfoDataForFilename"
	| "reducedHyperlinkInlineData"
	| "reducedHyperlinkRecruiterInfoData"
	| "aiTags"
	| "experiences"
	| "experiencesInline"
	| "education"
	| "requirements"
	| "requirementsAIOutputs"
	| "jobAssessment"
	| "jobData"
	| "inlineJobData"
	| "careerGaps"
	| "generatedData"
	| "fullText"

export type TaggingDatasets = {
	[key in TaggingDataSetNames]: TaggingDatasetConstructor
}

interface BaseTaggingDatasetConstructor {
	data: TaggingDataset
	condition?: (permissions: { [feature: string]: boolean }) => boolean
}

interface StandardTaggingDatasetConstructor extends BaseTaggingDatasetConstructor {
	isArray?: false
}

interface ArrayTaggingDatasetConstructor extends BaseTaggingDatasetConstructor {
	isArray: true
	arrayPath: Array<string | number>
}

interface EmbeddedArrayTaggingDatasetConstructor extends BaseTaggingDatasetConstructor {
	isEmbeddedArray: true
	arrayPath: Array<string | number>
}

type TaggingDatasetConstructor =
	| StandardTaggingDatasetConstructor
	| ArrayTaggingDatasetConstructor
	| EmbeddedArrayTaggingDatasetConstructor

export const taggingDatasets: TaggingDatasets = {
	generatedData: {
		data: generatedData,
	},
	fullText: {
		data: fullText,
	},
	jobData: {
		condition: (permissions) => permissions.fullJobDescription,
		data: { ...blockRoleData, ...inlineJobData },
	},
	inlineJobData: {
		condition: (permissions) => permissions.fullJobDescription,
		data: inlineJobData,
	},
	jobAssessment: {
		condition: (permissions) => permissions.fullJobDescription,
		data: jobAssessment,
	},
	requirements: {
		data: requirements,
		arrayPath: ["requirements"],
		isEmbeddedArray: true,
	},
	requirementsAIOutputs: {
		data: requirementsAIOutputs,
		arrayPath: ["tagData", "jobTagData", "requirements"],
		isEmbeddedArray: true,
	},
	appInline: {
		data: { ...appInlineData, ...aiTagData },
	},
	appBlock: {
		data: appBlockData,
	},
	appAll: {
		data: {
			...appInlineData,
			...appBlockData,
			...aiTagData,
			...jobAssessment,
			...generatedData,
		},
	},
	recruiterInfo: {
		data: recruiterInfoData,
	},
	reducedAppInlineDataForFilename: {
		data: reducedAppInlineDataForFilename,
	},
	reducedRecruiterInfoDataForFilename: {
		data: reducedRecruiterInfoDataForFilename,
	},
	reducedHyperlinkInlineData: {
		data: reducedHyperlinkInlineData,
	},
	reducedHyperlinkRecruiterInfoData: {
		data: reducedHyperlinkRecruiterInfoData,
	},
	aiTags: {
		data: aiTagData,
	},
	experiences: {
		data: { ...experiencesBlockData, ...experiencesInlineData },
		arrayPath: ["experiences"],
		isArray: true,
	},
	experiencesInline: {
		data: experiencesInlineData,
		arrayPath: ["experiences"],
		isArray: true,
	},
	education: {
		data: educationData,
		arrayPath: ["qualifications"],
		isArray: true,
	},
	careerGaps: {
		data: careerGapsData,
		arrayPath: ["careerGaps"],
		isArray: true,
	},
}

export type TaggingOptions = {
	[key in TaggingDataSetNames]?: {
		setFunc?: (arg0: object) => void
		dataSource: object
		arrayIndex?: number
	}
}

// need to map the tag names to the correct combined tag name
export const TAG_MAPPINGS = {
	"job-array-requirementGrade": "job-array-requirementGradedSummary",
	"job-array-requirementSummary": "job-array-requirementGradedSummary",
	"job-overallRequirementGrade": "job-overallRequirementGradedSummary",
	"job-overallRequirementSummary": "job-overallRequirementGradedSummary",
}
