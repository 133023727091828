import { createContext, useContext } from "react"

const TableContext = createContext<TableContextProps>({})

export const TableContextProvider = ({ children, ...value }: TableContextProps & { children: React.ReactNode }) => {
	return <TableContext.Provider value={value}>{children}</TableContext.Provider>
}

export const useTableContext = () => useContext(TableContext)

export type EdgeHighlightTuple = [string | null, number | null, number | null]

interface TableContextProps {
	shapeRef?: React.MutableRefObject<number[]>
	activeRef?: React.MutableRefObject<HTMLDivElement>
	changeRef?: React.MutableRefObject<number>
	tableRef?: React.MutableRefObject<HTMLDivElement>
	colWidths?: number[]
	exactColWidths?: number[]
	setColWidths?: (colWidths: number[]) => void
	resetColWidths?: () => void
	allowAddRows?: boolean
	addNew?: (change: [number, number], at?: Array<number>) => (e: React.MouseEvent) => void
	edgeHighlighting?: EdgeHighlightTuple
	setEdgeHighlighting?: (edgeHighlighting: EdgeHighlightTuple) => void
	blockedRows?: number[]
	blockedCols?: number[]
}
